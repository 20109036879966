import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { Comments } from "@/containers/comments/Comments";
import { ErrorPage } from "@components/ErrorPage";
import { useCommentsContext } from "@/containers/comments/comments.context";
import { CommentsSkeleton } from "@/containers/comments/CommentsSkeleton";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";

/**
 * Renders the Comments page wrapped in App context.
 * Handles loading logic for comments and user data.
 */
const CommentsPage = () => {
  const { isLoadingUser } = useUserContext();
  const { isLoadingInitialData: isLoadingComments } = useCommentsContext(); // Use loading state from comments context
  const { isLoadingSubscriptionDetails } = useSubscriptionContext();

  const isLoading: boolean =
    isLoadingUser || isLoadingComments || isLoadingSubscriptionDetails;

  return (
    <AppWrapper isLoading={isLoading} loadingSkeleton={<CommentsSkeleton />}>
      <Comments />
    </AppWrapper>
  );
};

export default CommentsPage;

/**
 * Error boundary handler component.
 */
export const Catch = () => {
  console.error("Error loading CommentsPage");
  return <ErrorPage />;
};
