// useSetOnboardingDone.ts
import { useFetcher } from "@axios/fetcher";
import { User } from "@types";

function useSetOnboardingDone() {
  const { fetcher } = useFetcher();

  const setOnboardingDone = async (user_id: string) => {
    const updatedUser = await fetcher(
      `/api/users/${user_id}/onboarding-done`,
      "patch",
      {},
    );

    return updatedUser as User;
  };

  return {
    setOnboardingDone,
  };
}

export { useSetOnboardingDone };
