// src/pages/DashboardPage.tsx

import { ErrorPage } from "@components/ErrorPage";
import { Dashboard } from "@containers/dashboard/Dashboard";
import { DashboardSkeleton } from "@containers/dashboard/DashboardSkeleton";
import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { DashboardProvider, useDashboard } from "@/layouts/Dashboard.context";

const DashboardContent = () => {
  const { isLoading, error } = useDashboard();
  const { isLoadingUser } = useUserContext();

  // Combine loading states
  const isLoadingCombined = isLoadingUser || isLoading;

  if (error) {
    return <ErrorPage />;
  }

  return (
    <AppWrapper
      isLoading={isLoadingCombined}
      loadingSkeleton={<DashboardSkeleton />}
    >
      <Dashboard />
    </AppWrapper>
  );
};

const DashboardPage = () => {
  return (
    <DashboardProvider>
      <DashboardContent />
    </DashboardProvider>
  );
};

export default DashboardPage;

export const Catch = () => {
  return <ErrorPage />;
};
