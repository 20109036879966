// useUserWorkspaces.ts
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { WorkspaceDetails } from "@types";
import { useState } from "react";

function useUserWorkspaces(user_id: string, enabled = false) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  // Build the cache key as a query string
  const queryString = new URLSearchParams({ user_id: user_id }).toString();
  const cacheKey = `/api/workspaces?${queryString}`;

  const { data, error, mutate, isValidating, isLoading } = useSWR<
    WorkspaceDetails[]
  >(cacheKey, (url: string) => fetcher(url, "get", null, false, enabled));

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<WorkspaceDetails[]> => {
    setIsRefetching(true);
    const updatedData = await mutate(); // Try to get updated data
    setIsRefetching(false);

    // If no updated data, return the current cached data
    if (!updatedData) {
      if (data) {
        return data; // Return cached data
      } else {
        throw new Error("No data available after refetching.");
      }
    }

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as WorkspaceDetails[],
    isLoading: isLoading || (!data && !error && !isValidating), // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useUserWorkspaces };
