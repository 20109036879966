// src/axios/mutations/useUpdateActiveAdAccounts.ts
import { useFetcher } from "@axios/fetcher";
import { Channel } from "@types"; // Ensure Channel type is imported

/**
 * Hook to update the activeAdAccounts for a channel.
 */
function useUpdateActiveAdAccounts() {
  const { fetcher } = useFetcher();

  /**
   * Function to update activeAdAccounts by making a PATCH request to the API.
   * @param workspace_id - The workspace ID.
   * @param channel_id - The channel ID.
   * @param activeAdAccounts - The new mapping of ad account IDs to booleans.
   * @returns The updated Channel object.
   */
  const updateActiveAdAccounts = async ({
    workspace_id,
    channel_id,
    activeAdAccounts,
  }: {
    workspace_id: string;
    channel_id: string;
    activeAdAccounts: { [key: string]: boolean };
  }): Promise<Channel> => {
    try {
      const updatedChannel = await fetcher(
        `/api/channels/${workspace_id}/${channel_id}/settings/active-ad-accounts`,
        "patch",
        { activeAdAccounts },
      );
      return updatedChannel as Channel;
    } catch (error) {
      console.error("Failed to update active ad accounts", {
        workspace_id,
        channel_id,
        error,
      });
      throw error;
    }
  };

  return {
    updateActiveAdAccounts,
  };
}

export { useUpdateActiveAdAccounts };
