// useUpdateLanguage.ts
import { useFetcher } from "@axios/fetcher";
import { User } from "@types";

function useUpdateLanguage() {
  const { fetcher } = useFetcher();

  const updateLanguage = async (user_id: string, language: string) => {
    const updatedUser = await fetcher(
      `/api/users/${user_id}/language`,
      "patch",
      { language },
    );

    return updatedUser as User;
  };

  return {
    updateLanguage,
  };
}

export { useUpdateLanguage };
