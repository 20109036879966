// Step2CreateWorkspace.tsx

import { useState } from "react";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { useCreateWorkspace } from "@axios/mutations/useCreateWorkspace";
import { useCreateMembership } from "@axios/mutations/useCreateMembership";
import { useUserWorkspaces } from "@axios/queries/useUserWorkspaces";
import { User, Workspace, WorkspaceDetails } from "@types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AiIcon, Loader } from "@/assets/icons";
import { AppPreview } from "@/assets";

import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { useTranslation } from "react-i18next";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useAnalytics } from "@segment/analytics-react";

type Step2CreateWorkspaceProps = {
  onNext: (data: { workspaceName: string; teamMembers: string[] }) => void;
  onPrevious: () => void;
  initialData: { workspaceName: string; teamMembers: string[] };
};

const Step2CreateWorkspace = ({
  onNext,
  onPrevious,
  initialData,
}: Step2CreateWorkspaceProps) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { track } = useAnalytics();

  const { user, refetchWorkspaces, changeLanguage, language } =
    useUserContext();
  const { changeWorkspace } = useWorkspaceContext();

  const [isLoading, setIsLoading] = useState(false);

  const [workspaceName, setWorkspaceName] = useState(
    initialData.workspaceName || "",
  );
  const [teamMembers, setTeamMembers] = useState<string[]>(
    initialData.teamMembers || [],
  );
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");

  const { data: userWorkspaces } = useUserWorkspaces(
    user?.user_id as string,
    true,
  );

  const { createWorkspace } = useCreateWorkspace();
  const { createMembership } = useCreateMembership();

  // Get the current workspace if it exists and the user is the owner
  const currentWorkspace = userWorkspaces?.find(
    (ws) => ws.name === workspaceName && ws.owner_user_id === user?.user_id,
  );

  const handleEmailInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Enter" || e.key === "," || e.key === "Tab") {
      e.preventDefault();
      addEmailToTeam();
    }
  };

  const addEmailToTeam = () => {
    const email = emailInput.trim().toLowerCase();

    console.debug("Email:", email);

    if (!email) {
      setEmailError(t("onboarding.step2_create_workspace.empty_email_error"));
      return false;
    } else if (!validateEmail(email)) {
      setEmailError(t("onboarding.step2_create_workspace.invalid_email_error"));
      return false;
    } else if (email == user?.email) {
      setEmailError(t("onboarding.step2_create_workspace.self_email_error"));
      return false;
    } else if (teamMembers.includes(email)) {
      setEmailError(t("onboarding.step2_create_workspace.email_exists_error"));
      return false;
    } else {
      setTeamMembers([...teamMembers, email]);
      setEmailInput("");
      setEmailError("");
      return [...teamMembers, email];
    }
  };

  const handleRemoveMember = (emailToRemove: string) => {
    setTeamMembers(teamMembers.filter((email) => email !== emailToRemove));
  };

  const validateEmail = (email: string) => {
    // Simple email validation regex
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);

      let newTeamMembers: false | string[] = Array.from(new Set(teamMembers));

      if (emailInput.trim()) {
        newTeamMembers = addEmailToTeam();
        if (!newTeamMembers) {
          return;
        }
      }

      // Filter out any empty team member emails
      const filteredTeamMembers = newTeamMembers.filter(
        (email) => email.trim() !== "",
      );

      // If workspace does not exist, create it
      let newWorkspace: Workspace | WorkspaceDetails =
        currentWorkspace as WorkspaceDetails;

      if (!currentWorkspace) {
        newWorkspace = (await createWorkspace(
          workspaceName && workspaceName.trim() !== ""
            ? workspaceName
            : (user?.name as string),
          user?.user_id as string,
        )) as Workspace;
        console.debug("Workspace created:", newWorkspace);

        const { user_id, source, referral_id } = user as User;

        track("Create Workspace", {
          user_id: user_id,
          workspace_id: newWorkspace._id,
          utm_source: source?.utm_source,
          utm_medium: source?.utm_medium,
          utm_campaign: source?.utm_campaign,
          referral_id: referral_id,
          timestamp: new Date().toISOString(),
        });
      }

      // Invite team members
      await Promise.all(
        filteredTeamMembers.map(async (email) => {
          await createMembership({
            user_id: user?.user_id as string,
            workspace_id: newWorkspace._id,
            email,
            role: "member",
          });
        }),
      );

      await changeWorkspace(newWorkspace._id);
      await refetchWorkspaces();

      // Update form data with the final state of members
      const updatedFormData = {
        workspaceName,
        teamMembers: filteredTeamMembers,
      };
      localStorage.setItem("onboardingData", JSON.stringify(updatedFormData));

      // Proceed to the next step
      onNext(updatedFormData);
    } catch (error) {
      console.error("Error creating workspace or inviting members:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isStepSkipped = !workspaceName && teamMembers.length === 0;

  return (
    <div className="flex h-screen w-screen items-center justify-center space-x-10 overflow-hidden bg-light-grey">
      {/* Left Section: Create Workspace & Invite Team Form */}
      <div className="flex h-full grow flex-col items-center justify-center p-8">
        <div className="flex size-full flex-col justify-center overflow-y-auto rounded-xl bg-white p-10 scrollbar-hide md:px-[20%] md:py-32 lg:justify-start">
          <AiIcon className="mb-8 size-10 shrink-0" />
          <h2 className="mb-4 text-2xl font-bold text-primary">
            {t("onboarding.step2_create_workspace.title")}
          </h2>

          <p className="mb-10 text-left text-base font-semibold text-dark-grey">
            {t("onboarding.step2_create_workspace.description")}
          </p>

          {/* Workspace Name */}
          <div className="mb-6">
            <label className="mb-3 block text-sm font-medium text-dark-grey">
              {t("onboarding.step2_create_workspace.workspace_name")}
            </label>
            <Input
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder={t(
                "onboarding.step2_create_workspace.enter_workspace_name",
              )}
              className="mt-1 w-full rounded-lg border border-light-grey p-3"
              disabled={!!currentWorkspace} // Disable if workspace is already created
            />
          </div>

          {/* Team Members */}
          <div className="mb-8">
            <label
              className={`block text-sm font-medium text-dark-grey ${teamMembers?.length ? "mb-4" : "mb-3"}`}
            >
              {t("onboarding.step2_create_workspace.team_members_email")}
            </label>

            {teamMembers?.length ? (
              <div className="mb-4 flex flex-wrap items-center gap-2">
                {Array.from(new Set(teamMembers))?.map((email) => (
                  <Badge
                    key={email}
                    variant="secondary"
                    className="flex items-center space-x-1"
                  >
                    <span className="mb-0.5">{email}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveMember(email)}
                      className="mb-1 ml-1 text-sm text-dark-grey hover:text-primary"
                    >
                      ×
                    </button>
                  </Badge>
                ))}
              </div>
            ) : null}

            <Input
              type="email"
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
                if (emailError) {
                  setEmailError("");
                }
              }}
              onKeyDown={handleEmailInputKeyDown}
              placeholder={t(
                "onboarding.step2_create_workspace.enter_team_emails",
              )}
              className={`mt-1 w-full rounded-lg border p-3 ${
                emailError ? "border-red" : "border-light-grey"
              }`}
            />
            {emailError ? (
              <p className="mt-2 text-sm text-red">{emailError}</p>
            ) : (
              <p className="mt-2 text-sm text-dark-grey">
                {t("onboarding.step2_create_workspace.add_email_hint")}
              </p>
            )}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              className="w-44 rounded-lg bg-light-grey px-4 py-3 text-base font-semibold text-primary hover:scale-[102%] active:scale-95"
              onClick={onPrevious}
            >
              {t("onboarding.step2_create_workspace.previous")}
            </button>
            <button
              className={`flex w-44 items-center justify-center rounded-lg px-4 py-3 text-base font-semibold text-white hover:scale-[102%] active:scale-95 ${
                isLoading
                  ? "cursor-not-allowed bg-dark-grey text-light-grey"
                  : isStepSkipped
                    ? "bg-dark-grey text-light-grey"
                    : "bg-primary"
              }`}
              onClick={handleNext}
            >
              {isStepSkipped
                ? t("onboarding.step2_create_workspace.skip")
                : t("onboarding.step2_create_workspace.next")}
              {isLoading && (
                <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Right Section: App Preview Image */}
      <div className="hidden h-[85%] items-center justify-end py-8 lg:flex">
        <img
          src={AppPreview}
          alt="App preview"
          className="max-h-full max-w-full rounded-xl transition-transform"
        />

        <div className="absolute bottom-8 right-32 flex shrink-0 items-center justify-center space-x-5 text-nowrap">
          <Select
            value={language}
            onValueChange={async (value) => {
              await changeLanguage(value);
            }}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language == "en" ? "English" : "Français"}
              />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
            </SelectContent>
          </Select>

          <button
            onClick={() => logout()}
            className="font-medium text-primary hover:underline active:brightness-110"
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export { Step2CreateWorkspace };
