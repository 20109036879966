// WorkspaceSettings.tsx
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { Button } from "@/components/ui/button";
import { MemberDetails } from "@/types";
import {
  useCreateMembership,
  useDeleteWorkspace,
  useUpdateMembershipStatus,
  useUpdateWorkspaceName,
} from "@/axios/mutations";
import MembersTable from "@/components/workspace-settings/MembersTable";
import AddMemberDialog from "@/components/workspace-settings/AddMemberDialog";
import AddWorkspaceDialog from "@/components/workspace-settings/AddWorkspaceDialog";

import DeleteWorkspaceDialog from "@/components/workspace-settings/DeleteWorkspaceDialog";
import { Input } from "@/components/ui/input";
import { EditPen, Loader } from "@/assets/icons";
import { useTranslation } from "react-i18next";
import RemoveMemberAlertDialog from "@/components/workspace-settings/RemoveMemberAlertDialog";

const WorkspaceSettings = () => {
  const { t } = useTranslation();

  const { user, refetchWorkspaces, workspaces } = useUserContext();
  const { currentWorkspace } = useWorkspaceContext();
  const { createMembership } = useCreateMembership();
  const { updateMembershipStatus } = useUpdateMembershipStatus();

  const [workspaceName, setWorkspaceName] = useState(
    currentWorkspace?.name || "",
  );

  const { deleteWorkspace } = useDeleteWorkspace();
  const { updateWorkspaceName } = useUpdateWorkspaceName();

  const [members, setMembers] = useState<MemberDetails[]>(
    currentWorkspace?.members.filter(
      (member) => member.status === "active" || member.status === "pending",
    ) || [],
  );

  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = useState(false);
  const [isDeleteWorkspaceModalOpen, setIsDeleteWorkspaceModalOpen] =
    useState(false);

  const [isQuitWorkspaceModalOpen, setIsQuitWorkspaceModalOpen] =
    useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isNameLoading, setIsNameLoading] = useState(false);

  const handleAddMember = async (email: string, name?: string) => {
    // Check if the member already exists
    if (members.find((member) => member.email === email)) {
      throw new Error("This user is already a member of the workspace.");
    }

    // Create membership
    const newMembership = await createMembership({
      user_id: user?.user_id as string,
      workspace_id: currentWorkspace?._id as string,
      email,
      role: "member",
    });

    setMembers([
      ...members,
      {
        user_id: "",
        name: name || "",
        email,
        membership_id: newMembership._id,
        role: newMembership.role,
        is_payer: newMembership.is_payer,
        status: newMembership.status,
      },
    ]);
  };

  const handleRemoveMember = async (member: MemberDetails) => {
    setMembers(members.filter((m) => m.email !== member.email));
    await updateMembershipStatus(member.membership_id as string, "canceled");
  };

  const handleNameChange = async () => {
    if (workspaceName.trim() === "") return;

    setIsNameLoading(true);
    try {
      await updateWorkspaceName(currentWorkspace?._id as string, workspaceName);
      await refetchWorkspaces();
      setIsEditingName(false);
    } finally {
      setIsNameLoading(false);
    }
  };

  const handleDeleteWorkspace = async () => {
    // Delete the workspace
    try {
      await deleteWorkspace(currentWorkspace?._id as string);
      await refetchWorkspaces();

      setIsDeleteWorkspaceModalOpen(false);

      // Reload Page
      window.location.reload();
    } catch (error) {
      // Handle error
      console.error("Error deleting workspace:", error);
    }
  };

  const handleConfirmQuit = async () => {
    setIsQuitWorkspaceModalOpen(false);
    handleRemoveMember(
      members.find((m) => m.email === user?.email) as MemberDetails,
    );

    // Reload Page
    window.location.reload();
  };

  useEffect(() => {
    setMembers(
      currentWorkspace?.members.filter(
        (member) => member.status === "active" || member.status === "pending",
      ) || [],
    );

    setWorkspaceName(currentWorkspace?.name || "");
  }, [currentWorkspace]);

  useEffect(() => {
    if (workspaceName != currentWorkspace?.name) {
      setIsEditingName(true);
    } else {
      setIsEditingName(false);
    }
  }, [setIsEditingName, workspaceName, currentWorkspace?.name]);

  return (
    <div className="flex h-full overflow-hidden bg-light-grey">
      <div className="flex h-full max-w-fit flex-col rounded-[12px] bg-white p-8">
        <div>
          <div className="mb-2 flex items-center justify-between">
            <h2 className="text-base font-semibold text-primary">
              {t("settings.workspace.title")}
            </h2>

            <button
              onClick={() => setIsAddWorkspaceModalOpen(true)}
              className="flex items-center justify-center rounded-[10px] bg-light-violet px-3.5 py-2.5 text-xs font-semibold text-primary hover:brightness-110"
            >
              <span>
                {t("settings.workspace.add_workspace")}
                <span className="ml-1 text-xs font-semibold text-primary">
                  +
                </span>
              </span>
            </button>
          </div>

          <p className="mb-10 max-w-[60%] text-left text-sm font-semibold text-dark-grey">
            {t("settings.workspace.instructions")}
          </p>

          {/* Workspace Name */}
          <div className="mb-10">
            <Label
              htmlFor="workspaceName"
              className="mb-4 block text-sm font-semibold text-primary"
            >
              {t("settings.workspace.workspace_name")}
            </Label>

            <div className="relative">
              <Input
                id="workspaceName"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                placeholder={t("settings.workspace.name_placeholder")}
                className="w-full rounded-lg border border-light-grey p-3 text-primary"
                disabled={isNameLoading}
              />
              <EditPen className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-primary" />
            </div>

            {isEditingName && (
              <div className="mt-4 flex space-x-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setWorkspaceName(currentWorkspace?.name || "");
                    setIsEditingName(false);
                  }}
                  className="min-w-24 px-2 font-semibold"
                >
                  {t("settings.workspace.cancel")}
                </Button>

                <Button
                  onClick={handleNameChange}
                  disabled={isNameLoading || workspaceName.trim() === ""}
                  className="min-w-24 px-2 font-semibold disabled:cursor-not-allowed"
                >
                  {t("settings.workspace.save")}
                  {isNameLoading && (
                    <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* MembersTable Section */}
        <div className="mt-2 flex grow flex-col overflow-y-auto">
          <div>
            {/* Label */}
            <Label className="mb-2 block text-sm font-semibold text-primary">
              {t("settings.workspace.members_list")}
            </Label>

            {/* MembersTable Component */}
            <MembersTable
              members={members}
              currentUserEmail={user?.email as string}
              onRemoveMember={handleRemoveMember}
            />

            {/* Add member button */}
            <div className="mt-4">
              <button
                onClick={() => setIsAddMemberModalOpen(true)}
                className="w-full items-center justify-center rounded-[8px] border border-light-grey bg-white py-2 text-center text-sm font-semibold text-primary hover:brightness-90"
              >
                {t("settings.workspace.add_member")}
              </button>
            </div>
          </div>
        </div>

        {/* Delete Workspace Button aligned at the bottom */}

        {workspaces && workspaces.length > 1 && (
          <div className="mt-10 flex justify-end">
            <Button
              variant="destructive"
              onClick={() => {
                if (currentWorkspace?.owner_user_id === user?.user_id) {
                  setIsDeleteWorkspaceModalOpen(true);
                } else {
                  setIsQuitWorkspaceModalOpen(true);
                }
              }}
              className="bg-red text-white hover:bg-red hover:brightness-90"
            >
              {currentWorkspace?.owner_user_id === user?.user_id
                ? t("settings.workspace.delete_workspace")
                : t("settings.workspace.quit_workspace")}
            </Button>
          </div>
        )}
        {/* Add Member Dialog */}
        <AddMemberDialog
          isOpen={isAddMemberModalOpen}
          onClose={() => setIsAddMemberModalOpen(false)}
          onAddMember={handleAddMember}
        />

        {/* Add Workspace Dialog */}
        <AddWorkspaceDialog
          isOpen={isAddWorkspaceModalOpen}
          onClose={() => setIsAddWorkspaceModalOpen(false)}
        />

        {/* Delete Workspace Dialog */}
        <DeleteWorkspaceDialog
          isOpen={isDeleteWorkspaceModalOpen}
          onClose={() => setIsDeleteWorkspaceModalOpen(false)}
          onConfirm={handleDeleteWorkspace}
          workspaceName={currentWorkspace?.name}
        />

        {/* Quit Workspace Dialog */}
        <RemoveMemberAlertDialog
          isOpen={isQuitWorkspaceModalOpen}
          onClose={() => setIsQuitWorkspaceModalOpen(false)}
          onConfirm={handleConfirmQuit}
          memberEmail={user?.email}
        />
      </div>
    </div>
  );
};

export default WorkspaceSettings;
