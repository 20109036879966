// useOrganization.ts
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { Organization } from "@types";
import { useState } from "react";

function useOrganization(user_id: string, enabled = true) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  const queryString = new URLSearchParams({
    owner_user_id: user_id,
  }).toString();
  const cacheKey = `/api/organizations?${queryString}`;

  const { data, error, mutate, isLoading } = useSWR<Organization>(
    cacheKey,
    (url: string) => fetcher(url, "get", null, false, enabled),
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<Organization> => {
    setIsRefetching(true);
    const updatedData = await mutate(); // Try to get updated data
    setIsRefetching(false);

    // If no updated data, return the current cached data
    if (!updatedData) {
      if (data) {
        return data; // Return cached data
      } else {
        throw new Error("No data available after refetching.");
      }
    }

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as Organization,
    isLoading: isLoading, // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useOrganization };
