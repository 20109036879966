import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";
import { AnalyticsFilter, TimeSavedStats } from "@/types";
import { getKey } from "../get_query_key.util";

function useTimeSavedStats(
  workspace_id: string,
  filter?: AnalyticsFilter,
  enabled = true,
) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false);

  // Construct the cache key based on the workspace_id
  const shouldFetch = enabled && workspace_id !== undefined;

  const cacheKey = shouldFetch
    ? `/api/analytics/time-saved/${workspace_id}${filter ? `?${getKey(filter)}` : ""}`
    : undefined;

  const { data, error, mutate, isLoading } = useSWR<TimeSavedStats>(
    enabled ? cacheKey : null,
    (url: string) => fetcher(url, "get"),
  );

  const refetch = async (): Promise<TimeSavedStats | undefined> => {
    setIsRefetching(true);
    const updatedData = await mutate();
    setIsRefetching(false);

    if (!updatedData && data) {
      return data;
    }

    if (!updatedData) {
      throw new Error("No data available after refetching.");
    }

    return updatedData;
  };

  return {
    data: data as TimeSavedStats,
    isLoading,
    isRefetching,
    error,
    refetch,
  };
}

export { useTimeSavedStats };
