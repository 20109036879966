// utils/randomPastelColor.ts
import { hashString } from "./hashString";

/**
 * Generates a consistent pastel color based on a given key (e.g., username),
 * now using MurmurHash3 for better hash distribution.
 */
export const getRandomPastelColor = (key: string): string => {
  const salt = "random_salt_string"; // Optional salt for added randomness
  const hash = hashString(key + salt); // Generate a MurmurHash from the salted key

  // Hue remains between 0 and 360 degrees
  const hue = hash % 360;

  // Add slight variation in saturation and lightness for uniqueness
  const saturation = 40 + (hash % 20); // Between 40% and 60%
  const lightness = 75 + (hash % 15); // Between 75% and 90%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
