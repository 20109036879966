export interface Classification {
  sentiment: "Positive" | "Negative" | "Neutral";
  category:
    | "Supportive"
    | "Direct Interactions"
    | "Humor"
    | "Questions"
    | "Business"
    | "Feedback"
    | "Sharing"
    | "Complaint"
    | "Sensitive Content";
}

// Map sentiment to specific emojis (Apple-style)
export const sentimentEmojis: Record<Classification["sentiment"], string> = {
  Positive: "😊",
  Neutral: "😐",
  Negative: "😡",
};

// Map category to specific emojis (Apple-style)
export const categoryEmojis: Record<Classification["category"], string> = {
  Supportive: "👍", // Supportive
  "Direct Interactions": "😍", // Direct Interactions
  Humor: "😂", // Humor
  Questions: "❓", // Questions
  Business: "💼", // Business
  Feedback: "💬", // Feedback
  Sharing: "🔗", // Sharing
  Complaint: "⚠️", // Complaint
  "Sensitive Content": "🚫", // Sensitive Content & Spam
};
