// useAuthEndpoints.ts

import { useFetcher } from "@axios/fetcher";

/**
 * Hook to call the send-verification endpoint.
 * Sends a verification code to the user's email.
 *
 * @returns A function that accepts an email and returns a Promise<boolean>
 */
function useSendVerification() {
  const { fetcher } = useFetcher();

  const sendVerification = async (email: string): Promise<boolean> => {
    if (!email) {
      throw new Error("Email is required to send verification code");
    }

    // POST request to /api/auth/send-verification with email as payload.
    const response: boolean = await fetcher(
      `/api/auth/send-verification`,
      "post",
      { email },
    );
    return response;
  };

  return {
    sendVerification,
  };
}

/**
 * Hook to call the verify-code endpoint.
 * Verifies the code provided by the user and marks their email as verified.
 *
 * @returns A function that accepts an email and code and returns a Promise<boolean>
 */
function useVerifyCode() {
  const { fetcher } = useFetcher();

  const verifyCode = async (email: string, code: string): Promise<boolean> => {
    if (!email || !code) {
      throw new Error("Both email and code are required for verification");
    }

    // POST request to /api/auth/verify-code with email and code as payload.
    const response: boolean = await fetcher(`/api/auth/verify-code`, "post", {
      email,
      code,
    });
    return response;
  };

  return {
    verifyCode,
  };
}

/**
 * Hook to call the request-reset-password endpoint.
 * Requests a password reset link to be sent to the user's email.
 *
 * @returns A function that accepts an email and returns a Promise<boolean>
 */
function useRequestResetPassword() {
  const { fetcher } = useFetcher();

  const requestResetPassword = async (email: string): Promise<boolean> => {
    if (!email) {
      throw new Error("Email is required to request a reset password link");
    }

    // POST request to /api/auth/request-reset-password with email as payload.
    const response: boolean = await fetcher(
      `/api/auth/request-reset-password`,
      "post",
      { email },
    );
    return response;
  };

  return {
    requestResetPassword,
  };
}

export { useSendVerification, useVerifyCode, useRequestResetPassword };
