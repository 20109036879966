// useOrganizationLicenses.ts

import { useFetcher } from "@axios/fetcher";

function useOrganizationLicenses() {
  const { fetcher } = useFetcher();

  const assignLicenseToWorkspace = async (
    organization_id: string,
    workspace_id: string,
  ) => {
    try {
      const response = await fetcher(
        `/api/organizations/${organization_id}/assign-license`,
        "post",
        {
          workspace_id,
        },
      );
      return response;
    } catch (error) {
      console.error("Error assigning license", error);
      throw error;
    }
  };

  const removeLicenseFromWorkspace = async (
    organization_id: string,
    workspace_id: string,
  ) => {
    try {
      const response = await fetcher(
        `/api/organizations/${organization_id}/remove-license`,
        "post",
        {
          workspace_id,
        },
      );
      return response;
    } catch (error) {
      console.error("Error removing license", error);
      throw error;
    }
  };

  return {
    assignLicenseToWorkspace,
    removeLicenseFromWorkspace,
  };
}

export { useOrganizationLicenses };
