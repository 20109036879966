// RemoveMemberAlertDialog.tsx
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useTranslation } from "react-i18next";

type RemoveMemberAlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  memberEmail?: string;
};

const RemoveMemberAlertDialog = ({
  isOpen,
  onClose,
  onConfirm,
  memberEmail,
}: RemoveMemberAlertDialogProps) => {
  const { t } = useTranslation();
  const { user } = useUserContext();

  const isSelfRemoval = user?.email === memberEmail;

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isSelfRemoval
              ? t("workspace.remove_member_dialog.quit_workspace")
              : t("workspace.remove_member_dialog.remove_member")}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {isSelfRemoval
              ? t("workspace.remove_member_dialog.confirm_quit_workspace")
              : `${t("workspace.remove_member_dialog.confirmation")} ${memberEmail} ${t("workspace.remove_member_dialog.from_workspace")}`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>
            {t("workspace.remove_member_dialog.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className={`${
              isSelfRemoval
                ? "bg-red hover:bg-red hover:brightness-90"
                : "bg-red hover:bg-red hover:brightness-90"
            }`}
          >
            {isSelfRemoval
              ? t("workspace.remove_member_dialog.quit")
              : t("workspace.remove_member_dialog.remove")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveMemberAlertDialog;
