// src/contexts/DashboardContext.tsx

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import {
  useMessageStats,
  useCommentStats,
  useTimeSavedStats,
  useActionMetrics,
  useEmotionalAnalysis,
} from "@/axios/queries";
import {
  AnalyticsFilter,
  AdsOrganicStats,
  TimeSavedStats,
  ActionMetricsResult,
  EmotionalAnalysisResult,
  WorkspaceDetails,
} from "@/types";

type DashboardContextType = {
  filterOptions: AnalyticsFilter;
  setFilterOptions: React.Dispatch<React.SetStateAction<AnalyticsFilter>>;
  applyFilters: (filters: Partial<AnalyticsFilter>) => void;
  resetFilters: () => void;
  messageStats: AdsOrganicStats;
  commentStats: AdsOrganicStats;
  timeSavedStats: TimeSavedStats;
  actionMetrics: ActionMetricsResult;
  emotionalAnalysis: EmotionalAnalysisResult;
  isLoading: boolean;
  refetchDashboard: () => void;
  error?: any; // You can define a more specific error type if needed
};

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined,
);

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const { currentWorkspace } = useWorkspaceContext();

  const defaultFilters: AnalyticsFilter = {
    startDate: undefined,
    endDate: undefined,
    channel_type: undefined,
    sentiment: undefined,
    category: undefined,
    period: undefined,
    adFilter: undefined,
  };

  // Initialize filterOptions from localStorage if available
  const [filterOptions, setFilterOptions] = useState<AnalyticsFilter>(() => {
    return defaultFilters;
    // const savedFilters = localStorage.getItem("dashboardFilterOptions");
    // if (savedFilters) {
    //   const parsedFilters = JSON.parse(savedFilters);
    //   // Convert date strings back to Date objects
    //   return {
    //     ...parsedFilters,
    //     startDate: undefined,
    //     endDate: undefined,
    //   };
    // } else {
    //   return defaultFilters;
    // }
  });

  // Persist filterOptions in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "dashboardFilterOptions",
      JSON.stringify({
        ...filterOptions,
        startDate: undefined,
        endDate: undefined,
      }),
    );
  }, [filterOptions]);

  // Data Fetching Hooks
  const {
    data: messageStats,
    isLoading: isLoadingMessages,
    error: errorMessages,
    refetch: refetchMessages,
  } = useMessageStats(
    (currentWorkspace as WorkspaceDetails)?._id,
    filterOptions,
  );

  const {
    data: commentStats,
    isLoading: isLoadingComments,
    error: errorComments,
    refetch: refetchComments,
  } = useCommentStats(
    (currentWorkspace as WorkspaceDetails)?._id,
    filterOptions,
  );

  const {
    data: timeSavedStats,
    isLoading: isLoadingTimeSaved,
    error: errorTimeSaved,
    refetch: refetchTimeSaved,
  } = useTimeSavedStats(
    (currentWorkspace as WorkspaceDetails)?._id,
    filterOptions,
  );

  const {
    data: actionMetrics,
    isLoading: isLoadingActions,
    error: errorActions,
    refetch: refetchActions,
  } = useActionMetrics(
    (currentWorkspace as WorkspaceDetails)?._id,
    filterOptions,
  );

  const {
    data: emotionalAnalysis,
    isLoading: isLoadingEmotions,
    error: errorEmotions,
    refetch: refetchEmotions,
  } = useEmotionalAnalysis(
    (currentWorkspace as WorkspaceDetails)?._id,
    filterOptions,
  );

  // Aggregate loading state
  const isLoading =
    isLoadingMessages ||
    isLoadingComments ||
    isLoadingTimeSaved ||
    isLoadingActions ||
    isLoadingEmotions;

  // Aggregate error state (optional)
  const error =
    errorMessages ||
    errorComments ||
    errorTimeSaved ||
    errorActions ||
    errorEmotions;

  // Refetch all data, memoized to prevent function recreation on each render
  const refetchDashboard = useCallback(() => {
    refetchMessages();
    refetchComments();
    refetchTimeSaved();
    refetchActions();
    refetchEmotions();
  }, [
    refetchMessages,
    refetchComments,
    refetchTimeSaved,
    refetchActions,
    refetchEmotions,
  ]);

  // Apply Filters
  const applyFilters = useCallback(
    (filters: Partial<AnalyticsFilter>) => {
      const newFilterOptions: AnalyticsFilter = {
        ...filterOptions,
        ...filters,
        // Optionally reset pagination or other related states here
      };

      setFilterOptions(newFilterOptions);
      refetchDashboard();
    },
    [filterOptions, refetchDashboard],
  );

  // Reset Filters
  const resetFilters = useCallback(() => {
    setFilterOptions(defaultFilters);
    refetchDashboard();
  }, [refetchDashboard]);

  return (
    <DashboardContext.Provider
      value={{
        filterOptions,
        setFilterOptions,
        applyFilters,
        resetFilters,
        messageStats,
        commentStats,
        timeSavedStats,
        actionMetrics,
        emotionalAnalysis,
        isLoading,
        refetchDashboard,
        error,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

// Custom hook to use the DashboardContext
export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
