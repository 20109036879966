import { useFetcher } from "@axios/fetcher";
import { Channel } from "@types"; // Make sure Channel type is defined and imported

/**
 * Hook to remove a channel from a workspace by marking it as disconnected.
 */
function useRemoveChannel() {
  const { fetcher } = useFetcher();

  /**
   * Function to remove a channel by making a DELETE request to the API.
   * @param workspace_id - The ID of the workspace.
   * @param channel_id - The ID of the channel to remove.
   * @returns The updated Channel object after removal.
   */
  const removeChannel = async ({
    workspace_id,
    channel_id,
  }: {
    workspace_id: string;
    channel_id: string;
  }): Promise<Channel> => {
    try {
      // Make the DELETE request to remove the channel
      const updatedChannel = await fetcher(
        `/api/channels/${workspace_id}/${channel_id}`,
        "delete",
      );

      return updatedChannel as Channel;
    } catch (error) {
      // Log the error if needed and rethrow for further handling
      console.error("Failed to remove channel", {
        workspace_id,
        channel_id,
        error,
      });
      throw error;
    }
  };

  return {
    removeChannel,
  };
}

export { useRemoveChannel };
