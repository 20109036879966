import { useLocation, useNavigate } from "react-router-dom";
import { HomeIcon, CommentIcon, MessageIcon, SettingIcon } from "@assets/icons";
import { Img } from "../Img";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";
import { navigateWithParams } from "@/utils/navigateWithParams";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const navItems = [
  { icon: HomeIcon, label: "home", path: "/" },
  {
    icon: CommentIcon,
    label: "comments",
    path: "/comments",
    countKey: "commentsTodoCount",
  },
  {
    icon: MessageIcon,
    label: "messages",
    path: "/messages",
    countKey: "messagesTodoCount",
  },
  { icon: SettingIcon, label: "settings", path: "/settings" },
];

type NavigationSectionProps = {
  isCollapsed: boolean;
};

export const NavigationSection = ({ isCollapsed }: NavigationSectionProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const { commentsTodoCount, messagesTodoCount } = useNavBar();

  return (
    <nav>
      <ul
        className={`space-y-[10px] ${
          isCollapsed ? " flex flex-col items-center" : ""
        }`}
      >
        {navItems.map(({ icon: Icon, label, path, countKey }) => {
          const isActive =
            (path != "/" && location.pathname.includes(path)) ||
            location.pathname == path;

          let count;
          if (countKey === "commentsTodoCount") {
            count = commentsTodoCount;
          } else if (countKey === "messagesTodoCount") {
            count = messagesTodoCount;
          }

          return (
            <NavItem
              key={label}
              icon={<Img src={Icon} />}
              label={t(`navbar.navigation.${label}`)}
              path={path}
              isCollapsed={isCollapsed}
              isActive={isActive}
              count={count}
            />
          );
        })}
      </ul>
    </nav>
  );
};

type NavItemProps = {
  icon: React.ReactNode;
  label: string;
  path: string;
  isCollapsed: boolean;
  isActive: boolean;
  count?: number;
};

const NavItem = ({
  icon,
  label,
  path,
  isCollapsed,
  isActive,
  count,
}: NavItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigateWithParams(path, navigate, location.search);
  };

  return (
    <li
      onClick={handleClick}
      className={`flex cursor-pointer items-center rounded-[12px] ${
        isCollapsed ? "size-11" : ""
      } ${
        isCollapsed && isActive
          ? "rounded-l-[12px] bg-light-grey"
          : isActive
            ? "bg-white"
            : "hover:backdrop-brightness-90"
      } ${!isCollapsed ? "h-10 text-primary" : ""}`}
    >
      <div
        className={`flex w-full items-center ${
          isCollapsed ? "justify-center" : "justify-between px-[22px]"
        }`}
      >
        {isCollapsed ? (
          <TooltipProvider key={label}>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div className="relative">
                  {icon}
                  {typeof count === "number" && count > 0 && (
                    <span
                      className={`${isActive ? "bg-violet" : "bg-dark-grey"} absolute right-[-18px] top-[-10px] flex w-[25px] items-center justify-center rounded-[8px] px-2 py-px text-[10px] font-medium text-white`}
                    >
                      {count > 99 ? "99+" : count}
                    </span>
                  )}
                </div>
              </TooltipTrigger>

              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                {label}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <>
            <div className="flex items-center">
              {icon}
              <span className="ml-4 font-sans text-[14px] font-bold text-primary">
                {label}
              </span>
            </div>
            {typeof count === "number" && count > 0 && (
              <span
                className={`${isActive ? "bg-violet" : "bg-dark-grey"} -mr-2.5 ml-2 rounded-[8px] px-2 py-px text-[10px] font-medium text-white`}
              >
                {count > 99 ? "99+" : count}
              </span>
            )}
          </>
        )}
      </div>
    </li>
  );
};
