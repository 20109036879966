// DeleteWorkspaceDialog.tsx
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Loader } from "@/assets/icons";
import { useTranslation } from "react-i18next";

type DeleteWorkspaceDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  workspaceName?: string;
};

const DeleteWorkspaceDialog = ({
  isOpen,
  onClose,
  onConfirm,
  workspaceName,
}: DeleteWorkspaceDialogProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    if (workspaceName) {
      setIsLoading(true);
      await onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("workspace.delete_workspace_dialog.delete_workspace")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "workspace.delete_workspace_dialog.are_you_sure_you_want_to_delete_workspace",
            )}{" "}
            <strong>{workspaceName}</strong>.{" "}
            {t(
              "workspace.delete_workspace_dialog.this_action_cannot_be_undone",
            )}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="secondary" onClick={onClose}>
            {t("workspace.delete_workspace_dialog.cancel")}
          </Button>

          <Button
            variant="destructive"
            onClick={handleConfirm}
            className="bg-red hover:bg-red hover:brightness-90 disabled:cursor-not-allowed disabled:bg-dark-grey disabled:text-primary"
            disabled={isLoading}
          >
            {t("workspace.delete_workspace_dialog.delete_workspace")}
            {isLoading && (
              <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteWorkspaceDialog;
