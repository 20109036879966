// useUpdateWorkspaceName.ts
import { useFetcher } from "@axios/fetcher";
import { Workspace } from "@types";

function useUpdateWorkspaceName() {
  const { fetcher } = useFetcher();

  // Function to update the name of a workspace
  const updateWorkspaceName = async (workspace_id: string, name: string) => {
    if (!workspace_id || !name) {
      throw new Error(
        "workspace_id and name are required to update a workspace name",
      );
    }

    // Make the API request to update the workspace name
    const updatedWorkspace = await fetcher(
      `/api/workspaces/${workspace_id}/name`,
      "patch",
      { name },
    );

    return updatedWorkspace as Workspace;
  };

  return {
    updateWorkspaceName,
  };
}

export { useUpdateWorkspaceName };
