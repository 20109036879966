import { createContext, ReactNode, useEffect, useState } from "react";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { WorkspaceDetails, Channel, Workspace } from "@types";
import { useWorkspaceChannels } from "@axios/queries/useWorkspaceChannels"; // Import the new hook
import { useCreateWorkspace } from "@/axios/mutations";
import {
  DocumentItem,
  useWorkspaceDocuments,
} from "@/axios/mutations/useWorkspaceDocuments";

type WorkspaceContextType = {
  currentWorkspace: WorkspaceDetails | null;
  channels: Channel[] | null;
  isLoadingChannels: boolean;
  isRefetchingChannels: boolean;
  refetchChannels: () => void;
  changeWorkspace: (workspaceId: string) => void;
  createWorkspace: (
    name: string,
    owner_user_id: string,
    organization_id?: string | null,
  ) => Promise<Workspace>;
  documents: DocumentItem[];
  fetchDocuments: () => void;
  deleteDocument: (documentId: string) => void;
  uploadDocument: (fileInfo: {
    file_url: string;
    filename: string;
    mime_type: string;
    size: number;
    uuid: string;
  }) => void;
};

export const WorkspaceContext = createContext<WorkspaceContextType | undefined>(
  undefined,
);

type WorkspaceProviderProps = {
  children: ReactNode;
};

export const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const { workspaces, isLoadingWorkspaces, refetchWorkspaces } =
    useUserContext();
  const [currentWorkspace, setCurrentWorkspace] =
    useState<WorkspaceDetails | null>(null);

  // Use our custom hook to fetch channels for the current workspace
  const {
    data: channels,
    isLoading: isLoadingChannels,
    refetch: refetchChannelsFn,
    isRefetching: isRefetchingChannels, // Track refetching state separately
  } = useWorkspaceChannels({
    workspace_id: currentWorkspace?.workspace_id || "", // Only fetch channels if workspace is available
    enabled: !!currentWorkspace, // Ensure the hook is only called when there's a current workspace
  });

  // Function to refetch channels
  const refetchChannels = async () => {
    await refetchChannelsFn(); // Expose the refetch function
  };

  const { createWorkspace } = useCreateWorkspace();

  const { documents, fetchDocuments, uploadDocument, deleteDocument } =
    useWorkspaceDocuments(currentWorkspace?.workspace_id);

  const handleCreateWorkspace = async (
    name: string,
    owner_user_id: string,
    organization_id?: string | null,
  ): Promise<Workspace> => {
    const newWorkspace = await createWorkspace(
      name,
      owner_user_id,
      organization_id,
    );
    console.debug("New workspace:", newWorkspace);

    // Refetch workspaces after successful creation
    await refetchWorkspaces();
    await changeWorkspace(newWorkspace._id);

    return newWorkspace;
  };

  // Function to change the current workspace
  const changeWorkspace = async (workspaceId: string) => {
    let workspace = workspaces?.find((ws) => ws.workspace_id === workspaceId);

    if (!workspaceId) {
      const updated_workspaces = await refetchWorkspaces();
      workspace = updated_workspaces?.find(
        (ws) => ws.workspace_id === workspaceId,
      );
    }

    if (workspace) {
      console.log("changing workspace", { workspace, workspaces: workspaces });

      setCurrentWorkspace(workspace);
      localStorage.setItem("selectedWorkspace", workspace.workspace_id);
    } else {
      console.log("workspace not found", { workspaceId, workspaces });
    }
  };

  // Load the initial workspace from cache or default to the first workspace
  useEffect(() => {
    if (
      !isLoadingWorkspaces &&
      workspaces &&
      workspaces.length > 0 &&
      !currentWorkspace
    ) {
      const savedWorkspaceId = localStorage.getItem("selectedWorkspace");
      const savedWorkspace =
        workspaces.find((ws) => ws.workspace_id === savedWorkspaceId) ||
        workspaces.find((ws) => ws.current_user_role === "owner") ||
        workspaces[0];
      setCurrentWorkspace(savedWorkspace);
    }
  }, [isLoadingWorkspaces, workspaces, currentWorkspace]);

  useEffect(() => {
    if (
      !isLoadingWorkspaces &&
      workspaces &&
      workspaces.length > 0 &&
      currentWorkspace
    ) {
      const updatedWorkspace = workspaces.find(
        (ws) => ws.workspace_id === currentWorkspace.workspace_id,
      );

      if (updatedWorkspace && updatedWorkspace !== currentWorkspace) {
        setCurrentWorkspace(updatedWorkspace);
      }
    }
  }, [isLoadingWorkspaces, workspaces, currentWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        currentWorkspace,
        channels: channels || null,
        isLoadingChannels,
        isRefetchingChannels,
        refetchChannels,
        changeWorkspace,
        createWorkspace: handleCreateWorkspace,
        documents,
        fetchDocuments,
        deleteDocument,
        uploadDocument,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
