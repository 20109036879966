// useSessionTracking.ts
import { useEffect, useRef } from "react";
import { useAnalytics } from "@segment/analytics-react";
import { v4 as uuidv4 } from "uuid";

const useSessionTracking = (user_id: string) => {
  const { track } = useAnalytics();
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Démarrage de la session
  useEffect(() => {
    const session_id = uuidv4();
    const start_timestamp = Date.now();
    localStorage.setItem("session_id", session_id);
    localStorage.setItem("sessionStart", start_timestamp.toString());

    track("Session Started", {
      user_id,
      session_id,
      start_timestamp: new Date(start_timestamp).toISOString(),
    });
  }, [user_id, track]);

  const endSession = () => {
    const session_id = localStorage.getItem("session_id");
    const start_timestamp = localStorage.getItem("sessionStart");
    if (!session_id || !start_timestamp) return;
    const duration = Math.floor(
      (Date.now() - parseInt(start_timestamp)) / 1000,
    );
    track("Session Ended", {
      user_id,
      session_id,
      duration,
      endTimestamp: new Date().toISOString(),
    });
    localStorage.removeItem("session_id");
    localStorage.removeItem("sessionStart");
  };

  // beforeunload pour détecter la fermeture d’onglet
  useEffect(() => {
    const handleBeforeUnload = () => {
      endSession();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  // visibilitychange pour détecter l'inactivité avec timer
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        inactivityTimerRef.current = setTimeout(
          () => {
            endSession();
          },
          5 * 60 * 1000,
        ); // 5 minutes d'inactivité
      } else {
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  // Optionnel : Heartbeat pour mesurer l'activité (chaque minute)
  useEffect(() => {
    const heartbeat = setInterval(() => {
      // Vous pouvez vérifier ici la dernière interaction et déclencher endSession() si nécessaire
    }, 60 * 1000);
    return () => clearInterval(heartbeat);
  }, []);
};

export default useSessionTracking;
