import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";
import { AdsOrganicStats, AnalyticsFilter } from "@types"; // Assuming you have this type defined
import { getKey } from "../get_query_key.util";

/**
 * Hook to fetch message statistics.
 *
 * @param workspace_id The workspace ID to fetch data for
 * @param filter Additional filter options like date range, channel types, etc.
 * @param enabled Optional flag to enable/disable the hook
 */
function useMessageStats(
  workspace_id: string,
  filter?: AnalyticsFilter,
  enabled = true,
) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false);

  // Construct the cache key based on the workspace_id
  const shouldFetch = enabled && workspace_id !== undefined;
  const cacheKey = shouldFetch
    ? `/api/analytics/messages/${workspace_id}${filter ? `?${getKey(filter)}` : ""}`
    : undefined;

  // Use SWR to fetch data with POST
  const { data, error, mutate, isLoading } = useSWR<AdsOrganicStats>(
    enabled ? cacheKey : null, // SWR only fetches if enabled is true
    (url: string) => fetcher(url, "get"),
  );

  // Manual refetch function
  const refetch = async (): Promise<AdsOrganicStats | undefined> => {
    setIsRefetching(true);
    const updatedData = await mutate();
    setIsRefetching(false);

    if (!updatedData && data) {
      return data;
    }

    if (!updatedData) {
      throw new Error("No data available after refetching.");
    }

    return updatedData;
  };

  return {
    data: data as AdsOrganicStats,
    isLoading,
    isRefetching,
    error,
    refetch,
  };
}

export { useMessageStats };
