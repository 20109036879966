// i18n.ts
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import english from "./en.json";
import french from "./fr.json";

const resources = {
  en: { translation: english },
  fr: { translation: french },
};

void i18next
  .use(initReactI18next)
  .use(LanguageDetector) // Use the LanguageDetector
  .init({
    resources,
    fallbackLng: "fr", // Corrected fallback language
    interpolation: { escapeValue: false },
    load: "languageOnly", // Ignore region codes and load base language
    detection: {
      order: [
        "localStorage",
        "navigator", // Priority to browser setting
        "querystring",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage"], // Store user's choice in localStorage
      excludeCacheFor: ["cimode"], // languages to not persist
    },
  })
  .catch((err) => {
    console.error("i18next initialization failed:", err);
  });

// Function to change language
export const changeLanguage = async (language: string) => {
  console.debug("LANGUAGE CHANGE TO:", language);
  await i18next.changeLanguage(language);
  // No need to manually set localStorage; LanguageDetector handles it
};

export default i18next;
