// components/AddPaymentMethodDialog.tsx

import { useState, FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loader } from "@/assets/icons";
import {
  PaymentElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSetDefaultPaymentMethod } from "@/axios/mutations";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";

import { useTranslation } from "react-i18next";

// Initialize Stripe outside the component to prevent re-initialization
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

interface AddPaymentMethodDialogProps {
  isOpen: boolean;
  onClose: () => void;
  clientSecret: string;
}

const AddPaymentMethodDialog: FC<AddPaymentMethodDialogProps> = ({
  isOpen,
  onClose,
  clientSecret,
}) => {
  const { t } = useTranslation();

  const [isAdding, setIsAdding] = useState(false);

  const { setDefaultPaymentMethod } = useSetDefaultPaymentMethod();
  const { refetchPaymentMethods } = useSubscriptionContext();

  const handlePaymentMethodSubmit = async (
    e: React.FormEvent,
    stripe: any,
    elements: any,
  ) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsAdding(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (error) {
      console.error("Error confirming setup:", error);
    } else if (setupIntent?.payment_method) {
      await setDefaultPaymentMethod(setupIntent.payment_method.toString());
      await refetchPaymentMethods();

      onClose();
    }

    setIsAdding(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={isAdding ? undefined : onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>{t("billing.add_payment_method_title")}</DialogTitle>
          <DialogDescription>
            {t("billing.add_payment_method_description")}
          </DialogDescription>
        </DialogHeader>

        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSecret as string }}
        >
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <form
                onSubmit={(e) => handlePaymentMethodSubmit(e, stripe, elements)}
              >
                <PaymentElement />
                <DialogFooter className="mt-4 flex justify-end space-x-2">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={isAdding ? undefined : onClose}
                    disabled={isAdding}
                  >
                    {t("billing.cancel")}
                  </Button>

                  <Button
                    type="submit"
                    disabled={isAdding || !stripe || !elements}
                  >
                    {isAdding ? (
                      <>
                        {t("billing.adding")}

                        <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
                      </>
                    ) : (
                      t("billing.confirm")
                    )}
                  </Button>
                </DialogFooter>
              </form>
            )}
          </ElementsConsumer>
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default AddPaymentMethodDialog;
