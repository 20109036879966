// utils/hashString.ts
import { x86 } from "murmurhash3js";

/**
 * Generates a consistent hash from a given string using MurmurHash3.
 * This offers better distribution compared to simpler hashing algorithms.
 */
export const hashString = (str: string): number => {
  return x86.hash32(str); // Returns a 32-bit positive integer hash
};
