// ConversationRenderer.tsx

import React, { useEffect, useRef } from "react";
import { Comment, Conversation, Message, Reply } from "@/types";
import { CommentRenderer } from "./CommentRenderer";
import { ProfileImg } from "../ProfileImg";
import { Loader } from "@/assets/icons";
import { formatTimeToNowShort } from "@/utils/formatTimeToNowShort";
import { Img } from "../Img";
import { ExpandableText } from "../ExpandableText";
import { useTranslation } from "react-i18next";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { RenderShare } from "./RenderShares";
import { RenderAttachment } from "./RenderAttachment";

type ConversationType = "comment" | "message";

interface ConversationRendererProps {
  conversation: Comment | Conversation;
  type: ConversationType;
}

/**
 * ConversationRenderer component to render comments or messages and their replies.
 *
 * @param {Comment | Conversation} conversation - The conversation data (comments or messages).
 * @param {"comment" | "message"} type - The type of conversation ("comment" or "message").
 */
export const ConversationRenderer: React.FC<ConversationRendererProps> = ({
  conversation,
  type,
}) => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const lastMessageRef = useRef<HTMLDivElement>(null);

  // Helper: Format the date to either "Today", "Yesterday", or a localized date
  const formatDate = (dateInput: Date | string) => {
    const date = new Date(dateInput);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) return t("tasks.conversation_renderer.today");
    if (isYesterday) return t("tasks.conversation_renderer.yesterday");

    return date.toLocaleDateString(user?.language || "fr-FR", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Helper: Format the time as HH:MM (24h)
  const formatTime = (timestampInput: Date | string) => {
    const timestamp = new Date(timestampInput);
    return timestamp.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  // Extract either .replies (if a Comment) or .messages (if a Conversation)
  const items =
    type === "comment"
      ? (conversation as Comment)?.replies
      : (conversation as Conversation)?.messages;

  // Sort items:
  // - For comments: descending (newest first)
  // - For messages: ascending (oldest first)
  const sortedConversations = items?.sort((a, b) => {
    const timestampA = a.timestamp
      ? new Date(a.timestamp).getTime()
      : Date.now();
    const timestampB = b.timestamp
      ? new Date(b.timestamp).getTime()
      : Date.now();
    if (type === "comment") {
      return timestampB - timestampA;
    }
    return timestampA - timestampB;
  });

  /**
   * Renders a single item (Reply for "comment" or Message for "message")
   */
  const renderItem = (item: Reply | Message) => {
    const isComment = type === "comment";
    const username = item.username;
    const owner = item.owner;

    // If it's a Message: attachments & story
    const story = !isComment ? (item as Message).story : undefined;

    let shares: Message["shares"] = [];

    if (!isComment) {
      const msg = item as Message; // cast
      shares = msg.shares || [];
    }

    const recipientName = !isComment
      ? (item as Message).recipient_name
      : undefined;
    const messageAttachments = !isComment
      ? (item as Message).attachments
      : undefined;

    // If it's a Reply (comment's reply): attachments are in item.attachments
    const commentAttachments = isComment
      ? (item as Reply).attachments
      : undefined;

    const not_supported =
      (!story &&
        !shares?.length &&
        !messageAttachments?.length &&
        !commentAttachments?.length &&
        !item.text?.length) ||
      item.is_unsupported;

    // For debugging: if there's no text, story or attachments in messages
    if (!isComment) {
      if (!item.text && !story && !messageAttachments?.length) {
        // console.debug("No text or story or attachments for message:", item);
        // return null;
      }
    } else {
      // If it's a comment reply and there's likewise no text or attachments, you can do the same:
      // (optional, not strictly necessary)
      if (!item.text && !commentAttachments?.length) {
        // console.debug("No text or attachments for reply:", item);
        // return null;
      }
    }

    // We unify the logic for showing attachments with the vertical line:
    // - For messages: show story + attachments
    // - For comments: show attachments only
    const hasAttachments = isComment
      ? !!commentAttachments?.length
      : !!messageAttachments?.length;

    const renderVerticalLineSection = () => {
      // We only render this block if we have attachments or story
      if (isComment && !hasAttachments) return null;

      // For messages, only show the block if we have attachments, story, or shares
      const msg = item as Message;
      const hasStory = !!msg.story;
      const hasShares = shares.length > 0;

      if (!hasAttachments && !hasStory && !hasShares) return null;

      // If "message," use messageAttachments + story
      // If "comment," use commentAttachments
      const attachments = isComment ? commentAttachments : messageAttachments;
      const hasAttachmentUrl = attachments?.some(
        (a) => a.url && a.type != "link" && a.type != "unknown",
      );

      return (
        <div className="flex w-full flex-col pl-12" key={item._id}>
          <div className="mb-5 flex w-full items-end">
            {/* Left vertical line if not the owner */}
            {!owner && (
              <div
                className={`mr-3 rounded-xl border-[1.5px] border-dark-grey/30 ${
                  // If we have a story thumbnail or attachments, make it tall
                  (!isComment && story?.thumbnail_url) || hasAttachmentUrl
                    ? "h-[150px]"
                    : "h-[18px]"
                }`}
              />
            )}

            <div
              className={`flex w-full flex-col  ${owner ? "items-end text-end" : ""}`}
            >
              {/* For messages: render story if present */}
              {!isComment && story && (
                <>
                  <span
                    className={`${story?.link ? "mb-2.5" : "mb-1.5"} text-sm font-semibold text-primary/40`}
                  >
                    {!owner
                      ? story.type === "reply_to"
                        ? t("tasks.conversation_renderer.replied_to_your_story")
                        : t("tasks.conversation_renderer.mentioned_you")
                      : story.type === "reply_to"
                        ? t(
                            "tasks.conversation_renderer.user_replied_to_a_story",
                          )
                        : t(
                            "tasks.conversation_renderer.user_mention_to_a_story",
                            {
                              username: recipientName || "",
                            },
                          )}
                  </span>
                  {(!story.link || !story.thumbnail_url) && (
                    <span
                      className={`text-sm font-medium text-dark-grey ${
                        owner ? "text-end" : ""
                      }`}
                    >
                      {t("tasks.conversation_renderer.no_story")}
                    </span>
                  )}
                  {story.link && story.thumbnail_url && (
                    <a
                      href={story.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative h-[150px] w-fit shrink-0 cursor-pointer overflow-hidden rounded-lg bg-white hover:brightness-90"
                    >
                      <Img
                        src={story.thumbnail_url}
                        alt="Story Thumbnail"
                        className="h-full object-cover"
                      />
                    </a>
                  )}
                </>
              )}

              {/* Render attachments (comment or message) */}
              {attachments?.length
                ? attachments.map((attachment, idx) => (
                    <>
                      {attachment.url ? (
                        <div key={idx}>
                          <RenderAttachment attachment={attachment} />
                        </div>
                      ) : (
                        <span
                          className={`text-sm font-medium text-dark-grey ${
                            owner ? "text-end" : ""
                          }`}
                        >
                          {t("tasks.comments_renderer.not_supported")}
                        </span>
                      )}
                    </>
                  ))
                : null}

              {/* Render each share */}
              {shares.map((share, idx) => (
                <>
                  {share.link ? (
                    <div key={`share-${idx}`} className="mt-2">
                      <RenderShare share={share} />
                    </div>
                  ) : (
                    <span
                      className={`text-sm font-medium text-dark-grey ${
                        owner ? "text-end" : ""
                      }`}
                    >
                      {t("tasks.comments_renderer.not_supported")}
                    </span>
                  )}
                </>
              ))}
            </div>

            {/* Right vertical line if owner */}
            {owner && (
              <div
                className={`ml-3 rounded-xl border-[1.5px] border-dark-grey/30 ${
                  (!isComment && story?.thumbnail_url) || hasAttachmentUrl
                    ? "h-[150px]"
                    : "h-[18px]"
                }`}
              />
            )}
          </div>
        </div>
      );
    };

    return (
      <div
        key={item._id}
        className={`relative mb-3 rounded-[10px] p-5 ${
          isComment
            ? "ml-auto max-w-[75%]"
            : `w-fit max-w-[85%] ${owner ? "ml-auto" : "mr-auto"}`
        } ${owner ? "border border-white bg-light-blue" : "bg-white"}`}
      >
        {/* Attachments or story block if needed */}
        {renderVerticalLineSection()}

        {/* Text block */}
        <div className="flex items-center">
          {/* Profile Picture */}
          {owner ? (
            <Img
              src={item?.channel?.profile_picture_url}
              alt={t("tasks.conversation_renderer.channel_avatar")}
              className="mr-3 size-10 rounded-full"
            />
          ) : (
            <ProfileImg
              username={
                isComment
                  ? item.username
                  : (item.username ?? (item as Message).conversation_id)
              }
              id={item._id}
              profilePictureUrl={(item as Reply)?.profile_picture_url}
              className="mr-3 size-10 rounded-full"
            />
          )}

          {/* Text content */}
          <div className="flex flex-1 flex-col justify-between pr-16">
            {/* Username */}
            {username && (
              <span className="text-sm font-extrabold text-primary">
                @{username.replace("@", "")}
              </span>
            )}

            {/* Actual text */}
            {item.text?.length ? (
              <ExpandableText text={item.text} maxLines={3} />
            ) : null}

            {not_supported ? (
              <span
                className={`mt-2 text-sm font-medium text-dark-grey ${
                  owner ? "text-end" : ""
                }`}
              >
                {t("tasks.comments_renderer.not_supported")}
              </span>
            ) : null}
          </div>
        </div>

        {/* Timestamp (bottom-right) */}
        <div className="absolute bottom-3.5 right-3.5 text-right text-xs font-medium">
          {item.timestamp ? (
            <span className="ml-2 text-dark-grey">
              {isComment
                ? formatTimeToNowShort(item.timestamp, t)
                : formatTime(item.timestamp)}
            </span>
          ) : (
            <Loader className="size-3.5 animate-spin stroke-dark-grey" />
          )}
        </div>
      </div>
    );
  };

  // Scroll to the last message in "message" mode
  useEffect(() => {
    if (type === "message" && lastMessageRef.current) {
      setTimeout(() => {
        lastMessageRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100); // Adjust the delay as needed
    }
  }, [sortedConversations, type]);

  return (
    <div>
      {type === "comment" ? (
        <>
          {/* Main Comment pinned at the top */}
          <div className="sticky top-0 z-10 bg-light-grey">
            <CommentRenderer
              comment={conversation as Comment}
              key={conversation._id}
            />
          </div>

          {/* Replies Section (scrollable) */}
          <div className="flex-1 overflow-y-auto scrollbar-hide">
            {sortedConversations?.map((reply) => renderItem(reply))}
          </div>
        </>
      ) : (
        // For messages, render them sequentially
        <div className="overflow-y-auto scrollbar-hide">
          {sortedConversations?.map((message, index) => {
            const messages = (conversation as Conversation).messages;
            const prevMessage = messages[index - 1];

            // Insert a date separator if day changed
            const currentTimestamp = new Date(message.timestamp);
            const currentDateString = currentTimestamp.toDateString();
            let prevDateString = "";
            if (prevMessage) {
              const prevTimestamp = new Date(prevMessage.timestamp);
              prevDateString = prevTimestamp.toDateString();
            }

            let dateSeparator = null;
            if (index === 0 || currentDateString !== prevDateString) {
              dateSeparator = (
                <div
                  className={`${
                    index === 0 ? "mb-3" : "my-3"
                  } text-center text-sm font-semibold text-dark-grey`}
                >
                  {message.timestamp && formatDate(message.timestamp)}
                </div>
              );
            }

            return (
              <React.Fragment key={message._id}>
                {dateSeparator}
                <div>{renderItem(message)}</div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {/* Dummy element at the end */}
      <div ref={lastMessageRef} />
    </div>
  );
};
