import React, { useState, useMemo } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import {
  ChecklistWhiteIcon,
  ChecklistOpenedIcon,
  DoneIcon,
  TodoIcon,
  VioletLoadingIcon,
} from "@assets/icons";

import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";
import { navigateWithParams } from "@/utils/navigateWithParams";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface StepConfig {
  key: string;
  title: string;
  description: string;
  completed: boolean;
  isModel?: boolean; // Mark if this is the AI model step
}

export const OnboardingChecklistPopover: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentWorkspace } = useWorkspaceContext();

  const [isOpen, setIsOpen] = useState(false);

  const { setAddChannelPopupIsOpen } = useNavBar();

  // 1) Extract the onboarding steps and relevant fields
  const onboardingSteps = currentWorkspace?.onboarding_steps;
  const repliedCount = currentWorkspace?.replied_count || 0;

  // 2) Prepare data for the three steps
  const steps = useMemo<StepConfig[]>(() => {
    if (!onboardingSteps) {
      return [];
    }
    const rawSteps = [
      {
        key: "add_channel",
        title: t("onboardingChecklist.step1_add_channel_title"),
        description: t("onboardingChecklist.step1_add_channel_desc"),
        completed: onboardingSteps.add_channel,
      },
      {
        key: "replied_50_comments",
        title: t("onboardingChecklist.step2_replied_25_title"),
        description: t("onboardingChecklist.step2_replied_25_desc", {
          count: repliedCount,
        }),
        completed: onboardingSteps.replied_50_comments,
      },
      {
        key: "model_ready",
        title: t("onboardingChecklist.step3_model_ready_title"),
        description: t("onboardingChecklist.step3_model_ready_desc"),
        completed: onboardingSteps.model_ready,
        isModel: true,
      },
    ];

    // Enforce sequential completion: if a previous step isn’t complete,
    // all subsequent steps should be marked as not completed.
    let chainComplete = true;
    return rawSteps.map((step) => {
      if (!chainComplete) {
        return { ...step, completed: false };
      }
      if (!step.completed) {
        chainComplete = false;
      }
      return step;
    });
  }, [onboardingSteps, repliedCount, t]);

  // 3) Find the first undone step (lowest index where completed = false)
  const firstNotDoneIndex = steps.findIndex((s) => !s.completed);

  // 4) For the 3 load bars
  //    - If all done, color all bars violet (#7F56D9).
  //    - Otherwise, color bars up to `firstNotDoneIndex` in violet, and the rest in gray (#D1D5DB).
  const barColors = [0, 1, 2].map((index) => {
    if (firstNotDoneIndex === -1) {
      // Means all steps done
      return "#7F56D9";
    }
    // If index <= firstNotDoneIndex => violet, else gray
    return index < firstNotDoneIndex ? "#7F56D9" : "#D1D5DB";
  });

  // 5) Compute which icon to show for each step
  //    - If completed => done icon
  //    - Else if step is model + step index = firstNotDoneIndex => loader icon
  //    - Otherwise => todo icon
  const getStatusIcon = (step: StepConfig, index: number) => {
    if (step.completed) {
      return <DoneIcon className="mt-2.5 size-6 shrink-0" />;
    }
    if (step.isModel && index === firstNotDoneIndex) {
      return (
        <VioletLoadingIcon className="mt-2.5 size-6 shrink-0 animate-spin" />
      );
    }
    return <TodoIcon className="mt-2.5 size-6 shrink-0" />;
  };

  // 6) CTA handler: open add-channel popup or navigate to comments page
  const handleStepCTA = (stepKey: string) => {
    setIsOpen(false);

    if (stepKey === "add_channel") {
      setAddChannelPopupIsOpen(true);
    } else if (stepKey === "replied_50_comments") {
      navigateWithParams("/comments", navigate, location.search);
    }
  };

  // 7) Compute whether there are any pending actions
  const hasPendingActions =
    steps?.length > 0 && steps.some((step) => !step.completed);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <button
                className={`relative flex size-[42px] items-center justify-center p-0 ${
                  !isOpen && "hover:brightness-90"
                }`}
              >
                {isOpen ? (
                  <ChecklistOpenedIcon className="size-[42px]" />
                ) : (
                  <>
                    <ChecklistWhiteIcon className="size-[42px]" />
                    {hasPendingActions && (
                      <span className="absolute right-[-1.5px] top-0 size-2.5 rounded-full bg-violet" />
                    )}
                  </>
                )}
              </button>
            </PopoverTrigger>
          </TooltipTrigger>

          {!isOpen && (
            <TooltipContent
              className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
              sideOffset={5}
            >
              {t("onboardingChecklist.title")}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <PopoverContent
        sideOffset={10}
        className="
          mr-20 max-h-[90vh] w-[450px]
          overflow-y-auto rounded-xl bg-white p-0
          shadow-[0px_0px_60px_0px_#00000014]
          scrollbar-hide
        "
      >
        <div className="px-6 pt-6">
          {/* Title */}
          <h3 className="mb-1 text-xl font-extrabold">
            {t("onboardingChecklist.title")}
          </h3>

          {/* 3 load bars */}
          <div className="my-4 flex items-center space-x-1">
            {barColors.map((color, idx) => (
              <div
                key={idx}
                className="h-[5px] flex-1 rounded-full"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>

          {/* Accordion, type multiple => can open more than one */}
          <Accordion
            key={isOpen ? "open" : "closed"} // Force remount on open if needed
            type="multiple"
            defaultValue={
              firstNotDoneIndex !== -1 ? [steps[firstNotDoneIndex].key] : []
            }
            className="mb-6 space-y-5"
          >
            {steps.map((step, idx) => (
              <AccordionItem
                key={step.key}
                value={step.key}
                // remove lines between items
                className="border-none"
              >
                <div className="flex items-start space-x-3">
                  {/* Status Icon */}
                  {getStatusIcon(step, idx)}

                  <div className="flex-1">
                    {/* Title */}
                    <AccordionTrigger className="h-[40px] items-center text-base font-bold text-primary">
                      {step.title}
                    </AccordionTrigger>

                    {/* Description */}
                    <AccordionContent className="pt-1 text-base leading-6 text-primary">
                      <p>
                        {step.key === "model_ready" && step.completed
                          ? t("onboardingChecklist.step3_model_ready_desc_done")
                          : step.description}
                      </p>

                      {(step.key === "add_channel" ||
                        step.key === "replied_50_comments") && (
                        <button
                          onClick={() => handleStepCTA(step.key)}
                          className="mt-4 items-center rounded-[6px] bg-violet px-4 py-2 text-white hover:brightness-90"
                        >
                          {step.key === "add_channel"
                            ? t("onboardingChecklist.step1_cta")
                            : t("onboardingChecklist.step2_cta")}
                        </button>
                      )}
                    </AccordionContent>
                  </div>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        {/* Sticky footer */}
        {/* <div className="sticky bottom-0 flex items-center justify-end border-t border-light-grey bg-white px-6 py-4 text-sm font-semibold">
          <button
            onClick={() => setIsOpen(false)}
            className="w-32 rounded-[8px] bg-primary py-2.5 text-white hover:brightness-150"
          >
            {t("onboardingChecklist.close")}
          </button>
        </div> */}
      </PopoverContent>
    </Popover>
  );
};
