import { ReactElement } from "react";
import { create } from "zustand";

// POPUP STORE
type PopupState = {
  popupComponent: ReactElement | null;
  pathToShow?: string | null;
  setPopup: (popup: ReactElement | null, path?: string | null) => void;
};

export const usePopupStore = create<PopupState>((set) => ({
  popupComponent: null,
  pathToShow: null,
  setPopup: (popup, path) => set({ popupComponent: popup, pathToShow: path }),
}));

// NAVBAR STORE
type NavbarState = {
  isNavbarExpanded: boolean;
  toggleNavbar: () => void;
};

export const useNavbarStore = create<NavbarState>((set) => ({
  isNavbarExpanded: true,
  toggleNavbar: () =>
    set((state) => ({ isNavbarExpanded: !state.isNavbarExpanded })),
}));

// IS ADMIN & USER ID STORE
type isAdminState = {
  user_id: string | null;
  isAdmin: boolean;
  isInitialized: boolean | null;
  setAdmin: (isAdmin: boolean) => void;
  setUserId: (user_id: string | null) => void;
  setInitialized: (isInitialized: boolean | null) => void;
};

export const useAdminStore = create<isAdminState>((set) => ({
  isAdmin: false,
  user_id: null,
  isInitialized: false, // New flag
  setAdmin: (isAdmin) => set({ isAdmin }),
  setUserId: (user_id) => set({ user_id }),
  setInitialized: (isInitialized) => set({ isInitialized }), // New action to update the flag
}));
