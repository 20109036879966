import { useAuth0 } from "@auth0/auth0-react";
import { Crisp } from "crisp-sdk-web";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ErrorMobile: React.FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  useEffect(() => {
    Crisp.setZIndex(-10);
  }, []);

  return (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center bg-light-grey p-6 text-primary">
      {/* Error Message Container */}
      <div className="flex w-full max-w-md flex-col items-center gap-4 rounded-lg bg-white p-6 text-center shadow-lg">
        <h1 className="text-xl font-semibold text-primary">
          {t("error.mobile.title")}
        </h1>
        <p className="text-base text-dark-grey">
          {t("error.mobile.description")}
        </p>
      </div>

      {/* Logout Button */}
      <button
        onClick={() => logout()}
        className="absolute bottom-8 items-center justify-center text-sm font-medium text-dark-grey transition-colors hover:text-primary"
      >
        {t("logout")}
      </button>
    </div>
  );
};

export default ErrorMobile;
