import { TaskItem } from "./TaskItem";
import { Loader } from "@/assets/icons";
import { useNavBar } from "@/layouts/NavBar.context";
import { useTaskContext } from "@/layouts/useTask.context";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { endOfDay, startOfDay, subMonths } from "date-fns";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

/**
 * Props for TaskListTabContent component.
 */
type TaskListTabContentProps = {
  status: string;
  type: "comment" | "message";
};
/**
 * Renders the list of tasks based on the status and items passed.
 * Each task is filtered by its status and rendered using TaskItem.
 *
 * @param items - The list of items (comments or messages) to be displayed.
 * @param status - The status used to filter items (e.g., 'to-do', 'replied').
 */
export const TaskListTabContent = ({
  status,
  type,
}: TaskListTabContentProps) => {
  const { t } = useTranslation();

  const { setAddChannelPopupIsOpen } = useNavBar();

  const {
    items,
    loadMoreItems,
    hasMore,
    isLoadingMore,
    resetFilters,
    activeTab,
    selectAllItems,
    deselectAllItems,
    filterOptions,
    nextItem,
    previousItem,
    openItem,
    openedItemId,
    selectedItemIds,
  } = useTaskContext();

  const { channels } = useWorkspaceContext();

  // Create a ref map to store refs for each task item
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Sorts items by timestamp in descending order (most recent first)
  const sortedItems = items.sort((a, b) => {
    const timestampA = a.timestamp
      ? new Date(a.timestamp).getTime()
      : Date.now();
    const timestampB = b.timestamp
      ? new Date(b.timestamp).getTime()
      : Date.now();
    return timestampB - timestampA;
  });

  const uniqueItems = Array.from(
    new Map(sortedItems.map((item) => [item._id, item])).values(),
  );

  const filteredItems = uniqueItems.filter((item) => item.status === status);
  // .filter((item) => {
  //   if (type == "comment") {
  //     if (
  //       item.text?.length > 0 ||
  //       (item as Comment).attachments?.length > 0
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // });

  const {
    limit,
    page,
    status: filterStatus,
    ...filteredOptions
  } = filterOptions;

  const filtersEmpty = Object.keys(filteredOptions).every((key) => {
    if (type == "comment") {
      if (key == "startDate") {
        if (
          filteredOptions[key] ==
          startOfDay(subMonths(new Date(), 1)).toISOString()
        ) {
          return true;
        }
      }

      if (key == "endDate") {
        if (filteredOptions[key] == endOfDay(new Date()).toISOString()) {
          return true;
        }
      }
    }

    return filterOptions[key] == undefined;
  });

  // console.debug("Filters:", filterOptions);
  // console.debug("FiltersEmpty:", filtersEmpty);

  /**
   * Selects all items in the active tab.
   */
  const handleSelectAll = useCallback(() => {
    const ids: string[] = filteredItems.map((item) => item._id);
    selectAllItems(ids);
  }, [filteredItems, selectAllItems]);

  /**
   * Renders a message when there are no items.
   */
  const NoItemsMessage = () => (
    <div className="mt-[50%] flex items-center justify-center">
      {!channels?.length ? (
        <button
          onClick={() => setAddChannelPopupIsOpen(true)}
          className="text-dark-blue hover:underline hover:brightness-90"
        >
          {t("tasks.task_list_tab_content.no_channels_connected")}
        </button>
      ) : filtersEmpty ? (
        <p className="w-4/5 text-center text-base font-semibold text-primary">
          <span className="mb-6 text-2xl font-extrabold">
            {t("tasks.task_list_tab_content.comments_handled_title")}
          </span>
          <br />
          <span>{t("tasks.task_list_tab_content.comments_handled")}</span>
        </p>
      ) : (
        <p className="w-4/5 text-center text-sm font-medium text-dark-grey">
          {t("tasks.task_list_tab_content.no_items")}
          {type === "comment"
            ? t("tasks.task_list_tab_content.comments")
            : t("tasks.task_list_tab_content.messages")}
          {t("tasks.task_list_tab_content.please")}{" "}
          <button
            onClick={resetFilters}
            className="text-dark-blue underline hover:brightness-90"
          >
            {t("tasks.task_list_tab_content.reset_filters")}
          </button>{" "}
          .
        </p>
      )}
    </div>
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isTextInput =
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement?.getAttribute("contenteditable") === "true";

      if (isTextInput) {
        return;
      }

      // Handle "Ctrl+A" or "Cmd+A" for select all
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "a") {
        event.preventDefault(); // Prevent the default select-all behavior
        handleSelectAll();
      }

      // Handle "Escape" key for deselect all
      if (event.key === "Escape") {
        deselectAllItems();
      }

      // If there are selected items, handle "ArrowRight" and "ArrowLeft"
      if (selectedItemIds?.length > 0) {
        if (event.key === "ArrowRight" || event.key === "ArrowDown") {
          event.preventDefault();
          nextItem();
          return;
        }

        if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
          event.preventDefault();
          previousItem();
          return;
        }
      } else {
        // Handle "ArrowDown" key to open the next item
        if (event.key === "ArrowDown") {
          event.preventDefault();
          // Find the index of the currently opened item
          const currentIndex = openedItemId
            ? filteredItems.findIndex((item) => item._id === openedItemId)
            : -1;

          if (currentIndex >= 0 && currentIndex < filteredItems.length - 1) {
            const nextItemToOpen = filteredItems[currentIndex + 1];
            openItem(nextItemToOpen._id);

            if (itemRefs.current[nextItemToOpen._id]) {
              itemRefs.current[nextItemToOpen._id]?.scrollIntoView({
                behavior: "smooth",
                block: "center", // Adjust as needed: 'start', 'center', 'end', or 'nearest'
              });
            }
          } else if (currentIndex === -1 && filteredItems.length > 0) {
            // If no item is currently open, open the first item
            openItem(filteredItems[0]._id);

            if (itemRefs.current[filteredItems[0]._id]) {
              itemRefs.current[filteredItems[0]._id]?.scrollIntoView({
                behavior: "smooth",
                block: "center", // Adjust as needed: 'start', 'center', 'end', or 'nearest'
              });
            }
          }

          return;
        }

        // Handle "ArrowUp" key to open the previous item
        if (event.key === "ArrowUp") {
          event.preventDefault();

          const currentIndex = openedItemId
            ? filteredItems.findIndex((item) => item._id === openedItemId)
            : -1;

          if (currentIndex > 0) {
            const prevItemToOpen = filteredItems[currentIndex - 1];
            openItem(prevItemToOpen._id);

            if (itemRefs.current[prevItemToOpen._id]) {
              itemRefs.current[prevItemToOpen._id]?.scrollIntoView({
                behavior: "smooth",
                block: "center", // Adjust as needed: 'start', 'center', 'end', or 'nearest'
              });
            }
          }
          return;
        }
      }
    };

    // Attach event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSelectAll, deselectAllItems, filteredItems]);

  /**
   * Scrolls smoothly to the item with the current openedItemId.
   */
  useEffect(() => {
    if (
      selectedItemIds?.length > 0 &&
      openedItemId &&
      itemRefs.current[openedItemId]
    ) {
      itemRefs.current[openedItemId]?.scrollIntoView({
        behavior: "smooth",
        block: "center", // Adjust as needed: 'start', 'center', 'end', or 'nearest'
      });
    }
  }, [openedItemId]);

  return (
    <div className="h-full overflow-y-auto scrollbar-hide">
      {filteredItems.length === 0 && !hasMore && activeTab === "to-do" ? (
        <NoItemsMessage />
      ) : (
        filteredItems.map((item, index) => (
          <div
            key={`${status}-${item._id}`}
            ref={(el) => (itemRefs.current[item._id] = el)}
          >
            <TaskItem item={item} type={type} />
            {/* Separator between items */}
            {index !== filteredItems.length - 1 && (
              <hr className="mx-[6px] my-2 border-t border-dashed border-light-grey" />
            )}
          </div>
        ))
      )}

      {hasMore && (
        <button
          onClick={loadMoreItems}
          disabled={isLoadingMore}
          className="mb-5 mt-6 flex w-full cursor-pointer flex-row items-center justify-center rounded-[8px] bg-light-grey py-3 text-center text-sm font-semibold text-primary hover:brightness-90"
        >
          {isLoadingMore
            ? t("tasks.task_list_tab_content.loading")
            : t("tasks.task_list_tab_content.load_more")}
          {isLoadingMore && (
            <Loader className="ml-2 size-3 animate-spin stroke-primary" />
          )}
        </button>
      )}
    </div>
  );
};
