// ChannelsDialog.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useWorkspaceContext } from "../workspace-provider/useWorkspaceContext";
import ConnectChannelsPopup from "@/components/connect-channel/ConnectChannelsPopup";
import { useSubscriptionContext } from "../subscription-provider/SubscriptionProvider";
import { setLastShown, shouldShowAlert } from "@/utils/alertUtils";
import { Channel } from "@/types";
import { useTranslation } from "react-i18next";

const ALERT_TYPE_CHANNELS = "channels";

const ChannelsDialog: React.FC = () => {
  const { t } = useTranslation();

  const { channels, isLoadingChannels, currentWorkspace } =
    useWorkspaceContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { subscriptionProblem, isLoadingSubscriptionDetails } =
    useSubscriptionContext();

  // Ensure workspaceId is available
  const workspaceId = currentWorkspace?.workspace_id;

  /**
   * Determines whether the Channel Alert should be shown based on current state and timing.
   * @returns True if the alert should be displayed, false otherwise.
   */
  const shouldShowChannelAlert = (): boolean => {
    if (!workspaceId) return false; // Ensure workspaceId is available
    if (isLoadingChannels) return false;
    if ((channels as Channel[])?.length > 0) return false;
    return shouldShowAlert(workspaceId, ALERT_TYPE_CHANNELS);
  };

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible" && shouldShowChannelAlert()) {
      setShowDialog(true);
      setLastShown(workspaceId as string, ALERT_TYPE_CHANNELS);
    }
  };

  useEffect(() => {
    if (!workspaceId) return; // Ensure workspaceId is available

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial check in case the user lands on the app without changing visibility
    if (document.visibilityState === "visible" && shouldShowChannelAlert()) {
      setShowDialog(true);
      setLastShown(workspaceId, ALERT_TYPE_CHANNELS);
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [channels, isLoadingChannels, workspaceId]);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleConnectChannels = () => {
    setShowDialog(false);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const isLoading = isLoadingChannels || isLoadingSubscriptionDetails;

  if (isLoading) {
    return null;
  }

  if (subscriptionProblem) {
    return null;
  }

  return (
    <>
      {/* Dialog for No Connected Channels */}
      <Dialog
        open={showDialog && !isPopupOpen}
        onOpenChange={handleDialogClose}
      >
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle className="mb-2">
              {t("channels_dialog.no_channels_connected")}
            </DialogTitle>
            <DialogDescription>
              {t("channels_dialog.connect_channel_prompt")}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              onClick={handleConnectChannels}
              className="mt-4 flex items-center justify-center"
            >
              {t("channels_dialog.connect_channels")}
            </Button>

            {/* <Button
              variant="ghost"
              onClick={handleDialogClose}
              className="ml-2 border-0 bg-dark-grey shadow-none hover:bg-dark-grey hover:brightness-90"
            >
              {t("channels_dialog.maybe_later")}
            </Button> */}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {/* Connect Channels Popup */}
      {isPopupOpen && <ConnectChannelsPopup onClose={handleClosePopup} />}
    </>
  );
};

export default ChannelsDialog;
