import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ColoredBlablaIcon, ColoredBlablaLogo } from "@assets/icons";
import { WorkspaceSelector } from "./WorkspaceSelector";
import { ChannelSection } from "./ChannelSection";
import { NavigationSection } from "./NavigationSection";
import { Img } from "../Img";
import { useNavBar } from "@/layouts/NavBar.context";
import { PanelRightOpen, PanelLeftOpen } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTranslation } from "react-i18next";
import { navigateWithParams } from "@/utils/navigateWithParams";

const NavBar = () => {
  const { t } = useTranslation();

  const { isCollapsed, setIsCollapsed, isHovered, setIsHovered } = useNavBar();
  const navigate = useNavigate();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsCollapsed]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);

    if (!isCollapsed) {
      setIsHovered(false);
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`group relative flex h-screen min-w-[106px] flex-none flex-col bg-light-grey px-[18px] py-8 ${
        isCollapsed ? "w-[106px]" : "w-[262px]"
      }`}
    >
      {/* Logo and Toggle Button */}
      <div
        className={`relative flex items-center ${
          isCollapsed ? "mb-5 justify-center" : "mb-6 ml-4 justify-between"
        }`}
      >
        {isCollapsed ? (
          <div className="relative">
            {/* Logo */}
            <button
              onClick={() => navigateWithParams("/", navigate, location.search)}
            >
              <Img
                src={ColoredBlablaIcon}
                alt="Logo"
                className={`h-8 cursor-pointer ${
                  isHovered
                    ? "opacity-0"
                    : "opacity-100 transition-opacity duration-300"
                }`}
              />
            </button>

            {/* Sidebar Toggle Button */}
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <button
                    onClick={toggleSidebar}
                    className={`absolute inset-0 -ml-1 flex size-fit cursor-pointer items-center justify-center rounded-[8px] bg-white active:bg-dark-grey ${
                      isHovered
                        ? "opacity-100 transition-opacity duration-300"
                        : "opacity-0"
                    }`}
                  >
                    <PanelLeftOpen className="m-1 size-5 text-primary" />
                  </button>
                </TooltipTrigger>
                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  <p>{t("navbar.show_sidebar")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ) : (
          <div className="flex w-full items-center justify-between">
            {/* Logo */}
            <button
              onClick={() => navigateWithParams("/", navigate, location.search)}
            >
              <Img
                src={ColoredBlablaLogo}
                alt="Logo"
                className="h-8 cursor-pointer"
              />
            </button>

            {/* Sidebar Toggle Button */}
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <button
                    onClick={toggleSidebar}
                    className={`relative cursor-pointer rounded-[8px] bg-white p-1 ${
                      isHovered
                        ? "opacity-100 transition-opacity duration-300"
                        : "opacity-0"
                    } active:bg-dark-grey`}
                  >
                    <PanelRightOpen className="size-5 text-primary" />
                  </button>
                </TooltipTrigger>
                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  <p>{t("navbar.hide_sidebar")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>

      <div
        className={`flex flex-1 flex-col ${
          isCollapsed && "rounded-[12px] bg-white py-[22px]"
        }`}
      >
        {/* Workspace Selector */}
        <div className="mb-14">
          <WorkspaceSelector isCollapsed={isCollapsed} />
        </div>

        {/* Navigation */}
        <div className="mb-10 grow">
          <NavigationSection isCollapsed={isCollapsed} />
        </div>

        {/* Channel Section */}
        <ChannelSection isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export { NavBar };
