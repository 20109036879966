import {
  ClockFilledIcon,
  ExternalLinkIcon,
  IconComments,
  LikeIcon,
  RepliedIcon,
} from "@/assets/icons";
import { Post } from "@/types";
import { CHANNELS_CONFIG } from "@/types/channel.config";
import { format } from "date-fns";
import { Img } from "../Img";
import { useCommentsContext } from "@/containers/comments/comments.context";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

/**
 * Displays post details with thumbnail, title, caption, stats, and a link to the post.
 */
export const PostDetails = (post: Post) => {
  const { t } = useTranslation();

  const {
    thumbnail_url,
    channel,
    media_type,
    like_count,
    comments_count,
    replied_count,
    timestamp,
    permalink,
    title,
    caption,
  } = post;

  const formattedDate = format(new Date(timestamp), "dd MMM");
  const formattedFullDate = format(new Date(timestamp), "dd MMM yyyy");

  const { selectedItemIds: selectedCommentIds } = useCommentsContext();

  if (!channel?.type) {
    return null;
  }

  const channelConfig = CHANNELS_CONFIG[channel.type];

  return (
    <div className="relative z-20 flex size-full items-start rounded-[10px] border border-white bg-white p-5">
      {/* Post Thumbnail and Channel Icon */}
      <a
        href={permalink}
        target="_blank"
        rel="noopener noreferrer"
        className="relative h-full shrink-0 cursor-pointer overflow-hidden rounded-lg bg-white hover:brightness-90"
      >
        <Img
          src={thumbnail_url as string}
          alt="Post Thumbnail"
          className="h-full object-cover"
        />
        <Img
          src={channelConfig.icon}
          alt={`${channel.type}-icon`}
          className="absolute bottom-2 right-2 size-4 shrink-0 rounded-full bg-white shadow-[0_0_0_2px_theme('colors.light-grey')]"
        />
      </a>

      {/* Post Content */}
      <div className="ml-4 flex h-full min-w-0 max-w-full flex-1 flex-col justify-evenly">
        {(title || caption) && (
          <div className="pr-24">
            {/* Post Title */}
            {title && (
              <h3 className="line-clamp-1 text-left font-inter text-base font-bold text-primary">
                {title}
              </h3>
            )}

            {/* Post Caption */}
            {caption && (
              <p className="mt-1 line-clamp-2 w-full min-w-0 text-sm font-medium text-dark-grey">
                {caption}
              </p>
            )}
          </div>
        )}

        {/* Post Stats and External Link */}
        <div className="mt-3 flex min-w-0 items-center justify-start text-sm font-semibold text-dark-grey">
          {/* Post Stats */}
          <div className="flex min-w-0 items-center space-x-3">
            <div className="flex items-center space-x-1">
              <LikeIcon className="h-5" /> <span>{like_count}</span>
            </div>
            <div className="flex items-center space-x-1">
              <IconComments className="h-5" /> <span>{comments_count}</span>
            </div>
            <div className="flex items-center space-x-1">
              <RepliedIcon className="h-5" /> <span>{replied_count}</span>
            </div>
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <div className="flex items-center space-x-1">
                    <ClockFilledIcon className="h-5" />
                    <span className="ml-1 text-dark-grey">{formattedDate}</span>
                  </div>
                </TooltipTrigger>

                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  {formattedFullDate}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* External Link to the Full Post */}
          <a
            href={permalink}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-auto flex shrink-0 items-center text-sm font-bold text-primary"
          >
            {t("tasks.post_details.see_post")}
            <ExternalLinkIcon className="ml-2" />
          </a>
        </div>
      </div>

      {/* Ad Label */}
      {media_type.includes("ADS") && (
        <div className="absolute right-4 top-4 rounded-full bg-light-grey px-4 py-2 text-xs font-extrabold text-primary">
          {t("tasks.post_details.ad")}
        </div>
      )}

      {selectedCommentIds.length > 1 && (
        <div className="absolute -bottom-2 left-1/2 -z-10 h-10 w-[94%] -translate-x-1/2 rounded-[10px] bg-white/50"></div>
      )}
    </div>
  );
};
