// types/PLANS.config.ts

export const PLANS = [
  {
    id: 0,
    name: "plans.basic.name",
    description: "plans.basic.description",
    keyFeatures: [
      { label: "plans.features.Channel", value: "1" },
      {
        label: "plans.features.direct_messaging",
        value: true,
      },
      {
        label: "plans.features.ad_comments",
        value: false,
      },
    ],
    additionalFeatures: [
      "plans.features.unlimited_comment_responses",
      "plans.features.ai_adaptation",
    ],
    monthlyPrice: 12,
    yearlyPrice: 9,
    isPopular: false,
    iconColor: "dark-grey",
  },
  {
    id: 1,
    name: "plans.standard.name",
    description: "plans.standard.description",
    keyFeatures: [
      {
        label: "plans.features.Channels",
        value: "plans.features.unlimited",
      },
      {
        label: "plans.features.direct_messaging",
        value: true,
      },
      {
        label: "plans.features.ad_comments",
        value: false,
      },
    ],
    additionalFeatures: [
      "plans.features.unlimited_comment_responses",
      "plans.features.ai_adaptation",
      "plans.features.automatic_comment_masking",
    ],
    monthlyPrice: 29,
    yearlyPrice: 19,
    isPopular: true,
    iconColor: "light-violet",
  },
  {
    id: 2,
    name: "plans.pro.name",
    description: "plans.pro.description",
    keyFeatures: [
      {
        label: "plans.features.Channels",
        value: "plans.features.unlimited",
      },
      {
        label: "plans.features.direct_messaging",
        value: true,
      },
      { label: "plans.features.ad_comments", value: true },
    ],
    additionalFeatures: [
      "plans.features.unlimited_comment_responses",
      "plans.features.ai_adaptation",
      "plans.features.automatic_comment_masking",
    ],
    monthlyPrice: 69,
    yearlyPrice: 49,
    isPopular: false,
    iconColor: "orange",
  },
  {
    id: 3,
    name: "plans.enterprise.name",
    description: "plans.enterprise.description",
    keyFeatures: [
      {
        label: "plans.features.enterprise_support",
        value: true,
      },
      {
        label: "plans.features.multi_user_access",
        value: true,
      },
      {
        label: "plans.features.multi_account_support",
        value: true,
      },
    ],
    additionalFeatures: [
      "plans.features.unlimited_channels",
      "plans.features.unlimited_comment_responses",
      "plans.features.direct_messaging",
      "plans.features.automatic_comment_masking",
      "plans.features.ai_adaptation",
      "plans.features.personalized_support",
    ],
    isPopular: false,
    iconColor: "red",
  },
];

// Define the PRICE_MAP as provided
export const PRICE_MAP: Record<
  0 | 1 | 2 | 3,
  { monthly?: string; yearly: string; productId?: string }
> = {
  0: {
    monthly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWwMAHUseIFDkZqP8A2Ulp"
        : "price_1QwRE7AHUseIFDkZqSArjV5D",
    yearly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWwMAHUseIFDkZZniJtY9z"
        : "price_1QwREOAHUseIFDkZXAfD4Weq",
    productId: "prod_RCJx0GqqmaNIts", // Add your product IDs here
  },
  1: {
    monthly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWx1AHUseIFDkZPWZcTwbH"
        : "price_1QwRHLAHUseIFDkZITw35PE3",
    yearly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWxPAHUseIFDkZzBZZ1ZS3"
        : "price_1QwRHgAHUseIFDkZPNg1WHbH",
    productId: "prod_RCJxzoLSsGs4k0",
  },
  2: {
    monthly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWykAHUseIFDkZ82MlMqpH"
        : "price_1QwRIyAHUseIFDkZE3oe3Kpl",
    yearly:
      process.env.NODE_ENV === "development"
        ? "price_1QIWzFAHUseIFDkZn0SvnHfp"
        : "price_1QwRJGAHUseIFDkZ67aBvIpS",
    productId: "prod_RCJxYtXKLL4N2J",
  },
  3: {
    monthly:
      process.env.NODE_ENV === "development"
        ? ""
        : "price_1QlRu3AHUseIFDkZv4Q0DxiT",
    yearly:
      process.env.NODE_ENV === "development"
        ? "price_1QJKAgAHUseIFDkZCFI2TpZT"
        : "price_1QJvJBAHUseIFDkZeEMw2r1T",
    productId: "prod_RCJxjfgHt7E3BS",
  },
};

// Define the possible billing cycles
export type BillingCycle = "monthly" | "yearly";
export type PlanId = 0 | 1 | 2 | 3;
