// settings/OrganizationSettings.tsx

import { useState, useMemo } from "react";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { WorkspaceDetails } from "@types";

import { useOrganizationLicenses } from "@/axios/mutations/useOrganizationLicenses";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Loader } from "@/assets/icons";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import { useTranslation } from "react-i18next";

const OrganizationSettings = () => {
  const { t } = useTranslation();

  const { organization, workspaces, refetchWorkspaces, refetchOrganization } =
    useUserContext();
  const { refetchSubscriptionDetails } = useSubscriptionContext();

  const { assignLicenseToWorkspace, removeLicenseFromWorkspace } =
    useOrganizationLicenses();

  const availableLicenses = useMemo(
    () => organization?.available_licenses || 0,
    [organization],
  );

  const [actionLoadingId, setActionLoadingId] = useState<string | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<WorkspaceDetails | null>(null);
  const [actionType, setActionType] = useState<"assign" | "remove" | null>(
    null,
  );

  if (!organization) {
    return;
  }

  const handleAssignLicense = async (workspace: WorkspaceDetails) => {
    setActionLoadingId(workspace._id);

    try {
      await assignLicenseToWorkspace(organization?._id, workspace._id);
      await refetchWorkspaces();
      await refetchOrganization();
      await refetchSubscriptionDetails();
    } catch (error) {
      console.error("Failed to assign license", error);
      // Optionally, display an error message to the user
    } finally {
      setActionLoadingId(null);
    }
  };

  const handleRemoveLicense = async (workspace: WorkspaceDetails) => {
    setActionLoadingId(workspace._id);

    try {
      await removeLicenseFromWorkspace(organization._id, workspace._id);
      await refetchWorkspaces();
      await refetchOrganization();
      await refetchSubscriptionDetails();
    } catch (error) {
      console.error("Failed to remove license", error);
      // Optionally, display an error message to the user
    } finally {
      setActionLoadingId(null);
    }
  };

  const openConfirmDialog = (
    workspace: WorkspaceDetails,
    type: "assign" | "remove",
  ) => {
    setSelectedWorkspace(workspace);
    setActionType(type);
    setIsConfirmDialogOpen(true);
  };

  const confirmAction = async () => {
    if (!selectedWorkspace || !actionType) return;
    if (actionType === "assign") {
      await handleAssignLicense(selectedWorkspace);
    } else if (actionType === "remove") {
      await handleRemoveLicense(selectedWorkspace);
    }

    setIsConfirmDialogOpen(false);
  };

  return (
    <div className="flex h-full overflow-hidden bg-light-grey">
      <div className="flex h-full w-[600px] flex-col overflow-auto rounded-[12px] bg-white p-8 scrollbar-hide">
        <div>
          <h2 className="mb-8 text-base font-semibold text-primary">
            {t("settings.organization.title")}
          </h2>
          <div className="mb-6">
            <p className="text-sm font-medium text-dark-grey">
              {t("settings.organization?.total_licenses")}{" "}
              {organization?.total_licenses}
            </p>
            <p className="text-sm font-medium text-dark-grey">
              {t("settings.organization.available_licenses")}{" "}
              {availableLicenses}
            </p>
          </div>

          <h3 className="mb-4 mt-6 text-base font-semibold text-primary">
            {t("settings.organization.workspaces")}
          </h3>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>
                  {t("settings.organization.workspace_name")}
                </TableHead>
                <TableHead>{t("settings.organization.status")}</TableHead>
                <TableHead>{t("settings.organization.action")}</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {workspaces?.map((ws) => {
                const isUsingLicense =
                  ws.organization_id &&
                  ws.subscription_id === organization.subscription_id;

                const hasLicense =
                  ws.subscription_id &&
                  ws.subscription_id !== organization.subscription_id &&
                  (ws.subscription_status == "active" ||
                    ws.subscription_status == "trialing");

                return (
                  <TableRow key={ws._id}>
                    <TableCell>{ws.name}</TableCell>
                    <TableCell>
                      {t(
                        isUsingLicense
                          ? "settings.organization.using_license"
                          : hasLicense
                            ? "settings.organization.already_has_license"
                            : "settings.organization.not_using_license",
                      )}
                    </TableCell>
                    <TableCell>
                      {(!hasLicense || isUsingLicense) && (
                        <Button
                          onClick={() =>
                            openConfirmDialog(
                              ws,
                              isUsingLicense ? "remove" : "assign",
                            )
                          }
                          disabled={
                            (!isUsingLicense && availableLicenses <= 0) ||
                            actionLoadingId === ws._id
                          }
                          className={
                            isUsingLicense
                              ? "bg-red hover:bg-red hover:brightness-90"
                              : "bg-violet font-semibold hover:bg-violet hover:brightness-110"
                          }
                        >
                          {t(
                            isUsingLicense
                              ? "settings.organization.remove_license"
                              : "settings.organization.assign_license",
                          )}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Confirm Action Dialog */}
      <Dialog open={isConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t(
                actionType === "assign"
                  ? "settings.organization.assign_license_title"
                  : "settings.organization.remove_license_title",
              )}
            </DialogTitle>
            <DialogDescription>
              {actionType === "assign"
                ? t("settings.organization.confirm_assign", {
                    workspace: selectedWorkspace?.name,
                  })
                : t("settings.organization.confirm_remove", {
                    workspace: selectedWorkspace?.name,
                  })}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              onClick={() => setIsConfirmDialogOpen(false)}
              disabled={actionLoadingId !== null}
            >
              {t("settings.organization.cancel")}
            </Button>

            <Button
              onClick={confirmAction}
              className={
                actionType === "assign"
                  ? "bg-violet hover:bg-violet hover:brightness-110"
                  : "bg-red hover:bg-red hover:brightness-90"
              }
              disabled={actionLoadingId ? true : false}
            >
              {t(
                actionType === "assign"
                  ? "settings.organization.assign"
                  : "settings.organization.remove",
              )}
              {actionLoadingId && (
                <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrganizationSettings;
