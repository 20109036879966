/* eslint-disable react-hooks/exhaustive-deps */
// comments.context.tsx

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Comment, GetCommentsDto } from "@/types/comments.type";
import { useComments } from "@/axios/queries/useComments";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { WorkspaceDetails } from "@/types";
import {
  AssistantMessage,
  useAssistantChat,
} from "@/axios/mutations/useAssistantChat";
import {
  useGenerateReply,
  useGenerateReplies,
  useReplyToItems,
  useLikeComments,
  useHideComments,
} from "@/axios/mutations";

import { isEqual } from "lodash";
import { TaskContextType } from "@/types/task-context.type";
import { useSkipItems } from "@/axios/mutations/useSkipItems";
import { CommentReplyResponse } from "@/axios/mutations/useReplyToItems";
import { useNavBar } from "@/layouts/NavBar.context";
import { useTranslation } from "react-i18next";

type AnimationType = "to-do" | "done" | "skipped" | "hidden";

export const CommentsContext = createContext<TaskContextType | undefined>(
  undefined,
);

export const useCommentsContext = (): TaskContextType => {
  const context = useContext(CommentsContext);
  if (!context) {
    throw new Error("useCommentsContext must be used within CommentsProvider");
  }
  return context;
};

export const CommentsProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  const { currentWorkspace } = useWorkspaceContext();
  const workspace_id = (currentWorkspace as WorkspaceDetails)?.workspace_id;
  const { refetchCountTodo } = useNavBar();

  /**
   * Initialize filterOptions from localStorage if available.
   */
  const [filterOptions, setFilterOptions] = useState<GetCommentsDto>(() => {
    return {
      limit: 25,
      page: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      category: undefined,
      sentiment: undefined,
      channel_type: undefined,
      hiddenFilter: undefined,
      likedFilter: undefined,
    };
  });

  // Persist filterOptions in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "commentsFilterOptions",
      JSON.stringify({
        ...filterOptions,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
      }),
    );
  }, [filterOptions]);

  // State for infinite scroll loading
  const {
    data: fetchedComments = [],
    isLoadingInitialData,
    isLoadingMore,
    size,
    setSize,
    hasMore,
    refetch: refetchComments,
  } = useComments(workspace_id, filterOptions);

  const { replyToItems } = useReplyToItems();
  const { likeComments } = useLikeComments();
  const { hideComments } = useHideComments();
  const { skipItems } = useSkipItems();

  const [comments, setComments] = useState<Comment[]>([]);
  const commentsRef = useRef(comments); // Keep a stable reference to `comments`

  // Synchronize commentsRef with the latest comments state
  useEffect(() => {
    commentsRef.current = comments;
  }, [comments]);

  const [activeTab, setActiveTab] = useState<"to-do" | "done" | "skipped">(
    "to-do",
  );

  const [isBulkActionLoading, setIsBulkActionLoading] = useState<
    string[] | null
  >(null);

  // States for opened and selected comments
  const [openedCommentId, setOpenedCommentId] = useState<string | null>(null);
  const [selectedCommentIds, setSelectedCommentIds] = useState<string[]>([]);

  const [reply, setReply] = useState<string>("");

  // Ref to keep track of the "active" comment ID for async operations
  const activeCommentIdRef = useRef<string | null>(null);

  // Function to check if the comment ID matches the active one
  const isCurrentComment = (commentId: string | null): boolean => {
    return commentId === activeCommentIdRef.current;
  };

  // AI Panel
  const [AiPanelIsOpen, setAIPanelIsOpen] = useState<boolean>(false);

  // Access assistant-related states and functions
  const {
    messages,
    interactWithAssistant,
    isStreaming,
    error,
    resetChat,
    addMessage,
  } = useAssistantChat();

  // Hooks for generating replies
  const { generateReply } = useGenerateReply();
  const { generateReplies } = useGenerateReplies();
  const [selectedGeneratedReplies, setSelectedGeneratedReplies] = useState<{
    [key: string]: string;
  }>({});

  // Create a ref to hold the previous workspace_id
  const prevWorkspaceIdRef = useRef(workspace_id);

  // Reset comments and related state when workspace_id changes
  useEffect(() => {
    // Only reset state if workspace_id has changed to a new value
    if (workspace_id && workspace_id !== prevWorkspaceIdRef.current) {
      setSelectedGeneratedReplies({});
      setComments([]);
      setOpenedCommentId(null);
      setSelectedCommentIds([]);
      commentsRef.current = [];

      setActiveTab("to-do");
      setAnimatingItems({});
      setIsBulkActionLoading(null);
      setAIPanelIsOpen(false);

      // Update the ref to the new workspace_id
      prevWorkspaceIdRef.current = workspace_id;
    }
  }, [workspace_id]);

  useEffect(() => {
    if (selectedCommentIds.length === 0 && !openedCommentId) {
      setSelectedGeneratedReplies({});
    }
  }, [selectedCommentIds, openedCommentId]);

  /**
   * Merges fetched comments with local comments, preserving optimistic updates.
   */
  useEffect(() => {
    const mergeComments = (): Comment[] => {
      const commentsMap = new Map<string, Comment>(
        commentsRef.current.map((c) => [c._id, c]),
      );

      let hasChanges = false;

      const mergedComments = fetchedComments
        ?.map((fetchedComment) => {
          if (!fetchedComment) return null;

          const localComment = commentsMap.get(fetchedComment._id);

          if (localComment && localComment.status !== fetchedComment.status) {
            // Preserve the local optimistic update
            return localComment;
          }

          if (!localComment || !isEqual(localComment, fetchedComment)) {
            hasChanges = true;
          }

          return fetchedComment;
        })
        .filter((c) => c !== null);

      // // Include any local comments not present in fetchedComments
      // commentsMap.forEach((localComment, id) => {
      //   if (!mergedComments.some((c) => c._id === id)) {
      //     mergedComments.push(localComment);
      //     hasChanges = true;
      //   }
      // });

      // Only update the comments state if there are changes
      if (hasChanges || mergedComments.length !== commentsRef.current.length) {
        return mergedComments;
      } else {
        return commentsRef.current; // No changes detected, return current state
      }
    };

    const newComments = mergeComments();
    setComments(newComments);
  }, [fetchedComments, workspace_id]);

  // Second useEffect: Update openedCommentId and selectedCommentIds based on comments
  useEffect(() => {
    const currentCommentIds = new Set(comments.map((c) => c._id));

    // Only set to null if the comment no longer exists
    if (openedCommentId && !currentCommentIds.has(openedCommentId)) {
      console.debug(`Comment ${openedCommentId} no longer exists. Closing.`);
      setOpenedCommentId(null);
    }

    // Update selectedCommentIds only if necessary
    if (selectedCommentIds.length) {
      const filteredIds = selectedCommentIds.filter((id) =>
        currentCommentIds.has(id),
      );
      if (filteredIds.length !== selectedCommentIds.length) {
        console.debug(`Updating selected comments:`, filteredIds);
        setSelectedCommentIds(filteredIds);
      }
    }

    // console.debug("Opened Comment:", {
    //   comment: comments?.find((c) => c._id == openedCommentId),
    //   openedCommentId,
    // });
  }, [comments, openedCommentId, selectedCommentIds]);

  /**
   * Applies new filters and resets the comments and pagination.
   *
   * @param filters - The filters to apply.
   */
  const applyFilters = useCallback(
    (filters: Partial<GetCommentsDto>) => {
      setComments([]);
      setFilterOptions((prev) => ({ ...prev, ...filters, page: 1 }));
      setSize(1); // Reset pagination to the first page
    },
    [setFilterOptions, setComments, setSize],
  );

  const resetFilters = useCallback(() => {
    setFilterOptions({
      limit: 25,
      page: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      category: undefined,
      sentiment: undefined,
      channel_type: undefined,
      hiddenFilter: undefined,
      likedFilter: undefined,
    });

    localStorage.setItem(
      "commentsFilterOptions",
      JSON.stringify({
        limit: 25,
        page: 1,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        category: undefined,
        sentiment: undefined,
        channel_type: undefined,
        hiddenFilter: undefined,
        likedFilter: undefined,
      }),
    );

    setComments([]);
    setSize(1);
    // Add any other state resets if necessary
  }, [setFilterOptions, setComments, setSize]);

  /**
   * Loads more comments for infinite scrolling.
   */
  const loadMoreComments = useCallback(() => {
    if (hasMore && !isLoadingMore) {
      setSize(size + 1);
    }
  }, [hasMore, isLoadingMore, setSize, size]);

  // Animation state
  const [animatingItems, setAnimatingItems] = useState<
    Record<string, AnimationType>
  >({});

  /**
   * Updates the status of a comment.
   *
   * @param id - The ID of the comment to update.
   * @param status - The new status ('done', 'skipped', etc.).
   */
  const updateItemStatus = useCallback(
    (id: string, status: "to-do" | "done" | "skipped") => {
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === id ? { ...comment, status } : comment,
        ),
      );
    },
    [],
  );

  /**
   * Handles the end of the animation.
   *
   * @param id - The ID of the item whose animation ended.
   */
  const handleAnimationEnd = useCallback(
    (id: string) => {
      let status = animatingItems[id];
      if (status) {
        if (status === "hidden") {
          status = "done";
        }

        updateItemStatus(id, status);
        setAnimatingItems((prev) => {
          const updated = { ...prev };
          delete updated[id];
          return updated;
        });
      }
    },
    [animatingItems, updateItemStatus],
  );

  /**
   * Deselects all comments and clears the opened comment.
   */
  const deselectAllComments = useCallback((): void => {
    setSelectedCommentIds([]);
    setOpenedCommentId(null);
    setSelectedGeneratedReplies({});
  }, []);

  /**
   * Deselects a selected generated reply for a specific comment.
   * @param commentId - The ID of the comment to remove the selected reply for.
   */
  const removeSelectedGeneratedReply = useCallback((commentId: string) => {
    setSelectedGeneratedReplies((prevReplies) => {
      const updatedReplies = { ...prevReplies };
      delete updatedReplies[commentId]; // Remove the entry by key
      return updatedReplies; // Return the new object without the deleted key
    });
  }, []);

  /**
   * Initiates a status update with animation.
   *
   * @param id - The ID of the item to update.
   * @param status - The new status ("done" or "skipped").
   */
  const initiateStatusUpdate = useCallback(
    (id: string, status: AnimationType) => {
      setAnimatingItems((prev) => ({ ...prev, [id]: status }));
      handleAnimationEnd(id);
    },
    [handleAnimationEnd],
  );

  // 1. Skip Comments Function
  const skipSelectedComments = useCallback(
    async (ids: string[], skip: boolean) => {
      setIsBulkActionLoading(
        isBulkActionLoading ? [...isBulkActionLoading, ...ids] : null,
      );

      const commentsToSkip = comments
        ?.filter((c) => ids.includes(c._id))
        ?.map((c) => ({ _id_comment: c._id, skip: skip }));

      commentsToSkip?.forEach((comment) => {
        initiateStatusUpdate(comment._id_comment, skip ? "skipped" : "to-do");
      });

      deselectAllComments();

      try {
        // Proceed with the API call
        void skipItems({ comments: commentsToSkip });
        console.debug("Skipped comments:", commentsToSkip);
        // Refetch comments to get updated data from the server
        refetchComments();

        // Optimistically update the comments
        const commentsToSkipIds = commentsToSkip.map((c) => c._id_comment);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            commentsToSkipIds.includes(comment._id)
              ? {
                  ...comment,
                  skipped: {
                    status: skip,
                    timestamp: new Date(),
                  },
                }
              : comment,
          ),
        );

        if (commentsToSkipIds?.length == 1 && activeTab == "to-do") {
          openNextOlderComment(commentsToSkipIds[0]);
        }
      } catch (error) {
        console.error("Error skipping comments:", error);
      } finally {
        await refetchCountTodo();
      }

      setIsBulkActionLoading(null);
    },
    [
      comments,
      initiateStatusUpdate,
      refetchComments,
      skipItems,
      deselectAllComments,
    ],
  );

  // 2. Like Comments Function
  const likeSelectedComments = useCallback(
    async (ids: string[], like = true) => {
      setIsBulkActionLoading(
        isBulkActionLoading ? [...isBulkActionLoading, ...ids] : null,
      );

      const commentsToLike = ids.map((id) => ({
        _id_comment: id,
        channel_id:
          comments.find((c) => c._id === id)?.channel.channel_id || "",
        like,
      }));

      commentsToLike.forEach(({ _id_comment }) => {
        const comment = comments.find((c) => c._id === _id_comment);

        if (comment?.status != "done") {
          initiateStatusUpdate(_id_comment, "done");
        }
      });

      if (activeTab != "done") {
        deselectAllComments();
      }

      try {
        await likeComments({ workspace_id, comments: commentsToLike });
        refetchComments();

        // Optimistically update the comments
        const commentsToLikeIds = commentsToLike.map((c) => c._id_comment);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            commentsToLikeIds.includes(comment._id)
              ? {
                  ...comment,
                  liked: {
                    status: like,
                    transition: like ? "liking" : "unliking",
                    timestamp: new Date(),
                  },
                  // status: "done",
                }
              : comment,
          ),
        );

        if (commentsToLike?.length == 1 && activeTab == "to-do") {
          openNextOlderComment(commentsToLike[0]._id_comment);
        }
      } catch (error) {
        console.error(`Error ${like ? "liking" : "unliking"} comments:`, error);
      } finally {
        await refetchCountTodo();
      }

      setIsBulkActionLoading(null);
    },
    [
      comments,
      initiateStatusUpdate,
      likeComments,
      refetchComments,
      workspace_id,
      deselectAllComments,
    ],
  );

  // 3. Hide Comments Function
  const hideSelectedComments = useCallback(
    async (ids: string[], hide = true) => {
      setIsBulkActionLoading(
        isBulkActionLoading ? [...isBulkActionLoading, ...ids] : null,
      );

      const commentsToHide = ids.map((id) => ({
        _id_comment: id,
        channel_id:
          comments.find((c) => c._id === id)?.channel.channel_id || "",
        hide,
      }));

      commentsToHide.forEach(({ _id_comment }) => {
        const comment = comments.find((c) => c._id === _id_comment);

        if (comment?.status != "done") {
          initiateStatusUpdate(_id_comment, "hidden");
        }
      });

      if (activeTab != "done") {
        deselectAllComments();
      }

      try {
        await hideComments({ workspace_id, comments: commentsToHide });
        refetchComments();

        // Optimistically update the comments
        const commentsToHideIds = commentsToHide.map((c) => c._id_comment);

        setComments((prevComments) =>
          prevComments.map((comment) =>
            commentsToHideIds.includes(comment._id)
              ? {
                  ...comment,
                  hidden: {
                    status: hide,
                    transition: hide ? "hiding" : "showing",
                    timestamp: new Date(),
                  },
                }
              : comment,
          ),
        );

        if (commentsToHide?.length == 1 && activeTab == "to-do") {
          openNextOlderComment(commentsToHide[0]._id_comment);
        }

        console.debug(`Hid comments: ${hide}`, commentsToHide);
      } catch (error) {
        console.error(`Error ${hide ? "hiding" : "unhiding"} comments:`, error);
      } finally {
        await refetchCountTodo();
      }

      setIsBulkActionLoading(null);
    },
    [
      comments,
      hideComments,
      initiateStatusUpdate,
      refetchComments,
      workspace_id,
      deselectAllComments,
    ],
  );

  // 4. Reply to Comments Function
  const replyToSelectedComments = useCallback(
    async (messagesArray: { id: string; message: string }[]) => {
      if (messagesArray.length === 0) return;

      setIsBulkActionLoading(
        isBulkActionLoading
          ? [...isBulkActionLoading, ...messagesArray?.map((c) => c.id)]
          : null,
      );

      const commentsToReply = messagesArray.map(({ id, message }) => ({
        _id_comment: id,
        channel_id:
          comments.find((c) => c._id === id)?.channel.channel_id || "",
        message,
      }));

      commentsToReply.forEach(({ _id_comment }) => {
        initiateStatusUpdate(_id_comment, "done");
      });

      if (
        commentsToReply.length === 1 &&
        commentsToReply[0]._id_comment == openedCommentId
      ) {
        commentsToReply.forEach(({ _id_comment }) => {
          initiateStatusUpdate(_id_comment, "done");
          deselectComment(_id_comment);
        });
      } else {
        closeComment();
        deselectAllComments();
      }

      try {
        const replies = (
          await replyToItems({
            workspace_id,
            comments: commentsToReply,
          })
        )?.comments;

        await refetchComments();

        (replies as CommentReplyResponse[]).forEach(({ comment_id, reply }) => {
          setComments((prevComments) =>
            prevComments.map((comment) =>
              comment._id === comment_id
                ? {
                    ...comment,
                    // status: "done",
                    reply,
                    replies: [...(comment.replies || []), reply],
                  }
                : comment,
            ),
          );
        });
      } catch (error) {
        console.error("Error replying to comments:", error);
      } finally {
        await refetchCountTodo();
      }

      setIsBulkActionLoading(null);
    },
    [
      comments,
      initiateStatusUpdate,
      refetchComments,
      replyToItems,
      workspace_id,
      deselectAllComments,
    ],
  );

  /**
   * Opens a specific comment by ID.
   */
  const openComment = useCallback((id: string): void => {
    setOpenedCommentId(id);
    activeCommentIdRef.current = id; // Update the ref when a comment is opened
  }, []);

  /**
   * Closes the currently opened comment.
   */
  const closeComment = useCallback((): void => {
    removeSelectedGeneratedReply(openedCommentId as string);
    setOpenedCommentId(null);
    activeCommentIdRef.current = null; // Update the ref when a comment is closed
  }, []);

  /**
   * Adds a comment to the selected list and sorts by timestamp.
   */
  const selectComment = useCallback(
    (id: string): void => {
      setSelectedCommentIds((prev) => {
        // Add new ids, avoiding duplicates
        const updatedList =
          openedCommentId &&
          openedCommentId !== id &&
          !prev.includes(openedCommentId)
            ? [...prev, openedCommentId, id]
            : [...prev, id];

        // Remove duplicates and sort by timestamp
        return Array.from(new Set(updatedList)).sort((aId, bId) => {
          const aComment = comments.find((comment) => comment._id === aId);
          const bComment = comments.find((comment) => comment._id === bId);

          const aTime = aComment?.timestamp
            ? new Date(aComment.timestamp).getTime()
            : -Infinity;
          const bTime = bComment?.timestamp
            ? new Date(bComment.timestamp).getTime()
            : -Infinity;

          return bTime - aTime;
        });
      });

      setOpenedCommentId(id);
    },
    [openedCommentId, comments],
  );

  /**
   * Deselects a comment from the selected list and maintains sorting.
   */
  const deselectComment = useCallback(
    (id: string): void => {
      setSelectedCommentIds((prev) =>
        prev
          .filter((commentId) => commentId !== id)
          .sort((aId, bId) => {
            const aComment = comments.find((comment) => comment._id === aId);
            const bComment = comments.find((comment) => comment._id === bId);

            const aTime = aComment?.timestamp
              ? new Date(aComment.timestamp).getTime()
              : -Infinity;
            const bTime = bComment?.timestamp
              ? new Date(bComment.timestamp).getTime()
              : -Infinity;

            return bTime - aTime;
          }),
      );

      // If we are deselecting the opened comment,
      // see if we should open the next older.
      if (id === openedCommentId && activeTab == "to-do") {
        // If there are more selected items besides this one,
        // we can search among that selection for the “next older”.
        // Otherwise, we search among all comments.
        // Our helper function does that automatically.
        openNextOlderComment(id);
      }
    },
    [comments, openedCommentId, selectedCommentIds],
  );

  /**
   * Opens the next older comment after "deselecting" or "acting on" a given comment.
   * If multiple comments are selected, it searches among those;
   * otherwise, it searches among all comments.
   */
  const openNextOlderComment = useCallback(
    (closedCommentId: string) => {
      // 1) Decide which IDs to consider for picking the “next older” comment:
      //    - If we still have multiple selected, we use that set (minus the deselected).
      //    - Otherwise, consider *all* comments (minus the deselected).
      const isMultiSelect = selectedCommentIds?.length > 1;
      const relevantIds = isMultiSelect
        ? selectedCommentIds
        : comments
            ?.filter((c) =>
              filterOptions?.status
                ? c?.status == filterOptions?.status
                : c?.status == "to-do",
            )
            ?.map((c) => c._id);

      // Build the candidate comments list, excluding the “deselected” ID
      const candidateComments = relevantIds
        .filter((id) => id !== closedCommentId)
        .map((id) => comments.find((c) => c._id === id))
        .filter((c): c is Comment => !!c)
        // Sort descending (newest first)
        .sort((a, b) => {
          const aTime = a.timestamp
            ? new Date(a.timestamp).getTime()
            : -Infinity;
          const bTime = b.timestamp
            ? new Date(b.timestamp).getTime()
            : -Infinity;
          return bTime - aTime; // newest first
        });

      // 2) Find the comment we just "removed" or "acted on"
      const deselectedComment = comments.find((c) => c._id === closedCommentId);
      if (!deselectedComment) {
        // If for some reason it’s not in state, just open the newest candidate
        setOpenedCommentId(candidateComments[0]?._id ?? null);
        removeSelectedGeneratedReply(closedCommentId);
        return;
      }
      const deselectedTime = new Date(deselectedComment.timestamp).getTime();

      // 3) Find the first candidate that is strictly older (timestamp < deselectedTime).
      const nextOlderComment = candidateComments.find((comment) => {
        const time = comment.timestamp
          ? new Date(comment.timestamp).getTime()
          : -Infinity;
        return time <= deselectedTime;
      });

      // 4) If there is a strictly older comment, open it; otherwise open the newest in the list
      const newOpenedCommentId =
        nextOlderComment?._id ?? candidateComments[0]?._id;
      setOpenedCommentId(newOpenedCommentId);

      console.debug("Set opened Comment Id to", newOpenedCommentId);

      // 5) Optionally remove AI-generated replies for that “deselected” comment
      removeSelectedGeneratedReply(closedCommentId);

      if (!selectedCommentIds || selectedCommentIds?.length <= 1) {
        setAIPanelIsOpen(false);
      }
    },
    [
      comments,
      selectedCommentIds,
      openedCommentId,
      setOpenedCommentId,
      removeSelectedGeneratedReply,
    ],
  );

  /**
   * Selects all comments by their IDs.
   */
  const selectAllComments = useCallback(
    (ids: string[]): void => {
      openComment(ids[0]);
      setSelectedCommentIds(ids);
    },
    [openComment],
  );

  /**
   * Moves to the next comment in the selected comments.
   */
  const nextComment = useCallback(() => {
    if (!openedCommentId) return;

    const index = selectedCommentIds.indexOf(openedCommentId);

    const nextIndex = index < selectedCommentIds.length - 1 ? index + 1 : 0;
    const nextCommentId = selectedCommentIds[nextIndex];
    openComment(nextCommentId);
  }, [openedCommentId, selectedCommentIds, openComment]);

  /**
   * Moves to the previous comment in the selected comments.
   */
  const previousComment = useCallback(() => {
    if (!openedCommentId) return;

    const index = selectedCommentIds.indexOf(openedCommentId);

    const previousIndex = index > 0 ? index - 1 : selectedCommentIds.length - 1;
    const previousCommentId = selectedCommentIds[previousIndex];
    openComment(previousCommentId);
  }, [openedCommentId, selectedCommentIds, openComment]);

  /**
   * Generates a reply for a specific comment.
   *
   * @param commentId - The ID of the comment.
   */
  const generateReplyForComment = useCallback(
    async (commentId: string): Promise<void> => {
      const comment = comments.find((c) => c._id === commentId);

      if (!comment) {
        console.error("Comment not found:", commentId);
        return;
      }

      try {
        setAIPanelIsOpen(true);

        // Set streaming state for the comment
        isStreaming[commentId] = true;

        if (!comment?.text?.trim()?.length) {
          addMessage(commentId, {
            text: t("tasks.ai_panel.not_supported_comment"),
            from: "ai",
            type: "error",
          });

          return;
        }

        // Call the generateReply function using the comment details
        const reply = await generateReply({
          _id_comment: comment._id,
          post_id: comment.post_id,
          channel_id: comment.channel.channel_id,
          workspace_id: currentWorkspace?._id as string,
        });

        if (reply && reply?.trim()?.length > 0) {
          addMessage(commentId, {
            text: reply as string,
            from: "ai",
            type: "generatedReply",
          });
        } else {
          addMessage(commentId, {
            text: t("tasks.ai_panel.errorMessage"),
            from: "ai",
            type: "error",
          });
        }
      } catch (error) {
        // The hook has already set error, so here we also display the message
        console.error("Failed to generate reply:", error);
        addMessage(commentId, {
          text: t("tasks.ai_panel.errorMessage"),
          from: "ai",
          type: "error",
        });
      } finally {
        // Reset streaming state for the comment
        isStreaming[commentId] = false;
      }
    },
    [
      addMessage,
      comments,
      currentWorkspace?._id,
      generateReply,
      isStreaming,
      setAIPanelIsOpen,
    ],
  );

  /**
   * Generates replies for multiple comments.
   *
   * @param commentIds - Array of comment IDs.
   */
  const generateRepliesForComments = useCallback(
    async (commentIds: string[]): Promise<void> => {
      if (commentIds.length === 0) return;

      try {
        setAIPanelIsOpen(true);

        // Set isStreaming to true for all comments
        commentIds.forEach((commentId) => {
          isStreaming[commentId] = true;
        });

        // Prepare data for API call
        const data = commentIds
          .map((commentId) => {
            const comment = comments.find((c) => c._id === commentId);
            if (!comment) return null;
            return {
              _id: comment._id,
              post_id: comment.post_id,
              channel_id: comment.channel.channel_id,
            };
          })
          .filter((item) => item !== null);

        const workspace_id = currentWorkspace?._id as string;

        const replies = await generateReplies({
          data: data as {
            _id: string;
            post_id: string;
            channel_id: string;
          }[],
          type: "comment",
          workspace_id,
        });

        if (replies) {
          // For each reply, set the generatedReply message for the comment
          replies.forEach((replyItem) => {
            const { _id: commentId, reply } = replyItem;

            if (reply && reply?.trim()?.length > 0) {
              addMessage(commentId, {
                text: reply,
                from: "ai",
                type: "generatedReply",
              });
            } else {
              const comment = comments?.find((com) => com?._id === commentId);

              if (!comment?.text?.trim()?.length) {
                addMessage(commentId, {
                  text: t("tasks.ai_panel.not_supported_comment"),
                  from: "ai",
                  type: "error",
                });
              } else {
                addMessage(commentId, {
                  text: t("tasks.ai_panel.errorMessage"),
                  from: "ai",
                  type: "error",
                });
              }

              addMessage(commentId, {
                text: t("tasks.ai_panel.errorMessage"),
                from: "ai",
                type: "error",
              });
            }

            // Reset streaming state for the comment
            isStreaming[commentId] = false;
          });
        }
      } catch (error) {
        addMessage(openedCommentId as string, {
          text: t("tasks.ai_panel.errorMessage"),
          from: "ai",
          type: "error",
        });

        console.error("Failed to generate replies:", error);
      } finally {
        // Reset isStreaming for all comments
        commentIds.forEach((commentId) => {
          isStreaming[commentId] = false;
        });
      }
    },
    [
      addMessage,
      comments,
      currentWorkspace?._id,
      generateReplies,
      isStreaming,
      setAIPanelIsOpen,
    ],
  );

  /**
   * Gets the last generatedReply messages for selected comments.
   *
   * @returns A record of commentId to AssistantMessage or undefined.
   */
  const getLastGeneratedReplies = useCallback((): Record<
    string,
    AssistantMessage | undefined
  > => {
    const replies: Record<string, AssistantMessage | undefined> = {};
    selectedCommentIds.forEach((commentId) => {
      const commentMessages = messages[commentId] || [];
      const lastGeneratedReply = commentMessages
        .slice()
        .reverse()
        .find((msg) => msg.type === "generatedReply");
      if (lastGeneratedReply) {
        replies[commentId] = lastGeneratedReply;
      }
    });
    return replies;
  }, [messages, selectedCommentIds]);

  /**
   * Gets the list of selected comments that do not have a generated reply.
   *
   * @returns An array of commentIds missing generated replies.
   */
  const getCommentsMissingGeneratedRepliesCount = useCallback((): string[] => {
    return selectedCommentIds.filter((commentId) => {
      const commentMessages = messages[commentId] || [];
      return !commentMessages.some(
        (msg) => msg.type === "generatedReply" || msg.type == "error",
      );
    });
  }, [messages, selectedCommentIds]);

  /**
   * Gets the list of selected comments that do have an error as last message.
   *
   * @returns An array of commentIds missing generated replies.
   */
  const getCommentsWithErrors = useCallback((): string[] => {
    return selectedCommentIds.filter((commentId) => {
      const commentMessages = messages[commentId] || [];
      return (
        !commentMessages.some((msg) => msg.type === "generatedReply") &&
        commentMessages.at(-1)?.type === "error"
      );
    });
  }, [messages, selectedCommentIds]);

  useEffect(() => {
    // Reset the reply state when the opened comment changes
    setReply("");
  }, [openedCommentId]);

  // Memoize contextValue and include all necessary dependencies
  const contextValue: TaskContextType = useMemo(
    () => ({
      reply,
      setReply,
      items: comments,
      isLoadingInitialData,
      isLoadingMore,
      hasMore,
      loadMoreItems: loadMoreComments,
      applyFilters,
      isBulkActionLoading,
      AiPanelIsOpen,
      setAIPanelIsOpen,
      messages,
      isStreaming,
      error,
      animatingItems,
      selectedGeneratedReplies,
      generateReplyForItem: generateReplyForComment,
      generateRepliesForItems: generateRepliesForComments,
      setSelectedGeneratedReplies,
      likeSelectedItems: likeSelectedComments,
      hideSelectedItems: hideSelectedComments,
      replyToSelectedItems: replyToSelectedComments,
      skipSelectedItems: skipSelectedComments,
      addMessage,
      interactWithAssistant,
      resetChat,
      getLastGeneratedReplies,
      getItemsMissingGeneratedRepliesCount:
        getCommentsMissingGeneratedRepliesCount,
      getItemsWithErrors: getCommentsWithErrors,
      updateItemStatus,
      initiateStatusUpdate,
      handleAnimationEnd,
      openedItemId: openedCommentId,
      selectedItemIds: selectedCommentIds,
      isCurrentItem: isCurrentComment,
      openItem: openComment,
      closeItem: closeComment,
      selectItem: selectComment,
      deselectItem: deselectComment,
      selectAllItems: selectAllComments,
      deselectAllItems: deselectAllComments,
      removeSelectedGeneratedReply,
      nextItem: nextComment,
      previousItem: previousComment,
      activeTab,
      setActiveTab,
      filterOptions,
      resetFilters,
    }),
    [
      reply,
      setReply,
      comments,
      isLoadingInitialData,
      isLoadingMore,
      hasMore,
      loadMoreComments,
      applyFilters,
      isBulkActionLoading,
      AiPanelIsOpen,
      setAIPanelIsOpen,
      messages,
      isStreaming,
      error,
      animatingItems,
      selectedGeneratedReplies,
      generateReplyForComment,
      generateRepliesForComments,
      setSelectedGeneratedReplies,
      likeSelectedComments,
      hideSelectedComments,
      replyToSelectedComments,
      skipSelectedComments,
      addMessage,
      interactWithAssistant,
      resetChat,
      getLastGeneratedReplies,
      getCommentsMissingGeneratedRepliesCount,
      getCommentsWithErrors,
      updateItemStatus,
      initiateStatusUpdate,
      handleAnimationEnd,
      openedCommentId,
      selectedCommentIds,
      isCurrentComment,
      openComment,
      closeComment,
      selectComment,
      deselectComment,
      selectAllComments,
      deselectAllComments,
      removeSelectedGeneratedReply,
      nextComment,
      previousComment,
      activeTab,
      setActiveTab,
      filterOptions,
    ],
  );

  return (
    <CommentsContext.Provider value={contextValue}>
      {children}
    </CommentsContext.Provider>
  );
};
