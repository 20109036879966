// src/axios/queries/useInstagramAccounts.ts
import { useState } from "react";
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { InstagramAccount } from "@types";

interface UseInstagramAccountsParams {
  workspace_id: string;
  channel_id?: string; // Optional channel_id to fetch a specific Instagram account
  enabled?: boolean; // To control whether the hook is enabled
}

function useInstagramAccounts({
  workspace_id,
  channel_id,
  enabled = true,
}: UseInstagramAccountsParams) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  const cacheKey = channel_id
    ? `/api/instagram/accounts/${workspace_id}?channel_id=${channel_id}`
    : `/api/instagram/accounts/${workspace_id}`;

  const { data, error, mutate, isValidating } = useSWR<
    InstagramAccount | InstagramAccount[]
  >(
    enabled ? cacheKey : null, // Fetch only if enabled
    (url: string) => fetcher(url, "get", null, false, enabled),
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<InstagramAccount[] | InstagramAccount> => {
    setIsRefetching(true);
    const updatedData = (await mutate()) as
      | InstagramAccount
      | InstagramAccount[]; // Try to get updated data
    setIsRefetching(false);

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as InstagramAccount | InstagramAccount[],
    isLoading: !data && !error && !isValidating, // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useInstagramAccounts };
