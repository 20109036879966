// SubscriptionAlertDialog.tsx
import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useSubscriptionContext } from "../subscription-provider/SubscriptionProvider";
import {
  getAlertDialogButtonText,
  getSubscriptionIssueMessage,
} from "@/utils/billingErrorMessage.utils";
import { Crisp } from "crisp-sdk-web";

import { PlanDetailsPopup } from "@/components/PlanDetailsPopup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { navigateWithParams } from "@/utils/navigateWithParams";

const SubscriptionAlertDialog: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const {
    subscriptionDetails,
    subscriptionProblem,
    isLoadingSubscriptionDetails,
  } = useSubscriptionContext();

  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showPlanPopup, setShowPlanPopup] = useState(false);
  const [hasAlertBeenShown, setHasAlertBeenShown] = useState(false);

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // Check if alert should be shown
      if (!hasAlertBeenShown) {
        setShowPlanPopup(true);
        setHasAlertBeenShown(true);
        sessionStorage.setItem("subscriptionAlertShown", "true");
      }
    }
  };

  // Show the alert dialog when there's a subscription problem
  useEffect(() => {
    // Initialize hasAlertBeenShown from sessionStorage
    const alertShown = sessionStorage.getItem("subscriptionAlertShown");

    if (alertShown === "true") {
      setHasAlertBeenShown(true);
    }

    // Add event listener for visibility change
    document.addEventListener(
      "visibilitychangeForSubscription",
      handleVisibilityChange,
    );

    if (subscriptionProblem) {
      if (!location.pathname.includes("/settings")) {
        setHasAlertBeenShown(false);

        if (location.pathname !== "/settings/billing") {
          navigateWithParams("/settings/billing", navigate, location.search);
        }
      }
      if (!hasAlertBeenShown) {
        setShowAlertDialog(true);
        setHasAlertBeenShown(true);
        sessionStorage.setItem("subscriptionAlertShown", "true");
      }
    } else {
      setShowAlertDialog(false);
    }

    return () => {
      document.removeEventListener(
        "visibilitychangeForSubscription",
        handleVisibilityChange,
      );
    };
  }, [hasAlertBeenShown, navigate, subscriptionProblem]);

  const handleAlertDialogClose = async () => {
    if (
      !subscriptionDetails ||
      subscriptionDetails.status === "canceled" ||
      subscriptionDetails.status === "incomplete_expired"
    ) {
      // No subscription or expired subscription, open PlanDetailsPopup
      setShowAlertDialog(false);
      setShowPlanPopup(true);
    } else if (
      subscriptionDetails.status === "past_due" ||
      subscriptionDetails.status === "unpaid"
    ) {
      // Payment issue, redirect to payment
      const invoices = subscriptionDetails.invoices;
      const openInvoices = invoices.filter(
        (invoice) => invoice.status === "open",
      );
      const sortedOpenInvoices = openInvoices.sort((a, b) => b.date - a.date);
      const lastOpenInvoice = sortedOpenInvoices[0];

      if (lastOpenInvoice?.hosted_invoice_url) {
        window.location.href = lastOpenInvoice.hosted_invoice_url;
      } else {
        console.error("No hosted_invoice_url found for open invoices.");
      }
    } else if (subscriptionDetails.status === "incomplete") {
      // Initial payment failed, guide user to complete payment
      setShowAlertDialog(false);
      setShowPlanPopup(true);
    } else {
      // Other statuses
      setShowAlertDialog(false);
    }
  };

  const handleClosePlanPopup = () => {
    setShowPlanPopup(false);

    if (!location.pathname.includes("/settings")) {
      setShowAlertDialog(true);
    }
  };

  useEffect(() => {
    if (subscriptionProblem && !subscriptionDetails) {
      const storageKey = "crispMessageSentAt";
      const now = Date.now();
      const tenMinutesInMs = 10 * 60 * 1000; // 10 minutes en millisecondes
      const delayInMs = 2000; // 2 secondes en millisecondes

      // Récupérer le timestamp de l'envoi précédent
      const sentAt = localStorage.getItem(storageKey);

      if (sentAt) {
        const sentTime = parseInt(sentAt, 10);
        if (now - sentTime < tenMinutesInMs) {
          // Le message a déjà été envoyé il y a moins de 10 minutes
          return;
        }
      }

      // Définir un délai de 2 secondes avant d'envoyer le message
      const timer = setTimeout(() => {
        if (window.$crisp) {
          try {
            // Envoyer le message via le SDK Crisp
            Crisp.message.showText(
              t("onboarding.step3_choose_plan.crisp_message"),
            );
            Crisp.message.send(
              t("onboarding.step3_choose_plan.crisp_message"),
              "string",
            );

            // Enregistrer le timestamp de l'envoi dans localStorage
            localStorage.setItem(storageKey, Date.now().toString());
          } catch (error) {
            console.error("Erreur lors de l'envoi du message Crisp:", error);
          }
        } else {
          console.warn("Crisp n'est pas disponible.");
        }
      }, delayInMs);

      // Nettoyer le timeout si le composant est démonté avant l'expiration du délai
      return () => clearTimeout(timer);
    }
  }, [t]);

  if (isLoadingSubscriptionDetails) {
    return null;
  }

  return (
    <>
      {/* Alert Dialog for Subscription Problems */}
      <AlertDialog open={showAlertDialog && !showPlanPopup}>
        <AlertDialogContent className="max-w-md">
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t(
                subscriptionDetails
                  ? "subscription_dialog.issue_title"
                  : "subscription_dialog.required_title",
              )}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {subscriptionDetails ? (
                <>
                  {t(getSubscriptionIssueMessage(subscriptionDetails.status), {
                    status: subscriptionDetails.status,
                  })}
                </>
              ) : (
                t("subscription_dialog.trial_ended")
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <Button
              onClick={handleAlertDialogClose}
              className="flex items-center justify-center"
            >
              {t(getAlertDialogButtonText(subscriptionDetails?.status))}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Plan Details Popup */}
      {showPlanPopup && <PlanDetailsPopup onClose={handleClosePlanPopup} />}
    </>
  );
};

export default SubscriptionAlertDialog;
