// useDeleteWorkspace.ts
import { useFetcher } from "@axios/fetcher";

function useDeleteWorkspace() {
  const { fetcher } = useFetcher();

  // Function to delete a workspace
  const deleteWorkspace = async (workspace_id: string) => {
    if (!workspace_id) {
      throw new Error("workspace_id is required to delete a workspace");
    }

    // Make the API request to delete the workspace
    await fetcher(`/api/workspaces/${workspace_id}`, "delete");

    return { message: "Workspace deleted successfully" };
  };

  return {
    deleteWorkspace,
  };
}

export { useDeleteWorkspace };
