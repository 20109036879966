// ConversationPanel.tsx

import { useEffect, useMemo, useRef, useState } from "react";
import { CustomTextarea } from "./CustomTextArea";
import { PostDetails } from "./PostDetails";
import { ConversationRenderer } from "./ConversationRenderer";
import { Comment, Conversation, Message, Post } from "@/types";
import { AiGreyIcon, GreyPlusIcon, Loader, MagicIcon } from "@/assets/icons";
import { BulkActionsBar } from "./BulkActionsBar";
import { useTaskContext } from "@/layouts/useTask.context";
import BulkActionPopup from "./BulkActionPopup";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { highlightMatch } from "@/utils/highlightMatch";
import AddPreSavedReplyDialog from "./AddPreSavedReplyDialog";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useDeletePreSavedReply } from "@/axios/mutations/useDeletePreSavedReply";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import DeletePreSavedReplyDialog from "./DeletePresavedReplyDialog";
import { addDays, isBefore } from "date-fns";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const ConversationPanel = ({
  post,
  conversation,
  type,
}: {
  post?: Post;
  conversation: Comment | Conversation;
  type: "comment" | "message";
}) => {
  const { t } = useTranslation();

  const { refetchCountTodo } = useNavBar();

  const { refetchWorkspaces } = useUserContext();
  const { currentWorkspace } = useWorkspaceContext();

  const {
    items,
    openedItemId,
    messages,
    isStreaming,
    reply,
    setReply,
    selectedItemIds,
    selectedGeneratedReplies,
    generateReplyForItem,
    generateRepliesForItems,
    getItemsMissingGeneratedRepliesCount,
    getItemsWithErrors,
    replyToSelectedItems,
    activeTab,
    setSelectedGeneratedReplies,
  } = useTaskContext();

  const { deletePreSavedReply } = useDeletePreSavedReply();

  // State for dialog
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedReplyIndex, setSelectedReplyIndex] = useState<number | null>(
    null,
  );
  const [actionLoading, setActionLoading] = useState(false);

  const [popupData, setPopupData] = useState<{
    message: string;
    icon: JSX.Element | null;
    selectedCount: number;
    position: { top: number; left: number };
    type: "comment" | "message";
  } | null>(null);

  const [isGeneratingReply, setIsGeneratingReply] = useState<boolean>(false);
  const missingReplies = getItemsMissingGeneratedRepliesCount();
  const itemsWithErrors = getItemsWithErrors();
  const hasSelectedReplies = Object.keys(selectedGeneratedReplies).length > 0;
  const totalSelectedItems = selectedItemIds.length;
  const selectedRepliesCount = Object.keys(selectedGeneratedReplies).length;

  const openedItem = items.find((item) => item._id === openedItemId);

  // Determine the channel type and set the character limit
  const channelType = openedItem?.channel?.type; // 'instagram', 'facebook', 'tiktok', 'youtube'
  const maxCharLimit = useMemo(() => {
    switch (channelType) {
      case "tiktok":
        return 150;
      case "tiktok-ads":
        return 150;
      case "youtube":
        return 500;
      case "instagram":
      case "facebook":
        if (type == "comment") {
          return 2000;
        } else {
          return 1000;
        }
      default:
        return 2000; // Default limit
    }
  }, [channelType]);

  // Determine if the conversation is older than 7 days (only for messages)
  const isConversationTooOld = useMemo(() => {
    if (type !== "message" || !(conversation as Conversation).messages) {
      return false;
    }

    // Find all messages where owner is false
    const nonOwnerMessages = (conversation as Conversation).messages.filter(
      (message: Message) => !message.owner,
    );

    if (nonOwnerMessages.length === 0) return false;

    // Get the most recent non-owner message
    const lastNonOwnerMessage = nonOwnerMessages.reduce((prev, current) => {
      return new Date(current.timestamp) > new Date(prev.timestamp)
        ? current
        : prev;
    });

    const sevenDaysAgo = addDays(new Date(), -7);
    const messageDate = new Date(lastNonOwnerMessage.timestamp);
    return isBefore(messageDate, sevenDaysAgo);
  }, [type, conversation]);

  // State for error message
  const [replyError, setReplyError] = useState<string | null>(null);

  const [isAddPreSavedDialogOpen, setAddPreSavedDialogOpen] = useState(false);

  const preSavedReplies = useMemo(() => {
    return currentWorkspace?.pre_saved_replies || [];
  }, [currentWorkspace]);

  const [popoverOpen, setPopoverOpen] = useState(false); // For the button-triggered popover
  const [searchValue, setSearchValue] = useState("");
  const filteredRepliesWithIndex = useMemo(() => {
    return preSavedReplies
      .map((reply, index) => ({ reply, index }))
      .filter(({ reply }) =>
        reply.toLowerCase().includes(searchValue.toLowerCase()),
      );
  }, [preSavedReplies, searchValue]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (popoverOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleSendReply = () => {
    if (reply.trim()) {
      replyToSelectedItems([{ id: openedItemId as string, message: reply }]);
      refetchCountTodo();
      setReply("");
      setReplyError(null); // Clear any existing errors
    }
  };

  const handleSendReplies = () => {
    const messagesToSend = Object.entries(selectedGeneratedReplies).map(
      ([id, text]) => ({ id, message: text }),
    );
    replyToSelectedItems(messagesToSend);
    refetchCountTodo();
  };

  const handleDeleteIconClick = (index: number) => {
    setSelectedReplyIndex(index);
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDeletion = async () => {
    if (selectedReplyIndex === null || !currentWorkspace) return;

    try {
      setActionLoading(true);

      await deletePreSavedReply(currentWorkspace._id, selectedReplyIndex);
      await refetchWorkspaces();

      // Once complete, close the dialog and reset state
      setIsConfirmDialogOpen(false);
      setSelectedReplyIndex(null);
    } catch (error) {
      console.error("Failed to delete pre-saved reply:", error);
    } finally {
      setActionLoading(false);
    }
  };

  /**
   * Handles generating a reply for the opened item.
   */
  const handleGenerateReply = async () => {
    if (!openedItemId) {
      console.error("No item is currently opened");
      return;
    }

    if (!openedItem) {
      console.error("Opened item not found");
      return;
    }

    try {
      setIsGeneratingReply(true);
      await generateReplyForItem(openedItemId);
    } catch (error) {
      console.error("Failed to generate reply:", error);
    } finally {
      setIsGeneratingReply(false);
    }
  };

  const initiateSendReplies = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const popupWidth = 500; // Max width as per BulkActionPopup's design
    const buttonCenterX = rect.left + rect.width / 2;
    const adjustedLeft = buttonCenterX - popupWidth / 2;
    const estimatedPopupHeight = 100; // in pixels
    const margin = 10; // space between the button and the popup

    setPopupData({
      message:
        selectedRepliesCount <= 1
          ? t("tasks.conversation_panel.send_confirmation_one", {
              count: selectedRepliesCount,
            })
          : t("tasks.conversation_panel.send_confirmation_other", {
              count: selectedRepliesCount,
            }),
      icon: <AiGreyIcon className="size-9 shrink-0" />, // Use an appropriate icon
      selectedCount: selectedRepliesCount,
      position: {
        top: rect.top + window.scrollY - estimatedPopupHeight - margin, // Position above the button
        left: adjustedLeft > 10 ? adjustedLeft : 10, // Prevent overflow on the left
      },
      type: "message", // Use "message" as per your BulkActionPopup design
    });
  };

  const handleConfirmSendReplies = () => {
    handleSendReplies();
    setPopupData(null); // Close the popup after action
  };

  const handleCancelSendReplies = () => {
    setPopupData(null); // Close the popup without performing any action
  };

  const insertPreSavedReply = (text: string) => {
    // Insert at cursor or at the end of the reply field
    setReply((prev) => (prev.trim() ? prev + " " + text : text));
    setPopoverOpen(false);
    setSearchValue("");
  };

  /**
   * Handles generating replies for selected items.
   */
  const handleGenerateReplies = async () => {
    if (!selectedItemIds.length) {
      console.error("No items selected");
      return;
    }

    try {
      setIsGeneratingReply(true);
      await generateRepliesForItems([...missingReplies, ...itemsWithErrors]);
    } catch (error) {
      console.error("Failed to generate replies:", error);
    } finally {
      setIsGeneratingReply(false);
    }
  };

  const generatedReply = messages[openedItemId as string]?.find(
    (m) => m.type === "generatedReply",
  );

  const selectAllGeneratedReplies = () => {
    for (const itemId of selectedItemIds) {
      const generatedReply = messages[itemId]
        ?.filter((m) => m.type === "generatedReply")
        ?.pop()?.text;

      if (!generatedReply) continue;

      setSelectedGeneratedReplies((prev) => ({
        ...prev,
        [itemId]: generatedReply,
      }));
    }
  };

  useEffect(() => {
    if (isConversationTooOld && type === "message") {
      setReplyError(t("error.conversation_too_old"));
    } else if (reply.length > maxCharLimit) {
      setReplyError(
        t("error.reply_too_long", {
          count: maxCharLimit,
          channel: t(`connect_channel.${channelType}.name`),
        }),
      );
    } else {
      setReplyError(null);
    }
  }, [reply, maxCharLimit, channelType, isConversationTooOld, type, t]);

  return (
    <div className="flex h-full flex-col rounded-lg bg-light-grey p-6">
      {/* Bulk Actions Bar */}
      <BulkActionsBar type={type} />

      {/* Scrollable Content */}
      <div className="conversation-scroll-container h-full scrollbar-hide">
        {/* Post Details */}
        {post && post.channel && (
          <div
            className={`h-full ${
              post?.title ? "max-h-[142px]" : "max-h-[118px]"
            } ${type == "comment" ? "conversation-snap-item" : ""}`}
          >
            <PostDetails {...post} />
          </div>
        )}

        {/* Middle Section: Conversation Renderer */}
        <div
          className={`middle-section ${type == "comment" ? "conversation-snap-item mt-5 " : "mt-0"}`}
        >
          <ConversationRenderer conversation={conversation} type={type} />
        </div>
      </div>

      {activeTab == "to-do" && (
        <div className="mt-4 flex flex-col">
          {/* AI Response Section */}
          {((!generatedReply &&
            !isGeneratingReply &&
            !isStreaming[openedItemId || ""]) ||
            missingReplies.length > 0 ||
            itemsWithErrors.includes(openedItemId as string) ||
            selectedItemIds.length > 0) && (
            <div className="flex w-[90%] items-center justify-between self-end rounded-[10px] bg-white px-5 py-4">
              <div className="flex items-center space-x-3">
                <AiGreyIcon className="size-10 shrink-0" />
                <span className="text-sm font-medium text-primary">
                  {selectedItemIds.length > 0 &&
                  missingReplies.length === 0 &&
                  !itemsWithErrors.includes(openedItemId as string) ? (
                    selectedRepliesCount < totalSelectedItems ? (
                      <>
                        <span>
                          {t(
                            "tasks.conversation_panel.selected_replies_count",
                            {
                              count: selectedRepliesCount,
                            },
                          )}
                        </span>

                        <button
                          onClick={() => selectAllGeneratedReplies()}
                          className={`ml-1 mt-1 text-dark-blue underline hover:brightness-150`}
                        >
                          {t("tasks.conversation_panel.select_all_replies")}
                        </button>
                      </>
                    ) : (
                      t("tasks.conversation_panel.ready_to_send", {
                        count: selectedRepliesCount,
                      })
                    )
                  ) : (
                    t("tasks.conversation_panel.interact_with_suggestions")
                  )}
                </span>
              </div>

              {/* Generate AI Response Button */}
              <button
                onClick={
                  selectedItemIds.length > 0 &&
                  hasSelectedReplies &&
                  missingReplies.length == 0 &&
                  !itemsWithErrors.includes(openedItemId as string)
                    ? initiateSendReplies
                    : selectedItemIds.length > 1
                      ? handleGenerateReplies
                      : handleGenerateReply
                }
                disabled={
                  (missingReplies.length == 0 &&
                    !itemsWithErrors.includes(openedItemId as string) &&
                    selectedItemIds.length > 0 &&
                    !hasSelectedReplies) ||
                  (selectedItemIds.length > 0 &&
                    Object.values(isStreaming).some(
                      (value) => value === true,
                    )) ||
                  isStreaming[openedItemId || ""]
                }
                className={`ml-5 flex items-center rounded-lg  p-3 text-sm font-bold text-white  ${
                  missingReplies.length > 0 ||
                  itemsWithErrors.includes(openedItemId as string)
                    ? ""
                    : "whitespace-nowrap"
                } ${
                  (!hasSelectedReplies &&
                    selectedItemIds.length > 0 &&
                    !missingReplies.length &&
                    !itemsWithErrors.includes(openedItemId as string)) ||
                  (selectedItemIds.length > 0 &&
                    Object.values(isStreaming).some(
                      (value) => value === true,
                    )) ||
                  isStreaming[openedItemId || ""]
                    ? "bg-dark-grey"
                    : "bg-dark-blue hover:bg-dark-blue/90"
                }`}
              >
                {missingReplies.length == 0 &&
                selectedItemIds.length > 0 &&
                !itemsWithErrors.includes(openedItemId as string)
                  ? t("tasks.conversation_panel.send_replies", {
                      count: selectedRepliesCount,
                    })
                  : t("tasks.conversation_panel.generate_reply")}

                {missingReplies.length > 0 ||
                itemsWithErrors.includes(openedItemId as string)
                  ? t("tasks.conversation_panel.generate_for_items", {
                      count: missingReplies.length + itemsWithErrors.length,
                    })
                  : ""}

                {isGeneratingReply ? (
                  <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
                ) : (
                  <MagicIcon className="mb-0.5 ml-2 h-4 shrink-0" />
                )}
              </button>
            </div>
          )}

          {/* Bottom Section: AI Prompt and Reply Input */}
          {/* Reply Input Section */}
          <div className="mt-4 flex items-start space-x-1 rounded-[10px] bg-white p-5">
            <CustomTextarea
              value={reply}
              setValue={setReply}
              onSubmit={handleSendReply}
              showPreSavedReplies={true}
              error={replyError}
            />

            <div className="flex items-center">
              {/* Button to open pre-saved replies popup */}
              <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                      <PopoverTrigger asChild>
                        <button
                          onClick={() => setPopoverOpen(true)}
                          className="focus-visible:outline-none"
                        >
                          <GreyPlusIcon className="mr-2 size-6 hover:brightness-150" />
                        </button>
                      </PopoverTrigger>
                    </TooltipTrigger>

                    {!popoverOpen && (
                      <TooltipContent
                        className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                        sideOffset={5}
                      >
                        {t("workspace.pre_saved_replies.title")}
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>

                <PopoverContent
                  sideOffset={12}
                  align={"end"}
                  className="z-50 w-[400px] rounded-md border border-light-grey bg-white p-2 shadow-xl"
                >
                  <Command>
                    <div className="mb-2 flex items-center justify-between px-2">
                      <span className="font-medium text-primary">
                        {t("workspace.pre_saved_replies.title")}
                      </span>

                      <button
                        onClick={() => setAddPreSavedDialogOpen(true)}
                        className="ml-10 text-sm font-bold text-dark-blue hover:underline"
                      >
                        {t("workspace.pre_saved_replies.add_new")}
                      </button>
                    </div>

                    <CommandInput
                      ref={inputRef}
                      placeholder={t("custom_textarea.search_placeholder")}
                      value={searchValue}
                      onValueChange={setSearchValue}
                    />

                    <CommandList className="max-h-48 scrollbar-hide">
                      <CommandEmpty>
                        {t("workspace.pre_saved_replies.no_replies_found")}
                      </CommandEmpty>
                      <CommandGroup>
                        {filteredRepliesWithIndex.map(({ reply, index }) => (
                          <div key={index} className="relative">
                            <CommandItem
                              onSelect={() => insertPreSavedReply(reply)}
                              className={`w-full cursor-pointer rounded p-2 pr-8 hover:bg-light-grey [&[aria-selected="true"]:not(:hover)]:bg-transparent`}
                            >
                              <span className="line-clamp-3">
                                {highlightMatch(reply, searchValue)}
                              </span>
                            </CommandItem>

                            {/* Delete button/icon */}
                            <button
                              onClick={(e) => {
                                // stopPropagation so it won’t trigger onSelect
                                e.stopPropagation();
                                handleDeleteIconClick(index); // Pass the original index
                              }}
                              className="absolute right-2 top-1/2 -translate-y-1/2 text-red hover:text-red hover:brightness-90"
                            >
                              ✕
                            </button>
                          </div>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <button
                onClick={handleSendReply}
                disabled={!reply.trim() || !!replyError}
                className={`h-[40px] rounded-lg bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-primary/90 disabled:cursor-not-allowed disabled:bg-dark-grey`}
              >
                {t("tasks.conversation_panel.send")}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Bulk Action Popup for Sending Replies */}
      {popupData && (
        <BulkActionPopup
          icon={popupData.icon}
          message={popupData.message}
          selectedCount={popupData.selectedCount}
          onConfirm={handleConfirmSendReplies}
          onCancel={handleCancelSendReplies}
          position={popupData.position}
          type={popupData.type}
        />
      )}

      {/* Add Pre-Saved Reply Dialog (same logic as AddWorkspaceDialog) */}
      <AddPreSavedReplyDialog
        isAddPreSavedDialogOpen={isAddPreSavedDialogOpen}
        setAddPreSavedDialogOpen={setAddPreSavedDialogOpen}
      />

      {/* Delete Confirmation Dialog */}
      <DeletePreSavedReplyDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleConfirmDeletion}
        replyText={
          selectedReplyIndex !== null ? preSavedReplies[selectedReplyIndex] : ""
        }
        loading={actionLoading}
      />
    </div>
  );
};
