import "@services/lang/i18n";

import "./index.css";

import { AuthProvider } from "@layouts/AuthProvider";
import { UserProvider } from "@layouts/user-provider/UserProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { WorkspaceProvider } from "@layouts/workspace-provider/WorkspaceProvider";
// import { StrictMode } from "react";
import { SubscriptionProvider } from "@layouts/subscription-provider/SubscriptionProvider";
import { NavBarProvider } from "./layouts/NavBar.context";

import { SWRConfig } from "swr";
import { WebSocketProvider } from "./layouts/WebSocketContext";
import { Toaster } from "./components/ui/toaster";

// defineLocale for the React Uploader
import { defineLocale } from "@uploadcare/react-uploader";
import fr from "@uploadcare/file-uploader/locales/file-uploader/fr.js";
defineLocale("fr", fr);

LogRocket.init("8kcd7g/nswr");
setupLogRocketReact(LogRocket);

import { createClient, AnalyticsProvider } from "@segment/analytics-react";
const segmentClient = createClient({
  writeKey: "XZ93eADGrhlY1gUMUskxCq6HNLZ2Z99q",
});

console.info("Blabla starting...");

const rootDiv = document.getElementById("root");
const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   refetchOnWindowFocus: true,
    // },
  },
});

ReactDOM.createRoot(rootDiv!).render(
  // <StrictMode>
  <BrowserRouter>
    <AnalyticsProvider client={segmentClient}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SWRConfig
            value={{
              revalidateOnFocus: true,
              revalidateOnReconnect: true,
              revalidateIfStale: true,
            }}
          >
            <UserProvider>
              <WorkspaceProvider>
                <WebSocketProvider>
                  <SubscriptionProvider>
                    <NavBarProvider>
                      {/* This renders all toast messages from ShadCN's toast */}
                      <Toaster />
                      <App />
                      <Analytics />
                    </NavBarProvider>
                    <SpeedInsights />
                  </SubscriptionProvider>
                </WebSocketProvider>
              </WorkspaceProvider>
            </UserProvider>
          </SWRConfig>
        </QueryClientProvider>
      </AuthProvider>
    </AnalyticsProvider>
  </BrowserRouter>,
  // </StrictMode>,
);
