// BillingSettingsSkeleton.tsx

import Skeleton from "react-loading-skeleton";

const baseColor = "#F2F4F7";
const highlightColor = "#F9FAFB";

const BillingSettingsSkeleton = () => {
  return (
    <div className="flex h-full w-[550px] overflow-hidden bg-light-grey">
      <div className="flex size-full flex-col rounded-[12px] bg-white p-8">
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={200}
          height={40}
        />
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          height={60}
          width={"100%"}
          className="mt-8"
        />

        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          height={240}
          width={"100%"}
          className="mt-8"
        />

        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          height={1000}
          width={"100%"}
          className="mt-8"
        />
      </div>
    </div>
  );
};

export default BillingSettingsSkeleton;
