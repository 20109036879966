// Onboarding.tsx
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Step1PersonalInfo,
  Step2CreateWorkspace,
  Step3ChoosePlan,
} from "@components";
import { useUserContext } from "@layouts/user-provider/useUserContext";

import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useSetOnboardingDone } from "@/axios/mutations";
import { navigateWithParams } from "@/utils/navigateWithParams";
import { useAnalytics } from "@segment/analytics-react";
import { User } from "@auth0/auth0-react";

const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useAnalytics();

  // Flag to ensure the tracking happens only once
  const [hasTrackedSignUp, setHasTrackedSignUp] = useState(false);

  const { user, workspaces } = useUserContext();
  const { currentWorkspace } = useWorkspaceContext();

  const { setOnboardingDone } = useSetOnboardingDone();

  // Extract step from query parameters, default to step 1
  const queryParams = new URLSearchParams(location.search);
  const initialStep = parseInt(queryParams.get("step") || "1", 10);

  // Check if the user has a pending workspace
  const ownWorkspace = workspaces?.find(
    (workspace) => workspace.current_user_role == "owner",
  );

  const hasWorkspace = workspaces?.some(
    (workspace) => workspace.current_user_status === "active",
  );

  const hasPendingWorkspace = workspaces?.some(
    (workspace) => workspace.current_user_status === "pending",
  );

  const memberOfWorskpace = workspaces?.some(
    (workspace) =>
      (workspace.current_user_role === "member" ||
        workspace.current_user_role == "admin") &&
      workspace.current_user_status === "active",
  );

  // console.debug("Onboarding:", {
  //   hasWorkspace,
  //   ownWorkspace,
  //   hasPendingWorkspace,
  //   memberOfWorskpace,
  //   workspaces,
  //   currentWorkspace,
  // });

  const [formData, setFormData] = useState<{
    fullName: string;
    email: string;
    workspaceName: string;
    teamMembers: string[];
  }>({
    fullName: (user?.email != user?.name ? user?.name : "") as string,
    email: user?.email || "",
    workspaceName: ownWorkspace ? ownWorkspace.name : "",
    teamMembers: ownWorkspace
      ? ownWorkspace.members
          ?.map((m) => (m.email !== user?.email ? m.email : null))
          .filter(
            (email): email is string => email !== null && email !== undefined,
          )
      : [],
  });

  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        fullName: user?.email != user?.name ? user?.name : "",
        email: user.email,
      }));
    }
  }, [user]);

  // Track "Sign Up" event once when the user is on step 1
  useEffect(() => {
    if (step === 1 && !hasTrackedSignUp && user && !user?.onboarding_done) {
      const { user_id, source, referral_id } = user as User;

      track("Sign Up", {
        user_id: user_id,
        utm_source: source?.utm_source,
        utm_medium: source?.utm_medium,
        utm_campaign: source?.utm_campaign,
        referral_id: referral_id,
        timestamp: new Date().toISOString(),
      });

      setHasTrackedSignUp(true);
    }
  }, [step, hasTrackedSignUp, user, currentWorkspace, track]);

  const handleNext = async (data?: {
    fullName?: string;
    workspaceName?: string;
    teamMembers?: string[];
  }) => {
    if (!user) {
      console.error("User is not defined.");
      return;
    }

    // Save the form data as before
    if (data) {
      const updatedFormData = { ...formData, ...data };
      setFormData(updatedFormData);
    }

    if (memberOfWorskpace) {
      await setOnboardingDone(user?.user_id as string);

      if (step == 1) {
        const currentQuery = window.location.search;
        const targetPath = currentQuery
          ? `/?${currentQuery.substring(1)}`
          : "/";

        // console.log("Redirecting to login", {
        //   currentQuery,
        //   targetPath,
        //   step,
        // });

        window.location.href = targetPath;
      }
    } else {
      const nextStep = step + 1;
      setStep(nextStep);
      navigateWithParams(`?step=${nextStep}`, navigate, location.search);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      const prevStep = step - 1;
      setStep(prevStep);
      navigateWithParams(`?step=${prevStep}`, navigate, location.search);
    }
  };

  useEffect(() => {
    if (!user) return;

    // Update URL with the current step
    navigateWithParams(`?step=${step}`, navigate, location.search, {
      replace: true,
    });
  }, [step, navigate, user]);

  const renderStep = () => {
    // console.debug("Rendering step:", step);
    // console.debug("Has workspace:", hasWorkspace);

    if (step == 1) {
      return (
        <Step1PersonalInfo
          onNext={(data) => handleNext(data)}
          initialData={{ fullName: formData.fullName, email: formData.email }}
        />
      );
    } else {
      if (step == 2) {
        if (hasPendingWorkspace) {
          setStep(1);
          return (
            <Step1PersonalInfo
              onNext={(data) => handleNext(data)}
              initialData={{
                fullName: formData.fullName,
                email: formData.email,
              }}
            />
          );
        }

        return (
          <Step2CreateWorkspace
            onNext={(data) => handleNext(data)}
            onPrevious={handlePrevious}
            initialData={{
              workspaceName: formData.workspaceName,
              teamMembers: formData.teamMembers,
            }}
          />
        );
      }

      if (step == 3 && hasWorkspace && currentWorkspace) {
        return (
          <Step3ChoosePlan onNext={handleNext} onPrevious={handlePrevious} />
        );
      } else {
        setStep(1);
        return;
      }
    }
  };

  return <div>{renderStep()}</div>;
};

export default Onboarding;
