// useSkipItems.ts
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

// types.ts
export interface SkipComment {
  _id_comment: string;
  skip: boolean;
}

export interface SkipConversation {
  conversation_id: string;
  skip: boolean;
}

export interface SkipItemsInput {
  comments?: SkipComment[];
  conversations?: SkipConversation[];
}

function useSkipItems() {
  const { fetcher } = useFetcher();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  /**
   * Marks or unmarks comments and/or messages as skipped.
   * @param data - Contains arrays of comments and/or messages with their skip statuses.
   * @returns A promise that resolves to true if the operation was successful, otherwise false.
   */
  const skipItems = async (data: SkipItemsInput): Promise<boolean> => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await fetcher("/api/engagement/skip", "post", data);
      setSuccess(true);
      return true;
    } catch (err: any) {
      // It's good practice to handle different error structures
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An unexpected error occurred.";
      setError(errorMessage);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    skipItems,
    isLoading,
    error,
    success,
  };
}

export { useSkipItems };
