// src/components/PlanRestrictionAlertDialog.tsx

import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useSubscriptionContext } from "../subscription-provider/SubscriptionProvider";

import { PlanDetailsPopup } from "@/components/PlanDetailsPopup";
import { useTranslation } from "react-i18next";
import { PlanId } from "@/types/PLANS.config";

interface PlanRestrictionAlertDialogProps {
  requiredPlanId?: PlanId;
  message: string; // Custom message for the alert dialog
  trigger: boolean; // Whether to show the alert
  onClose?: () => void; // Optional callback when dialog is closed
}

const PlanRestrictionAlertDialog: React.FC<PlanRestrictionAlertDialogProps> = ({
  requiredPlanId = null,
  message,
  trigger,
  onClose,
}) => {
  const { t } = useTranslation();

  const { subscriptionDetails, isLoadingSubscriptionDetails } =
    useSubscriptionContext();

  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showPlanPopup, setShowPlanPopup] = useState(false);

  useEffect(() => {
    if (isLoadingSubscriptionDetails) {
      return;
    }

    if (trigger) {
      if (requiredPlanId != null) {
        if (subscriptionDetails) {
          if (subscriptionDetails.plan_id < requiredPlanId) {
            console.debug(
              "Plan restriction triggered:",
              subscriptionDetails.plan_id,
            );
            console.debug("Required plan:", requiredPlanId);

            setShowAlertDialog(true);
          }
        }
      } else {
        setShowAlertDialog(true);
      }
    }
  }, [
    trigger,
    subscriptionDetails,
    requiredPlanId,
    isLoadingSubscriptionDetails,
  ]);

  const handleClose = () => {
    setShowAlertDialog(false);
    if (onClose) {
      onClose();
    }
  };

  const handleUpgrade = () => {
    setShowAlertDialog(false);
    setShowPlanPopup(true);
  };

  const handleClosePlanPopup = () => {
    setShowPlanPopup(false);
    setShowAlertDialog(true);
  };

  if (isLoadingSubscriptionDetails) {
    return null;
  }

  return (
    <>
      {/* Alert Dialog for Plan Restrictions */}
      <AlertDialog open={showAlertDialog && !showPlanPopup}>
        <AlertDialogContent className="max-w-md">
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("plan_restriction_dialog.title")}
            </AlertDialogTitle>
            <AlertDialogDescription>{message}</AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <Button variant="ghost" onClick={handleClose} className="mr-2">
              {t("plan_restriction_dialog.close_button")}
            </Button>
            <Button
              onClick={handleUpgrade}
              className="flex items-center justify-center"
            >
              {t("plan_restriction_dialog.upgrade_button")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Plan Details Popup */}
      {showPlanPopup && <PlanDetailsPopup onClose={handleClosePlanPopup} />}
    </>
  );
};

export default PlanRestrictionAlertDialog;
