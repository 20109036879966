import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { IconHours, IllustrationClock, IconInformation } from "@/assets/icons"; // Import your icons

interface HoursSavedProps {
  hoursSaved: number;
  hoursWithPlatform: number;
  hoursWithoutPlatform: number;
}

export function HoursSaved({
  hoursSaved,
  hoursWithPlatform,
  hoursWithoutPlatform,
}: HoursSavedProps) {
  const { t } = useTranslation();

  return (
    <Card
      title={t("dashboard.HoursSaved.title")}
      icon={IconHours}
      className="min-h-[420px]"
    >
      {/* Top Section: Illustration, Hours Saved, and Description */}
      <div className="mb-6 flex items-stretch justify-between">
        {/* Illustration on the left */}
        <div className="flex shrink-0 items-center pt-1">
          <IllustrationClock className="size-32" />
        </div>

        {/* Hours Saved on the right */}
        <div className="ml-6 flex flex-1 flex-col justify-between">
          <div className="striped-text text-5xl font-black">{hoursSaved}</div>

          {/* Description below the hours saved, with icon */}
          <div className="mt-5 flex items-start rounded-[10px] bg-light-grey p-3 text-sm text-primary">
            {/* Bigger IconInformation */}
            <IconInformation className="mr-2 size-6" />

            <span>
              <span className="font-semibold">
                {t("dashboard.HoursSaved.with_the_help_of_the_platform")}
              </span>{" "}
              {t("dashboard.HoursSaved.you_were_able_to_save", {
                hoursSaved,
              })}
            </span>
          </div>
        </div>
      </div>
      {/* Big Horizontal Separator */}
      <div className="my-8 border-t border-dashed border-light-grey"></div>
      {/* Bottom Section: Hours with and without platform (align to the left) */}
      <div className="flex justify-start space-x-16">
        {/* Hours with the platform */}
        <div>
          <div className="text-3xl font-extrabold text-primary">
            {hoursWithPlatform}
          </div>
          <div className="mt-3 max-w-[200px] text-sm font-semibold text-primary">
            {t("dashboard.HoursSaved.hours_spent_with_the_platform")}
          </div>
        </div>

        {/* Hours without the platform */}
        <div>
          <div className="text-3xl font-extrabold text-dark-grey">
            {hoursWithoutPlatform}
          </div>
          <div className="mt-3 max-w-[200px] text-sm font-semibold text-primary">
            {t("dashboard.HoursSaved.hours_spent_without_platform")}
          </div>
        </div>
      </div>
    </Card>
  );
}
