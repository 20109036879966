// utils/randomMemoji.ts
import { hashString } from "./hashString";

/**
 * Returns a consistent memoji URL based on a given key (e.g., username),
 * with MurmurHash3 providing better hash distribution.
 */
export const getRandomMemoji = (key: string): string => {
  const salt = "random_salt_string"; // Optional salt to increase randomness
  const totalMemojiCount = 249; // Number of available memojis
  const hash = hashString(key + salt); // Generate a MurmurHash from the salted key

  // Use the hash to select a memoji from the range of available images
  const memojiIndex = (hash % totalMemojiCount) + 1;

  return `assets/memojis/memoji-${memojiIndex}.png`;
};
