import { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { DateRange } from "react-day-picker";
import { startOfDay, endOfDay, subMonths } from "date-fns";
import { Channel, Classification, GetCommentsDto } from "@/types";
import {
  FacebookCircleIcon,
  // FilterDateIcon,
  InstagramCircleIcon,
  LinkedinCircleIcon,
  OptionOpenedButton,
  OptionWhiteButton,
  TiktokCircleIcon,
  YoutubeCircleIcon,
} from "@/assets/icons";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { SelectIcon, SelectedIcon } from "@assets/icons";
// import { categoryEmojis, sentimentEmojis } from "@/types/classification.types";
import { useTranslation } from "react-i18next";
import PlanRestrictionAlertDialog from "@/layouts/app-wrapper/PlanRestrictionAlertDialog";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useDashboard } from "@/layouts/Dashboard.context";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
// import { Info } from "lucide-react"; // Importing Info icon from Lucide

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DateRangePicker } from "../tasks/DateRangePicker";

export type AllowedCategory = "Business" | "Sensitive Content";

export const FilterPopoverDashboard = () => {
  const { t } = useTranslation();

  const { subscriptionDetails } = useSubscriptionContext();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { applyFilters, resetFilters, filterOptions } = useDashboard();

  const { channels } = useWorkspaceContext();

  const defaultDate = {
    from: subMonths(new Date(), 1),
    to: new Date(),
  };

  const defaultSentiments: Classification["sentiment"][] = [
    "Positive",
    "Negative",
  ];

  const defaultCategories: Classification["category"][] = [
    "Business",
    "Direct Interactions",
    "Sensitive Content",
    "Feedback",
    "Questions",
  ];

  const defaultPlatforms: Channel["type"][] = [
    "facebook",
    "instagram",
    "youtube",
    // "linkedin",
    "tiktok",
  ];

  const defaultAdsFilter = "both";

  const defaultPlatformIcons = {
    facebook: <FacebookCircleIcon className="size-3.5" />,
    instagram: <InstagramCircleIcon className="size-3.5" />,
    youtube: <YoutubeCircleIcon className="size-3.5" />,
    tiktok: <TiktokCircleIcon className="size-3.5" />,
    "tiktok-ads": <TiktokCircleIcon className="size-3.5" />,
    "facebook-ads": <FacebookCircleIcon className="size-3.5" />,
    linkedin: <LinkedinCircleIcon className="size-3.5" />,
  };

  const [date, setDate] = useState<DateRange | undefined>({
    from: filterOptions.startDate
      ? new Date(filterOptions.startDate)
      : defaultDate.from,
    to: filterOptions.endDate
      ? new Date(filterOptions.endDate)
      : defaultDate.to,
  });

  const [sentiments, setSentiments] = useState<Classification["sentiment"][]>(
    filterOptions.sentiment ?? defaultSentiments,
  );

  const [categories, setCategories] = useState<Classification["category"][]>(
    filterOptions.category ?? defaultCategories,
  );

  const [platforms, setPlatforms] = useState<Channel["type"][]>(
    filterOptions.channel_type ? filterOptions.channel_type : defaultPlatforms,
  );

  const [adFilter, setAdFilter] = useState<"organic" | "ads" | "both">(
    filterOptions.adFilter ?? defaultAdsFilter,
  );

  const [firstChangeDone, setFirstChangeDone] = useState(false);

  const [showAlertDialog, setShowAlertDialog] = useState(false);

  // Add this useEffect to sync local state with context's filterOptions
  useEffect(() => {
    // console.debug("Filters changed, syncing local state with context");
    // console.debug("Filter options:", filterOptions);

    setDate({
      from: filterOptions.startDate
        ? new Date(filterOptions.startDate)
        : defaultDate.from,
      to: filterOptions.endDate
        ? new Date(filterOptions.endDate)
        : defaultDate.to,
    });
    setSentiments(filterOptions.sentiment ?? defaultSentiments);
    setCategories(filterOptions.category ?? defaultCategories);
    setPlatforms(filterOptions.channel_type ?? defaultPlatforms);
  }, [resetFilters]);

  useEffect(() => {
    setFirstChangeDone(false);
  }, []);

  const handleApply = () => {
    const startDate = date?.from ? startOfDay(date.from) : undefined;
    const endDate = date?.to ? endOfDay(date.to) : undefined;

    // Determine if all sentiments are selected
    const allSentimentsSelected =
      defaultSentiments.length === sentiments.length;

    // Determine if all categories are selected
    const allCategoriesSelected =
      defaultCategories.length === categories.length;

    // Determine if all platforms are selected
    const allPlatformsSelected = defaultPlatforms.length === platforms.length;

    // Construct filters conditionally
    const filters: Partial<GetCommentsDto> = {
      ...(startDate
        ? { startDate: startDate.toISOString() }
        : { startDate: undefined }),
      ...(endDate
        ? { endDate: endDate.toISOString() }
        : { endDate: undefined }),
      // Include sentiment only if not all selected and at least one is selected
      ...(!allSentimentsSelected
        ? { sentiment: sentiments }
        : { sentiment: undefined }),
      // Include category only if not all selected and at least one is selected
      ...(!allCategoriesSelected
        ? { category: categories }
        : { category: undefined }),
      // Include channel_type only if not all selected and at least one is selected
      ...(!allPlatformsSelected
        ? { channel_type: platforms }
        : { channel_type: undefined }),
      // Include adFilter only if it's not "both"
      ...(adFilter !== "both" ? { adFilter } : { adFilter: undefined }),
    };

    console.debug("Filters to apply:", filters);

    applyFilters(filters);
    setIsSelectOpen(false);
    console.info("Filters applied", filters);
    setFirstChangeDone(false);
  };

  const handleReset = () => {
    setDate(defaultDate);
    setSentiments(defaultSentiments);
    setCategories(defaultCategories);
    setPlatforms(defaultPlatforms);
    setAdFilter(defaultAdsFilter);
    console.info("Filters reset to default values");
  };

  // const toggleSentiment = (sentiment: Classification["sentiment"]) => {
  //   if (sentiments.length + categories.length == 1 && !firstChangeDone) {
  //     setSentiments([sentiment]);
  //     setCategories([]);
  //   } else if (
  //     sentiments.length + categories.length ==
  //     defaultSentiments.length + defaultCategories.length
  //   ) {
  //     setSentiments([sentiment]);
  //     setCategories([]);
  //   } else {
  //     setSentiments((prev) =>
  //       prev.includes(sentiment)
  //         ? prev.filter((s) => s !== sentiment)
  //         : [...prev, sentiment],
  //     );
  //   }

  //   setFirstChangeDone(true);
  // };

  // const toggleCategory = (category: Classification["category"]) => {
  //   if (sentiments.length + categories.length == 1 && !firstChangeDone) {
  //     setCategories([category]);
  //     setSentiments([]);
  //   } else if (
  //     sentiments.length + categories.length ==
  //     defaultSentiments.length + defaultCategories.length
  //   ) {
  //     setCategories([category]);
  //     setSentiments([]);
  //   } else {
  //     setCategories((prev) =>
  //       prev.includes(category)
  //         ? prev.filter((c) => c !== category)
  //         : [...prev, category],
  //     );
  //   }

  //   setFirstChangeDone(true);
  // };

  const togglePlatform = (platform: Channel["type"]) => {
    if (!firstChangeDone) {
      setPlatforms([platform]);
      setFirstChangeDone(true);
    } else if (platforms.length == defaultPlatforms.length) {
      setPlatforms([platform]);
    } else {
      setPlatforms((prev) =>
        prev.includes(platform)
          ? prev.filter((p) => p !== platform)
          : [...prev, platform],
      );
    }

    setFirstChangeDone(true);
  };

  const handleAdFilterChange = (value: "organic" | "ads" | "both" | null) => {
    if ((subscriptionDetails?.plan_id as number) < 2 && value != "organic") {
      setShowAlertDialog(true);
    } else {
      setAdFilter(value || "both"); // Fallback to "both" if deselected
    }
  };

  return (
    <Popover open={isSelectOpen} onOpenChange={setIsSelectOpen}>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <button
                className={`flex size-[42px] shrink-0 p-0 ${!isSelectOpen && "hover:brightness-90"}`}
              >
                {isSelectOpen ? (
                  <OptionOpenedButton className="size-[42px] shrink-0" />
                ) : (
                  <OptionWhiteButton className="size-[42px] shrink-0" />
                )}
              </button>
            </PopoverTrigger>
          </TooltipTrigger>

          {!isSelectOpen && (
            <TooltipContent
              className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
              sideOffset={5}
            >
              {t("tasks.FilterPopover.filter_tooltip")}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <PopoverContent
        className="mr-8 max-h-[90vh] w-fit overflow-y-auto rounded-xl bg-white p-0 shadow-lg scrollbar-hide"
        sideOffset={10}
      >
        <div className="px-6 pt-6">
          {/* Informations sur la période (Non-interactif) */}
          {/* <div className="mb-5">
            <p className="mb-1 text-base font-extrabold">
              {t("tasks.FilterPopover.current_date_range")}
            </p>

            <p className="mb-4 mt-2 flex flex-row items-center text-sm text-dark-grey">
              <Info className="mr-1.5 size-4 text-dark-grey" />
              {t("tasks.FilterPopover.date_info_message")}
            </p>

            <div className="flex w-full items-center justify-between rounded-[6px] bg-light-grey px-4 py-3 font-semibold">
              <span className="text-sm text-primary">
                {date?.from && date?.to
                  ? `${format(date.from, "dd/MM/yyyy")} - ${format(
                      date.to,
                      "dd/MM/yyyy",
                    )}`
                  : ""}
              </span>
              <FilterDateIcon className="size-3.5 shrink-0" />
            </div>
          </div> */}

          {/* Date Selection */}
          <div className="mb-5">
            <p className="mb-4 text-base font-extrabold">
              {t("tasks.FilterPopover.select_by_dates")}
            </p>
            <DateRangePicker date={date} setDate={setDate} />
          </div>

          {/* Sentiments, Categories, and Platforms */}
          <div className="mb-6">
            <p className="mb-5 text-base font-extrabold">
              {t("tasks.FilterPopover.filter_options")}
            </p>

            {defaultPlatforms.filter((platform) =>
              channels?.find((c) => c.type == platform),
            )?.length ? (
              <div className="flex flex-row space-x-8">
                {/* <div className="flex flex-col">
                    <p className="mb-4 text-sm font-semibold text-dark-grey">
                      {t("tasks.FilterPopover.sentiments")}
                    </p>
      
                    <div className="flex flex-col space-y-1.5">
                      {defaultSentiments.map((sentiment) => (
                        <div
                          key={sentiment}
                          onClick={() => toggleSentiment(sentiment)}
                          className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                            sentiments.includes(sentiment)
                              ? "bg-light-grey"
                              : "bg-white hover:bg-light-grey/60"
                          }`}
                        >
                          <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                            <span>{sentimentEmojis[sentiment]}</span>
                            <span>
                              {t(
                                `tasks.classification.sentiment.${sentiment.toLowerCase()}`,
                              )}
                            </span>
                          </span>
                          {sentiments.includes(sentiment) ? (
                            <SelectedIcon />
                          ) : (
                            <SelectIcon />
                          )}
                        </div>
                      ))}
      
                      {defaultCategories.map((category) => (
                        <div
                          key={category}
                          onClick={() => toggleCategory(category)}
                          className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                            categories.includes(category)
                              ? "bg-light-grey"
                              : "bg-white hover:bg-light-grey/60"
                          }`}
                        >
                          <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                            <span>{categoryEmojis[category]}</span>
                            <span>
                              {t(
                                `tasks.classification.category.${category.toLowerCase().replace(/ /g, "_")}`,
                              )}
                            </span>
                          </span>
                          {categories.includes(category) ? (
                            <SelectedIcon />
                          ) : (
                            <SelectIcon />
                          )}
                        </div>
                      ))}
                    </div>
                  </div> */}

                {/* Platforms */}
                <div>
                  <p className="mb-4 text-sm font-semibold text-dark-grey">
                    {t("tasks.FilterPopover.select_platform")}
                  </p>

                  <div className="flex flex-col space-y-1.5">
                    {defaultPlatforms
                      .filter((platform) =>
                        channels?.find((c) => c.type == platform),
                      )
                      .map((platform) => (
                        <div
                          key={platform}
                          onClick={() => togglePlatform(platform)}
                          className={`flex w-[210px] cursor-pointer items-center justify-between rounded-[6px] px-3 py-2 ${
                            platforms.includes(platform)
                              ? "bg-light-grey"
                              : "bg-white hover:bg-light-grey/60"
                          }`}
                        >
                          <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                            <span>{defaultPlatformIcons[platform]}</span>
                            <span>
                              {platform.charAt(0).toUpperCase() +
                                platform.slice(1)}
                            </span>
                          </span>
                          {platforms.includes(platform) ? (
                            <SelectedIcon />
                          ) : (
                            <SelectIcon />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* Toggle Group */}
          <div className="mb-6">
            <p className="mb-4 text-sm font-semibold text-dark-grey">
              {t("tasks.FilterPopover.comment_types")}
            </p>
            <ToggleGroup
              type="single"
              value={adFilter}
              onValueChange={(value: string) =>
                handleAdFilterChange(value as "organic" | "ads" | "both" | null)
              }
              className="flex items-start justify-around space-x-2"
            >
              {["organic", "ads", "both"].map((type) => (
                <ToggleGroupItem
                  key={type}
                  value={type}
                  className={`flex w-28 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                    adFilter === type
                      ? "bg-light-grey"
                      : "border border-light-grey bg-white hover:bg-light-grey/60"
                  }`}
                  aria-label={t(`tasks.FilterPopover.${type}`)}
                >
                  {t(`tasks.FilterPopover.${type}`)}
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </div>

          {/* Sticky Footer with Buttons */}
          <div className="sticky bottom-0 flex items-center justify-end border-t border-light-grey bg-white py-6 text-sm font-semibold">
            <button
              onClick={handleReset}
              className="mr-3 w-32 cursor-pointer rounded-[8px] bg-light-grey py-2.5 text-primary hover:brightness-90"
            >
              {t("tasks.FilterPopover.reset")}
            </button>
            <button
              onClick={handleApply}
              className="w-32 cursor-pointer rounded-[8px] bg-primary py-2.5 text-white hover:brightness-150"
            >
              {t("tasks.FilterPopover.apply")}
            </button>
          </div>
        </div>
      </PopoverContent>

      {/* PlanRestrictionAlertDialog */}
      <PlanRestrictionAlertDialog
        message={t("plan_restriction_dialog.messages.need_plan_pro")}
        trigger={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
      />
    </Popover>
  );
};
