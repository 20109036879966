import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const baseColor = "#F2F4F7";
const highlightColor = "#F9FAFB";

// Card component as per your structure
const Card = ({ children }: { title?: string; children: React.ReactNode }) => {
  return (
    <div className="h-[300px] overflow-hidden rounded-[10px] bg-white p-6">
      <Skeleton
        baseColor={baseColor}
        highlightColor={highlightColor}
        width={150}
        height={20}
      />

      <div className="mt-6 max-h-full overflow-hidden">{children}</div>
    </div>
  );
};

const DashboardSkeleton = () => {
  return (
    <div className="min-h-screen bg-light-grey p-8">
      <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* MetricCounter Skeletons */}
        <Card title="Direct Messages">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        <Card title="Comments">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* Emotional Analysis Skeleton */}
        <Card title="Emotional Analysis">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* BarChartWidget Skeletons */}
        <Card title="Comments (Organic vs Ads)">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        <Card title="Comments (Replied vs Not Replied)">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        <Card title="Direct Messages (Replied vs Unreplied)">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* HoursSaved Skeleton */}
        <Card title="Hours Saved">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ResponseRatio Skeleton */}
        <Card title="Response Ratio">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ActionMetrics Skeleton */}
        <Card title="Action Metrics">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* HoursSaved Skeleton */}
        <Card title="Hours Saved">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ResponseRatio Skeleton */}
        <Card title="Response Ratio">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ActionMetrics Skeleton */}
        <Card title="Action Metrics">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* HoursSaved Skeleton */}
        <Card title="Hours Saved">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ResponseRatio Skeleton */}
        <Card title="Response Ratio">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>

        {/* ActionMetrics Skeleton */}
        <Card title="Action Metrics">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            width="100%"
            height={200}
          />
        </Card>
      </div>
    </div>
  );
};

export { DashboardSkeleton };
