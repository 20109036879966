// components/RemoveDocumentAlertDialog.tsx
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useTranslation } from "react-i18next";

interface RemoveDocumentAlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documentName?: string;
}

const RemoveDocumentAlertDialog = ({
  isOpen,
  onClose,
  onConfirm,
  documentName,
}: RemoveDocumentAlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="mb-2">
            {t("settings.smart_features.remove_document_title")}
          </AlertDialogTitle>

          <AlertDialogDescription className="text-base leading-relaxed">
            {t("settings.smart_features.remove_document_description", {
              docName: documentName,
            })}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className="mt-4">
          <AlertDialogCancel onClick={onClose}>
            {t("settings.smart_features.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className="bg-red text-white hover:bg-red hover:brightness-90"
          >
            {t("settings.smart_features.delete")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveDocumentAlertDialog;
