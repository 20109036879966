// utils/preserveQueryParams.ts

import { NavigateFunction } from "react-router-dom";

/**
 * Appends or updates query parameters to the target path.
 * @param targetPath - The path to navigate to (can include its own query params).
 * @param navigate - The navigate function from useNavigate.
 * @param currentSearch - The current query string from useLocation.
 * @param options - Optional navigation options.
 */
export const navigateWithParams = (
  targetPath: string,
  navigate: NavigateFunction,
  currentSearch: string,
  options: { replace?: boolean; state?: any } = {},
): void => {
  // Parse the current search params
  const currentParams = new URLSearchParams(currentSearch);

  // Remove Auth0-specific parameters to prevent interfering with the auth flow
  currentParams?.delete("code");
  currentParams?.delete("state");

  // Split targetPath into pathname and its own search params
  const [pathname, targetSearch] = targetPath.split("?");
  const targetParams = targetSearch
    ? new URLSearchParams(targetSearch)
    : new URLSearchParams();

  // Merge targetParams into currentParams (targetParams take precedence)
  targetParams.forEach((value, key) => {
    currentParams.set(key, value);
  });

  // Construct the new search string
  const mergedSearch = currentParams.toString()
    ? `?${currentParams.toString()}`
    : "";

  // Navigate to the new path with merged search params
  navigate(`${pathname}${mergedSearch}`, options);
};
