import React, { Dispatch, SetStateAction, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format, addMonths, subDays, subMonths } from "date-fns";
import { cn } from "@/lib/utils";
import { DateRange } from "react-day-picker";
import { FilterDateIcon } from "@/assets/icons";
import { useTranslation } from "react-i18next";

interface DateRangePickerProps {
  date: DateRange | undefined;
  setDate: Dispatch<SetStateAction<DateRange | undefined>>;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  date,
  setDate,
}) => {
  const { t } = useTranslation();

  // State to control the displayed month in the calendar
  const [displayMonth, setDisplayMonth] = useState<Date>(
    subMonths(new Date(), 1),
  );

  // Handle selection of date range

  const handleDayClick = (day: Date) => {
    // 1. If no 'from', set the first click as the start date
    if (!date?.from) {
      setDate({ from: day, to: undefined });
      return;
    }

    // 2. If we have a 'from' but no 'to', this click is intended to set the end date
    if (date?.from && !date?.to) {
      // If user clicks on a date *before* the existing 'from', reset 'from'
      if (day <= date?.from) {
        setDate({ from: day, to: undefined });
      } else {
        // Otherwise, treat it as the end date. Check the "within 1 month" constraint
        const maxDate = addMonths(date?.from, 1);
        if (day > maxDate) {
          console.error("Please select a range within one month.");
          return;
        }
        setDate({ from: date?.from, to: day });
      }
      return;
    }

    // 3. If from & to both exist, any new click starts a brand-new selection
    //    (Reset, and set the new start date.)
    setDate({ from: day, to: undefined });
  };

  // Define presets for quick date selection
  const handlePresetSelect = (preset: string) => {
    const today = new Date();
    let fromDate, toDate;

    switch (preset) {
      case "today":
        fromDate = today;
        toDate = today;
        break;
      case "last3Days":
        fromDate = subDays(today, 3);
        toDate = today;
        break;
      case "lastWeek":
        fromDate = subDays(today, 7);
        toDate = today;
        break;
      case "lastMonth":
        fromDate = subMonths(today, 1);
        toDate = today;
        break;
      default:
        return;
    }
    setDate({ from: fromDate, to: toDate });
  };

  // Handle popover open state changes
  const handlePopoverOpenChange = (open: boolean) => {
    if (!open && date?.from) {
      // Reset displayMonth to the initial 'from' date when popover is closed
      setDisplayMonth(date.from);
    }
  };

  return (
    <Popover onOpenChange={handlePopoverOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            "w-full justify-between flex grow text-left font-semibold hover:bg-light-grey",
            "bg-light-grey text-primary rounded-[6px] px-4 py-4",
          )}
        >
          <span>
            {date?.from && date?.to
              ? `${format(date.from, "dd/MM/yyyy")} - ${format(date.to, "dd/MM/yyyy")}`
              : t("tasks.date_range_picker.select_date_range")}
          </span>

          <FilterDateIcon className="size-3.5 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="flex w-auto flex-col space-y-4 p-2"
        sideOffset={5}
      >
        {/* Preset Selector */}
        <Select onValueChange={handlePresetSelect}>
          <SelectTrigger>
            <SelectValue
              placeholder={t("tasks.date_range_picker.quick_select")}
            />
          </SelectTrigger>
          <SelectContent position="popper" className="w-full">
            <SelectItem value="today">
              {t("tasks.date_range_picker.today")}
            </SelectItem>
            <SelectItem value="last3Days">
              {t("tasks.date_range_picker.last_3_days")}
            </SelectItem>
            <SelectItem value="lastWeek">
              {t("tasks.date_range_picker.last_week")}
            </SelectItem>
            <SelectItem value="lastMonth">
              {t("tasks.date_range_picker.last_month")}
            </SelectItem>
          </SelectContent>
        </Select>

        {/* Date Range Picker */}
        <div className="w-full rounded-md border">
          <Calendar
            initialFocus
            mode="range"
            month={displayMonth} // Controlled month
            onMonthChange={(newMonth) => {
              setDisplayMonth(newMonth); // Update displayMonth on month change
            }}
            selected={date}
            onDayClick={handleDayClick}
            numberOfMonths={2}
            className="rounded-lg"
            disabled={[
              {
                after: new Date(),
                before: subMonths(new Date(), 1),
              },
            ]}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
