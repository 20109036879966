import { getRandomMemoji } from "@/utils/getRandomMemoji";
import { getRandomPastelColor } from "@/utils/getRandomPastelColor";
import { Img } from "./Img";

type ProfileImgProps = {
  username?: string;
  id: string;
  profilePictureUrl?: string | null;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * ProfileImg component to handle profile picture rendering with fallback to random memoji and pastel color.
 *
 * @param {ProfileImgProps} props - The component props.
 * @param {string} props.username - The username for generating fallback memoji.
 * @param {string} props.id - The unique id (used to generate the random memoji if username is absent).
 * @param {string} props.profilePictureUrl - The profile picture URL, which can be null.
 * @param {string} props.className - Optional className for the img element for styling.
 * @param {object} props.style - Optional style object for custom inline styles.
 */
export const ProfileImg = ({
  username,
  id,
  profilePictureUrl,
  className = "size-10 rounded-full object-cover",
  style = {},
}: ProfileImgProps) => {
  const identifier = username || id; // Fallback to id if username is not provided
  const bgColor = getRandomPastelColor(identifier); // Random background color for memoji

  return (
    <Img
      src={profilePictureUrl || ""}
      alt={username || "User Avatar"}
      className={className}
      style={{ backgroundColor: bgColor, ...style }} // Apply custom styles and random background color
      unloader={
        <img
          src={getRandomMemoji(identifier)}
          alt={username || "Avatar"}
          className={className}
          style={{ backgroundColor: bgColor, ...style }}
        />
      } // Fallback image if the src fails to load
    />
  );
};
