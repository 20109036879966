// useAssistantChat.ts

import { useState, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

export interface AssistantMessage {
  text: string;
  from: "ai" | "user";
  type: "message" | "generatedReply" | "error";
}

export interface InteractWithAssistantInput {
  new_message: string;
  comment_id?: string;
  conversation_id?: string;
  post_id?: string;
  channel_id: string;
  workspace_id: string;
  ai_reply?: string;
}

const useAssistantChat = () => {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  const [messages, setMessages] = useState<Record<string, AssistantMessage[]>>(
    {},
  );
  const [isStreaming, setIsStreaming] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<Record<string, string | null>>({});
  const threadIdRefs = useRef<Record<string, string | null>>({});

  /**
   * Adds a message to the specified comment's message list.
   * Ensures messages are appended in chronological order.
   *
   * @param item_id - The ID of the item.
   * @param message - The AssistantMessage to add.
   */
  const addMessage = useCallback(
    (item_id: string, message: AssistantMessage) => {
      setMessages((prev) => {
        const prevMessages = prev[item_id] || [];
        return {
          ...prev,
          [item_id]: [...prevMessages, message],
        };
      });
    },
    [],
  );

  /**
   * Interacts with the assistant for a specific item.
   *
   * @param input - Input parameters for interaction.
   */
  const interactWithAssistant = useCallback(
    async (input: InteractWithAssistantInput) => {
      const {
        comment_id,
        conversation_id,
        new_message,
        post_id,
        channel_id,
        workspace_id,
        ai_reply,
      } = input;

      setIsStreaming((prev) => ({ ...prev, [item_id]: true }));
      setError((prev) => ({ ...prev, [item_id]: null }));

      // Append the user's message to the chat for the specific item
      setMessages((prev) => {
        const prevMessages = prev[item_id] || [];
        return {
          ...prev,
          [item_id]: [
            ...prevMessages,
            { text: new_message, from: "user", type: "message" },
          ],
        };
      });

      const item_id = (comment_id || conversation_id) as string;

      const data = {
        new_message,
        comment_id,
        conversation_id,
        post_id,
        channel_id,
        workspace_id,
        ...(threadIdRefs.current[item_id] && {
          thread_id: threadIdRefs.current[item_id],
        }),
        ...(ai_reply !== undefined && { ai_reply }),
      };

      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_SERVER_URL}/api/assistant/interact`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          },
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorText}`,
          );
        }

        if (!response.body) {
          throw new Error("Response body is null");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        let aiMessage = "";

        // Initialize AI message in messages
        setMessages((prev) => {
          const prevMessages = prev[item_id] || [];
          return {
            ...prev,
            [item_id]: [
              ...prevMessages,
              { text: "", from: "ai", type: "message" },
            ],
          };
        });

        let done = false;
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (value) {
            const chunkValue = decoder.decode(value);
            const lines = chunkValue.split("\n");

            for (let i = 0; i < lines.length; i++) {
              let line = lines[i].trim();
              if (line.startsWith("data:")) {
                const data = line.slice(5).trim();
                if (data) {
                  const parsedData = JSON.parse(data);
                  if (parsedData.text) {
                    aiMessage += parsedData.text;
                    // Update the last AI message
                    setMessages((prev) => {
                      const prevMessages = prev[item_id] || [];
                      const lastMessage = prevMessages[prevMessages.length - 1];
                      const updatedMessage = {
                        ...lastMessage,
                        text: aiMessage,
                      };
                      return {
                        ...prev,
                        [item_id]: [
                          ...prevMessages.slice(0, -1),
                          updatedMessage,
                        ],
                      };
                    });
                  }
                }
              } else if (line.startsWith("event:")) {
                const eventName = line.slice(6).trim();
                // The next line should be data:
                const nextLine = lines[++i]?.trim();
                if (nextLine && nextLine.startsWith("data:")) {
                  const data = nextLine.slice(5).trim();
                  if (data) {
                    const parsedData = JSON.parse(data);
                    if (eventName === "threadId") {
                      if (parsedData.thread_id) {
                        threadIdRefs.current[item_id] = parsedData.thread_id;
                        console.debug(
                          "Received thread_id:",
                          threadIdRefs.current[item_id],
                        );
                      }
                    } else if (
                      eventName === "generatedReply" ||
                      eventName === "updatedReply"
                    ) {
                      if (parsedData.thread_id) {
                        threadIdRefs.current[item_id] = parsedData.thread_id;
                        console.debug(
                          "Updated thread_id:",
                          threadIdRefs.current[item_id],
                        );
                      }

                      if (parsedData.reply) {
                        // Replace existing generatedReply message for the item
                        setMessages((prev) => {
                          const prevMessages = prev[item_id] || [];

                          return {
                            ...prev,
                            [item_id]: [
                              ...prevMessages,
                              {
                                text: parsedData.reply,
                                from: "ai",
                                type: "generatedReply",
                              },
                            ],
                          };
                        });
                      }
                    } else if (eventName === "end") {
                      // Handle end of stream
                      setIsStreaming((prev) => ({
                        ...prev,
                        [item_id]: false,
                      }));
                    } else if (eventName === "error") {
                      // <-- Handle SSE-based errors right here
                      if (parsedData.error) {
                        // Mark streaming as finished
                        setIsStreaming((prev) => ({
                          ...prev,
                          [item_id]: false,
                        }));
                        // Optionally set an error state, if needed
                        setError((prev) => ({
                          ...prev,
                          [item_id]: parsedData.error,
                        }));
                        // And push a message to the conversation
                        addMessage(item_id, {
                          text: t("tasks.ai_panel.errorMessage"),
                          from: "ai",
                          type: "error",
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } catch (err: any) {
        setError((prev) => ({ ...prev, [item_id]: err.message }));
        setIsStreaming((prev) => ({ ...prev, [item_id]: false }));

        console.error("Failed to stream:", err);

        // Also add a chat message of type "error"
        addMessage(item_id, {
          text: t("tasks.ai_panel.errorMessage"),
          from: "ai",
          type: "error",
        });
      } finally {
        setIsStreaming((prev) => ({ ...prev, [item_id]: false }));
      }
    },
    [addMessage, getAccessTokenSilently],
  );

  /**
   * Resets the chat for a specific item.
   *
   * @param item_id - The ID of the item.
   */
  const resetChat = useCallback((item_id: string) => {
    setMessages((prev) => ({ ...prev, [item_id]: [] }));
    setError((prev) => ({ ...prev, [item_id]: null }));
    threadIdRefs.current[item_id] = null;
    setIsStreaming((prev) => ({ ...prev, [item_id]: false }));
  }, []);

  return {
    messages,
    interactWithAssistant,
    isStreaming,
    error,
    resetChat,
    addMessage, // Expose addMessage function
  };
};

export { useAssistantChat };
