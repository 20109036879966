// useUser.ts
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { User } from "@types";
import { useState } from "react";

function useUser(user_id: string, enabled = true) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  const queryString = new URLSearchParams({ user_id }).toString();
  const cacheKey = `/api/users?${queryString}`;

  const { data, error, mutate, isValidating, isLoading } = useSWR<User>(
    cacheKey,
    (url: string) => fetcher(url, "get", null, false, enabled),
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<User> => {
    setIsRefetching(true);
    const updatedData = await mutate(); // Try to get updated data
    setIsRefetching(false);

    // If no updated data, return the current cached data
    if (!updatedData) {
      if (data) {
        return data; // Return cached data
      } else {
        throw new Error("No data available after refetching.");
      }
    }

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as User,
    isLoading: (!data && !error && !isValidating) || isLoading, // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useUser };
