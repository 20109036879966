import { Comment, Conversation } from "@/types";

/**
 * Type guard to check if the item is a Comment.
 */
export const isComment = (
  type: "comment" | "message",
  _item: Comment | Conversation,
): _item is Comment => {
  return type === "comment";
};
