// LoginPage.tsx

import { AppState, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { AppPreview } from "@/assets";
import { AiIcon } from "@/assets/icons";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { collectQueryParams } from "@/utils/collectQueryParams";

const LoginPage = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  const { changeLanguage, language } = useUserContext();
  const queryParams = collectQueryParams();

  const handleLogin = async (params: RedirectLoginOptions<AppState>) => {
    try {
      await loginWithRedirect({
        ...params,
        appState: { queryParams }, // Pass all query params via appState
      });
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Fonction pour extraire les paramètres de requête
  const getQueryParam = (param: string) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // Récupérer le paramètre 'screen' de l'URL
  const screenHint = getQueryParam("screen");

  useEffect(() => {
    if (!screenHint || (screenHint !== "signup" && screenHint !== "login")) {
      setIsLoading(false); // Pas de redirection nécessaire, arrêter le chargement
      return;
    }

    // Appeler 'handleLogin' lors du chargement de la page
    handleLogin({
      authorizationParams: {
        screen_hint: screenHint,
      },
    });
  }, [screenHint]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center space-x-10 overflow-hidden bg-light-grey">
      {/* Left Section: Login/Signup */}
      <div className="flex h-full grow flex-col items-center justify-center p-8">
        <div className="flex size-full flex-col justify-center rounded-xl bg-white p-10 md:px-[15%] md:pb-[10%]">
          <AiIcon className="mb-8 size-10 shrink-0" />
          <div className="mb-10 max-w-[400px] text-left text-base font-semibold text-primary">
            {t("login.create_or_login_message")}
          </div>

          {/* Updated Button Container */}
          <div className="flex flex-row flex-wrap gap-4">
            <button
              className="min-w-[180px] max-w-[180px] flex-1 rounded-[8px] bg-light-grey px-4 py-3 text-base font-semibold text-primary transition-transform hover:scale-105 active:scale-95"
              onClick={async () => {
                await handleLogin({
                  authorizationParams: {
                    screen_hint: "login",
                  },
                });
              }}
            >
              {t("login.login")}
            </button>

            <button
              className="min-w-[180px] max-w-[180px] flex-1 rounded-[8px] bg-primary px-4 py-3 text-base font-semibold text-white transition-transform hover:scale-105 active:scale-95"
              onClick={async () => {
                await handleLogin({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                });
              }}
            >
              {t("login.signup")}
            </button>
          </div>
        </div>
      </div>

      {/* Right Section: App Preview Image */}
      <div className="hidden h-[85%] items-center justify-end py-8 lg:flex">
        <img
          src={AppPreview}
          alt="App preview"
          className="max-h-full max-w-full rounded-xl transition-transform"
        />

        <div className="absolute bottom-8 right-8 flex shrink-0 items-center justify-center text-nowrap">
          <Select
            value={language}
            onValueChange={async (value) => {
              await changeLanguage(value);
            }}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language == "en" ? "English" : "Français"}
              />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
