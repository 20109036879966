import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const baseColor = "#F2F4F7";
const highlightColor = "#F9FAFB";

const ScreenLoader = () => {
  useEffect(() => {
    // Schedule the page refresh after 1 minute
    const timeoutId = setTimeout(() => {
      window.location.reload();
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="flex h-screen bg-light-grey">
      {/* Left Navbar Skeleton */}
      <div className="relative flex h-full w-[114px] flex-col bg-light-grey p-[22px]">
        <div className="h-full px-2 py-[22px]">
          <Skeleton
            className="size-full rounded-[12px] bg-white px-2 py-[22px]"
            baseColor={"#FFFFFF"}
            highlightColor={highlightColor}
          />
        </div>
      </div>

      {/* Main Content Skeleton */}
      <div className="h-full grow bg-white p-8">
        <Skeleton
          className="size-full rounded-[10px] bg-light-grey"
          baseColor={baseColor}
          highlightColor={highlightColor}
        />
      </div>
    </div>
  );
};

export { ScreenLoader };
