/**
 * Returns a user-friendly message based on the subscription status.
 */
export const getSubscriptionIssueMessage = (status: string) => {
  switch (status) {
    case "past_due":
      return "subscription.issue.past_due";
    case "unpaid":
      return "subscription.issue.unpaid";
    case "incomplete":
      return "subscription.issue.incomplete";
    case "incomplete_expired":
      return "subscription.issue.incomplete_expired";
    case "canceled":
      return "subscription.issue.canceled";
    default:
      return "subscription.issue.default";
  }
};

/**
 * Returns the appropriate button text based on the subscription status.
 */
export const getAlertDialogButtonText = (status?: string) => {
  switch (status) {
    case "past_due":
    case "unpaid":
      return "subscription.button.update_payment";
    case "incomplete":
      return "subscription.button.complete_subscription";
    case "incomplete_expired":
    case "canceled":
      return "subscription.button.choose_plan";
    default:
      return "subscription.button.understood";
  }
};
