// settings/GeneralSettings.tsx
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

import { EditPen, Loader } from "@/assets/icons";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useUpdateFullName } from "@/axios/mutations";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

/**
 * General Settings form with styled inputs and buttons for a consistent layout.
 */
const GeneralSettings = () => {
  const { t } = useTranslation(); // Destructure i18n to get the current language

  const { user, changeLanguage, language, setLanguage } = useUserContext();

  const { updateFullName } = useUpdateFullName();

  const [name, setName] = useState(user?.name || "");

  const email = user?.email || "";

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      // Save the user's name
      console.info("Name saved:", name);
      await updateFullName(user?.user_id as string, name);
      await changeLanguage();

      // Optionally, you can trigger a state update or notification here
    } catch (error) {
      console.error("Error saving settings:", error);
      // Handle error (e.g., show notification)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-full bg-light-grey">
      <div className="size-full max-w-[400px] rounded-[12px] bg-white p-8">
        <h2 className="mb-2 text-base font-semibold text-primary">
          {t("settings.general.account")}
        </h2>

        <p className="mb-10 text-left text-sm font-semibold text-dark-grey">
          {t("settings.general.update_info")}
        </p>

        {/* Email Field */}
        <div className="mb-8">
          <Label
            htmlFor="email"
            className="mb-3 block text-sm font-medium text-dark-grey"
          >
            {t("settings.general.email")}
          </Label>
          <div className="relative">
            <Input
              id="email"
              type="email"
              value={email}
              disabled
              className="w-full cursor-not-allowed rounded-lg border border-light-grey bg-light-grey p-3 text-dark-grey"
            />
          </div>
        </div>

        {/* Name Field */}
        <div className="mb-10">
          <Label
            htmlFor="name"
            className="mb-3 block text-sm font-medium text-dark-grey"
          >
            {t("settings.general.name")}
          </Label>

          <div className="relative">
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("settings.general.name_placeholder")}
              className="w-full rounded-lg border border-light-grey p-3 text-primary"
            />
            <EditPen className="absolute right-3 top-1/2 shrink-0 -translate-y-1/2 cursor-pointer text-primary" />
          </div>
        </div>

        {/* Language Selector */}
        <div className="mb-10">
          <Label
            htmlFor="language"
            className="mb-3 block text-sm font-medium text-dark-grey"
          >
            {t("settings.general.language")}
          </Label>
          <Select
            value={language}
            onValueChange={(value) => setLanguage(value)}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language == "en" ? "English" : "Français"}
              />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
              {/* Add more languages as needed */}
            </SelectContent>
          </Select>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <Button
            onClick={handleSave}
            className="rounded-lg bg-primary px-6 py-2 font-semibold text-white hover:bg-primary hover:brightness-150"
          >
            {t("settings.general.save")}
            {isLoading && (
              <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
