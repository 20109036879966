// useTiktokAccounts.ts
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { TiktokAccount } from "@types";
import { useState } from "react";

function useTiktokAccounts(workspace_id: string, enabled = true) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  // Construct the cache key using workspace_id
  const cacheKey = `/api/tiktok/accounts/${workspace_id}`;

  const { data, error, mutate, isValidating } = useSWR<TiktokAccount>(
    cacheKey,
    (url: string) => fetcher(url, "get", null, false, enabled),
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<TiktokAccount[] | TiktokAccount> => {
    setIsRefetching(true);
    const updatedData = (await mutate()) as TiktokAccount[] | TiktokAccount; // Try to get updated data
    setIsRefetching(false);

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as TiktokAccount,
    isLoading: !data && !error && !isValidating, // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useTiktokAccounts };
