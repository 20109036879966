// hooks/useWorkspaceDocuments.ts
import { useState, useEffect } from "react";
import { useFetcher } from "@axios/fetcher";

export interface DocumentItem {
  _id?: string; // now optional
  filename: string;
  file_url: string;
  mime_type: string;
  size: number;
  createdAt: string;
}

export const useWorkspaceDocuments = (workspaceId: string | undefined) => {
  const { fetcher } = useFetcher();
  const [documents, setDocuments] = useState<DocumentItem[]>([]);

  const fetchDocuments = async () => {
    if (!workspaceId) return;
    try {
      const data = await fetcher(
        `/api/workspaces/${workspaceId}/documents`,
        "get",
      );
      setDocuments(data);
    } catch (error) {
      console.error("Error fetching documents", error);
    }
  };

  const uploadDocument = async (fileInfo: {
    file_url: string;
    filename: string;
    mime_type: string;
    size: number;
    uuid: string;
  }) => {
    if (!workspaceId) return;
    try {
      await fetcher(
        `/api/workspaces/${workspaceId}/documents`,
        "post",
        fileInfo,
      );
    } catch (error) {
      console.error("Error uploading document", error);
      throw error;
    }
  };

  const deleteDocument = async (documentId: string) => {
    if (!workspaceId) return;
    try {
      await fetcher(
        `/api/workspaces/${workspaceId}/documents/${documentId}`,
        "delete",
      );
    } catch (error) {
      console.error("Error deleting document", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [workspaceId]);

  return {
    documents,
    fetchDocuments,
    uploadDocument,
    deleteDocument,
  };
};
