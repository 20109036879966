// TaskItem.tsx

import { Comment, Conversation } from "@/types";
import { CHANNELS_CONFIG } from "@/types/channel.config";
import {
  sentimentEmojis,
  categoryEmojis,
  Classification,
} from "@/types/classification.types";
import { formatTimeToNowShort } from "@/utils/formatTimeToNowShort";
import { ClockIcon, SelectIcon, SelectedIcon, Loader } from "@assets/icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Img } from "../Img";
import { ProfileImg } from "../ProfileImg";
import { useTaskContext } from "@/layouts/useTask.context";
import { isComment } from "@/utils/isComment";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@/utils/useWindowWidth";
import { useNavBar } from "@/layouts/NavBar.context";
import { useEffect, useRef, useState } from "react";

/**
 * Props for TaskItem component.
 */
type TaskItemProps = {
  item: Comment | Conversation;
  type: "comment" | "message";
};

/**
 * Renders a single task item (comment or message), handling selection,
 * displaying profile info, timestamp, category, and animations on status change.
 *
 * @param item - The item (comment or message) to be rendered.
 * @param type - The type of the item ("comment" or "message").
 */
export const TaskItem = ({ item, type }: TaskItemProps) => {
  const { t } = useTranslation();

  const width = useWindowWidth();
  const isBelow1440 = width < 1440;
  const { isCollapsed } = useNavBar();

  // Determine if the item is a comment or a message using the type guard
  const isCommentItem = isComment(type, item);

  const {
    openedItemId,
    selectedItemIds,
    openItem,
    closeItem,
    selectItem,
    deselectItem,
    animatingItems,
    handleAnimationEnd,
  } = useTaskContext();

  const username = isCommentItem
    ? item.username
    : item?.participants?.find((p) => !p.owner)?.username;

  // Use profile picture or generate a random one
  const profilePicture = isCommentItem
    ? item?.profile_picture_url
    : item?.participants?.find((p) => !p.owner)?.profile_picture_url;

  const timestamp = item.timestamp;

  const text = item.text;
  const classification = item.classification;

  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const story = !isCommentItem
    ? (item as Conversation).last_message?.story
    : undefined;
  const shares = !isCommentItem
    ? (item as Conversation).last_message?.shares
    : undefined;
  const attachments = !isCommentItem
    ? (item as Conversation).last_message?.attachments
    : (item as Comment).attachments;

  const is_unsupported = !isCommentItem
    ? (item as Conversation).last_message?.is_unsupported
    : (item as Comment).is_unsupported;

  const not_supported =
    (!story && !shares?.length && !attachments?.length && !item.text?.length) ||
    is_unsupported;

  const owner = !isCommentItem
    ? (item as Conversation).last_message?.owner
    : (item as Comment).owner;

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      // Compare the visible width with the full scrollable width
      setIsTruncated(el.offsetWidth < el.scrollWidth);
    }
  }, [text]);

  if (!item.channel) {
    return null;
  }

  const channelConfig = CHANNELS_CONFIG[item.channel.type];

  const isOpened = openedItemId === item._id;
  const isSelected = selectedItemIds.includes(item._id);
  const isAnimating = animatingItems[item._id] !== undefined;

  /**
   * Handles selecting or deselecting an item.
   * Prevents the click from affecting the parent element's events.
   */
  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isSelected) {
      deselectItem(item._id);
    } else {
      if (!isOpened && !openedItemId && selectedItemIds.length === 0) {
        openItem(item._id);
      }
      selectItem(item._id);
    }
  };

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isOpened) {
      closeItem();
    } else {
      openItem(item._id);
    }
  };

  const getMessage = () => {
    if (item.text?.length) {
      return text;
    }
    if (not_supported) {
      return t("tasks.comments_renderer.not_supported");
    }
    if (attachments?.length || shares?.length) {
      return !owner
        ? t("tasks.conversation_renderer.sent_you_attachment")
        : t("tasks.conversation_renderer.you_sent_attachment");
    }
    if (story) {
      if (!owner) {
        return story.type === "reply_to"
          ? t("tasks.conversation_renderer.replied_to_your_story")
          : t("tasks.conversation_renderer.mentioned_you");
      } else {
        return story.type === "reply_to"
          ? t("tasks.conversation_renderer.user_replied_to_a_story")
          : t("tasks.conversation_renderer.user_mention_to_a_story", {
              username:
                (item as Conversation)?.last_message?.recipient_name || "",
            });
      }
    }
    return t("tasks.conversation_renderer.mentioned_you");
  };

  return (
    <div
      className={`relative flex w-full cursor-pointer items-center rounded-[10px] px-3 py-[10px] transition-colors ${
        isOpened
          ? "bg-dark-blue/20"
          : isSelected
            ? "bg-light-grey"
            : "bg-transparent hover:bg-light-grey"
      } ${isAnimating ? "overflow-hidden" : ""}`}
      onClick={selectedItemIds.length > 0 ? handleSelect : handleOpen}
    >
      {/* Background div that expands to push content */}
      {isAnimating && (
        <div
          className={`absolute inset-0 ${
            animatingItems[item._id] === "skipped" ||
            animatingItems[item._id] === "to-do"
              ? "bg-dark-grey"
              : animatingItems[item._id] === "hidden"
                ? "bg-violet"
                : "bg-green"
          } z-0 flex animate-backgroundExpand items-center justify-start`}
          onAnimationEnd={() => handleAnimationEnd(item._id)}
        >
          <span className="ml-10 whitespace-nowrap text-base font-semibold text-white">
            {t(`tasks.task_item.${animatingItems[item._id] || "done"}`)}
          </span>
        </div>
      )}

      {/* Content div that gets pushed */}
      <div
        className={`relative flex w-full flex-1 items-center  transition-transform ${
          isAnimating ? "z-10 animate-contentPush overflow-hidden" : "z-10"
        }`}
      >
        {/* Select button with increased clickable area */}
        <button
          onClick={handleSelect}
          className="mr-2 brightness-90 hover:brightness-50 focus-visible:outline-none"
          style={{ minWidth: "20px", minHeight: "20px" }}
        >
          {isSelected || isOpened ? <SelectedIcon /> : <SelectIcon />}
        </button>

        {/* Profile picture and channel icon */}
        <div className="relative shrink-0">
          <ProfileImg
            username={
              isCommentItem ? username : (username ?? item.conversation_id)
            }
            id={item._id}
            profilePictureUrl={profilePicture}
            className="size-10 shrink-0 rounded-full border border-light-grey object-cover"
          />

          <Img
            src={channelConfig.icon}
            alt={`${item.channel.type}-icon`}
            className="absolute bottom-0 right-0 size-4 shrink-0 rounded-full bg-white shadow-[0_0_0_2px_theme('colors.light-grey')]"
          />
        </div>

        {/* Item content */}
        <div className="ml-4 min-w-0 flex-1">
          {username ? (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <span
                  className={`${isBelow1440 || !isCollapsed ? "max-w-[70px]" : "max-w-[150px]"} truncate text-sm font-extrabold`}
                >
                  {username}
                </span>
              </div>

              {/* Reusable Timestamp and Emoji Component */}
              <TimestampAndEmoji
                timestamp={timestamp}
                classification={classification}
              />
            </div>
          ) : (
            <div className="flex w-full items-center justify-between">
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <p
                      ref={textRef}
                      className={`flex-1 truncate pr-6 text-sm font-medium ${
                        item.text?.length ? "text-primary" : "text-dark-grey"
                      }`}
                    >
                      {getMessage()}
                    </p>
                  </TooltipTrigger>

                  {isTruncated && (
                    <TooltipContent
                      className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                      sideOffset={5}
                    >
                      <p className="max-w-[350px]">{text}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>

              {/* Reusable Timestamp and Emoji Component */}
              <TimestampAndEmoji
                timestamp={timestamp}
                classification={classification}
              />
            </div>
          )}

          {username && (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <p
                    ref={textRef}
                    className={`mt-1 flex-1 truncate pr-2 text-sm font-medium ${
                      item.text?.length ? "text-primary" : "text-dark-grey"
                    }`}
                  >
                    {getMessage()}
                  </p>
                </TooltipTrigger>

                {isTruncated && (
                  <TooltipContent
                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium leading-relaxed text-white"
                    sideOffset={5}
                  >
                    <p className="max-w-[350px]">{text}</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Reusable component for displaying timestamp and category emoji with tooltip.
 */
const TimestampAndEmoji = ({
  timestamp,
  classification,
}: {
  timestamp: Date | null;
  classification?: Classification;
}) => {
  const { t } = useTranslation();

  const { category, sentiment } = classification || {};
  const categoryEmoji: string | null =
    category && category in categoryEmojis ? categoryEmojis[category] : null;

  const sentimentEmoji: string | null =
    sentiment && sentiment in sentimentEmojis
      ? sentimentEmojis[sentiment]
      : null;

  return (
    <TooltipProvider>
      <div className="flex shrink-0 items-center">
        {categoryEmoji && (
          <Tooltip delayDuration={100}>
            {/* Faster tooltip */}
            <TooltipTrigger>
              <div className="text-sm text-primary">{categoryEmoji}</div>
            </TooltipTrigger>
            <TooltipContent
              className="rounded-md bg-primary/90 px-2 py-1 font-inter text-xs font-medium text-white"
              sideOffset={5}
            >
              <p>
                {sentimentEmoji && (
                  <span className="mr-1">{sentimentEmoji}</span>
                )}
                {sentiment && (
                  <span>
                    {t(
                      `tasks.classification.sentiment.${sentiment.toLowerCase().replace(/ /g, "_")}`,
                    )}
                  </span>
                )}
                {" - "}
                {categoryEmoji && <span className="mr-1">{categoryEmoji}</span>}
                {category && (
                  <span>
                    {t(
                      `tasks.classification.category.${category.toLowerCase().replace(/ /g, "_")}`,
                    )}
                  </span>
                )}
              </p>
            </TooltipContent>
          </Tooltip>
        )}
        <div className="flex w-[55px] items-center text-xs font-medium text-dark-grey">
          {timestamp ? (
            <>
              <ClockIcon className="ml-2.5 mr-1.5 mt-0.5 shrink-0" />
              <span className="mt-0.5 w-[24px]">
                {formatTimeToNowShort(timestamp, t)}
              </span>
            </>
          ) : (
            <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-dark-grey" />
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};

export default TaskItem;
