import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import {
  FacebookCircleIcon,
  InstagramCircleIcon,
  LinkedinCircleIcon,
  Loader,
  TiktokCircleIcon,
  YoutubeCircleIcon,
  SelectIcon,
  SelectedIcon,
} from "@/assets/icons";

import {
  categoryEmojis,
  Classification,
  sentimentEmojis,
} from "@/types/classification.types";
import { useTranslation } from "react-i18next";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useUpdateAutoHideFilter } from "@/axios/mutations/useUpdateAutoHideFilter";
import { useUpdateAutoReplyFilter } from "@/axios/mutations/useUpdateAutoReplyFilter";
import { SmartFeatureFilter } from "@/types/workspace.type";
import { Channel } from "@/types";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import { PlanId } from "@/types/PLANS.config";
import PlanRestrictionAlertDialog from "@/layouts/app-wrapper/PlanRestrictionAlertDialog";
import { useUpdateAutoHide } from "@/axios/mutations/useUpdateAutoHide";
import { useUpdateAutoReply } from "@/axios/mutations/useUpdateAutoReply";

/**
 * Props:
 *  - feature: which feature is being edited, or undefined
 *  - isEnablingFlow: whether the user is in the middle of turning it on for the first time
 *  - onClose(didApply: boolean): parent callback to close and notify whether user saved or not
 */
type SmartFeatureFilterDialogProps = {
  open: boolean;
  onClose: (didApply: boolean) => void;
  feature?: "autoHide" | "autoReply";
  isEnablingFlow: boolean;
};

export const SmartFeatureFilterDialog = ({
  open,
  onClose,
  feature,
  isEnablingFlow,
}: SmartFeatureFilterDialogProps) => {
  const { t } = useTranslation();
  const { currentWorkspace, channels } = useWorkspaceContext();
  const { subscriptionDetails } = useSubscriptionContext();

  const { updateAutoHideFilter } = useUpdateAutoHideFilter();
  const { updateAutoReplyFilter } = useUpdateAutoReplyFilter();
  const { updateAutoHide } = useUpdateAutoHide();
  const { updateAutoReply } = useUpdateAutoReply();

  const availableSentiments: Classification["sentiment"][] = [
    "Positive",
    "Negative",
    "Neutral",
  ];
  const availableCategories: Classification["category"][] = [
    "Business",
    "Direct Interactions",
    "Sensitive Content",
    "Feedback",
    "Questions",
    "Complaint",
    "Humor",
    "Sharing",
  ];

  // Default filters if user has none
  const defaultSentiments: Classification["sentiment"][] = [];
  const defaultCategories: Classification["category"][] =
    feature === "autoHide" ? ["Sensitive Content"] : ["Business"];
  const defaultPlatforms: Channel["type"][] = [
    "facebook",
    "instagram",
    "youtube",
    "tiktok",
  ];

  const defaultPlatformIcons = {
    facebook: <FacebookCircleIcon className="size-3.5" />,
    "facebook-ads": <FacebookCircleIcon className="size-3.5" />,
    instagram: <InstagramCircleIcon className="size-3.5" />,
    youtube: <YoutubeCircleIcon className="size-3.5" />,
    tiktok: <TiktokCircleIcon className="size-3.5" />,
    "tiktok-ads": <TiktokCircleIcon className="size-3.5" />,
    linkedin: <LinkedinCircleIcon className="size-3.5" />,
  };

  const defaultAdsFilter =
    (subscriptionDetails?.plan_id as PlanId) >= 2 ? "both" : "organic";

  // Local states for filter
  const [sentiments, setSentiments] =
    useState<Classification["sentiment"][]>(defaultSentiments);
  const [categories, setCategories] =
    useState<Classification["category"][]>(defaultCategories);
  const [platforms, setPlatforms] =
    useState<Channel["type"][]>(defaultPlatforms);
  const [adFilter, setAdFilter] = useState<"organic" | "ads" | "both">(
    defaultAdsFilter,
  );
  const [applyTo, setApplyTo] = useState<"comment" | "message" | "both">(
    "both",
  );

  const [firstChangeDone, setFirstChangeDone] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Whenever the dialog opens, load existing filters if they exist
  useEffect(() => {
    if (!currentWorkspace || !feature) return;

    const existingFilter: SmartFeatureFilter =
      feature === "autoHide"
        ? (currentWorkspace.settings?.auto_hide_filter as SmartFeatureFilter)
        : (currentWorkspace.settings?.auto_reply_filter as SmartFeatureFilter);

    if (existingFilter) {
      setApplyTo(existingFilter.apply_to || "both");
      setSentiments(existingFilter.sentiments || defaultSentiments);
      setCategories(existingFilter.categories || defaultCategories);
      setPlatforms(existingFilter.channels || defaultPlatforms);
      setAdFilter(existingFilter.ads || defaultAdsFilter);
    } else {
      // No existing filter: use defaults
      setApplyTo("both");
      setSentiments(defaultSentiments);
      setCategories(defaultCategories);
      setPlatforms(defaultPlatforms);
      setAdFilter(defaultAdsFilter);
    }

    setFirstChangeDone(false);
  }, [currentWorkspace, feature, open]);

  // Toggle sentiment selection
  const toggleSentiment = (sentiment: Classification["sentiment"]) => {
    if (sentiments.includes(sentiment)) {
      setSentiments(sentiments.filter((s) => s !== sentiment));
    } else {
      setSentiments([...sentiments, sentiment]);
    }
    setFirstChangeDone(true);
  };

  // Toggle category selection
  const toggleCategory = (category: Classification["category"]) => {
    if (categories.includes(category)) {
      setCategories(categories.filter((c) => c !== category));
    } else {
      setCategories([...categories, category]);
    }
    setFirstChangeDone(true);
  };

  // Toggle platform selection
  const togglePlatform = (platform: Channel["type"]) => {
    if (!firstChangeDone) {
      // If it's the first change from “all selected”, pick only this one
      setPlatforms([platform]);
      setFirstChangeDone(true);
    } else if (
      platforms.length === defaultPlatforms.length &&
      platforms.includes(platform)
    ) {
      // If everything is selected, and the user re-clicks an already selected platform
      setPlatforms([platform]);
    } else {
      // Otherwise normal toggling
      if (platforms.includes(platform)) {
        setPlatforms(platforms.filter((p) => p !== platform));
      } else {
        setPlatforms([...platforms, platform]);
      }
    }
    setFirstChangeDone(true);
  };

  // Toggle ad vs. organic filter
  const handleAdFilterChange = (value: "organic" | "ads" | "both" | null) => {
    if (
      (subscriptionDetails?.plan_id as number) < 2 &&
      value !== "organic" &&
      value !== null
    ) {
      // Show plan restriction dialog if user tries "ads" or "both" but doesn’t have the plan
      setShowAlertDialog(true);
    } else {
      setAdFilter(value || "both");
    }
  };

  // Toggle apply to message, comment, or both (for autoReply)
  const handleTypeFilterChange = (
    value: "message" | "comment" | "both" | null,
  ) => {
    setApplyTo(value || "both");
  };

  // Reset everything to defaults
  const handleReset = () => {
    setSentiments(defaultSentiments);
    setCategories(defaultCategories);
    setPlatforms(defaultPlatforms);
    setApplyTo("both");
    setAdFilter(defaultAdsFilter);
    setFirstChangeDone(false);
  };

  // Actually apply: updates filter + turns on the feature on the server (if enabling)
  const handleApply = async () => {
    if (!currentWorkspace?._id || !feature) {
      onClose(false); // Something’s wrong – close and revert if enabling
      return;
    }

    setIsLoading(true);

    const filterPayload: SmartFeatureFilter = {
      apply_to: applyTo,
      sentiments,
      categories,
      channels: platforms,
      ads: adFilter,
    };

    try {
      if (feature === "autoHide") {
        await updateAutoHideFilter(currentWorkspace._id, filterPayload);
        if (isEnablingFlow) {
          // Only enable if we were turning it on
          await updateAutoHide(currentWorkspace._id, true);
        }
      } else if (feature === "autoReply") {
        await updateAutoReplyFilter(currentWorkspace._id, filterPayload);
        if (isEnablingFlow) {
          await updateAutoReply(currentWorkspace._id, true);
        }
      }

      // Let the parent know we successfully applied (didApply = true)
      onClose(true);
    } catch (error) {
      console.error("Error updating filter:", error);
      onClose(false); // On error, revert if we were enabling
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * If user tries to close the dialog while `isLoading` is true,
   * do nothing – we block closing to meet your requirement that user
   * cannot close while applying changes is in progress.
   */
  const handleDialogOpenChange = (open: boolean) => {
    if (isLoading) {
      // Block closure
      return;
    }
    // If user is closing and not loading, that means they canceled
    if (!open) {
      onClose(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleDialogOpenChange}>
        <DialogContent className="max-h-[80vh] w-fit overflow-y-auto rounded-lg bg-white p-0 shadow-lg scrollbar-hide">
          <div className="px-6 pt-6">
            {/* Sentiments and Categories */}
            <div className="mb-6">
              <p className="mb-5 text-base font-extrabold">
                {t("tasks.FilterPopover.filter_options")}
              </p>

              <div className="flex flex-row space-x-8">
                {/* Sentiments & Categories */}
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold text-dark-grey">
                    {t("tasks.FilterPopover.sentiments")}
                  </p>
                  <div className="flex flex-col space-y-1.5">
                    {availableSentiments.map((sentiment) => (
                      <div
                        key={sentiment}
                        onClick={() => toggleSentiment(sentiment)}
                        className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                          sentiments.includes(sentiment)
                            ? "bg-light-grey"
                            : "bg-white hover:bg-light-grey/60"
                        }`}
                      >
                        <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                          <span>{sentimentEmojis[sentiment]}</span>
                          <span>
                            {t(
                              `tasks.classification.sentiment.${sentiment.toLowerCase()}`,
                            )}
                          </span>
                        </span>
                        {sentiments.includes(sentiment) ? (
                          <SelectedIcon />
                        ) : (
                          <SelectIcon />
                        )}
                      </div>
                    ))}
                  </div>

                  {/* Categories */}
                  <p className="mb-2 mt-4 text-sm font-semibold text-dark-grey">
                    {t("tasks.FilterPopover.categories")}
                  </p>
                  <div className="flex flex-col space-y-1.5">
                    {availableCategories.map((category) => (
                      <div
                        key={category}
                        onClick={() => toggleCategory(category)}
                        className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                          categories.includes(category)
                            ? "bg-light-grey"
                            : "bg-white hover:bg-light-grey/60"
                        }`}
                      >
                        <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                          <span>{categoryEmojis[category]}</span>
                          <span>
                            {t(
                              `tasks.classification.category.${category
                                .toLowerCase()
                                .replace(/ /g, "_")}`,
                            )}
                          </span>
                        </span>
                        {categories.includes(category) ? (
                          <SelectedIcon />
                        ) : (
                          <SelectIcon />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Platforms */}
                {defaultPlatforms?.filter((p) =>
                  channels?.find((c) => c.type === p),
                )?.length ? (
                  <div>
                    <p className="mb-4 text-sm font-semibold text-dark-grey">
                      {t("tasks.FilterPopover.select_platform")}
                    </p>
                    <div className="flex flex-col space-y-1.5">
                      {defaultPlatforms
                        .filter((platform) =>
                          channels?.find((c) => c.type === platform),
                        )
                        .map((platform) => (
                          <div
                            key={platform}
                            onClick={() => togglePlatform(platform)}
                            className={`flex w-[210px] cursor-pointer items-center justify-between rounded-[6px] px-3 py-2 ${
                              platforms.includes(platform)
                                ? "bg-light-grey"
                                : "bg-white hover:bg-light-grey/60"
                            }`}
                          >
                            <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                              <span>{defaultPlatformIcons[platform]}</span>
                              <span>
                                {platform.charAt(0).toUpperCase() +
                                  platform.slice(1)}
                              </span>
                            </span>
                            {platforms.includes(platform) ? (
                              <SelectedIcon />
                            ) : (
                              <SelectIcon />
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* Apply to messages vs comments (for autoReply only) */}
            {feature === "autoReply" && (
              <div className="mb-6">
                <p className="mb-4 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.apply_to")}
                </p>
                <ToggleGroup
                  type="single"
                  value={applyTo}
                  onValueChange={(value: string) =>
                    handleTypeFilterChange(
                      value as "comment" | "message" | "both" | null,
                    )
                  }
                  className="flex items-start justify-around space-x-2"
                >
                  {["message", "comment", "both"].map((type) => (
                    <ToggleGroupItem
                      key={type}
                      value={type}
                      className={`flex w-32 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                        applyTo === type
                          ? "bg-light-grey"
                          : "border border-light-grey bg-white hover:bg-light-grey/60"
                      }`}
                      aria-label={t(`tasks.FilterPopover.${type}`)}
                    >
                      {t(`tasks.FilterPopover.${type}`)}
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              </div>
            )}

            {/* Organic / Ads / Both */}
            <div className="mb-6">
              <p className="mb-4 text-sm font-semibold text-dark-grey">
                {t("tasks.FilterPopover.comment_types")}
              </p>
              <ToggleGroup
                type="single"
                value={adFilter}
                onValueChange={(value: string) =>
                  handleAdFilterChange(
                    value as "organic" | "ads" | "both" | null,
                  )
                }
                className="flex items-start justify-around space-x-2"
              >
                {["organic", "ads", "both"].map((type) => (
                  <ToggleGroupItem
                    key={type}
                    value={type}
                    className={`flex w-32 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                      adFilter === type
                        ? "bg-light-grey"
                        : "border border-light-grey bg-white hover:bg-light-grey/60"
                    }`}
                    aria-label={t(`tasks.FilterPopover.${type}`)}
                  >
                    {t(`tasks.FilterPopover.${type}`)}
                  </ToggleGroupItem>
                ))}
              </ToggleGroup>
            </div>
          </div>

          {/* Sticky Footer with Reset and Apply */}
          <div className="sticky bottom-0 flex items-center justify-end border-t border-light-grey bg-white p-6 text-sm font-semibold">
            <button
              onClick={handleReset}
              disabled={isLoading}
              className={`mr-3 w-32 cursor-pointer rounded-[8px] py-2.5 ${
                isLoading
                  ? "bg-light-grey text-primary opacity-70"
                  : "bg-light-grey text-primary hover:brightness-90"
              }`}
            >
              {t("tasks.FilterPopover.reset")}
            </button>
            <button
              onClick={handleApply}
              disabled={isLoading}
              className={`flex w-32 flex-row items-center justify-center rounded-[8px] py-2.5 text-white ${
                isLoading
                  ? "cursor-not-allowed bg-dark-grey"
                  : "cursor-pointer bg-primary hover:brightness-150"
              }`}
            >
              {t("tasks.FilterPopover.apply")}
              {isLoading && (
                <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Plan restriction dialog (for Ads filter) */}
      <PlanRestrictionAlertDialog
        message={t("plan_restriction_dialog.messages.need_plan_pro")}
        trigger={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
      />
    </>
  );
};

export default SmartFeatureFilterDialog;
