import { createContext, ReactNode, useEffect, useState } from "react";
import { useUser } from "@axios/queries";
import { useUserWorkspaces } from "@axios/queries";
import { useAuth0 } from "@auth0/auth0-react";
import { Organization, User, WorkspaceDetails } from "@types";
import { useOrganization } from "@/axios/queries/useOrganizations";
import { useTranslation } from "react-i18next";

import { useUpdateLanguage } from "@/axios/mutations";
import { changeLanguage } from "@/services/lang/i18n";
import { useLocation } from "react-router-dom";
import { useUpdateSource } from "@/axios/mutations/useUpdateSource";

type UserContextType = {
  user: User | null;
  workspaces: WorkspaceDetails[] | null;
  isLoadingUser: boolean;
  isLoadingWorkspaces: boolean;
  error: unknown;
  refetchUser: () => void;
  refetchWorkspaces: () => Promise<WorkspaceDetails[]>;
  organization: Organization | null;
  isLoadingOrganization: boolean;
  refetchOrganization: () => void;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  changeLanguage: (language?: string) => void;
  isChangingLanguage: boolean;
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const { i18n } = useTranslation(); // Destructure i18n to get the current language
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const { user: authUser } = useAuth0();

  const {
    data: user,
    isLoading: isLoadingUser,
    error,
    refetch: refetchUser,
  } = useUser(authUser?.sub as string, !!authUser);

  const {
    data: organization,
    isLoading: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useOrganization(authUser?.sub as string, !!authUser);

  const {
    data: workspaces,
    isLoading: isLoadingWorkspaces,
    refetch: refetchWorkspaces,
  } = useUserWorkspaces(authUser?.sub as string, !!authUser);

  const { updateLanguage } = useUpdateLanguage();
  const { updateSource } = useUpdateSource();

  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const [language, setLanguage] = useState<string>(
    user?.language?.trim() ||
      localStorage.getItem("i18nextLng")?.trim() ||
      i18n.language?.trim() ||
      "fr",
  );

  const handleChangeLanguage = async (new_language?: string) => {
    setIsChangingLanguage(true);

    try {
      if (!new_language) {
        new_language = language;
      }

      // Change the language first to trigger re-render
      if (new_language !== i18n.language) {
        await changeLanguage(new_language);
        console.info("Language changed to:", new_language);
      }

      // Update the user's language in the backend
      if (user?.user_id && user?.language != new_language) {
        await updateLanguage(user.user_id, new_language);
        console.info("User language updated in backend:", new_language);
      }

      // Optionally, you can trigger a state update or notification here
    } catch (error) {
      console.error("Error saving settings:", error);
      // Handle error (e.g., show notification)
    } finally {
      setIsChangingLanguage(false);
    }
  };

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (user && !user.source?.utm_source && queryParams?.get("utm_source")) {
      const utm_source = queryParams.get("utm_source") || undefined;
      const utm_medium = queryParams.get("utm_medium") || undefined;
      const utm_campaign = queryParams.get("utm_campaign") || undefined;
      const referral_id = queryParams.get("ref") || undefined;

      updateSource(user.user_id, {
        utm_source,
        utm_medium,
        utm_campaign,
        referral_id,
      });
    }
  }, [location?.pathname, user, queryParams]);

  return (
    <UserContext.Provider
      value={{
        user: user ?? null,
        workspaces: workspaces ?? null,
        isLoadingUser,
        isLoadingWorkspaces,
        error,
        refetchUser,
        refetchWorkspaces,
        organization,
        isLoadingOrganization,
        refetchOrganization,
        language,
        setLanguage,
        changeLanguage: handleChangeLanguage,
        isChangingLanguage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
