// src/components/GuideTip.tsx

import { AiIcon, ArrowPointingLeft } from "@/assets/icons";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Img } from "./Img";
import { useTranslation } from "react-i18next";

type GuideTipProps = {
  title: string | null;
  description: string | ReactElement;
  onClose?: () => void;
  onSkip?: () => void;
  position?: "left" | "right";
  warning?: boolean;
  append?: boolean;
};

const GuideTip = ({
  title,
  description,
  onClose,
  onSkip,
  position = "left",
  warning = false,
  append = false,
}: GuideTipProps) => {
  const { t } = useTranslation();

  const [content, setContent] = useState<ReactElement | string>("");
  const typingIndex = useRef(0);
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof description !== "string") {
      setContent(description);
      return;
    }

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    if (append) {
      const startIndex = typeof content === "string" ? content.length : 0;
      typingIndex.current = startIndex;
    } else {
      typingIndex.current = 0;
      setContent("");
    }

    const typeNextChar = () => {
      if (typingIndex.current < description.length) {
        const nextChar = description.charAt(typingIndex.current);
        setContent((prevContent) => {
          const newContent =
            (typeof prevContent === "string" ? prevContent : "") + nextChar;
          return newContent;
        });
        typingIndex.current++;
        typingTimeout.current = setTimeout(typeNextChar, 25);
      } else {
        typingTimeout.current = null;
      }
    };

    typingTimeout.current = setTimeout(typeNextChar, 25);

    return () => {
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }
    };
  }, [description, append]);

  const isLeft = position === "left";

  return (
    <div
      className={`relative flex flex-col gap-4 rounded-xl bg-white p-5 shadow-lg ${
        warning ? "border border-red text-red" : "text-primary"
      }`}
      style={{
        marginLeft: isLeft ? "30px" : "0",
        marginRight: !isLeft ? "30px" : "0",
        width: "280px",
      }}
    >
      {/* Arrow */}
      <Img
        src={ArrowPointingLeft}
        alt="Arrow"
        className={`absolute top-12 z-20 ${isLeft ? "-left-2" : "-right-2 rotate-180"}`}
      />
      {/* Icon */}
      <div>
        <AiIcon className="size-10" />
      </div>
      {/* Title */}
      {title && <h2 className="text-[16px] font-bold text-primary">{title}</h2>}

      {/* Description */}
      <p
        className={`text-[16px] leading-5 ${warning ? "text-red" : "text-primary"}`}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {content}
      </p>
      {/* Action Buttons */}
      {(onClose || onSkip) && (
        <div className="flex gap-2">
          {onClose && (
            <button
              className="w-24 rounded-lg bg-light-grey py-2 text-center text-[16px] font-semibold text-primary hover:brightness-90"
              onClick={onClose}
            >
              {t("guideTip.gotIt")}
            </button>
          )}
          {onSkip && (
            <button
              className="w-24 rounded-lg bg-primary py-2 text-center text-[16px] font-semibold text-white hover:brightness-90"
              onClick={onSkip}
            >
              {t("guideTip.skipForNow")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GuideTip;
