import { useContext } from "react";
import { WorkspaceContext } from "@layouts/workspace-provider/WorkspaceProvider";

// Hook to use workspace context
export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);

  if (!context) {
    throw new Error(
      "useWorkspaceContext must be used within a WorkspaceProvider",
    );
  }

  return context;
};
