// useUpdateMembershipStatus.ts
import { useFetcher } from "@axios/fetcher";
import { Membership } from "@types";

function useUpdateMembershipStatus() {
  const { fetcher } = useFetcher();

  const updateMembershipStatus = async (
    membershipId: string,
    status: Membership["status"],
  ) => {
    const updatedMembership = await fetcher(
      `/api/memberships/${membershipId}/status`,
      "patch",
      { status },
    );

    return updatedMembership as Membership;
  };

  return {
    updateMembershipStatus,
  };
}

export { useUpdateMembershipStatus };
