// MessagesSkeleton.tsx
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const baseColor = "#F2F4F7";
const highlightColor = "#F9FAFB";

export const MessagesSkeleton = () => {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Left Side - TaskList Skeleton */}
      <div className="h-full min-w-[420px] max-w-[420px]">
        <div className="flex size-full flex-col bg-white px-6 pt-5">
          {/* Header Skeleton */}
          <div className="flex items-center justify-between">
            {/* Title Skeleton */}
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              width={100}
              height={24}
              className="mr-7 shrink-0"
            />

            {/* Tabs and Options Skeleton */}
            <div className="flex shrink-0 items-center space-x-2">
              {/* Select Dropdown Skeleton */}
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                width={120}
                height={40}
                borderRadius={10}
              />

              {/* Option Buttons Skeleton */}
              {Array.from({ length: 2 }).map((_, index) => (
                <Skeleton
                  key={index}
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  width={40}
                  height={40}
                  borderRadius={12}
                />
              ))}
            </div>
          </div>

          {/* Content Skeleton */}
          <div className="flex size-full flex-col overflow-hidden py-6">
            <div className="h-full overflow-hidden scrollbar-hide">
              {Array.from({ length: 10 }).map((_, index) => (
                <div key={index}>
                  {/* Task Item Skeleton */}
                  <div className="flex w-full items-center rounded-[10px] px-3 py-[10px]">
                    {/* Select Button Skeleton */}
                    <Skeleton
                      baseColor={baseColor}
                      highlightColor={highlightColor}
                      width={20}
                      height={20}
                      className="mr-0.5"
                    />

                    {/* Profile Picture Skeleton */}
                    <div className="relative ml-2 shrink-0">
                      <Skeleton
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                        circle
                        width={40}
                        height={40}
                      />
                    </div>

                    {/* Content Skeleton */}
                    <div className="ml-4 flex-1">
                      {/* Username Skeleton */}
                      <Skeleton
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                        width="60%"
                        height={16}
                      />

                      {/* Text Skeleton */}
                      <Skeleton
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                        width="80%"
                        height={12}
                        className="mt-2"
                      />
                    </div>
                  </div>

                  {/* Separator between items */}
                  {index !== 9 && (
                    <hr className="mx-[6px] my-2 border-t border-dashed border-light-grey" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Middle Section - ConversationPanel Skeleton */}
      <div className="flex size-full min-w-[500px] flex-col bg-light-grey p-6">
        {/* Scrollable Content */}
        <div className="conversation-scroll-container h-full scrollbar-hide">
          {/* Post Details Skeleton */}
          <div className="conversation-snap-item h-full max-h-[142px]">
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              height={120}
            />
          </div>

          {/* Conversation Renderer Skeleton */}
          <div className="conversation-snap-item middle-section mt-5">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="mb-3 ml-auto max-w-[75%]">
                <Skeleton
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  height={80}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Bottom Section Skeleton */}
        <div className="mt-4 flex flex-col">
          {/* AI Response Skeleton */}
          <div className="flex w-[90%] items-center justify-between self-end rounded-[10px] bg-white px-5 py-4">
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              width="80%"
              height={20}
            />
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              width={150}
              height={40}
              className="ml-5"
            />
          </div>

          {/* Reply Input Skeleton */}
          <div className="mt-4 flex items-center space-x-2 rounded-[10px] bg-white p-5">
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              width="100%"
              height={80}
            />
          </div>
        </div>
      </div>

      {/* Right Side - AIPanel Skeleton */}
      <div className="h-full w-[50px] bg-white">
        <div className="flex justify-center p-2">
          <Skeleton
            baseColor={baseColor}
            highlightColor={highlightColor}
            circle
            width={40}
            height={40}
          />
        </div>
      </div>
    </div>
  );
};
