// useFetcher.ts
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback } from "react";
import { useAdminStore } from "@redux/store";

const useFetcher = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const isAdmin = useAdminStore((state) => state.isAdmin);
  const selectedUserId = useAdminStore((state) => state.user_id);

  const fetcher = useCallback(
    async (
      url: string,
      method: "get" | "post" | "put" | "delete" | "patch",
      data?: unknown,
      streaming = false,
      enabled = true,
    ) => {
      // Log the API request initiation
      // console.info(`Starting request: ${method.toUpperCase()} ${url}`, { data });

      if (!enabled) {
        console.info(`Request disabled for ${url}`);
        return null;
      }

      if (!isLoading && !isAuthenticated) {
        throw new Error("User is not authenticated");
      }

      const accessToken = await getAccessTokenSilently();

      // console.debug("Access Token:", accessToken);

      const headers: { [key: string]: string } = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      // If the user is an admin and a user is selected, add an extra header
      if (isAdmin && selectedUserId) {
        headers["X-Selected-User"] = selectedUserId;
      }

      const instance = axios.create({
        baseURL: import.meta.env.VITE_BACKEND_SERVER_URL,
        headers,
      });

      try {
        const response = await instance({
          url,
          method,
          data: method !== "get" ? data : undefined,
          responseType: streaming ? "stream" : "json",
        });

        return response.data;
      } catch (error) {
        console.error(`Request failed: ${method.toUpperCase()} ${url}`, {
          error,
        });
        throw error;
      }
    },
    [
      getAccessTokenSilently,
      isAuthenticated,
      isLoading,
      isAdmin,
      selectedUserId,
    ],
  );

  return {
    fetcher,
    isAuthenticated: !isLoading && isAuthenticated,
  };
};

export { useFetcher };
