// src/components/RenderShare.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import { ShareItem } from "@/types/messages.types";

export const RenderShare: React.FC<{ share: ShareItem }> = ({ share }) => {
  const { t } = useTranslation();

  // If no link, display a "Not supported" message
  if (!share.link) {
    return;
  }

  // Display share link, name, and (optionally) the description
  return (
    <div className="">
      <a
        href={share.link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm font-medium text-dark-blue underline hover:font-semibold hover:brightness-75"
      >
        {share.name || t("tasks.comments_renderer.open_attachment")}
      </a>
      {/* Description (if any) */}
      {share.description && (
        <p className="mt-1 text-sm text-dark-grey">{share.description}</p>
      )}
    </div>
  );
};
