import { PlusIcon, VioletPlusIcon } from "@assets/icons";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";
import { useState } from "react";
import ConnectChannelsPopup from "@components/connect-channel/ConnectChannelsPopup";
import { Img } from "../Img";
import ChannelButton from "./ChannelButton"; // Ensure correct import path

import { useTranslation } from "react-i18next";
import PlanRestrictionAlertDialog from "@/layouts/app-wrapper/PlanRestrictionAlertDialog";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import { PlanId } from "@/types/PLANS.config";
import { useNavBar } from "@/layouts/NavBar.context";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type ChannelSectionProps = {
  isCollapsed: boolean;
};

export const ChannelSection = ({ isCollapsed }: ChannelSectionProps) => {
  const { t } = useTranslation();

  const { channels } = useWorkspaceContext(); // Ensure disconnectChannel is available
  const { subscriptionDetails } = useSubscriptionContext();

  // Sort channels by connection status (connected first)
  const sortedChannels = (
    channels
      ? [...channels].sort(
          (a, b) => Number(b.is_connected) - Number(a.is_connected),
        )
      : []
  )?.filter((c) => {
    if (c.type.includes("ads")) {
      return false;
    }
    return true;
  });

  const { addChannelPopupIsOpen, setAddChannelPopupIsOpen } = useNavBar();
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const closePopup = () => setAddChannelPopupIsOpen(false);

  const handleAddChannel = () => {
    if ((subscriptionDetails?.plan_id as PlanId) == 0) {
      setShowAlertDialog(true);
    } else {
      setAddChannelPopupIsOpen(true);
    }
  };

  return (
    <div
      className={`w-full rounded-[12px] bg-white ${
        isCollapsed
          ? ""
          : "justify-between border border-light-grey p-2 shadow-sm"
      }`}
    >
      {sortedChannels.map((channel, index) => (
        <div key={channel.channel_id || index} className="w-full">
          <div
            className={`cursor-pointer rounded-[6px]  hover:bg-light-grey ${isCollapsed ? "mx-2 py-2" : "p-2"}`}
            onClick={() => {
              setAddChannelPopupIsOpen(true);
            }}
          >
            {/* Render Channel Button with Disconnect Handler */}
            <ChannelButton
              channel={channel}
              isCollapsed={isCollapsed}
              openPopup={handleAddChannel}
            />
          </div>

          {/* Separator line for each channel */}
          {!isCollapsed && index < sortedChannels.length - 1 && (
            <hr className={`border-t border-dashed border-light-grey`} />
          )}
        </div>
      ))}

      {/* Add Channel Button */}
      {sortedChannels.length < 4 && (
        <>
          {isCollapsed ? (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <button
                    className="mt-[16px] flex w-full items-center justify-center hover:cursor-pointer hover:brightness-90"
                    onClick={handleAddChannel}
                  >
                    <Img src={VioletPlusIcon} className="size-7" />
                  </button>
                </TooltipTrigger>

                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  {t("navbar.channel_section.add_channel")}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <button
              className={`${
                sortedChannels.length > 0 && "mt-5"
              } flex h-[40px] w-full items-center justify-center rounded-[8px] bg-violet py-2 hover:cursor-pointer hover:brightness-90`}
              onClick={handleAddChannel}
            >
              <Img src={PlusIcon} alt="Add Channel" className="size-5" />
              <span className="ml-2 font-sans text-[14px] font-bold text-white">
                {t("navbar.channel_section.add_channel")}
              </span>
            </button>
          )}
        </>
      )}

      {/* Connect Channels Popup */}
      {addChannelPopupIsOpen && !showAlertDialog && (
        <ConnectChannelsPopup onClose={closePopup} />
      )}

      {/* PlanRestrictionAlertDialog */}
      <PlanRestrictionAlertDialog
        message={t("plan_restriction_dialog.messages.need_plan_pro")}
        trigger={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
      />
    </div>
  );
};
