import { TaskList, ConversationPanel, AIPanel } from "@/components";
import { useCommentsContext } from "@/containers/comments/comments.context";
import { useNavBar } from "@/layouts/NavBar.context";
import { Comment } from "@/types";
import { useWindowWidth } from "@/utils/useWindowWidth";

/**
 * Renders the Comments page with a task list, conversation panel,
 * and an AI suggestions panel.
 */
export const Comments = () => {
  const { isCollapsed } = useNavBar();

  const width = useWindowWidth();
  const isBelow1440 = width < 1440;

  const {
    items: comments,
    openedItemId: openedCommentId,
    activeTab,
    AiPanelIsOpen,
  } = useCommentsContext();

  const selectedComment = comments.find(
    (comment) => comment._id === openedCommentId,
  );

  // Determine widths based on conditions
  const taskListWidth = isCollapsed && !isBelow1440 ? 450 : 350;
  const aiPanelWidth = AiPanelIsOpen ? 300 : 0;

  // Calculate the available width for ConversationPanel
  const availableWidth =
    width - taskListWidth - (AiPanelIsOpen ? aiPanelWidth : 0);

  return (
    <div className="flex h-screen w-full overflow-x-auto scrollbar-hide">
      <div
        className={`h-full ${isCollapsed && !isBelow1440 ? "w-[450px]" : "w-[350px]"}`}
      >
        <TaskList title={"Comments"} type="comment" />
      </div>

      <div className={`w-[${availableWidth}px] min-w-[580px]`}>
        {selectedComment && (
          // Usage in ConversationPanel component
          <ConversationPanel
            type={"comment"}
            post={(selectedComment as Comment).post}
            conversation={selectedComment}
          />
        )}
      </div>

      {activeTab == "to-do" && <AIPanel type="comment" />}
    </div>
  );
};
