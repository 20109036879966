import {
  CommentTitleDashboardIcon,
  MessageTitleDashboardIcon,
  CommentWidgetIcon,
  MessageWidgetIcon,
} from "@/assets/icons";

import { Card } from "./Card";
import { AdsOrganicStats } from "@types"; // Assuming you have this type defined
import React from "react";
import { useTranslation } from "react-i18next";

interface MetricCounterProps {
  title: string;
  type: "comment" | "message";
  metrics: AdsOrganicStats; // Accept AdsOrganicStats directly
}

export function MetricCounter({ title, type, metrics }: MetricCounterProps) {
  const { t } = useTranslation();

  const IconComponent =
    type === "comment" ? CommentWidgetIcon : MessageWidgetIcon;

  // Helper function to handle color based on category
  const getIconColor = (category: string) => {
    switch (category) {
      case "organic":
        return "#1E1E1E"; // Dark
      case "ads":
        return "#EF4444"; // Red
      case "replied":
        return "#BBBFC5"; // Dark-grey
      case "not replied":
        return "#CCC1F0"; // Light-violet
      default:
        return "#1E1E1E"; // Default color
    }
  };

  // Helper function to map AdsOrganicStats to metric structure
  const mapMetrics = (metrics: AdsOrganicStats) => {
    return [
      {
        label:
          type == "comment"
            ? t("dashboard.MetricCounter.organic")
            : t("dashboard.direct_messages"),
        value: metrics?.organic?.total,
        category: "organic",
      },
      {
        label: t("dashboard.MetricCounter.ads"),
        value: metrics?.ads?.total,
        category: "ads",
      },
      {
        label: t("dashboard.MetricCounter.replied"),
        value: metrics?.organic?.replied + metrics?.ads?.replied,
        category: "replied",
      },
      {
        label: t("dashboard.MetricCounter.not_replied"),
        value: metrics?.organic?.unreplied + metrics?.ads?.unreplied,
        category: "not replied",
      },
    ];
  };

  // Reusable render function for metric items
  const renderMetric = (
    metric: { label: string; value: number | null; category: string },
    index: number,
    showSeparator: boolean,
  ) => {
    const showContent = metric.value !== null;

    return (
      <React.Fragment key={metric.category}>
        {/* Use a unique key */}
        <div key={index} className="flex flex-1">
          {showContent ? (
            <>
              <IconComponent
                className="mr-4 mt-1.5 size-6"
                style={{ fill: getIconColor(metric.category) }}
              />
              <div className="flex flex-col">
                <span className="mb-1.5 font-sans text-3xl font-extrabold text-primary">
                  {metric.value?.toLocaleString()}
                </span>

                {/* Prevent label from breaking into two lines */}
                <span className="whitespace-nowrap font-sans text-sm font-semibold text-primary">
                  {metric.label}
                </span>
              </div>
            </>
          ) : (
            <div className="grow"></div>
          )}
        </div>
        {showSeparator && (
          <div className="mx-8 h-auto border-l border-dashed border-light-grey"></div>
        )}
      </React.Fragment>
    );
  };

  const mappedMetrics = mapMetrics(metrics); // Convert AdsOrganicStats to expected metrics format

  return (
    <Card
      title={title}
      icon={
        type === "comment"
          ? CommentTitleDashboardIcon
          : MessageTitleDashboardIcon
      }
    >
      {/* Top Row - Messages/Comments (Organic or Ads) */}
      <div className="flex w-full gap-3">
        {mappedMetrics
          .filter(
            (metric) =>
              metric.category === "organic" || metric.category === "ads",
          )
          .map((metric, index) => renderMetric(metric, index, index === 0))}
      </div>

      {/* Big Horizontal Separator */}
      <div className="my-8 border-t border-dashed border-light-grey"></div>

      {/* Bottom Row - Replied/Unreplied */}
      <div className="flex w-full gap-3">
        {mappedMetrics
          .filter(
            (metric) =>
              metric.category === "replied" ||
              metric.category === "not replied",
          )
          .map((metric, index) => renderMetric(metric, index, index === 0))}
      </div>
    </Card>
  );
}
