import { FC, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { useAddPreSavedReply } from "@/axios/mutations/useAddPreSavedReply";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { Loader } from "@/assets/icons";
import { WorkspaceDetails } from "@/types";
import { useTranslation } from "react-i18next";
import { Info } from "lucide-react";

interface AddPreSavedReplyDialogProps {
  isAddPreSavedDialogOpen: boolean;
  setAddPreSavedDialogOpen: (open: boolean) => void;
}

const AddPreSavedReplyDialog: FC<AddPreSavedReplyDialogProps> = ({
  isAddPreSavedDialogOpen,
  setAddPreSavedDialogOpen,
}) => {
  const { t } = useTranslation();

  const { currentWorkspace } = useWorkspaceContext();
  const [newPreSavedReply, setNewPreSavedReply] = useState("");
  const [isAddingPreSavedReply, setIsAddingPreSavedReply] = useState(false);

  const { addPreSavedReply } = useAddPreSavedReply();
  const { refetchWorkspaces } = useUserContext();

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleAddPreSavedReply = async () => {
    if (newPreSavedReply.trim()) {
      setIsAddingPreSavedReply(true);

      await addPreSavedReply(currentWorkspace?._id as string, newPreSavedReply);
      await refetchWorkspaces();

      (currentWorkspace as WorkspaceDetails).pre_saved_replies = [
        ...(currentWorkspace as WorkspaceDetails).pre_saved_replies,
        newPreSavedReply.trim(),
      ];

      setNewPreSavedReply("");
      setAddPreSavedDialogOpen(false);
    }

    setIsAddingPreSavedReply(false);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 400)}px`;
    }
  }, [newPreSavedReply]);

  useEffect(() => {
    if (!isAddPreSavedDialogOpen) {
      setNewPreSavedReply("");
    }
  }, [isAddPreSavedDialogOpen]);

  return (
    <Dialog
      open={isAddPreSavedDialogOpen}
      onOpenChange={() => setAddPreSavedDialogOpen(false)}
    >
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>
            {t("workspace.pre_saved_replies.add_pre_saved_reply.title")}
          </DialogTitle>

          <DialogDescription>
            <p className="mb-4 mt-2 flex items-center text-sm text-dark-grey ">
              <Info className="mr-1.5 size-4 shrink-0 text-dark-grey" />
              <span className="flex-1 break-words">
                {t(
                  "workspace.pre_saved_replies.add_pre_saved_reply.shared_info_message",
                )}
              </span>
            </p>
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          <Textarea
            ref={textareaRef}
            value={newPreSavedReply}
            onChange={(e) => setNewPreSavedReply(e.target.value)}
            placeholder={t(
              "workspace.pre_saved_replies.add_pre_saved_reply.placeholder",
            )}
            className="mt-4 w-full resize-none overflow-auto rounded border bg-white p-2 font-medium text-primary scrollbar-hide placeholder:text-dark-grey"
            style={{ maxHeight: "400px" }}
          />
        </div>
        <DialogFooter className="mt-4">
          <Button
            variant="secondary"
            onClick={() => setAddPreSavedDialogOpen(false)}
          >
            {t("workspace.pre_saved_replies.add_pre_saved_reply.cancel")}
          </Button>
          <Button
            onClick={handleAddPreSavedReply}
            disabled={!newPreSavedReply.trim() || isAddingPreSavedReply}
            className="flex items-center justify-center space-x-2 disabled:cursor-not-allowed disabled:bg-dark-grey disabled:text-primary"
          >
            {t("workspace.pre_saved_replies.add_pre_saved_reply.add_reply")}
            {isAddingPreSavedReply && (
              <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddPreSavedReplyDialog;
