// settings/SettingsPage.tsx
import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";

import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { Settings } from "@/containers/settings/Settings";
import SettingsSkeleton from "@/containers/settings/SettingsSkeleton";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";

/**
 * Main Settings Page with navigation for each settings section.
 */
const SettingsPage = () => {
  const { isLoadingUser, isLoadingWorkspaces, isLoadingOrganization } =
    useUserContext();

  const { isLoadingChannels } = useWorkspaceContext();

  const isLoading: boolean =
    isLoadingUser ||
    isLoadingOrganization ||
    isLoadingWorkspaces ||
    isLoadingChannels;

  return (
    <AppWrapper isLoading={isLoading} loadingSkeleton={<SettingsSkeleton />}>
      <Settings />
    </AppWrapper>
  );
};

export default SettingsPage;
