// settings/Settings.tsx
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import GeneralSettings from "@/containers/settings/GeneralSettings";
import WorkspaceSettings from "@/containers/settings/WorkspaceSettings";
import ChannelsSettings from "@/containers/settings/channel-settings/ChannelsSettings";
import SmartFeaturesSettings from "./smart-features/SmartFeaturesSettings";
import BillingSettings from "@/containers/settings/billing-settings/BillingSettings";
import OrganizationSettings from "./OrganizationSettings";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useTranslation } from "react-i18next";
import { MagicColoredIcon } from "@/assets/icons";

/**
 * Renders the Settings page with sidebar navigation for different settings sections.
 */
export const Settings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { logout } = useAuth0();
  const { organization } = useUserContext();

  const navLinks = [
    { name: t("settings.general.title"), path: "" },
    { name: t("settings.workspace.title"), path: "workspace" },
    { name: t("settings.channels.title"), path: "channels" },
    { name: t("settings.smart_features.title"), path: "smart-features" },
    { name: t("settings.billing.title"), path: "billing" },
  ];

  if (organization) {
    navLinks.push({
      name: t("settings.organization.title"),
      path: "organization",
    });
  }

  return (
    <div className="flex h-screen">
      {/* Sidebar for navigation */}
      <div className="flex w-fit flex-col justify-between bg-white p-7 pb-10 pt-[100px]">
        <nav>
          {navLinks.map(({ name, path }) => {
            const targetPath =
              path === ""
                ? `/settings${location.search}`
                : `/settings/${path}${location.search}`;
            return (
              <NavLink
                key={path}
                to={targetPath}
                className={({ isActive }) =>
                  `flex px-5 py-3 rounded-[12px] mb-2 font-bold text-primary text-sm items-center justify-start ${
                    path === ""
                      ? location.pathname === "/settings" ||
                        location.pathname === "/settings/"
                        ? "bg-light-grey"
                        : ""
                      : isActive
                        ? "bg-light-grey"
                        : ""
                  }`
                }
              >
                {name}
                {path === "smart-features" && (
                  <MagicColoredIcon className="mb-0.5 ml-1 h-5 shrink-0 text-primary" />
                )}
              </NavLink>
            );
          })}
        </nav>

        {/* Log Out Button */}
        <div className="flex justify-end">
          <Button
            variant="ghost"
            onClick={() => logout()}
            className="bg-violet text-white hover:bg-violet hover:text-white hover:brightness-90"
          >
            {t("logout")}
          </Button>
        </div>
      </div>

      {/* Main content area */}
      <main className="flex-1 p-5">
        <Routes>
          {/* General Settings */}
          <Route index element={<GeneralSettings />} />
          <Route path="workspace" element={<WorkspaceSettings />} />
          {/* Our channels route (note the wildcard so that dynamic channel_id is part of the URL) */}
          <Route path="channels" element={<ChannelsSettings />} />
          <Route path="channels/:channelId" element={<ChannelsSettings />} />
          <Route path="smart-features" element={<SmartFeaturesSettings />} />
          <Route path="billing" element={<BillingSettings />} />
          {organization && (
            <Route path="organization" element={<OrganizationSettings />} />
          )}
        </Routes>
      </main>
    </div>
  );
};

export default Settings;
