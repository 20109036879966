import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";

interface WorkspaceSyncResponse {
  isCommentSyncInProgress: boolean;
  commentSyncStatus: string; // "syncing" | "complete"
  isMessageSyncInProgress: boolean;
  messageSyncStatus: string; // "syncing" | "complete"
}

/**
 * A hook to check whether comments or messages sync is in progress at the workspace level.
 */
export function useWorkspaceSyncStatus() {
  const { currentWorkspace } = useWorkspaceContext();
  const { fetcher } = useFetcher();

  // We'll poll the server for sync status every 10 seconds by setting refreshInterval.
  // If you prefer manual revalidation, remove or adjust this as needed.
  const workspaceId = currentWorkspace?.workspace_id;
  const shouldFetch = !!workspaceId;

  const cacheKey = shouldFetch
    ? `/api/sync-data/check-workspace-sync-status?workspace_id=${workspaceId}`
    : null;

  const {
    data,
    error,
    isValidating, // indicates whether a request is in flight
    mutate,
  } = useSWR<WorkspaceSyncResponse>(cacheKey, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 5000, // poll every 5s – you can adjust this
    refreshWhenHidden: true,
    refreshWhenOffline: true,
  });

  return {
    // If the data isn't loaded yet, default to false
    isCommentSyncInProgress: data?.isCommentSyncInProgress ?? false,
    isMessageSyncInProgress: data?.isMessageSyncInProgress ?? false,

    // Additional helpers
    error,
    isLoading: isValidating && !data && !error,
    refresh: mutate, // manually revalidate if needed
  };
}
