import { useState } from "react";
import { Button } from "@/components/ui/button";
import { TrashIcon } from "lucide-react";
import { toast } from "sonner";

import "@uploadcare/react-uploader/core.css";

import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useTranslation } from "react-i18next";
import RemoveDocumentAlertDialog from "./RemoveDocumentAlertDialog";
import { Loader, PDFIcon } from "@/assets/icons";

export const WorkspaceDocumentsManager = () => {
  const { t } = useTranslation();

  const { documents, fetchDocuments, deleteDocument } = useWorkspaceContext();

  const [docToDelete, setDocToDelete] = useState<string | null>(null); // store the doc._id
  const [docDeleting, setDocDeleting] = useState<string | null>(null); // store the doc._id
  const [docNameToDelete, setDocNameToDelete] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  /**
   * Deletes a document by its Mongo _id.
   */
  const handleClickDelete = (docId: string, docName: string) => {
    setDocToDelete(docId);
    setDocNameToDelete(docName);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!docToDelete) return;
    try {
      setDocDeleting(docToDelete);
      await deleteDocument(docToDelete);
      await fetchDocuments();
    } catch (error) {
      console.error("Error deleting document", error);
      toast.error(t("settings.smart_features.delete_documents_error"));
    }
    setDocDeleting(null);
    setDocToDelete(null);
    setDocNameToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  return (
    <div className="size-full rounded-lg bg-white">
      {/* Documents list */}
      <div className="space-y-4">
        {/* Documents list */}
        {documents?.length === 0 ? (
          <p className="mt-2 text-sm font-medium text-dark-grey">
            {t("settings.smart_features.no_documents_placeholder")}
          </p>
        ) : (
          <div className="space-y-4">
            {documents?.map((doc) => (
              <div
                key={doc._id}
                className="flex items-center justify-between rounded-lg bg-light-grey p-3"
              >
                <div className="flex items-center space-x-3">
                  <PDFIcon className="size-8 shrink-0" />

                  <a
                    href={doc.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-primary hover:text-dark-blue hover:underline"
                  >
                    {doc.filename}
                  </a>
                </div>

                <Button
                  variant="destructive"
                  className="flex size-fit items-center justify-center rounded-lg bg-red p-2 text-white hover:bg-red hover:brightness-75"
                  onClick={() => handleClickDelete(doc._id!, doc.filename)}
                >
                  {docDeleting == doc._id ? (
                    <Loader className="size-4 shrink-0 animate-spin stroke-white" />
                  ) : (
                    <TrashIcon className="size-4 shrink-0 text-white" />
                  )}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>

      <RemoveDocumentAlertDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setDocToDelete(null);
          setDocNameToDelete(null);
        }}
        onConfirm={confirmDelete}
        documentName={docNameToDelete || ""}
      />
    </div>
  );
};
