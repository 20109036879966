import { BarChart, Bar, CartesianGrid, XAxis, Rectangle } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"; // Keep the custom imports
import { Card } from "./Card";
import { CommentWidgetIcon } from "@/assets/icons";

interface BarChartWidgetProps {
  title: string;
  chartData: {
    name: string;
    primaryMetric: number;
    secondaryMetric: number;
  }[];
  primaryColor: string;
  secondaryColor: string;
  totalPrimary: number;
  totalSecondary: number;
  primaryLabel: string; // Label for primary metric (organic or replied)
  secondaryLabel: string; // Label for secondary metric (ads or not replied)
}

// Custom Background Component
const CustomBackground = (props: any) => {
  const {
    x,
    width,
    height,
    fill,
    tooltipPosition,
    primaryMetric,
    secondaryMetric,
  } = props;

  const backgroundHeight = height / 10;
  const backgroundY =
    height - (height - tooltipPosition.y) * 2 - backgroundHeight * 0.75;

  if (!primaryMetric || !secondaryMetric) {
    return;
  }

  return (
    <Rectangle
      x={x}
      y={
        backgroundY + backgroundHeight >= height
          ? height - backgroundHeight / 2 - 1
          : backgroundY
      }
      width={width}
      height={backgroundHeight}
      fill={fill}
    />
  );
};

export function BarChartWidget({
  title,
  chartData,
  primaryColor,
  secondaryColor,
  totalPrimary,
  totalSecondary,
  primaryLabel,
  secondaryLabel,
}: BarChartWidgetProps) {
  const chartConfig = {
    primaryMetric: { label: primaryLabel, color: primaryColor },
    secondaryMetric: { label: secondaryLabel, color: secondaryColor },
  } satisfies ChartConfig;

  return (
    <Card
      title={title}
      icon={CommentWidgetIcon}
      iconStyle={{ fill: "#BBBFC5", marginTop: "5px" }}
    >
      <div className="-mt-3 mb-6 flex items-center justify-end text-sm font-bold">
        <div className="mr-4 flex items-center">
          <div
            className="mr-2 size-2.5 rounded-full"
            style={{ backgroundColor: primaryColor }}
          ></div>
          <span>
            {totalPrimary} {primaryLabel}
          </span>
        </div>

        <div className="flex items-center">
          <div
            className="mr-2 size-2.5 rounded-full"
            style={{ backgroundColor: secondaryColor }}
          ></div>
          <span>
            {totalSecondary} {secondaryLabel}
          </span>
        </div>
      </div>

      <div className="w-full flex-1">
        <ChartContainer
          config={chartConfig}
          className="h-[175px] min-h-[100px] w-full"
        >
          <BarChart data={chartData}>
            <CartesianGrid vertical={false} stroke="#F2F4F7" opacity={0.25} />
            <XAxis
              dataKey="name"
              tickLine={false}
              tickMargin={5}
              axisLine={false}
              tick={{ fill: "#1E1E1E", fontSize: 12, fontWeight: 500 }}
            />

            <ChartTooltip
              cursor={{ fill: "#F2F4F7", stroke: "#F2F4F7", opacity: 0.25 }}
              content={
                <ChartTooltipContent className="w-[140px] rounded-md bg-white p-2 shadow-md" />
              }
            />

            {/* Primary Metric (organic or replied) */}
            <Bar
              dataKey="primaryMetric"
              stackId="a"
              fill={primaryColor}
              background={<CustomBackground fill={secondaryColor} />}
              radius={[10, 10, 10, 10]}
              isAnimationActive={false}
            />
            {/* Secondary Metric (ads or not replied) */}
            <Bar
              dataKey="secondaryMetric"
              stackId="a"
              fill={secondaryColor}
              radius={[10, 10, 0, 0]}
              isAnimationActive={false}
            />
          </BarChart>
        </ChartContainer>
      </div>
    </Card>
  );
}
