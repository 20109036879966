// SubscriptionProvider.tsx

import React, { createContext, useContext, useMemo } from "react";
import { useSubscriptionDetails, usePaymentMethods } from "@/axios/queries";
import { PaymentMethodDetails, SubscriptionDetails } from "@types";
import { useWorkspaceContext } from "../workspace-provider/useWorkspaceContext";

type SubscriptionContextType = {
  subscriptionDetails: SubscriptionDetails | null;
  isLoadingSubscriptionDetails: boolean;
  refetchSubscriptionDetails: () => void;
  subscriptionProblem: boolean;
  paymentMethods: PaymentMethodDetails[] | null;
  isLoadingPaymentMethods: boolean;
  refetchPaymentMethods: () => void;
};

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined,
);

export const SubscriptionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentWorkspace } = useWorkspaceContext();

  const {
    subscriptionDetails,
    isLoading: isLoadingSubscriptionDetails,
    refetch: refetchSubscriptionDetails,
  } = useSubscriptionDetails(currentWorkspace?.workspace_id);

  const {
    paymentMethods,
    isLoading: isLoadingPaymentMethods,
    refetch: refetchPaymentMethods,
  } = usePaymentMethods();

  // Determine if there's a subscription problem
  const subscriptionProblem = useMemo(() => {
    if (isLoadingSubscriptionDetails) {
      return false;
    }

    if (!subscriptionDetails) {
      // No subscription at all
      return true;
    }

    const { status, trial_end } = subscriptionDetails;

    switch (status) {
      case "active":
        // Subscription is active
        return false;

      case "trialing":
        // Check if trial has ended
        const currentTime = Math.floor(Date.now() / 1000);
        if (trial_end && trial_end < currentTime) {
          // Trial has ended
          return true;
        } else {
          // Trial is ongoing
          return false;
        }

      case "past_due":
      case "unpaid":
      case "canceled":
      case "incomplete":
      case "incomplete_expired":
        // These statuses indicate a subscription problem
        return true;

      default:
        // Handle other statuses or treat them as a problem
        return true;
    }
  }, [subscriptionDetails, isLoadingSubscriptionDetails]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionDetails: subscriptionDetails || null,
        isLoadingSubscriptionDetails,
        refetchSubscriptionDetails,
        subscriptionProblem,
        paymentMethods: paymentMethods || null,
        isLoadingPaymentMethods,
        refetchPaymentMethods,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscriptionContext must be used within a SubscriptionProvider",
    );
  }
  return context;
};
