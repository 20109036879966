// components/icons/PaymentIcon.tsx
import React from "react";
import SEPAIcon from "./SEPAIcon";
import VisaIcon from "./VisaIcon";
import MastercardIcon from "./MastercardIcon";
import AmexIcon from "./AmexIcon";
import UnionPayIcon from "./UnionPayIcon";
import OtherCreditCardIcon from "./OtherCreditCardIcon";
import ApplePayIcon from "./ApplePayIcon";
import PayPalIcon from "./PaypalIcon";
import Stripe from "stripe";
import LinkIcon from "./LinkIcon";

interface PaymentIconProps {
  brand: string;
  type: Stripe.PaymentMethod.Type;
}

const PaymentIcon: React.FC<PaymentIconProps> = ({ brand, type }) => {
  if (type == "card") {
    switch (brand.toLowerCase()) {
      case "sepa":
        return <SEPAIcon />;
      case "visa":
        return <VisaIcon />;
      case "mastercard":
        return <MastercardIcon />;
      case "amex":
        return <AmexIcon />;
      case "unionpay":
        return <UnionPayIcon />;
      case "other_credit_card":
        return <OtherCreditCardIcon />;
      case "apple_pay":
        return <ApplePayIcon />;
      case "paypal":
        return <PayPalIcon />;
      default:
        return;
    }
  } else if (type == "link") {
    return <LinkIcon />;
  } else {
    return;
  }
};

export default PaymentIcon;
