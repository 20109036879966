/* eslint-disable react/prop-types */
import { Pie, PieChart, Sector, Cell } from "recharts";
import { Card } from "./Card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { EmotionIcon } from "@/assets/icons";
import { EmotionalAnalysisResult } from "@types";
import { useTranslation } from "react-i18next";

interface EmotionalAnalysisProps {
  data: EmotionalAnalysisResult;
}

const chartConfig = {} satisfies ChartConfig;

export function EmotionalAnalysis({ data }: EmotionalAnalysisProps) {
  const { t } = useTranslation();

  // Extract sentiments and categories from the data or default to empty objects

  if (!data) {
    return;
  }

  const { sentiments = {}, categories = {} } = data;

  // Default values if data is missing
  const totalSentiments = {
    Positive:
      (sentiments.Positive?.count || 0) + (categories.Business?.count || 0),
    Negative:
      (sentiments.Negative?.count || 0) + (categories.Business?.count || 0),
    Neutral:
      (sentiments.Neutral?.count || 0) + (categories.Business?.count || 0),
  };

  // Handle when there's no data at all
  const hasData =
    Object.values(totalSentiments).some((value) => value > 0) ||
    categories.Business?.count > 0;

  // Build the data array for the PieChart
  const chartData = [
    {
      name: t("dashboard.EmotionalAnalysis.positive"),
      value: totalSentiments.Positive,
      color: "#375DFB",
    },
    {
      name: t("dashboard.EmotionalAnalysis.negative"),
      value: totalSentiments.Negative,
      color: "#EF4444",
    },
    {
      name: t("dashboard.EmotionalAnalysis.business"),
      value: categories.Business?.count || 0,
      color: "#1E1E1E",
    },
    {
      name: t("dashboard.EmotionalAnalysis.neutral"),
      value: totalSentiments.Neutral,
      color: "#BBBFC5",
    },
  ];

  // Calculate the total for percentage calculation
  const total = chartData.reduce((sum, item) => sum + item.value, 0);

  if (!hasData) {
    return (
      <Card title={"Emotional Analysis"} icon={EmotionIcon}>
        <div className="flex h-40 items-center justify-center text-base text-primary">
          <p>{t("dashboard.EmotionalAnalysis.no_data_available")}</p>
        </div>
      </Card>
    );
  }

  return (
    <Card title={t("dashboard.EmotionalAnalysis.title")} icon={EmotionIcon}>
      <div className="flex items-center justify-between">
        {/* Responsive Chart Container */}
        <ChartContainer
          config={chartConfig}
          className="aspect-square w-full max-w-[200px] flex-1"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent className="rounded-md bg-white p-2 shadow-md" />
              }
            />
            <Pie
              isAnimationActive={false}
              data={chartData}
              outerRadius="80%"
              innerRadius="45%"
              paddingAngle={5} // Space between segments
              dataKey="value"
              cornerRadius={"25%"} // Round the segments
              activeIndex={2} // Business is always active index
              activeShape={({ ...props }) => {
                return (
                  <g>
                    {/* Highlighted Sector */}
                    <Sector {...props} outerRadius={props.outerRadius * 1.2} />
                    {/* Smaller inner segment to simulate the cut-in effect */}
                    <Sector
                      {...props}
                      outerRadius={props.outerRadius * 0.9}
                      innerRadius={props.innerRadius * 0.5}
                    />
                  </g>
                );
              }}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ChartContainer>

        {/* Legend Section */}
        <div className="ml-6 mt-4 w-full flex-1">
          {chartData.map((item, index) => (
            <div key={index}>
              <div className="flex items-center justify-between">
                {/* Value (percentage) on the left */}
                <div className="ml-2 flex items-center">
                  <div
                    className="mr-5 size-2.5 rounded-full"
                    style={{ backgroundColor: item.color }}
                  />
                  <span className=" text-base font-bold">
                    {Math.round((item.value / total) * 100)}%
                  </span>
                </div>

                {/* Label on the right */}
                <span className="ml-4 mr-2 text-base">{item.name}</span>
              </div>

              {/* Full-width dashed separator */}
              {index < chartData.length - 1 && (
                <div className="my-2.5 w-full border-b border-dashed border-light-grey"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}
