// hooks/useGenerateReport.ts
import { useFetcher } from "@axios/fetcher";

// types/report.ts
export type PredefinedTimeRangeType =
  | "last_week"
  | "last_month"
  | "last_3_months";

export interface PredefinedTimeRange {
  predefined: {
    type: PredefinedTimeRangeType;
  };
}

export interface CustomTimeRange {
  custom: {
    startDate: string; // ISO8601 date string
    endDate: string; // ISO8601 date string
  };
}

export type TimeRange = PredefinedTimeRange | CustomTimeRange;

function useGenerateReport() {
  const { fetcher } = useFetcher();

  /**
   * Generates a report based on the provided workspace ID and time range.
   * @param workspaceId - The ID of the workspace.
   * @param timeRange - The time range for the report.
   * @returns The generated report as a markdown string.
   */
  const generateReport = async (
    workspaceId: string,
    timeRange: TimeRange,
  ): Promise<string> => {
    const body = { workspaceId, timeRange };
    const response = await fetcher("/api/reporting/generate", "post", body);
    return response.report;
  };

  return {
    generateReport,
  };
}

export { useGenerateReport };
