// components/icons/OtherCreditCardIcon.tsx
import React from "react";

const OtherCreditCardIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    focusable="false"
    className="size-6"
  >
    <path fill="#016797" d="M0 0h32v32H0z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.434 10.495v5.115h8.164a2.557 2.557 0 0 0 0-5.115h-8.164Zm-.697 5.115c-.046-1.006-.297-1.909-.72-2.675a5.076 5.076 0 0 0-2.007-2.007c-.852-.47-1.874-.728-3.019-.728H9.91c-1.145 0-2.166.258-3.018.728a5.076 5.076 0 0 0-2.007 2.007c-.471.853-.729 1.874-.729 3.019s.258 2.166.729 3.019A5.076 5.076 0 0 0 6.89 20.98c.852.47 1.873.728 3.018.728h1.082c1.145 0 2.167-.257 3.019-.728a5.076 5.076 0 0 0 2.007-2.007c.423-.766.674-1.669.72-2.675h-6.09v-.688h6.09Zm.697.688v5.115h8.164a2.557 2.557 0 0 0 0-5.115h-8.164Z"
      fill="#fff"
    ></path>
  </svg>
);

export default OtherCreditCardIcon;
