// OnboardingSkeleton.jsx
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const baseColor = "#F2F4F7";

const OnboardingSkeleton = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center overflow-hidden bg-light-grey">
      {/* Left Section */}
      <div className="flex h-full w-1/2 grow flex-col p-8">
        <div className="size-full rounded-xl bg-white">
          <Skeleton
            baseColor={"#FFFFFF"}
            highlightColor={baseColor}
            width="100%"
            height="100%"
          />
        </div>
      </div>

      {/* Right Section */}
      <div className="flex h-[85%] w-1/2 items-center justify-end rounded-l-xl bg-white lg:flex">
        <div className="size-full">
          <Skeleton
            baseColor={"#FFFFFF"}
            highlightColor={baseColor}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export { OnboardingSkeleton };
