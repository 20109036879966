import { useTranslation } from "react-i18next";
import { PeriodStats } from "@types";
import { MetricCounter } from "@/components/dashboard/MetricCounter";
import { BarChartWidget } from "@/components/dashboard/BarChart";
import { HoursSaved } from "@/components/dashboard/HoursSaved";
import { ResponseRatio } from "@/components/dashboard/ResponseRatio";
import { ActionMetrics } from "@/components/dashboard/ActionMetrics";
import { EmotionalAnalysis } from "@/components/dashboard/EmotionalAnalysis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDashboard } from "@/layouts/Dashboard.context";
import { FilterPopoverDashboard } from "@/components/dashboard/FilterPopoverDashboard";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import {
  PredefinedTimeRange,
  PredefinedTimeRangeType,
  useGenerateReport,
} from "@/axios/mutations/useGenerateReport";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import ReactMarkdown from "react-markdown";
import { OnboardingChecklistPopover } from "@/components/dashboard/OnboardingChecklistPopover";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";

const Dashboard = () => {
  const { t } = useTranslation();

  const { user } = useUserContext();
  const { currentWorkspace } = useWorkspaceContext();

  const {
    messageStats,
    commentStats,
    timeSavedStats,
    actionMetrics,
    emotionalAnalysis,
  } = useDashboard();

  const [isWideContainer, setIsWideContainer] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { generateReport } = useGenerateReport();

  // State for report generation
  // State for report generation
  const [selectedTimeRange, setSelectedTimeRange] =
    useState<PredefinedTimeRangeType>("last_week");
  const [isReportOpen, setIsReportOpen] = useState<boolean>(false);
  const [reportContent, setReportContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { filterOptions } = useDashboard();

  const [dateRange] = useState<DateRange | undefined>({
    from: filterOptions.startDate
      ? new Date(filterOptions.startDate)
      : undefined,
    to: filterOptions.endDate ? new Date(filterOptions.endDate) : undefined,
  });

  const filterStatsByDateRange = (
    statsByPeriod: { [key: string]: PeriodStats } | undefined,
    dateRange?: { from?: Date; to?: Date },
  ) => {
    if (!statsByPeriod) return [];

    let fromDate: Date;
    let toDate: Date;

    if (dateRange?.from && dateRange?.to) {
      fromDate = dateRange.from;
      toDate = dateRange.to;
    } else {
      // Default to last 7 days
      toDate = new Date(); // "today"
      fromDate = new Date();
      fromDate.setDate(toDate.getDate() - 6); // 7-day window
    }

    const results: {
      name: string; // e.g. "30/03"
      total: number;
      replied: number;
      unreplied: number;
    }[] = [];

    // Iterate from fromDate to toDate (inclusive)
    const current = new Date(fromDate);
    while (current <= toDate) {
      const key = current.toISOString().split("T")[0]; // YYYY-MM-DD
      // Use date-fns to format the label, e.g. "30/03"
      const label = format(current, "dd/MM");

      console.debug("fornatedLabel:", { key, label });
      const stat = statsByPeriod[key] || { total: 0, replied: 0, unreplied: 0 };

      results.push({
        name: label,
        total: stat.total,
        replied: stat.replied,
        unreplied: stat.unreplied,
      });

      current.setDate(current.getDate() + 1);
    }

    return results;
  };

  useEffect(() => {
    const checkContainerWidth = () => {
      if (containerRef.current) {
        // Check if the container's width is greater than or equal to 1200px
        setIsWideContainer(containerRef.current.offsetWidth >= 1300);
      }
    };

    // Check container width on mount
    checkContainerWidth();

    // Add resize listener to window to adjust when resized
    window.addEventListener("resize", checkContainerWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkContainerWidth);
  }, []);

  // Graph 1: Organic vs Ads Total Comments
  const transformCommentsTotalData = useCallback(() => {
    const filteredOrganic = filterStatsByDateRange(
      commentStats?.organic?.statsByPeriod,
      dateRange,
    );
    const filteredAds = filterStatsByDateRange(
      commentStats?.ads?.statsByPeriod,
      dateRange,
    );

    return filteredOrganic.map((stat, index) => ({
      name: stat.name,
      primaryMetric: filteredAds[index]?.total || 0,
      secondaryMetric: stat?.total,
    }));
  }, [commentStats]);

  const mergeStatsByPeriod = useCallback(
    (
      organicStats?: { [key: string]: PeriodStats },
      adsStats?: { [key: string]: PeriodStats },
    ): { [key: string]: PeriodStats } => {
      const combined: { [key: string]: PeriodStats } = {};

      const addStats = (stats?: { [key: string]: PeriodStats }) => {
        if (!stats) return;
        for (const period in stats) {
          if (!combined[period]) {
            combined[period] = { total: 0, replied: 0, unreplied: 0 };
          }
          combined[period].total += stats[period]?.total ?? 0;
          combined[period].replied += stats[period]?.replied ?? 0;
          combined[period].unreplied += stats[period]?.unreplied ?? 0;
        }
      };

      addStats(organicStats);
      addStats(adsStats);

      return combined;
    },
    [],
  );

  // Graph 2: Ads Replied vs Unreplied Comments
  const transformCommentsRepliedData = useCallback(() => {
    // Fusionner les stats organic et ads
    const combinedStats = mergeStatsByPeriod(
      commentStats?.organic?.statsByPeriod,
      commentStats?.ads?.statsByPeriod,
    );

    // Filtrer les 7 derniers jours
    const filteredCombined = filterStatsByDateRange(combinedStats, dateRange);

    return filteredCombined.map((stat) => ({
      name: stat.name,
      primaryMetric: stat?.replied,
      secondaryMetric: stat?.unreplied,
    }));
  }, [commentStats]);

  // Graph 3: Messages Replied vs Unreplied
  const transformMessagesRepliedData = useCallback(() => {
    const filteredOrganic = filterStatsByDateRange(
      messageStats?.organic?.statsByPeriod,
      dateRange,
    );

    return filteredOrganic.map((stat) => ({
      name: stat.name,
      primaryMetric: stat?.replied,
      secondaryMetric: stat?.unreplied,
    }));
  }, [messageStats]);

  // Prepare data for Graph 1
  const commentsTotalData = useMemo(transformCommentsTotalData, [
    transformCommentsTotalData,
  ]);

  // Prepare data for Graph 2
  const commentsRepliedData = useMemo(transformCommentsRepliedData, [
    transformCommentsRepliedData,
  ]);

  // Prepare data for Graph 3
  const messagesRepliedData = useMemo(transformMessagesRepliedData, [
    transformMessagesRepliedData,
  ]);

  // Helper function to sum Ads and Organic stats
  const sumAdsAndOrganic = useCallback(
    (adsStats: PeriodStats, organicStats: PeriodStats) => ({
      total: (adsStats?.total ?? 0) + (organicStats?.total ?? 0),
      replied: (adsStats?.replied ?? 0) + (organicStats?.replied ?? 0),
      unreplied: (adsStats?.unreplied ?? 0) + (organicStats?.unreplied ?? 0),
    }),
    [],
  );

  // Calculate summed stats for ResponseRatio
  const summedCommentStats = useMemo(() => {
    return sumAdsAndOrganic(commentStats?.ads, commentStats?.organic);
  }, [commentStats, sumAdsAndOrganic]);

  /**
   * Handles the generation of the report.
   */
  const handleGenerateReport = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Define the timeRange based on selectedTimeRange
      const timeRange: PredefinedTimeRange = {
        predefined: { type: selectedTimeRange },
      };

      const workspaceId = currentWorkspace?._id as string;
      const report = await generateReport(workspaceId, timeRange);
      setReportContent(report);
      setIsReportOpen(true);
    } catch (err: any) {
      setError(err.message || "Failed to generate report.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-light-grey py-8 pl-[10px] pr-8">
      {/* Popovers for filtering and sorting */}
      <div className="mb-4 flex items-center justify-end space-x-8">
        {user?.is_admin && import.meta.env.VITE_NODE_ENV == "development" && (
          <div className={"flex flex-row space-x-4"}>
            {/* Select Component for Time Range */}
            <Select
              onValueChange={(value: PredefinedTimeRangeType) =>
                setSelectedTimeRange(value)
              }
              value={selectedTimeRange}
            >
              <SelectTrigger className="h-[42px] w-[200px] rounded-[10px]">
                <SelectValue placeholder={t("dashboard.select_time_range")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="last_week">
                  {t("dashboard.last_week")}
                </SelectItem>
                <SelectItem value="last_month">
                  {t("dashboard.last_month")}
                </SelectItem>
                <SelectItem value="last_3_months">
                  {t("dashboard.last_3_months")}
                </SelectItem>
              </SelectContent>
            </Select>

            {/* Generate Report Button */}

            <button
              onClick={handleGenerateReport}
              className="rounded-[10px] bg-violet px-4 py-2 text-white hover:brightness-90 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading
                ? t("dashboard.generating_report")
                : t("dashboard.generate_report")}
            </button>
          </div>
        )}

        <div className="flex items-center justify-end space-x-2.5">
          {/* Our new Onboarding checklist */}
          <OnboardingChecklistPopover />

          <FilterPopoverDashboard />
        </div>
      </div>

      {/* Report Modal */}
      <Dialog open={isReportOpen} onOpenChange={setIsReportOpen}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>{t("dashboard.report")}</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <div className="prose max-h-[60vh] overflow-y-auto">
            {reportContent ? (
              <ReactMarkdown>{reportContent}</ReactMarkdown>
            ) : (
              <p>{t("dashboard.no_report_available")}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Error Message */}
      {error && (
        <div className="mb-4 rounded-[10px] bg-red p-4 text-white">{error}</div>
      )}

      <div
        ref={containerRef} // Assign ref to the container
        className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3"
        style={{
          // Conditionally apply the gridTemplateColumns style only if the container is less than 1200px
          gridTemplateColumns: !isWideContainer
            ? "repeat(auto-fit, minmax(400px, 1fr))"
            : undefined, // No inline style when the container width is >= 1200px
        }}
      >
        <MetricCounter
          title={t("dashboard.direct_messages")}
          type="message"
          metrics={messageStats} // Pass AdsOrganicStats
        />

        <MetricCounter
          title={t("dashboard.comments")}
          type="comment"
          metrics={commentStats} // Pass AdsOrganicStats
        />

        <EmotionalAnalysis data={emotionalAnalysis} />

        {/* Graph 1: Organic vs Ads Total Comments */}
        <BarChartWidget
          title={t("dashboard.comments_organic_vs_ads")}
          chartData={commentsTotalData}
          primaryColor="#CCC1F0"
          secondaryColor="#F2F4F7"
          totalPrimary={commentStats?.ads?.total || 0}
          totalSecondary={commentStats?.organic?.total || 0}
          primaryLabel={t("dashboard.ads")}
          secondaryLabel={t("dashboard.organic")}
        />

        {/* Graph 2: Ads Replied vs Unreplied Comments */}
        <BarChartWidget
          title={t("dashboard.comments_replied_vs_unreplied")}
          chartData={commentsRepliedData}
          primaryColor="#EF4444"
          secondaryColor="#F2F4F7"
          totalPrimary={
            commentStats?.organic?.replied + commentStats?.ads?.replied || 0
          }
          totalSecondary={
            commentStats?.organic?.unreplied + commentStats?.ads?.unreplied || 0
          }
          primaryLabel={t("dashboard.replied")}
          secondaryLabel={t("dashboard.not_replied")}
        />

        {/* Graph 3: Messages Replied vs Unreplied */}
        <BarChartWidget
          title={t("dashboard.messages_replied_vs_unreplied")}
          chartData={messagesRepliedData}
          primaryColor="#1E1E1E"
          secondaryColor="#F2F4F7"
          totalPrimary={messageStats?.organic?.replied || 0}
          totalSecondary={messageStats?.organic?.unreplied || 0}
          primaryLabel={t("dashboard.replied")}
          secondaryLabel={t("dashboard.not_replied")}
        />

        <HoursSaved
          hoursSaved={timeSavedStats?.timeSaved || 0}
          hoursWithPlatform={timeSavedStats?.timeSpentWithPlatform || 0}
          hoursWithoutPlatform={timeSavedStats?.timeSpentWithoutPlatform || 0}
        />

        <ResponseRatio
          responseRatio={Math.round(
            (summedCommentStats?.replied / (summedCommentStats?.total || 1)) *
              100,
          )}
          totalComments={summedCommentStats?.total}
          repliedComments={summedCommentStats?.replied}
          unrepliedComments={summedCommentStats?.unreplied}
        />

        <ActionMetrics
          likedComments={actionMetrics?.liked || 0}
          hiddenComments={actionMetrics?.hidden || 0}
          skippedComments={actionMetrics?.skipped || 0}
          repliedComments={actionMetrics?.replied || 0}
        />
      </div>
    </div>
  );
};

export { Dashboard };
