// useAddPreSavedReply.ts
import { Workspace } from "@/types";
import { useFetcher } from "@axios/fetcher";

function useAddPreSavedReply() {
  const { fetcher } = useFetcher();

  /**
   * Function to add a new pre-saved reply to a workspace
   * @param workspace_id - The ID of the workspace
   * @param reply - The text of the pre-saved reply
   * @returns The updated workspace with the new pre-saved reply
   */
  const addPreSavedReply = async (
    workspace_id: string,
    reply: string,
  ): Promise<string[]> => {
    if (!workspace_id || !reply.trim()) {
      throw new Error(
        "workspace_id and reply are required to add a pre-saved reply",
      );
    }

    // Make the API request to add the pre-saved reply
    const updatedWorkspace: Workspace = await fetcher(
      `/api/workspaces/${workspace_id}/pre-saved-replies`,
      "post",
      { reply },
    );

    return updatedWorkspace.pre_saved_replies;
  };

  return {
    addPreSavedReply,
  };
}

export { useAddPreSavedReply };
