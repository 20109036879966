import { Tabs, TabsContent } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Loader,
  SelectButton,
  SelectedButton,
  SelectedOpenedButton,
} from "@assets/icons";
import { useState } from "react";
import { TaskListTabContent } from "./TaskListTabContent";
import { FilterPopover } from "./FilterPopover";
import { useTaskContext } from "@/layouts/useTask.context";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";
import { useWindowWidth } from "@/utils/useWindowWidth";
import { useWorkspaceSyncStatus } from "@/axios/queries/useWorkspaceSyncStatus";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

/**
 * Renders a task list with filtering, sorting, and tab-based status control.
 */
type TaskListProps = {
  title: string;
  type: "comment" | "message";
};

export const TaskList = ({ title, type }: TaskListProps) => {
  const { t } = useTranslation();

  const { isCollapsed } = useNavBar();

  const width = useWindowWidth();
  const isBelow1440 = width < 1440;

  const [isSelectAllOpen, setIsSelectAllOpen] = useState(false);

  const [isTabsOpened, setIsTabsOpened] = useState(false);

  const {
    selectAllItems,
    deselectAllItems,
    closeItem,
    activeTab,
    setActiveTab,
    selectedItemIds,
    items,
    applyFilters,
  } = useTaskContext();

  const { isCommentSyncInProgress, isMessageSyncInProgress } =
    useWorkspaceSyncStatus();

  /**
   * Selects all items in the active tab.
   */
  const handleSelectAll = () => {
    const ids: string[] = items
      .filter((item) => item.status === activeTab)
      .map((item) => item._id);

    selectAllItems(ids);
  };

  /**
   * Handles switching between task status tabs.
   * @param tabValue - The active tab value (status).
   */
  const handleTabChange = (tabValue: "to-do" | "done" | "skipped") => {
    applyFilters({ status: tabValue });
    closeItem();
    setActiveTab(tabValue);
    deselectAllItems(); // Reset selected items on tab change
  };

  const syncInProgress =
    (type == "comment" && isCommentSyncInProgress) ||
    (type == "message" && isMessageSyncInProgress);

  return (
    <div className="flex h-screen w-full flex-col bg-white px-6 pt-5">
      <div className="flex items-center justify-between">
        {isCollapsed && !isBelow1440 && (
          <h1
            className={`mr-5 shrink-0 whitespace-nowrap text-base font-extrabold`}
          >
            {t(`tasks.task_list.${title}`)}
          </h1>
        )}

        {/* Tabs and Options */}
        <div className="flex shrink-0 items-center space-x-2">
          {/* <Tabs defaultValue="to-do" onValueChange={handleTabChange}>
            <TabsList className="h-fit shrink-0 space-x-0 rounded-[12px] border border-light-grey p-[2px]">
              <TabsTrigger
                value="to-do"
                className="h-[40px] w-[80px] cursor-pointer rounded-[10px] text-sm font-semibold transition-none data-[state=active]:bg-primary data-[state=inactive]:font-semibold data-[state=active]:text-white data-[state=inactive]:text-primary"
              >
                To do
              </TabsTrigger>
              <TabsTrigger
                value="done"
                className="h-[40px] w-[80px] cursor-pointer rounded-[10px] text-sm font-semibold transition-none data-[state=active]:bg-primary data-[state=inactive]:font-semibold data-[state=active]:text-white data-[state=inactive]:text-primary"
              >
                Done
              </TabsTrigger>
              <TabsTrigger
                value="skipped"
                className="h-[40px] w-[80px] cursor-pointer rounded-[10px] text-sm font-semibold transition-none data-[state=active]:bg-primary data-[state=inactive]:font-semibold data-[state=active]:text-white data-[state=inactive]:text-primary"
              >
                Skipped
              </TabsTrigger>
            </TabsList>
          </Tabs> */}

          <Select
            value={activeTab}
            onValueChange={handleTabChange}
            open={isTabsOpened}
            onOpenChange={() => setIsTabsOpened((prev) => !prev)}
          >
            <SelectTrigger className="mr-2 h-[40px] w-[120px] rounded-[10px] border border-light-grey bg-white text-sm font-semibold text-primary">
              <SelectValue placeholder={t("tasks.task_list.to_do")} />
            </SelectTrigger>

            <SelectContent className="z-50 bg-white shadow-lg">
              <SelectItem
                value="to-do"
                className="cursor-pointer rounded-md hover:bg-light-blue hover:text-primary"
              >
                <div className="flex items-center justify-center">
                  {t("tasks.task_list.to_do")}
                  {/* If this TaskList is for comments, show loader if comments are syncing */}
                  {syncInProgress &&
                    (!isTabsOpened || activeTab == "to-do") && (
                      <Loader
                        className={`ml-2 size-3 shrink-0 animate-spin stroke-primary stroke-2`}
                      />
                    )}
                </div>
              </SelectItem>
              <SelectItem
                value="done"
                className="cursor-pointer rounded-md hover:bg-light-blue hover:text-primary"
              >
                <div className="flex items-center justify-center">
                  {t("tasks.task_list.done")}
                  {/* If this TaskList is for comments, show loader if comments are syncing */}
                  {syncInProgress && (!isTabsOpened || activeTab == "done") && (
                    <Loader
                      className={`ml-2 size-3 shrink-0 animate-spin stroke-primary stroke-2`}
                    />
                  )}
                </div>
              </SelectItem>
              <SelectItem
                value="skipped"
                className="cursor-pointer rounded-md hover:bg-light-blue hover:text-primary"
              >
                <div className="flex items-center justify-center">
                  {t("tasks.task_list.skipped")}
                  {/* If this TaskList is for comments, show loader if comments are syncing */}
                  {syncInProgress &&
                    (!isTabsOpened || activeTab == "skipped") && (
                      <Loader
                        className={`ml-2 size-3 shrink-0 animate-spin stroke-primary stroke-2`}
                      />
                    )}
                </div>
              </SelectItem>
            </SelectContent>
          </Select>

          {/* Popovers for filtering and sorting */}
          <FilterPopover type={type} />

          {/* <Popover open={isSortOpen} onOpenChange={setIsSortOpen}>
            <PopoverTrigger asChild>
              <Button variant="ghost" className="flex size-fit shrink-0 p-0">
                {isSortOpen ? <SortOpenedButton /> : <SortButton />}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-4">
              <p>Sort options go here</p>
            </PopoverContent>
          </Popover> */}

          <Popover open={isSelectAllOpen} onOpenChange={setIsSelectAllOpen}>
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <PopoverTrigger asChild>
                    <button
                      className={`flex size-fit shrink-0 p-0 ${!isSelectAllOpen && "hover:brightness-90"}`}
                    >
                      {isSelectAllOpen ? (
                        <SelectedOpenedButton />
                      ) : selectedItemIds.length > 0 ? (
                        <SelectedButton />
                      ) : (
                        <SelectButton />
                      )}
                    </button>
                  </PopoverTrigger>
                </TooltipTrigger>

                {!isSelectAllOpen && (
                  <TooltipContent
                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                    sideOffset={5}
                  >
                    {t("tasks.task_list.select_deselect_all_tooltip")}
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>

            <PopoverContent className="flex w-fit flex-col space-y-2 rounded-[10px] bg-white p-2 shadow-sm">
              <Button
                onClick={handleSelectAll}
                className="rounded-[10px] bg-light-grey px-4 py-2 text-sm font-semibold text-primary hover:bg-light-grey hover:brightness-90 "
              >
                {t("tasks.task_list.select_all")}
              </Button>
              <Button
                className="rounded-[10px] bg-light-grey px-4 py-2 text-sm font-semibold text-primary hover:bg-light-grey hover:brightness-90 "
                onClick={deselectAllItems}
              >
                {t("tasks.task_list.deselect_all")}
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div className="flex flex-col overflow-hidden">
        <Tabs value={activeTab} className="size-full flex-1 py-6">
          <TabsContent value="to-do" className="h-full overflow-hidden">
            <TaskListTabContent status="to-do" type={type} />
          </TabsContent>

          <TabsContent value="done" className="h-full overflow-hidden">
            <TaskListTabContent status="done" type={type} />
          </TabsContent>

          <TabsContent value="skipped" className="h-full overflow-hidden">
            <TaskListTabContent status="skipped" type={type} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
