import { useState, FC } from "react";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

import {
  useCreateSetupIntent,
  useRemovePaymentMethod,
  useSetDefaultPaymentMethod,
} from "@/axios/mutations";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";

import { Loader } from "@/assets/icons";
import PaymentIcon from "./payment-method-icons/PaymentMethodIcons";

// Import AlertDialog components from shadcn
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import AddPaymentMethodDialog from "./AddPaymentMethodDialog";
import { useTranslation } from "react-i18next";
import Stripe from "stripe";

const PaymentMethods: FC = () => {
  const { t } = useTranslation();

  const [isAdding, setIsAdding] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // State for AlertDialog
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [paymentMethodToRemove, setPaymentMethodToRemove] = useState<
    string | null
  >(null);

  // Custom hooks for managing payment methods
  const { setDefaultPaymentMethod } = useSetDefaultPaymentMethod();
  const [isLoadingSetDefault, setIsLoadingSetDefault] = useState<string | null>(
    null,
  );

  const { removePaymentMethod } = useRemovePaymentMethod();
  const [isRemoving, setIsRemoving] = useState<string | null>(null);

  const { paymentMethods, refetchPaymentMethods } = useSubscriptionContext();

  // Hook to create a Setup Intent
  const { createSetupIntent } = useCreateSetupIntent();

  // Handler to set a payment method as default
  const handleSetDefault = async (paymentMethodId: string) => {
    try {
      setIsLoadingSetDefault(paymentMethodId);
      await setDefaultPaymentMethod(paymentMethodId);
      await refetchPaymentMethods();
      setIsLoadingSetDefault(null);
    } catch (error) {
      console.error("Error setting default payment method:", error);
    }
  };

  // Handler to initiate removal of a payment method
  const handleRemove = (paymentMethodId: string) => {
    setPaymentMethodToRemove(paymentMethodId);
    setIsAlertOpen(true);
  };

  // Handler to confirm removal of the payment method
  const confirmRemove = async () => {
    if (!paymentMethodToRemove) return;

    try {
      setIsRemoving(paymentMethodToRemove);

      await removePaymentMethod(paymentMethodToRemove);
      await refetchPaymentMethods();

      setPaymentMethodToRemove(null);
      setIsRemoving(null);
      setIsAlertOpen(false);
    } catch (error) {
      console.error("Error removing payment method:", error);
    }
  };

  // Handler to open the Add Payment Method dialog
  const handleOpenDialog = async () => {
    setIsAdding(true);
    try {
      const secret = await createSetupIntent();
      setClientSecret(secret);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error creating setup intent:", error);
    }
    setIsAdding(false);
  };

  return (
    <div className="mb-6">
      <Label className="mb-2 block text-sm font-semibold text-primary">
        {t("billing.payment_methods.title")}
      </Label>
      <p className="mb-6 text-sm font-medium text-dark-grey">
        {t("billing.payment_methods.instructions")}
      </p>

      <div className="mb-12">
        {paymentMethods?.map((pm) => (
          <div
            key={pm.id}
            className="mb-3 flex w-full items-center justify-between rounded-[12px] bg-light-grey px-5 py-4"
          >
            <div className="flex items-center space-x-4">
              {/* Display Card Icon based on type */}
              <PaymentIcon
                brand={pm.card?.brand || "unknown"}
                type={pm.type as Stripe.PaymentMethod.Type}
              />

              {pm.type == "card" ? (
                <p className="text-sm font-bold text-primary">
                  ************{pm.card?.last4}&nbsp;&nbsp;&#40;
                  {pm.card?.exp_month}/{pm.card?.exp_year}&#41;
                </p>
              ) : (
                <p className="text-sm font-bold capitalize text-primary">
                  {pm.type}
                </p>
              )}
            </div>

            <div className="flex items-center space-x-3">
              {pm.default ? (
                <span className="rounded-lg bg-dark-blue px-2 py-1 text-sm font-medium text-white">
                  {t("billing.payment_methods.default")}
                </span>
              ) : (
                <button
                  onClick={() => handleSetDefault(pm.id)}
                  className="flex items-center justify-center text-sm font-semibold text-dark-blue hover:underline"
                >
                  {isLoadingSetDefault == pm.id ? (
                    <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-dark-blue" />
                  ) : (
                    t("billing.payment_methods.set_default")
                  )}
                </button>
              )}

              {/* Remove Payment Method Button */}
              {!pm.default && paymentMethods?.length > 1 && (
                <button
                  className="flex size-6 items-center justify-center rounded-full bg-white pb-0.5 text-center text-dark-grey hover:bg-red hover:text-white"
                  onClick={() => handleRemove(pm.id)}
                >
                  <span className="text-[12px] font-medium">✕</span>
                </button>
              )}
            </div>
          </div>
        ))}

        <Button
          onClick={handleOpenDialog}
          disabled={isAdding}
          className={`mt-6 flex justify-self-end rounded-lg px-5 py-3 ${
            isAdding
              ? "cursor-not-allowed bg-dark-grey text-primary hover:bg-dark-grey"
              : ""
          }`}
        >
          {isAdding ? (
            <>
              <span>{isAdding ? "Adding..." : "isPreparing..."}</span>
              <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-primary" />
            </>
          ) : (
            t("billing.payment_methods.add_payment_method")
          )}
        </Button>
      </div>
      {/* Add Payment Method Dialog */}
      {isDialogOpen && (
        <AddPaymentMethodDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          clientSecret={clientSecret as string}
        />
      )}
      {/* AlertDialog for Confirming Removal */}
      <AlertDialog open={isAlertOpen} onOpenChange={() => {}}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("billing.payment_methods.remove_title")}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t("billing.payment_methods.remove_confirmation")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => setIsAlertOpen(false)}
              disabled={isRemoving ? true : false}
            >
              {t("billing.payment_methods.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmRemove}
              className="bg-red hover:bg-red hover:brightness-90"
            >
              {t("billing.payment_methods.confirm_remove")}
              {isRemoving && (
                <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default PaymentMethods;
