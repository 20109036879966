import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { Button } from "@/components/ui/button";

import { MagicColoredIcon } from "@/assets/icons";
import { useTranslation } from "react-i18next";
import { Switch } from "@/components/ui/switch";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";

import { useUpdateAutoHide } from "@/axios/mutations/useUpdateAutoHide";
import { useUpdateAutoReply } from "@/axios/mutations/useUpdateAutoReply";
import { SmartFeatureFilterDialog } from "./SmartFeatureFilterDialog";
import { WorkspaceDocumentsDialog } from "./WorkspaceDocumentsDialog";

/**
 * Tracks whether the user is toggling on a feature for the first time
 * or simply editing it while it's already on.
 */
type FeatureFlowState = {
  feature: "autoHide" | "autoReply" | undefined;
  isEnablingFlow: boolean; // true => user is turning it on; false => user is just editing
};

const SmartFeaturesSettings = () => {
  const { t } = useTranslation();
  const { currentWorkspace } = useWorkspaceContext();

  const { updateAutoHide } = useUpdateAutoHide();
  const { updateAutoReply } = useUpdateAutoReply();

  // Feature toggles in local state
  const [autoHideEnabled, setAutoHideEnabled] = useState<boolean>(
    currentWorkspace?.settings?.auto_hide || false,
  );
  const [autoReplyEnabled, setAutoReplyEnabled] = useState<boolean>(
    currentWorkspace?.settings?.auto_reply || false,
  );

  // Loading states for disabling (off)
  const [isUpdatingAutoHide, setIsUpdatingAutoHide] = useState(false);
  const [isUpdatingAutoReply, setIsUpdatingAutoReply] = useState(false);

  // Which feature user is *currently* dealing with (autoHide vs autoReply),
  // plus whether we are in the "enabling" flow or just editing.
  const [flowState, setFlowState] = useState<FeatureFlowState>({
    feature: undefined,
    isEnablingFlow: false,
  });

  // The disclaimers confirmation dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // The filter configuration dialog
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  // The “Manage Documents” dialog
  const [isDocumentsDialogOpen, setIsDocumentsDialogOpen] = useState(false);

  // Sync local state if workspace changes
  useEffect(() => {
    setAutoHideEnabled(currentWorkspace?.settings?.auto_hide || false);
    setAutoReplyEnabled(currentWorkspace?.settings?.auto_reply || false);
  }, [currentWorkspace]);

  /**
   * Disable Auto Hide on the server
   */
  const disableAutoHide = async () => {
    setIsUpdatingAutoHide(true);
    try {
      await updateAutoHide(currentWorkspace?._id as string, false);
      setAutoHideEnabled(false);
    } catch (error) {
      console.error("Error disabling auto hide:", error);
    } finally {
      setIsUpdatingAutoHide(false);
    }
  };

  /**
   * Disable Auto Reply on the server
   */
  const disableAutoReply = async () => {
    setIsUpdatingAutoReply(true);
    try {
      await updateAutoReply(currentWorkspace?._id as string, false);
      setAutoReplyEnabled(false);
    } catch (error) {
      console.error("Error disabling auto reply:", error);
    } finally {
      setIsUpdatingAutoReply(false);
    }
  };

  /**
   * Called when user tries to turn ON Auto Hide
   */
  const onEnableAutoHide = () => {
    setFlowState({ feature: "autoHide", isEnablingFlow: true });
    setTermsAccepted(false);
    setIsDialogOpen(true);
    // Optimistically set local toggle on
    setAutoHideEnabled(true);
  };

  /**
   * Called when user tries to turn ON Auto Reply
   */
  const onEnableAutoReply = () => {
    setFlowState({ feature: "autoReply", isEnablingFlow: true });
    setTermsAccepted(false);
    setIsDialogOpen(true);
    // Optimistically set local toggle on
    setAutoReplyEnabled(true);
  };

  /**
   * Called when the user wants to edit the filter for an *already on* feature.
   */
  const onEditFeature = (feature: "autoHide" | "autoReply") => {
    setFlowState({ feature, isEnablingFlow: false });
    setIsFilterDialogOpen(true);
  };

  return (
    <div className="flex h-full overflow-hidden bg-light-grey">
      <div className="flex h-full max-w-[550px] flex-col overflow-auto rounded-[12px] bg-white p-8 scrollbar-hide">
        <div className="mb-2 flex items-center justify-between">
          <h2 className="flex text-base font-semibold text-primary">
            {t("settings.smart_features.title")}
            <MagicColoredIcon className="mb-0.5 ml-1 h-5 shrink-0 text-primary" />
          </h2>
        </div>

        <p className="mb-10 w-full text-left text-sm font-semibold text-dark-grey">
          {t("settings.smart_features.description")}
        </p>

        <div className="mb-10">
          <Label
            htmlFor="workspaceName"
            className="mb-6 block text-sm font-semibold text-primary"
          >
            {t("settings.smart_features.auto_features")}
          </Label>

          {/* ----- Auto Hide ----- */}
          <div className="mb-6">
            <div className="rounded-xl border border-light-grey p-4">
              <div className="flex items-center justify-between space-x-10">
                <div className="space-y-2">
                  <Label className="block text-base font-semibold text-primary">
                    {t("settings.smart_features.auto_hide_label")}
                  </Label>
                  <p className="text-sm text-dark-grey">
                    {t("settings.smart_features.auto_hide_description")}
                  </p>
                </div>

                <Switch
                  checked={autoHideEnabled}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      onEnableAutoHide();
                    } else {
                      // User wants to disable auto hide
                      disableAutoHide();
                    }
                  }}
                  disabled={isUpdatingAutoHide}
                />
              </div>

              {autoHideEnabled && (
                <div className="mt-5 flex w-full justify-end">
                  <Button
                    variant="ghost"
                    onClick={() => onEditFeature("autoHide")}
                    className="h-12 w-full rounded-lg bg-primary text-white hover:border hover:border-primary hover:bg-white hover:text-primary"
                  >
                    {t("settings.smart_features.change_auto_hide_settings")}
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* ----- Auto Reply ----- */}
          <div className="rounded-xl border border-light-grey p-4">
            <div className="flex items-center justify-between space-x-10">
              <div className="space-y-2">
                <Label className="block text-base font-semibold text-primary">
                  {t("settings.smart_features.auto_reply_label")}
                </Label>
                <p className="text-sm text-dark-grey">
                  {t("settings.smart_features.auto_reply_description")}
                </p>
              </div>

              <Switch
                checked={autoReplyEnabled}
                onCheckedChange={(checked) => {
                  if (checked) {
                    onEnableAutoReply();
                  } else {
                    // User wants to disable auto reply
                    disableAutoReply();
                  }
                }}
                disabled={isUpdatingAutoReply}
              />
            </div>

            {autoReplyEnabled && (
              <div className="mt-5 flex w-full justify-end">
                <Button
                  variant="ghost"
                  onClick={() => onEditFeature("autoReply")}
                  className="h-12 w-full rounded-lg bg-primary text-white hover:border hover:border-primary hover:bg-white hover:text-primary"
                >
                  {t("settings.smart_features.change_auto_reply_settings")}
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* ----- Manage Documents button ----- */}
        <div className="mb-10">
          <div className="mb-6">
            <Label
              htmlFor="workspaceName"
              className="mb-2 block text-sm font-semibold text-primary"
            >
              {t("settings.smart_features.documents_title")}
            </Label>

            <p className="text-sm text-dark-grey">
              {t("settings.smart_features.documents_description")}
            </p>
          </div>

          <Button
            variant="ghost"
            onClick={() => setIsDocumentsDialogOpen(true)}
            className="h-12 w-full rounded-lg bg-primary text-white hover:border hover:border-primary hover:bg-white hover:text-primary"
          >
            {t("settings.smart_features.manage_documents")}
          </Button>
        </div>
      </div>

      {/* ----- Confirmation Dialog (Disclaimers) ----- */}
      <Dialog
        open={isDialogOpen}
        onOpenChange={(open) => {
          // If user closed the disclaimers without confirming:
          if (!open) {
            setIsDialogOpen(false);
            setTermsAccepted(false);

            // If we were in the enabling flow, revert toggle
            if (flowState.isEnablingFlow) {
              if (flowState.feature === "autoHide") {
                setAutoHideEnabled(false);
              } else if (flowState.feature === "autoReply") {
                setAutoReplyEnabled(false);
              }
            }

            // Reset flow state
            setFlowState({ feature: undefined, isEnablingFlow: false });
          }
        }}
      >
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle className="mb-4 leading-snug">
              {flowState.feature === "autoHide"
                ? t("settings.smart_features.auto_hide_confirmation_title")
                : t("settings.smart_features.auto_reply_confirmation_title")}
            </DialogTitle>
            <DialogDescription className="leading-relaxed">
              {flowState.feature === "autoHide"
                ? t(
                    "settings.smart_features.auto_hide_confirmation_description",
                  )
                    .split("\n\n")
                    .map((paragraph, idx) => (
                      <p key={idx} className="mb-2">
                        {paragraph}
                      </p>
                    ))
                : t(
                    "settings.smart_features.auto_reply_confirmation_description",
                  )
                    .split("\n\n")
                    .map((paragraph, idx) => (
                      <p key={idx} className="mb-2">
                        {paragraph}
                      </p>
                    ))}
            </DialogDescription>
          </DialogHeader>

          {/* ----- Disclaimer ----- */}
          <div className="mb-4 flex items-center justify-center leading-relaxed">
            <Checkbox
              id="terms"
              checked={termsAccepted}
              onCheckedChange={(checked) => setTermsAccepted(!!checked)}
              className="size-4 border-primary text-primary data-[state=checked]:bg-primary"
            />
            <Label htmlFor="terms" className="ml-2 text-xs">
              {flowState.feature === "autoHide"
                ? t("settings.smart_features.disclaimer_auto_hide")
                : t("settings.smart_features.disclaimer_auto_reply")}
            </Label>
          </div>

          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => {
                // User canceled disclaimers
                setIsDialogOpen(false);
                setTermsAccepted(false);

                // Revert if enabling
                if (flowState.isEnablingFlow) {
                  if (flowState.feature === "autoHide") {
                    setAutoHideEnabled(false);
                  } else if (flowState.feature === "autoReply") {
                    setAutoReplyEnabled(false);
                  }
                }

                setFlowState({ feature: undefined, isEnablingFlow: false });
              }}
              disabled={isUpdatingAutoHide || isUpdatingAutoReply}
            >
              {t("settings.smart_features.cancel")}
            </Button>

            <Button
              onClick={() => {
                // User accepted disclaimers, open the filter dialog
                setIsDialogOpen(false);
                setIsFilterDialogOpen(true);
              }}
              disabled={!termsAccepted}
            >
              {t("settings.smart_features.confirm")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* ----- Smart Feature Filters Dialog ----- */}
      <SmartFeatureFilterDialog
        open={isFilterDialogOpen}
        feature={flowState.feature}
        isEnablingFlow={flowState.isEnablingFlow}
        onClose={(didApply) => {
          // Filter dialog is closing
          setIsFilterDialogOpen(false);

          // If user did NOT apply changes *and* we are in an enabling flow, revert the toggle
          if (!didApply && flowState.isEnablingFlow) {
            if (flowState.feature === "autoHide") {
              setAutoHideEnabled(false);
            } else if (flowState.feature === "autoReply") {
              setAutoReplyEnabled(false);
            }
          }

          // Clear flow state
          setFlowState({ feature: undefined, isEnablingFlow: false });
        }}
      />

      {/* ----- Documents Manager Dialog ----- */}
      <WorkspaceDocumentsDialog
        open={isDocumentsDialogOpen}
        onClose={() => setIsDocumentsDialogOpen(false)}
      />
    </div>
  );
};

export default SmartFeaturesSettings;
