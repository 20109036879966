// useGenerateReplies.ts

import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

/**
 * Type for generating replies to comments.
 */
interface GenerateCommentsRepliesInput {
  type: "comment"; // Discriminant
  data: {
    _id: string;
    post_id: string;
    channel_id: string;
  }[];
  workspace_id: string;
  additional_instruction?: string;
}

/**
 * Type for generating replies to messages.
 */
interface GenerateMessagesRepliesInput {
  type: "message"; // Discriminant
  data: {
    _id: string;
    channel_id: string;
  }[];
  workspace_id: string;
  additional_instruction?: string;
}

/**
 * Union type for generating replies to either comments or messages.
 */
export type GenerateRepliesInput =
  | GenerateCommentsRepliesInput
  | GenerateMessagesRepliesInput;

/**
 * Interface representing a single generated reply.
 */
export interface GeneratedReply {
  _id: string;
  reply: string;
}

/**
 * Type representing the response from the generate replies API.
 */
export type GenerateRepliesResponse = GeneratedReply[];

/**
 * Custom hook to generate replies for multiple comments or messages.
 * Provides a function to call the backend API and handles loading and error states.
 */
function useGenerateReplies() {
  const { fetcher } = useFetcher();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [generatedReplies, setGeneratedReplies] =
    useState<GenerateRepliesResponse | null>(null);

  /**
   * Generates replies for the provided data, which can be either comments or messages.
   *
   * @param input - Object containing type, data array, and optional additional instruction.
   * @returns The generated replies or null in case of error.
   */
  const generateReplies = async (
    input: GenerateRepliesInput,
  ): Promise<GenerateRepliesResponse | null> => {
    setIsLoading(true);
    setError(null);
    setGeneratedReplies(null);

    try {
      // Construct the payload based on the input type
      const payload: any = {
        type: input.type,
        data: input.data,
        workspace_id: input.workspace_id,
        additional_instruction: input.additional_instruction,
      };

      // Additional validation can be added here if necessary

      // Make the API request to generate replies
      const response = await fetcher(
        `/api/assistant/generate-replies`,
        "post",
        payload,
      );

      // Cast the response to the expected type
      const replies = response as GenerateRepliesResponse;
      setGeneratedReplies(replies);

      // Return the replies directly to the caller
      return replies;
    } catch (err: any) {
      // Handle errors and set error state
      setError(err.message || "An unexpected error occurred.");
      return null; // Return null in case of error
    } finally {
      // End the loading state
      setIsLoading(false);
    }
  };

  return {
    generateReplies, // Function to trigger the API call
    isLoading, // Loading state for UI feedback
    error, // Error state for UI feedback
    generatedReplies, // The generated replies after a successful API call
  };
}

export { useGenerateReplies };
