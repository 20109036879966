import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
} from "date-fns";

/**
 * Formats a timestamp into a relative time string like "5m" or "3h",
 * taking into account the user's local timezone.
 * @param {Date} date - The date to format
 * @returns {string} The formatted time string.
 */
export const formatTimeToNowShort = (date: Date, t: any): string => {
  // Convert the input date to the local timezone
  const localDate = new Date(date.toLocaleString());
  const now = new Date();

  // Calculate the differences
  const diffYears = differenceInYears(now, localDate);
  const diffMonths = differenceInMonths(now, localDate);
  const diffDays = differenceInCalendarDays(now, localDate);
  const diffHours = differenceInHours(now, localDate);
  const diffMinutes = differenceInMinutes(now, localDate);
  const diffSeconds = differenceInSeconds(now, localDate);

  if (diffYears >= 1) return `${diffYears}${t("timeSuffixes.year")}`;
  if (diffMonths >= 1) return `${diffMonths}${t("timeSuffixes.month")}`;
  if (diffDays >= 2) return `${diffDays}${t("timeSuffixes.day")}`;
  if (diffHours >= 1) return `${diffHours}${t("timeSuffixes.hour")}`;
  if (diffMinutes >= 1) return `${diffMinutes}${t("timeSuffixes.minute")}`;
  return `${diffSeconds}${t("timeSuffixes.second")}`;
};
