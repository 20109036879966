// useUpdateAutoReplyFilter.ts
import { SmartFeatureFilter } from "@/types/workspace.type";
import { useFetcher } from "@axios/fetcher";
import { Workspace } from "@types";

export function useUpdateAutoReplyFilter() {
  const { fetcher } = useFetcher();

  // filterData should match SmartFeatureFilter or partial
  const updateAutoReplyFilter = async (
    workspace_id: string,
    filterData: SmartFeatureFilter,
  ) => {
    if (!workspace_id) {
      throw new Error("workspace_id is required to update auto-reply filter");
    }

    const updatedWorkspace = await fetcher(
      `/api/workspaces/${workspace_id}/settings/auto-reply/filter`,
      "patch",
      filterData,
    );

    return updatedWorkspace as Workspace;
  };

  return { updateAutoReplyFilter };
}
