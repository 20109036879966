// hooks/useCreateCheckoutSession.ts
import { useFetcher } from "@axios/fetcher";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";

/**
 * Custom hook to create a Stripe Checkout Session.
 */
export function useCreateCheckoutSession() {
  const { fetcher } = useFetcher();
  const { currentWorkspace } = useWorkspaceContext();

  if (!currentWorkspace) {
    throw new Error("No current workspace found");
  }

  /**
   * Function to extract `fp_tid` from FirstPromoter.
   */
  const getFPTid = (): string | undefined => {
    // Access the FPROM data if available
    return (window as any).FPROM?.data.tid;
  };

  /**
   * Creates a Stripe Checkout Session.
   * @param price_id - The Stripe price ID.
   * @returns The session ID.
   */
  const createCheckoutSession = async (price_id: string): Promise<string> => {
    const maxRetries = 3;
    let attempt = 0;
    let lastError: unknown;

    while (attempt < maxRetries) {
      try {
        const fp_tid = getFPTid(); // Extract `fp_tid` from FirstPromoter

        const response = await fetcher(
          "/api/stripe/create-checkout-session",
          "post",
          {
            workspace_id: currentWorkspace.workspace_id,
            price_id: price_id,
            success_url:
              window.location.origin + "?status=subscription-success",
            cancel_url: window.location.origin + "?status=subscription-cancel",
            fp_tid: fp_tid, // Include `fp_tid` in the request body
          },
          false,
          true,
        );

        const { sessionId } = response;
        return sessionId;
      } catch (error) {
        attempt++;
        lastError = error;
        console.error(
          `Attempt ${attempt} failed while creating Checkout Session:`,
          error,
        );
        if (attempt < maxRetries) {
          // Optionally, implement an exponential backoff delay
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
        }
      }
    }

    // If all attempts fail, throw the last encountered error.
    throw lastError;
  };

  return {
    createCheckoutSession,
  };
}
