// src/components/popup/ChannelList.tsx

import { CHANNELS_CONFIG } from "@/types/channel.config";
import { Channel } from "@/types";
import {
  Loader,
  CheckDarkCircleIcon,
  UnlinkIcon,
  SettingsIcon,
  UnlinkWhiteIcon,
} from "@/assets/icons";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { Img } from "../Img";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../ui/button";

import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";

interface ChannelListProps {
  isLoading: Record<string, boolean>;
  handleConnect: (type: Channel["type"]) => void;
  confirmDisconnect: (type: Channel) => void;
  isAlertOpen: Channel | null;
  setIsAlertOpen: React.Dispatch<React.SetStateAction<Channel | null>>;
  onClose: () => void;
}

const ChannelList = ({
  isLoading,
  handleConnect,
  confirmDisconnect,
  isAlertOpen,
  setIsAlertOpen,
  onClose,
}: ChannelListProps) => {
  const { t } = useTranslation();

  const { channels } = useWorkspaceContext();

  const [activeTab] = useState("organic");

  const navigate = useNavigate();

  // Handler to open AlertDialog
  const openAlert = (channel: Channel) => setIsAlertOpen(channel);
  // Handler to close AlertDialog
  const closeAlert = () => setIsAlertOpen(null);

  return (
    <>
      {/* <Tabs
        defaultValue="organic"
        className="mb-6 flex w-full items-center justify-center"
        onValueChange={(value) => {
          setActiveTab(value);
        }}
      >
        <TabsList className="size-fit shrink-0 items-center justify-around space-x-0 rounded-[12px] border border-light-grey p-1">
          <TabsTrigger
            value="organic"
            className="h-[40px] w-[200px] cursor-pointer rounded-[10px] text-sm font-semibold transition-none data-[state=active]:bg-primary data-[state=inactive]:font-semibold data-[state=active]:text-white data-[state=inactive]:text-primary"
          >
            {t("connect_channel.social_channel")}
          </TabsTrigger>

          <TabsTrigger
            value="ads"
            className="h-[40px] w-[200px] cursor-pointer rounded-[10px] text-sm font-semibold transition-none data-[state=active]:bg-primary data-[state=inactive]:font-semibold data-[state=active]:text-white data-[state=inactive]:text-primary"
          >
            {t("connect_channel.ad_channel")}
          </TabsTrigger>
        </TabsList>
      </Tabs> */}

      <div className="flex w-full flex-col space-y-3">
        {Object.keys(CHANNELS_CONFIG).map((type) => {
          if (type == "linkedin") {
            return;
          }

          if (
            (activeTab == "ads" && !type.includes("ads")) ||
            (activeTab == "organic" && type.includes("ads"))
          ) {
            return;
          }

          const channel = channels?.find((c) => c.type === type);
          const isConnected = channel?.is_connected;
          const isDisconnected = channel && !channel.is_connected;

          return (
            <button
              key={type}
              className={`flex h-[72px] items-center rounded-lg border p-[10px] hover:bg-light-grey
                    ${isConnected ? "border-dark-blue/50 bg-light-blue" : isDisconnected ? "border border-red" : "border-light-grey"} 
                    ${isConnected ? "justify-between" : ""}
                    ${isConnected ? "cursor-default" : "cursor-pointer"}
              `}
              onClick={() => {
                return !isConnected && handleConnect(type as Channel["type"]);
              }}
            >
              <div
                className={`flex items-center gap-4 
              `}
              >
                {channel?.profile_picture_url ? (
                  <div className="relative shrink-0">
                    <Img
                      src={channel?.profile_picture_url}
                      alt={channel?.username || channel?.type}
                      className="size-9 shrink-0 rounded-full border border-light-grey bg-white object-contain"
                    />

                    {/* Social Network Icon Badge */}
                    <div className="absolute bottom-0 right-[-3px] flex size-[16px] items-center justify-center rounded-full bg-white shadow-[0_0_0_2px_theme('colors.light-grey')]">
                      <Img
                        src={CHANNELS_CONFIG[type as Channel["type"]].icon}
                        alt={CHANNELS_CONFIG[type as Channel["type"]].name}
                        className="size-full rounded-full object-contain"
                      />
                    </div>
                  </div>
                ) : type.includes("ads") ? (
                  <div className="relative shrink-0">
                    <Img
                      src={CHANNELS_CONFIG[type as Channel["type"]].icon}
                      alt={CHANNELS_CONFIG[type as Channel["type"]].name}
                      className="size-9 rounded-full"
                    />

                    {/* Social Network Icon Badge */}
                    <div className="absolute bottom-[-6px] right-[-8px] flex h-[16px] w-fit items-center justify-center rounded-full bg-black px-1 pb-[0.5px] shadow-[0_0_0_2px_theme('colors.light-grey')]">
                      <p className="text-[10px] font-extrabold text-white">
                        AD
                      </p>
                    </div>
                  </div>
                ) : (
                  <Img
                    src={CHANNELS_CONFIG[type as Channel["type"]].icon}
                    alt={CHANNELS_CONFIG[type as Channel["type"]].name}
                    className="size-9 rounded-full"
                  />
                )}
                <div className="flex flex-col items-start">
                  <div className="flex items-center justify-start">
                    <span className="text-base font-bold text-primary">
                      {t(CHANNELS_CONFIG[type as Channel["type"]].name)}
                    </span>

                    {isLoading[type] ? (
                      <Loader className="ml-2 size-3.5 animate-spin stroke-primary" />
                    ) : isConnected ? (
                      <Img
                        src={CheckDarkCircleIcon} // Show check icon if connected
                        alt="Connected"
                        className="ml-2 size-3.5"
                      />
                    ) : null}
                  </div>

                  {isDisconnected ? (
                    <span className="text-left text-red">
                      {t("connect_channel.channel_disconnected")}
                    </span>
                  ) : (
                    CHANNELS_CONFIG[type as Channel["type"]].description && (
                      <span className="text-left text-dark-grey">
                        {t(
                          CHANNELS_CONFIG[type as Channel["type"]]
                            ?.description as string,
                        )}
                      </span>
                    )
                  )}
                </div>
              </div>

              {isConnected && !isLoading[type] && (
                <Popover>
                  <PopoverTrigger asChild>
                    <button
                      className="hover:brightness-50"
                      onClick={(e) => e.stopPropagation()} // Prevent triggering parent onClick
                      aria-label="Options"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        className="size-4 text-dark-grey"
                      />
                    </button>
                  </PopoverTrigger>

                  <PopoverContent className="z-50 flex w-fit flex-col items-start rounded-lg border-0 bg-white p-2 text-left font-semibold shadow-2xl">
                    <Button
                      variant="ghost"
                      onClick={() => {
                        onClose();
                        navigate(`/settings/channels/${channel.channel_id}`);
                      }}
                      className="w-full items-center justify-start text-primary hover:bg-light-grey hover:text-primary"
                    >
                      <SettingsIcon className="mr-2 size-4" />
                      {t("navbar.channel.settings")}
                    </Button>

                    {/* Button to open AlertDialog instead of directly disconnecting */}
                    <Button
                      variant="ghost"
                      onClick={(e) => {
                        openAlert(channel);
                        e.stopPropagation(); // Prevent triggering parent onClick
                      }}
                      className="w-full items-center justify-start text-red hover:bg-light-grey hover:text-red"
                    >
                      <UnlinkIcon className="mr-2 size-4" />
                      {t("navbar.channel.disconnect")}
                    </Button>
                  </PopoverContent>
                </Popover>
              )}
            </button>
          );
        })}
      </div>

      {/* Dialog for confirming disconnection */}
      <Dialog open={!!isAlertOpen} onOpenChange={() => setIsAlertOpen(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold text-red">
              {t("navbar.channel.disconnect")}
            </DialogTitle>
            <DialogDescription className="text-base text-primary">
              {t("navbar.channel.disconnect_description")}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="mt-4 space-x-2">
            <Button
              variant="secondary"
              className={"hover:brightness-90"}
              onClick={() => setIsAlertOpen(null)}
            >
              {t("settings.workspace.cancel")}
            </Button>

            <Button
              variant="destructive"
              onClick={() => {
                confirmDisconnect(isAlertOpen as Channel);
                closeAlert();
              }}
              className="bg-red text-white hover:bg-red hover:brightness-90"
            >
              <UnlinkWhiteIcon className="mr-1.5 size-4" />
              {t("navbar.channel.disconnect")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChannelList;
