// useLikeComments.ts
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

// types.ts
export interface LikeCommentInput {
  workspace_id: string;
  comments: { _id_comment: string; channel_id: string; like: boolean }[];
}

function useLikeComments() {
  const { fetcher } = useFetcher();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const likeComments = async (data: LikeCommentInput): Promise<boolean> => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await fetcher("/api/engagement/like", "post", data);
      setSuccess(true);
      return true;
    } catch (err: any) {
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    likeComments,
    isLoading,
    error,
    success,
  };
}

export { useLikeComments };
