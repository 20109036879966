/* eslint-disable react-hooks/exhaustive-deps */
// conversations.context.tsx

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { WorkspaceDetails } from "@/types";
import {
  AssistantMessage,
  useAssistantChat,
} from "@/axios/mutations/useAssistantChat";
import {
  useGenerateReply,
  useGenerateReplies,
  useSkipItems,
} from "@/axios/mutations";

import { isEqual } from "lodash";
import { Conversation, GetMessagesDto, Message } from "@/types/messages.types";
import { useMessages } from "@/axios/queries/useMessages";
import { TaskContextType } from "@/types/task-context.type";
import {
  ConversationReplyResponse,
  useReplyToItems,
} from "@/axios/mutations/useReplyToItems";
import { useNavBar } from "@/layouts/NavBar.context";
import { useTranslation } from "react-i18next";

type AnimationType = "to-do" | "done" | "skipped" | "hidden";

export const ConversationsContext = createContext<TaskContextType | undefined>(
  undefined,
);

export const useConversationsContext = (): TaskContextType => {
  const context = useContext(ConversationsContext);
  if (!context) {
    throw new Error(
      "useConversationsContext must be used within ConversationsProvider",
    );
  }
  return context;
};

export const ConversationsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  const { currentWorkspace } = useWorkspaceContext();
  const workspace_id = (currentWorkspace as WorkspaceDetails)?.workspace_id;
  const { refetchCountTodo } = useNavBar();

  /**
   * Initialize filterOptions from localStorage if available.
   */
  const [filterOptions, setFilterOptions] = useState<GetMessagesDto>(() => {
    return {
      limit: 25,
      page: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      category: undefined,
      sentiment: undefined,
      channel_type: undefined,
    };

    // const savedFilters = localStorage.getItem("conversationsFilterOptions");
    // if (savedFilters) {
    //   return {
    //     ...JSON.parse(savedFilters),
    //     startDate: undefined,
    //     endDate: undefined,
    //     status: undefined,
    //   };
    // } else {
    //   return {
    //     limit: 25,
    //     page: 1,
    //     status: undefined,
    //     startDate: undefined,
    //     endDate: undefined,
    //     category: undefined,
    //     sentiment: undefined,
    //     channel_type: undefined,
    //   };
    // }
  });

  // Persist filterOptions in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "conversationsFilterOptions",
      JSON.stringify({
        ...filterOptions,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
      }),
    );
  }, [filterOptions]);

  // State for infinite scroll loading
  const {
    data: fetchedConversations = [],
    isLoadingInitialData,
    isLoadingMore,
    size,
    setSize,
    hasMore,
    refetch: refetchConversations,
  } = useMessages(workspace_id, filterOptions);

  const { replyToItems } = useReplyToItems();
  const { skipItems } = useSkipItems();

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const conversationsRef = useRef(conversations); // Keep a stable reference to `conversations`

  // Synchronize conversationsRef with the latest conversations state
  useEffect(() => {
    conversationsRef.current = conversations;
  }, [conversations]);

  const [activeTab, setActiveTab] = useState<"to-do" | "done" | "skipped">(
    "to-do",
  );

  const [isBulkActionLoading, setIsBulkActionLoading] = useState<
    string[] | null
  >(null);

  // States for opened and selected conversations
  const [openedConversationId, setOpenedConversationId] = useState<
    string | null
  >(null);
  const [selectedConversationIds, setSelectedConversationIds] = useState<
    string[]
  >([]);

  const [reply, setReply] = useState<string>("");

  // Ref to keep track of the "active" conversation ID for async operations
  const activeConversationIdRef = useRef<string | null>(null);

  // Function to check if the conversation ID matches the active one
  const isCurrentConversation = (conversationId: string | null): boolean => {
    return conversationId === activeConversationIdRef.current;
  };

  // AI Panel
  const [AiPanelIsOpen, setAIPanelIsOpen] = useState<boolean>(false);

  // Access assistant-related states and functions
  const {
    messages,
    interactWithAssistant,
    isStreaming,
    error,
    resetChat,
    addMessage,
  } = useAssistantChat();

  // Hooks for generating replies
  const { generateReply } = useGenerateReply();
  const { generateReplies } = useGenerateReplies();
  const [selectedGeneratedReplies, setSelectedGeneratedReplies] = useState<{
    [key: string]: string;
  }>({});

  // Create a ref to hold the previous workspace_id
  const prevWorkspaceIdRef = useRef(workspace_id);

  // Reset conversations and related state when workspace_id changes
  useEffect(() => {
    // Only reset state if workspace_id has changed to a new value
    if (workspace_id && workspace_id !== prevWorkspaceIdRef.current) {
      setSelectedGeneratedReplies({});
      setConversations([]);
      setOpenedConversationId(null);
      setSelectedConversationIds([]);
      conversationsRef.current = [];

      setActiveTab("to-do");
      setAnimatingItems({});
      setIsBulkActionLoading(null);
      setAIPanelIsOpen(false);

      // Update the ref to the new workspace_id
      prevWorkspaceIdRef.current = workspace_id;
    }
  }, [workspace_id]);

  useEffect(() => {
    if (selectedConversationIds.length === 0 && !openedConversationId) {
      setSelectedGeneratedReplies({});
    }
  }, [selectedConversationIds, openedConversationId]);

  /**
   * Merges fetched conversations with local conversations, preserving optimistic updates.
   */
  useEffect(() => {
    const mergeConversations = (): Conversation[] => {
      const conversationsMap = new Map<string, Conversation>(
        conversationsRef.current.map((c) => [c._id, c]),
      );

      let hasChanges = false;

      const mergedConversations = fetchedConversations.map(
        (fetchedConversation) => {
          const localConversation = conversationsMap.get(
            fetchedConversation._id,
          );

          if (
            localConversation &&
            localConversation.status !== fetchedConversation.status
          ) {
            // Preserve the local optimistic update
            return localConversation;
          }

          if (
            !localConversation ||
            !isEqual(localConversation, fetchedConversation)
          ) {
            hasChanges = true;
          }

          return fetchedConversation;
        },
      );

      // // Include any local conversations not present in fetchedConversations
      // conversationsMap.forEach((localConversation, id) => {
      //   if (!mergedConversations.some((c) => c._id === id)) {
      //     mergedConversations.push(localConversation);
      //     hasChanges = true;
      //   }
      // });

      // Only update the conversations state if there are changes
      if (
        hasChanges ||
        mergedConversations.length !== conversationsRef.current.length
      ) {
        return mergedConversations;
      } else {
        return conversationsRef.current; // No changes detected, return current state
      }
    };

    const newConversations = mergeConversations();
    setConversations(newConversations);
  }, [fetchedConversations, workspace_id]);

  // Second useEffect: Update openedConversationId and selectedConversationIds based on conversations
  useEffect(() => {
    const currentConversationIds = new Set(conversations.map((c) => c._id));

    // Only set to null if the conversation no longer exists
    if (
      openedConversationId &&
      !currentConversationIds.has(openedConversationId)
    ) {
      console.debug(
        `Conversation ${openedConversationId} no longer exists. Closing.`,
      );
      setOpenedConversationId(null);
    }

    // Update selectedConversationIds only if necessary
    if (selectedConversationIds.length) {
      const filteredIds = selectedConversationIds.filter((id) =>
        currentConversationIds.has(id),
      );
      if (filteredIds.length !== selectedConversationIds.length) {
        console.debug(`Updating selected conversations:`, filteredIds);
        setSelectedConversationIds(filteredIds);
      }
    }
  }, [conversations, openedConversationId, selectedConversationIds]);

  /**
   * Applies new filters and resets the conversations and pagination.
   *
   * @param filters - The filters to apply.
   */
  const applyFilters = useCallback(
    (filters: Partial<GetMessagesDto>) => {
      setConversations([]);

      const newFilterOptions = {
        ...{
          ...filterOptions,
          status: undefined,
          startDate: undefined,
          endDate: undefined,
        },
        ...filters,
        page: 1,
      };

      setFilterOptions(newFilterOptions);
      localStorage.setItem(
        "conversationsFilterOptions",
        JSON.stringify(newFilterOptions),
      );

      setSize(1); // Reset pagination to the first page
    },
    [setFilterOptions, setConversations, setSize],
  );

  const resetFilters = useCallback(() => {
    setFilterOptions({
      limit: 25,
      page: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      category: undefined,
      sentiment: undefined,
      channel_type: undefined,
    });

    localStorage.setItem(
      "conversationsFilterOptions",
      JSON.stringify({
        limit: 25,
        page: 1,
        startDate: undefined,
        endDate: undefined,
        category: undefined,
        sentiment: undefined,
        channel_type: undefined,
      }),
    );
    setConversations([]);
    setSize(1);
    // Add any other state resets if necessary
  }, [setFilterOptions, setConversations, setSize]);

  /**
   * Loads more conversations for infinite scrolling.
   */
  const loadMoreConversations = useCallback(() => {
    if (hasMore && !isLoadingMore) {
      setSize(size + 1);
    }
  }, [hasMore, isLoadingMore, setSize, size]);

  // Animation state
  const [animatingItems, setAnimatingItems] = useState<
    Record<string, AnimationType>
  >({});

  /**
   * Updates the status of a conversation.
   *
   * @param id - The ID of the conversation to update.
   * @param status - The new status ('done', 'skipped', etc.).
   */
  const updateItemStatus = useCallback(
    (id: string, status: "to-do" | "done" | "skipped") => {
      setConversations((prevConversations) =>
        prevConversations.map((conversation) =>
          conversation._id === id ? { ...conversation, status } : conversation,
        ),
      );
    },
    [],
  );

  /**
   * Handles the end of the animation.
   *
   * @param id - The ID of the item whose animation ended.
   */
  const handleAnimationEnd = useCallback(
    (id: string) => {
      let status = animatingItems[id];
      if (status) {
        if (status === "hidden") {
          status = "done";
        }

        updateItemStatus(id, status);
        setAnimatingItems((prev) => {
          const updated = { ...prev };
          delete updated[id];
          return updated;
        });
      }
    },
    [animatingItems, updateItemStatus],
  );

  /**
   * Deselects all conversations and clears the opened conversation.
   */
  const deselectAllConversations = useCallback((): void => {
    setSelectedConversationIds([]);
    setOpenedConversationId(null);
    setSelectedGeneratedReplies({});
  }, []);

  /**
   * Deselects a selected generated reply for a specific conversation.
   * @param conversationId - The ID of the conversation to remove the selected reply for.
   */
  const removeSelectedGeneratedReply = useCallback((conversationId: string) => {
    setSelectedGeneratedReplies((prevReplies) => {
      const updatedReplies = { ...prevReplies };
      delete updatedReplies[conversationId]; // Remove the entry by key
      return updatedReplies; // Return the new object without the deleted key
    });
  }, []);

  /**
   * Initiates a status update with animation.
   *
   * @param id - The ID of the item to update.
   * @param status - The new status ("done" or "skipped").
   */
  const initiateStatusUpdate = useCallback(
    (id: string, status: AnimationType) => {
      setAnimatingItems((prev) => ({ ...prev, [id]: status }));
      handleAnimationEnd(id);
    },
    [handleAnimationEnd],
  );

  // 1. Skip Conversations Function
  const skipSelectedConversations = useCallback(
    async (ids: string[], skip: boolean) => {
      setIsBulkActionLoading(
        isBulkActionLoading ? [...isBulkActionLoading, ...ids] : null,
      );

      const conversationsToSkip = conversations
        ?.filter((c) => ids.includes(c._id))
        ?.map((c) => ({ conversation_id: c._id, skip: skip }));

      conversationsToSkip?.forEach((conversation) => {
        initiateStatusUpdate(
          conversation.conversation_id,
          skip ? "skipped" : "to-do",
        );
      });

      deselectAllConversations();

      try {
        // Proceed with the API call
        void skipItems({ conversations: conversationsToSkip });
        console.debug("Skipped conversations:", conversationsToSkip);
        // Refetch conversations to get updated data from the server
        refetchConversations();

        // Optimistically update the conversations
        const conversationsToSkipIds = conversationsToSkip.map(
          (c) => c.conversation_id,
        );

        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversationsToSkipIds.includes(conversation._id)
              ? {
                  ...conversation,
                  // status: skip ? "skipped" : "to-do",
                  skipped: { status: skip, timestamp: new Date() },
                  messages: [
                    ...(conversation.messages || []).map((message) => ({
                      ...message,
                      skipped: {
                        status: skip,
                        timestamp: new Date(),
                      },
                      // status: skip ? "skipped" : "to-do",
                    })),
                  ],
                }
              : conversation,
          ),
        );

        if (conversationsToSkipIds?.length == 1 && activeTab == "to-do") {
          openNextOlderConversation(conversationsToSkipIds[0]);
        }
      } catch (error) {
        console.error("Error skipping conversations:", error);
      } finally {
        setIsBulkActionLoading(null);
        await refetchCountTodo();
      }
    },
    [
      conversations,
      initiateStatusUpdate,
      refetchConversations,
      skipItems,
      deselectAllConversations,
    ],
  );

  // Reply to Conversations Function
  const replyToSelectedConversations = useCallback(
    async (conversationsArray: { id: string; message: string }[]) => {
      if (conversationsArray.length === 0) return;

      setIsBulkActionLoading(
        isBulkActionLoading
          ? [...isBulkActionLoading, ...conversationsArray?.map((c) => c.id)]
          : null,
      );

      const conversationsToReply = conversationsArray.map(
        ({ id, message }) => ({
          conversation_id: id,
          message_id: getLastMessageId(id),
          channel_id:
            conversations.find((c) => c._id === id)?.channel.channel_id || "",
          message,
        }),
      );

      conversationsToReply?.forEach(({ conversation_id }) => {
        initiateStatusUpdate(conversation_id, "done");
      });

      if (
        conversationsToReply.length === 1 &&
        conversationsToReply[0].conversation_id == openedConversationId
      ) {
        conversationsToReply?.forEach(({ conversation_id }) => {
          initiateStatusUpdate(conversation_id, "done");
          deselectConversation(conversation_id);
        });
      } else {
        closeConversation();
        deselectAllConversations();
      }

      try {
        const replies = (
          await replyToItems({
            workspace_id,
            conversations: conversationsToReply,
          })
        )?.conversations;

        await refetchConversations();

        (replies as ConversationReplyResponse[]).forEach(
          ({ conversation_id, reply }) => {
            setConversations((prevConversations) =>
              prevConversations.map((conversation) =>
                conversation._id === conversation_id
                  ? {
                      ...conversation,
                      // status: "done",
                      messages: [
                        { ...reply, status: "done" },
                        ...(conversation.messages || []).map((message) => ({
                          ...message,
                          // status: "done",
                        })),
                      ],
                    }
                  : conversation,
              ),
            );
          },
        );
      } catch (error) {
        console.error("Error replying to conversations:", error);
      } finally {
        setIsBulkActionLoading(null);
        await refetchCountTodo();
      }
    },
    [
      conversations,
      initiateStatusUpdate,
      refetchConversations,
      replyToItems,
      workspace_id,
      deselectAllConversations,
    ],
  );

  /**
   * Retrieves the last message ID for a given conversation ID based on the most recent timestamp.
   *
   * @param conversationId - The ID of the conversation.
   * @returns The ID of the last message or an empty string if not found.
   */
  const getLastMessageId = useCallback(
    (conversationId: string): string => {
      // Find the conversation by ID
      const conversation = conversations.find((c) => c._id === conversationId);
      if (
        !conversation ||
        !conversation.messages ||
        conversation.messages.length === 0
      ) {
        console.warn("Conversation not found or has no messages.");
        return "";
      }

      // Filter messages where `owner` is true
      const filteredMessages = conversation.messages.filter((m) => !m.owner);

      if (filteredMessages.length === 0) {
        console.warn("No messages with owner found.");
        return "";
      }

      // Function to convert timestamp to a comparable number
      const getComparableTimestamp = (m: Message): number => {
        if (m.timestamp === null) {
          // Assign Infinity to `null` timestamps to treat them as most recent
          return Infinity;
        }

        let date: Date;

        if (typeof m.timestamp === "string") {
          date = new Date(m.timestamp);
        } else if (m.timestamp instanceof Date) {
          date = m.timestamp;
        } else {
          // If timestamp is neither string nor Date (shouldn't happen), treat as least recent
          return -Infinity;
        }

        if (isNaN(date.getTime())) {
          // If timestamp is invalid, treat it as the least recent
          console.warn(
            `Invalid timestamp for message ID ${m._id}: ${m.timestamp}`,
          );
          return -Infinity;
        }

        return date.getTime();
      };

      // Use reduce to find the message with the latest timestamp
      const lastMessage = filteredMessages.reduce((latest, current) => {
        const latestTime = getComparableTimestamp(latest);
        const currentTime = getComparableTimestamp(current);

        // If currentTime is greater, select current; otherwise, keep latest
        return currentTime > latestTime ? current : latest;
      }, filteredMessages[0]);

      return lastMessage._id;
    },
    [conversations],
  );

  /**
   * Opens a specific conversation by ID.
   */
  const openConversation = useCallback((id: string): void => {
    setOpenedConversationId(id);
    activeConversationIdRef.current = id; // Update the ref when a conversation is opened
  }, []);

  /**
   * Closes the currently opened conversation.
   */
  const closeConversation = useCallback((): void => {
    removeSelectedGeneratedReply(openedConversationId as string);
    setOpenedConversationId(null);
    activeConversationIdRef.current = null; // Update the ref when a conversation is closed
  }, []);

  /**
   * Adds a conversation to the selected list and sorts by timestamp.
   */
  const selectConversation = useCallback(
    (id: string): void => {
      setSelectedConversationIds((prev) => {
        // Add new ids, avoiding duplicates
        const updatedList =
          openedConversationId &&
          openedConversationId !== id &&
          !prev.includes(openedConversationId)
            ? [...prev, openedConversationId, id]
            : [...prev, id];

        // Remove duplicates and sort by timestamp
        return Array.from(new Set(updatedList)).sort((aId, bId) => {
          const aConversation = conversations.find(
            (conv) => conv.conversation_id === aId,
          );
          const bConversation = conversations.find(
            (conv) => conv.conversation_id === bId,
          );

          const aTime = aConversation?.timestamp
            ? new Date(aConversation.timestamp).getTime()
            : -Infinity;
          const bTime = bConversation?.timestamp
            ? new Date(bConversation.timestamp).getTime()
            : -Infinity;

          return bTime - aTime;
        });
      });

      setOpenedConversationId(id);
    },
    [openedConversationId, conversations],
  );

  /**
   * Deselects a conversation from the selected list and maintains sorting.
   */
  const deselectConversation = useCallback(
    (id: string): void => {
      setSelectedConversationIds((prev) =>
        prev
          .filter((conversationId) => conversationId !== id)
          .sort((aId, bId) => {
            const aConversation = conversations.find(
              (conv) => conv.conversation_id === aId,
            );
            const bConversation = conversations.find(
              (conv) => conv.conversation_id === bId,
            );

            const aTime = aConversation?.timestamp
              ? new Date(aConversation.timestamp).getTime()
              : -Infinity;
            const bTime = bConversation?.timestamp
              ? new Date(bConversation.timestamp).getTime()
              : -Infinity;

            return bTime - aTime;
          }),
      );

      // Update openedConversationId if necessary
      if (id === openedConversationId) {
        if (selectedConversationIds.length > 1) {
          // Build the updated list of conversation objects in descending order
          const updatedSelectedConversations = selectedConversationIds
            .filter((convId) => convId !== id)
            .map((convId) =>
              conversations.find((conv) => conv.conversation_id === convId),
            )
            .filter((conv): conv is Conversation => !!conv)
            .sort((a, b) => {
              const aTime = a.timestamp
                ? new Date(a.timestamp).getTime()
                : -Infinity;
              const bTime = b.timestamp
                ? new Date(b.timestamp).getTime()
                : -Infinity;
              return bTime - aTime; // newest first
            });

          if (updatedSelectedConversations.length === 0) {
            // Nothing is left, so clear the openedConversationId
            setOpenedConversationId(null);
            removeSelectedGeneratedReply(id);
            return;
          }

          // // Find the deselected conversation’s timestamp.
          // const deselectedConversation = conversations.find(
          //   (conv) => conv.conversation_id === id,
          // );
          // const deselectedTime = deselectedConversation
          //   ? new Date(deselectedConversation.timestamp).getTime()
          //   : -Infinity;

          // // Find the “next older” conversation (assuming descending order = newest first).
          // const nextOlderConversation = updatedSelectedConversations.find(
          //   (conv) => {
          //     const time = conv.timestamp
          //       ? new Date(conv.timestamp).getTime()
          //       : -Infinity;
          //     return time < deselectedTime;
          //   },
          // );

          // // If found a strictly older one, open it;
          // // otherwise fall back to the newest conversation in the updated list.
          // if (nextOlderConversation) {
          //   setOpenedConversationId(nextOlderConversation.conversation_id);
          // } else {
          //   setOpenedConversationId(
          //     updatedSelectedConversations[0].conversation_id,
          //   );
          // }

          // Clean up generated reply, if needed
          removeSelectedGeneratedReply(id);
        } else {
          setOpenedConversationId(null);
        }

        // If we are deselecting the opened comment,
        // see if we should open the next older.
        if (activeTab == "to-do") {
          openNextOlderConversation(id);
        }
      }
    },
    [
      conversations,
      openedConversationId,
      selectedConversationIds,
      removeSelectedGeneratedReply,
    ],
  );

  /**
   * Opens the next older conversation after removing or acting on `deselectedId`.
   * If multiple conversations are selected, it searches among that selection;
   * otherwise, it searches among all conversations.
   */
  const openNextOlderConversation = useCallback(
    (deselectedId: string) => {
      // 1) Decide which conversation IDs to consider
      //    If we still have multiple selected, use selectedConversationIds.
      //    Otherwise, use all conversation IDs in `conversations`.
      const isMultiSelect = selectedConversationIds?.length > 1;
      const relevantIds = isMultiSelect
        ? selectedConversationIds
        : conversations
            ?.filter((c) =>
              filterOptions?.status
                ? c?.status == filterOptions?.status
                : c?.status == "to-do",
            )
            .map((conv) => conv._id);

      // Build the candidate list, excluding the deselected conversation
      const candidateConversations = relevantIds
        .filter((conversationId) => conversationId !== deselectedId)
        .map((conversationId) =>
          conversations.find((c) => c.conversation_id === conversationId),
        )
        .filter((conv): conv is Conversation => !!conv)
        // Sort in descending order by timestamp (newest first)
        .sort((a, b) => {
          const aTime = a.timestamp
            ? new Date(a.timestamp).getTime()
            : -Infinity;
          const bTime = b.timestamp
            ? new Date(b.timestamp).getTime()
            : -Infinity;
          return bTime - aTime; // newest first
        });

      // 2) Find the conversation we just “removed” or “acted on”
      const deselectedConversation = conversations.find(
        (c) => c._id === deselectedId,
      );
      if (!deselectedConversation) {
        // If for some reason that conversation doesn’t exist in state, open the top candidate
        setOpenedConversationId(
          candidateConversations[0]?.conversation_id ?? null,
        );
        removeSelectedGeneratedReply(deselectedId);
        return;
      }

      const deselectedTime = new Date(
        deselectedConversation.timestamp,
      ).getTime();

      // 3) Find the first candidate that is strictly older (timestamp < deselectedTime)
      const nextOlderConversation = candidateConversations.find((conv) => {
        const time = conv.timestamp
          ? new Date(conv.timestamp).getTime()
          : -Infinity;
        return time <= deselectedTime;
      });

      // 4) If we find a strictly older conversation, open it;
      //    otherwise, open the newest in the candidate list (index 0).
      if (nextOlderConversation) {
        setOpenedConversationId(nextOlderConversation.conversation_id);
      } else {
        setOpenedConversationId(
          candidateConversations[0]?.conversation_id ?? null,
        );
      }

      // 5) Optionally remove the AI-generated reply for the conversation you just deselected
      removeSelectedGeneratedReply(deselectedId);

      if (!selectedConversationIds || selectedConversationIds?.length <= 1) {
        setAIPanelIsOpen(false);
      }
    },
    [
      conversations,
      selectedConversationIds,
      setOpenedConversationId,
      removeSelectedGeneratedReply,
    ],
  );

  /**
   * Selects all conversations by their IDs.
   */
  const selectAllConversations = useCallback(
    (ids: string[]): void => {
      openConversation(ids[0]);
      setSelectedConversationIds(ids);
    },
    [openConversation],
  );

  /**
   * Moves to the next conversation in the selected conversations.
   */
  const nextConversation = useCallback(() => {
    if (!openedConversationId) return;

    const index = selectedConversationIds.indexOf(openedConversationId);

    const nextIndex =
      index < selectedConversationIds.length - 1 ? index + 1 : 0;
    const nextConversationId = selectedConversationIds[nextIndex];
    openConversation(nextConversationId);
  }, [openedConversationId, selectedConversationIds, openConversation]);

  /**
   * Moves to the previous conversation in the selected conversations.
   */
  const previousConversation = useCallback(() => {
    if (!openedConversationId) return;

    const index = selectedConversationIds.indexOf(openedConversationId);

    const previousIndex =
      index > 0 ? index - 1 : selectedConversationIds.length - 1;
    const previousConversationId = selectedConversationIds[previousIndex];
    openConversation(previousConversationId);
  }, [openedConversationId, selectedConversationIds, openConversation]);

  /**
   * Generates a reply for a specific conversation.
   *
   * @param conversationId - The ID of the conversation.
   */
  const generateReplyForConversation = useCallback(
    async (conversationId: string): Promise<void> => {
      const conversation = conversations.find((c) => c._id === conversationId);

      if (!conversation) {
        console.error("Conversation not found:", conversationId);
        return;
      }

      try {
        setAIPanelIsOpen(true);

        // Set streaming state for the conversation
        isStreaming[conversationId] = true;

        if (!conversation?.text?.trim()?.length) {
          addMessage(conversationId, {
            text: t("tasks.ai_panel.not_supported_message"),
            from: "ai",
            type: "error",
          });

          return;
        }

        // Call the generateReply function using the conversation details
        const reply = await generateReply({
          _id_message: getLastMessageId(conversationId),
          channel_id: conversation.channel.channel_id,
          workspace_id: currentWorkspace?._id as string,
        });

        if (reply && reply?.trim()?.length > 0) {
          addMessage(conversationId, {
            text: reply as string,
            from: "ai",
            type: "generatedReply",
          });
        } else {
          addMessage(conversationId, {
            text: t("tasks.ai_panel.errorMessage"),
            from: "ai",
            type: "error",
          });
        }
      } catch (error) {
        console.error("Failed to generate reply:", error);
        addMessage(conversationId, {
          text: t("tasks.ai_panel.errorMessage"),
          from: "ai",
          type: "error",
        });
      } finally {
        // Reset streaming state for the conversation
        isStreaming[conversationId] = false;
      }
    },
    [
      addMessage,
      conversations,
      currentWorkspace?._id,
      generateReply,
      isStreaming,
      setAIPanelIsOpen,
    ],
  );

  /**
   * Generates replies for multiple conversations.
   *
   * @param conversationIds - Array of conversation IDs.
   */
  const generateRepliesForConversations = useCallback(
    async (conversationIds: string[]): Promise<void> => {
      if (conversationIds.length === 0) return;

      try {
        setAIPanelIsOpen(true);

        // Set isStreaming to true for all conversations
        conversationIds.forEach((conversationId) => {
          isStreaming[conversationId] = true;
        });

        // Prepare data for API call
        const data = conversationIds
          .map((conversationId) => {
            const conversation = conversations.find(
              (c) => c._id === conversationId,
            );
            if (!conversation) return null;
            return {
              _id: getLastMessageId(conversation._id),
              channel_id: conversation.channel.channel_id,
            };
          })
          .filter((item) => item !== null);

        const workspace_id = currentWorkspace?._id as string;

        const replies = await generateReplies({
          data: data as {
            _id: string;
            channel_id: string;
          }[],
          type: "message",
          workspace_id,
        });

        if (replies) {
          // For each reply, set the generatedReply message for the conversation
          replies.forEach((replyItem) => {
            const { _id: conversationId, reply } = replyItem;

            if (reply && reply?.trim()?.length > 0) {
              addMessage(conversationId, {
                text: reply,
                from: "ai",
                type: "generatedReply",
              });
            } else {
              const conv = conversations?.find(
                (conv) => conv?._id === conversationId,
              );

              if (!conv?.text?.trim()?.length) {
                addMessage(conversationId, {
                  text: t("tasks.ai_panel.not_supported_message"),
                  from: "ai",
                  type: "error",
                });
              } else {
                addMessage(conversationId, {
                  text: t("tasks.ai_panel.errorMessage"),
                  from: "ai",
                  type: "error",
                });
              }
            }

            // Reset streaming state for the conversation
            isStreaming[conversationId] = false;
          });
        }
      } catch (error) {
        console.error("Failed to generate replies:", error);

        addMessage(openedConversationId as string, {
          text: t("tasks.ai_panel.errorMessage"),
          from: "ai",
          type: "error",
        });
      } finally {
        // Reset isStreaming for all conversations
        conversationIds.forEach((conversationId) => {
          isStreaming[conversationId] = false;
        });
      }
    },
    [
      addMessage,
      conversations,
      currentWorkspace?._id,
      generateReplies,
      isStreaming,
      setAIPanelIsOpen,
    ],
  );

  /**
   * Gets the last generatedReply messages for selected conversations.
   *
   * @returns A record of conversationId to AssistantMessage or undefined.
   */
  const getLastGeneratedReplies = useCallback((): Record<
    string,
    AssistantMessage | undefined
  > => {
    const replies: Record<string, AssistantMessage | undefined> = {};
    selectedConversationIds.forEach((conversationId) => {
      const conversationMessages = messages[conversationId] || [];
      const lastGeneratedReply = conversationMessages
        .slice()
        .reverse()
        .find((msg) => msg.type === "generatedReply");
      if (lastGeneratedReply) {
        replies[conversationId] = lastGeneratedReply;
      }
    });
    return replies;
  }, [messages, selectedConversationIds]);

  /**
   * Gets the list of selected conversations that do not have a generated reply.
   *
   * @returns An array of conversationIds missing generated replies.
   */
  const getConversationsMissingGeneratedRepliesCount =
    useCallback((): string[] => {
      return selectedConversationIds.filter((conversationId) => {
        const conversationMessages = messages[conversationId] || [];
        return !conversationMessages.some(
          (msg) => msg.type === "generatedReply" || msg.type == "error",
        );
      });
    }, [messages, selectedConversationIds]);

  /**
   * Gets the list of selected conversations that do have an error as last message.
   *
   * @returns An array of conversationIds that have an error as last message.
   */
  const getMessagesWithErrors = useCallback((): string[] => {
    return selectedConversationIds.filter((conversationId) => {
      const conversationMessages = messages[conversationId] || [];

      return (
        !conversationMessages.some((msg) => msg.type === "generatedReply") &&
        conversationMessages.at(-1)?.type == "error"
      );
    });
  }, [messages, selectedConversationIds]);

  useEffect(() => {
    // Reset the reply state when the opened conversation changes
    setReply("");
  }, [openedConversationId]);

  // Memoize contextValue and include all necessary dependencies
  const contextValue: TaskContextType = useMemo(
    () => ({
      reply,
      setReply,
      items: conversations,
      isLoadingInitialData,
      isLoadingMore,
      hasMore,
      loadMoreItems: loadMoreConversations,
      applyFilters,
      isBulkActionLoading,
      AiPanelIsOpen,
      setAIPanelIsOpen,
      messages,
      isStreaming,
      error,
      animatingItems,
      selectedGeneratedReplies,
      generateReplyForItem: generateReplyForConversation,
      generateRepliesForItems: generateRepliesForConversations,
      setSelectedGeneratedReplies,
      replyToSelectedItems: replyToSelectedConversations,
      skipSelectedItems: skipSelectedConversations,
      addMessage,
      interactWithAssistant,
      resetChat,
      getLastGeneratedReplies,
      getItemsMissingGeneratedRepliesCount:
        getConversationsMissingGeneratedRepliesCount,
      getItemsWithErrors: getMessagesWithErrors,
      updateItemStatus,
      initiateStatusUpdate,
      handleAnimationEnd,
      openedItemId: openedConversationId,
      selectedItemIds: selectedConversationIds,
      isCurrentItem: isCurrentConversation,
      openItem: openConversation,
      closeItem: closeConversation,
      selectItem: selectConversation,
      deselectItem: deselectConversation,
      selectAllItems: selectAllConversations,
      deselectAllItems: deselectAllConversations,
      removeSelectedGeneratedReply,
      nextItem: nextConversation,
      previousItem: previousConversation,
      activeTab,
      setActiveTab,
      filterOptions,
      resetFilters,
    }),
    [
      reply,
      setReply,
      conversations,
      isLoadingInitialData,
      isLoadingMore,
      hasMore,
      loadMoreConversations,
      applyFilters,
      isBulkActionLoading,
      AiPanelIsOpen,
      setAIPanelIsOpen,
      messages,
      isStreaming,
      error,
      animatingItems,
      selectedGeneratedReplies,
      generateReplyForConversation,
      generateRepliesForConversations,
      setSelectedGeneratedReplies,
      replyToSelectedConversations,
      skipSelectedConversations,
      addMessage,
      interactWithAssistant,
      resetChat,
      getLastGeneratedReplies,
      getConversationsMissingGeneratedRepliesCount,
      getMessagesWithErrors,
      updateItemStatus,
      initiateStatusUpdate,
      handleAnimationEnd,
      openedConversationId,
      selectedConversationIds,
      isCurrentConversation,
      openConversation,
      closeConversation,
      selectConversation,
      deselectConversation,
      selectAllConversations,
      deselectAllConversations,
      removeSelectedGeneratedReply,
      nextConversation,
      previousConversation,
      activeTab,
      setActiveTab,
      filterOptions,
    ],
  );

  return (
    <ConversationsContext.Provider value={contextValue}>
      {children}
    </ConversationsContext.Provider>
  );
};
