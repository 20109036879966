// hooks/useCreateSetupIntent.ts

import { useFetcher } from "@axios/fetcher";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";

/**
 * Custom hook to create a Setup Intent for adding a new payment method.
 */
export function useCreateSetupIntent() {
  const { fetcher } = useFetcher();
  const { currentWorkspace } = useWorkspaceContext();
  const subscription_id = currentWorkspace?.subscription_id;

  const createSetupIntent = async (): Promise<string> => {
    try {
      const clientSecret = await fetcher(
        `/api/stripe/setup-intent`,
        "post",
        {
          subscription_id: subscription_id,
          workspace_id: currentWorkspace?.workspace_id,
        },
        false,
        true,
      );
      return clientSecret;
    } catch (error) {
      console.error("Error creating setup intent:", error);
      throw error;
    }
  };

  return { createSetupIntent };
}
