// src/components/popup/ChooseAccountStep.tsx

import { WhiteLoaderIcon } from "@/assets/icons";
import { useCreateChannel } from "@/axios/mutations/useCreateChannel";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import {
  Channel,
  FacebookPage,
  InstagramAccount,
  WorkspaceDetails,
  YoutubeChannel,
} from "@/types";
import { CHANNELS_CONFIG } from "@/types/channel.config";

import { useState } from "react";
import { Img } from "../Img";
import { useTranslation } from "react-i18next";
import { TiktokAdAccount } from "@/types/tiktok.type";
import { LinkedinAccount } from "@/types/linkedin.type";

interface ChooseAccountStepProps {
  accounts:
    | FacebookPage[]
    | InstagramAccount[]
    | YoutubeChannel[]
    | TiktokAdAccount[]
    | LinkedinAccount[];
  onAccountSelect: (accountId: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
  selectedAccount: string | null;
  channelType: Channel["type"];
}

const ChooseAccountStep = ({
  accounts,
  onAccountSelect,
  onConfirm,
  onCancel,
  selectedAccount,
  channelType,
}: ChooseAccountStepProps) => {
  const { t } = useTranslation();

  console.debug("SelectedAccount:", selectedAccount);

  const { currentWorkspace, refetchChannels } = useWorkspaceContext();
  const workspace_id = (currentWorkspace as WorkspaceDetails).workspace_id;

  const { createChannel } = useCreateChannel();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (!selectedAccount) return; // Ensure an account is selected

    setLoading(true);

    try {
      // Call createChannel with the appropriate data
      const newChannel = await createChannel({
        workspace_id,
        channel_id: selectedAccount,
        type: channelType,
      });

      console.debug("Channel created successfully:", newChannel);

      // Refetch channels after successful creation
      await refetchChannels();

      // Call onConfirm callback to close or proceed
      onConfirm();
    } catch (error) {
      console.error("Error creating channel:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex size-full flex-col items-center gap-4">
      <div className="w-full">
        {/* Title */}
        <h2 className="mb-4 text-[16px] font-bold text-primary">
          {t("connect_channel.choose_account_title")}
        </h2>

        {/* Account List */}
        <div className="max-h-[370px] overflow-y-auto scrollbar-hide">
          <ul className="space-y-3">
            {accounts.map((account) => {
              const profile_picture_url =
                channelType === "youtube"
                  ? (account as YoutubeChannel).snippet.thumbnails.high.url
                  : channelType === "linkedin"
                    ? (account as LinkedinAccount)?.profilePictureUrl
                    : (account as InstagramAccount | FacebookPage)
                        .profile_picture_url;

              const name =
                channelType === "youtube"
                  ? (account as YoutubeChannel).snippet.title
                  : channelType === "linkedin"
                    ? (account as LinkedinAccount)?.fullName
                    : (account as InstagramAccount | FacebookPage).name;

              const username = (
                channelType == "youtube"
                  ? (account as YoutubeChannel).snippet.customUrl
                  : channelType === "linkedin"
                    ? (account as LinkedinAccount)?.username
                    : (account as InstagramAccount | FacebookPage).username
              )?.replace("@", "");

              if (channelType == "tiktok-ads") {
                return (
                  <li
                    key={account.id}
                    onClick={() => onAccountSelect(account.id)}
                    className={`flex h-[72px] cursor-pointer items-center gap-5 rounded-lg border border-light-grey p-[10px] hover:bg-light-grey ${
                      selectedAccount === account.id && "bg-light-grey"
                    }`}
                  >
                    <div className="relative shrink-0">
                      <Img
                        src={CHANNELS_CONFIG[channelType].icon} // Use channelType to get the correct icon
                        alt={`${channelType}-icon`}
                        className="size-full rounded-full object-contain"
                      />

                      {/* Social Network Icon Badge */}
                      <div className="absolute bottom-[-6px] right-[-8px] flex size-fit items-center justify-center rounded-full bg-black px-1 pb-[0.5px] shadow-[0_0_0_2px_theme('colors.light-grey')]">
                        <p className="text-xs font-extrabold text-white">AD</p>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-base font-bold text-primary">
                        {name}
                      </span>
                      <span className="text-dark-grey">ID: {account.id}</span>
                    </div>
                  </li>
                );
              }

              return (
                <li
                  key={account.id}
                  onClick={() => onAccountSelect(account.id)}
                  className={`flex h-[72px] cursor-pointer items-center gap-3 rounded-lg border border-light-grey p-[10px] hover:bg-light-grey ${
                    selectedAccount === account.id && "bg-light-grey"
                  }`}
                >
                  <div className="relative shrink-0">
                    <Img
                      src={profile_picture_url}
                      alt={name}
                      className="size-9 shrink-0 rounded-full border border-light-grey bg-white object-contain"
                    />

                    {/* Social Network Icon Badge */}
                    <div className="absolute bottom-0 right-[-3px] flex size-[16px] items-center justify-center rounded-full bg-white shadow-[0_0_0_2px_theme('colors.light-grey')]">
                      <Img
                        src={CHANNELS_CONFIG[channelType].icon} // Use channelType to get the correct icon
                        alt={`${channelType}-icon`}
                        className="size-full rounded-full object-contain"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-bold text-primary">
                      {name}
                    </span>
                    {username && (
                      <span className="text-dark-grey">@{username}</span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Action Buttons */}
        <div className="mt-10 flex justify-end gap-4">
          <button
            className="w-32 rounded-lg bg-light-grey py-2 text-center text-[16px] font-semibold text-primary hover:brightness-90"
            onClick={onCancel}
          >
            {t("connect_channel.back")}
          </button>

          <button
            className={`flex w-32 items-center justify-center rounded-lg py-2 text-center text-[16px] font-semibold hover:brightness-90 ${
              selectedAccount
                ? "bg-primary text-white"
                : "cursor-not-allowed bg-light-grey text-dark-grey"
            }`}
            onClick={handleConfirm}
            disabled={!selectedAccount || loading} // Disable while loading or no account selected
          >
            {t("connect_channel.confirm")}
            {loading && (
              <Img
                src={WhiteLoaderIcon}
                alt="Loading..."
                className="ml-2 animate-spin"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseAccountStep;
