// hooks/useChangePlan.ts

import { useFetcher } from "@axios/fetcher";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";

/**
 * Custom hook to change the subscription plan.
 */
export function useChangePlan() {
  const { fetcher } = useFetcher();
  const { currentWorkspace } = useWorkspaceContext();
  const subscriptionId = currentWorkspace?.subscription_id;

  /**
   * Changes the subscription plan to a new price ID.
   * @param newprice_id - The ID of the new price.
   */
  const changePlan = async (newprice_id: string) => {
    try {
      await fetcher(
        `/api/stripe/subscription/${subscriptionId}/change-plan`,
        "post",
        { new_price_id: newprice_id },
        false,
        true,
      );
    } catch (error) {
      console.error("Error changing plan:", error);
      throw error;
    }
  };

  return {
    changePlan,
  };
}
