import { useEffect, useCallback } from "react";
import useSWRInfinite from "swr/infinite";
import { useFetcher } from "@axios/fetcher";
import {
  GetMessagesDto,
  PaginatedConversationsResponse,
} from "@/types/messages.types";
import { useWebSocket } from "@/layouts/WebSocketContext";

function useMessages(
  workspace_id: string,
  filter: GetMessagesDto,
  enabled = true,
) {
  const { fetcher } = useFetcher();

  /**
   * Create the SWR key for each page
   */
  const getKey = useCallback(
    (
      pageIndex: number,
      previousPageData: PaginatedConversationsResponse | null,
    ) => {
      if (!enabled || !workspace_id) return null;
      if (previousPageData && !previousPageData.conversations.length) {
        return null;
      }

      const { page, limit, ...restFilter } = filter;
      const params = new URLSearchParams();

      Object.entries(restFilter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            params.append(key, "");
          } else {
            value.forEach((v) => params.append(key, v));
          }
        } else if (value !== null && value !== undefined && value !== "") {
          params.append(key, value as string);
        }
      });

      params.append("page", (pageIndex + 1).toString());
      params.append("limit", limit?.toString() || "100");

      return `/api/messages/${workspace_id}?${params.toString()}`;
    },
    [enabled, workspace_id, filter],
  );

  // The fetcher
  const fetcherFn = useCallback(
    (url: string) => fetcher(url, "get"),
    [fetcher],
  );

  // SWR infinite
  const { data, error, mutate, size, setSize, isValidating } =
    useSWRInfinite<PaginatedConversationsResponse>(getKey, fetcherFn, {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    });

  /**
   * Grab the shared socket from context
   */
  const { socket } = useWebSocket();

  /**
   * Listen for "messagesUpdated" from the server
   */
  useEffect(() => {
    if (!socket || !workspace_id || !enabled) return;

    // If your server needs explicit join:
    socket.emit("joinWorkspace", { workspace_id });

    const handleMessagesUpdated = async () => {
      // console.info("[useMessages] 'messagesUpdated' => revalidating...");
      await refetch();
    };

    // Subscribe
    socket.on("messagesUpdated", handleMessagesUpdated);

    // Cleanup
    return () => {
      socket.off("messagesUpdated", handleMessagesUpdated);
    };
  }, [socket, workspace_id, enabled, mutate]);

  // Refs
  const refetch = async (): Promise<PaginatedConversationsResponse[]> => {
    try {
      const updatedData = await mutate();
      return updatedData || [];
    } catch (err) {
      console.error("Error refetching messages:", { error: err });
      throw err;
    }
  };

  const loadMore = async () => {
    setSize(size + 1);
  };

  const hasMore = data
    ? data[data.length - 1].page * filter.limit < data[data.length - 1]?.total
    : true;

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  const allConversations = data
    ? data.flatMap((page) => page.conversations)
    : [];

  return {
    data: allConversations,
    isLoadingInitialData,
    isLoadingMore,
    isValidating,
    error,
    size,
    setSize,
    hasMore,
    refetch,
    loadMore,
  };
}

export { useMessages };
