import React from "react";

const LinkIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    focusable="false"
    className="size-6"
  >
    <path fill="#00D66F" d="M0 0h32v32H0z"></path>
    <path
      d="M15.144 6H10c1 4.18 3.923 7.753 7.58 10C13.917 18.246 11 21.82 10 26h5.144c1.275-3.867 4.805-7.227 9.142-7.914v-4.18c-4.344-.68-7.874-4.04-9.142-7.906Z"
      fill="#011E0F"
    ></path>
  </svg>
);

export default LinkIcon;
