// ExpandableText.tsx

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ExpandableTextProps {
  text: string;
  maxLines?: number;
  className?: string; // Custom class for the text container
  toggleClassName?: string; // Custom class for the toggle button
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxLines = 3,
  className = "",
  toggleClassName = "",
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      // Calculate height based on line height and maxLines
      const lineHeight = parseInt(
        window.getComputedStyle(el).lineHeight || "16",
        10,
      ); // Default to 16px if line-height is not set
      const maxHeight = lineHeight * maxLines;

      const isOverflowing = el.scrollHeight > maxHeight;
      setIsTruncated(isOverflowing);
    }
  }, [text, maxLines]);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="relative">
      <div
        ref={textRef}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={`${className ? className : "text-sm font-medium text-primary"} ${
          !isExpanded
            ? `line-clamp-${maxLines}` // Ensure line-clamp is applied when not expanded
            : ""
        }`}
        style={{
          overflowWrap: "break-word", // Allows breaking for very long words
          wordBreak: "break-word", // Breaks only if necessary for very long words
        }}
      >
        {text}
      </div>
      {isTruncated && (
        <button
          onClick={toggleExpand}
          className={`mt-1 text-xs text-dark-blue hover:underline ${toggleClassName}`}
        >
          {isExpanded
            ? t("expandableText.showLess", { defaultValue: "Show less" })
            : t("expandableText.showMore", {
                defaultValue: "Show more...",
              })}{" "}
        </button>
      )}
    </div>
  );
};
