import { useState } from "react";
import { Img } from "../Img";
import { Loader } from "@/assets/icons";

type PopupComponentProps = {
  title: string;
  description?: string | JSX.Element;
  buttonText?: string;
  icon?: string;
  onConfirm?: (arg?: unknown) => void; // Optional argument with type `any`
  onCancelButtonText?: string;
  onCancel?: () => void; // Void return type with no parameter
  widthIcon?: string;
};

const PopupComponent = ({
  title,
  description,
  buttonText,
  icon,
  onConfirm,
  onCancelButtonText,
  onCancel,
  widthIcon,
}: PopupComponentProps) => {
  const [isLoading, setIsLoading] = useState<null | "confirm" | "cancel">(null);

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
      <div className="flex w-11/12 max-w-md flex-col items-center gap-4 rounded-lg bg-white p-6 shadow-lg">
        {/* Optional Icon */}
        {icon && (
          <Img
            src={icon}
            alt=""
            className={`mb-4 ${widthIcon ? `w-[${widthIcon}]` : "w-[100px]"}`} // Use Tailwind's arbitrary width utility
          />
        )}

        {/* Title */}
        <h2 className="text-center text-2xl font-medium text-primary">
          {title}
        </h2>

        {/* Description */}
        {description && (
          <p className="mt-2 text-center text-sm text-dark-grey">
            {description}
          </p>
        )}

        {/* Buttons */}
        <div className="mt-6 flex w-full flex-col gap-3 sm:flex-row">
          {/* Optional Cancel Button */}
          {onCancelButtonText && onCancel && (
            <button
              onClick={async () => {
                setIsLoading("cancel");
                await onCancel();
                setIsLoading(null);
              }}
              className="flex w-full flex-1 items-center justify-center rounded-lg bg-primary px-4 py-2 text-white hover:brightness-95 active:brightness-90 sm:w-auto"
            >
              {onCancelButtonText}
              {isLoading === "cancel" && (
                <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          )}
          {/* Confirm Button */}
          {buttonText && onConfirm && (
            <button
              onClick={async () => {
                setIsLoading("confirm");
                await onConfirm();
                setIsLoading(null);
              }}
              className="flex w-full flex-1 items-center justify-center rounded-lg bg-dark-blue px-4 py-2 text-white hover:brightness-95 active:brightness-90 sm:w-auto"
            >
              {buttonText}
              {isLoading === "confirm" && (
                <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupComponent;
