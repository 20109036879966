import { Img as ReactImage } from "react-image";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Flexible Img component to handle loading, error states, and custom styling.
 *
 * @param {string} src - The image source URL.
 * @param {React.ReactNode} loader - The loading skeleton or spinner.
 * @param {React.ReactNode} unloader - The fallback content when the image fails to load.
 * @param {string} alt - The alt text for the image.
 * @param {string} className - Custom class names for the image.
 * @param {React.CSSProperties} style - Inline styles for the image.
 * @param {object} rest - Any other props passed to the image component.
 */
export const Img = ({
  src,
  alt,
  className = "",
  style,
  loader,
  unloader,
  ...rest
}: {
  src: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  loader?: JSX.Element;
  unloader?: JSX.Element;
}) => {
  // Function to extract size number from className and compute size
  const getSizeFromClassName = (
    className: string,
  ): { width: number | string; height: number | string } => {
    const sizeClass = className
      .split(" ")
      .find((cls) => cls.startsWith("size-"));

    if (sizeClass) {
      const sizeNumber = Number(
        sizeClass
          .replace("size-", "")
          .replace("[", "")
          .replace("]", "")
          .replace("px", ""),
      );

      if (sizeNumber) {
        const sizeInPx = sizeNumber * 4; // Each size unit is 4p

        return {
          width: sizeClass.includes("px") ? sizeNumber : sizeInPx,
          height: sizeClass.includes("px") ? sizeNumber : sizeInPx,
        };
      }
    }

    // Default size if no valid size class is found
    return { width: "100%", height: "100%" }; // Default to size-9 (9 * 4 = 36px)
  };

  // Compute size based on className
  const { width, height } = getSizeFromClassName(className);

  // Define the default loader if none is provided
  const defaultLoader = (
    <Skeleton
      baseColor="#EBF1FF"
      highlightColor="#F9FAFB"
      duration={1.2}
      width={width}
      height={height}
      circle={className.includes("rounded-full") ? true : false}
      className="shrink-0 rounded-full"
    />
  );

  return (
    <ReactImage
      src={src}
      alt={alt}
      loader={loader ? loader : defaultLoader}
      unloader={unloader || defaultLoader}
      style={style}
      className={`shrink-0 ${className}`} // Ensure shrink-0 is always present
      {...rest} // Spread any other props
    />
  );
};
