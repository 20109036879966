import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { iconWidgetHours } from "@/assets/icons";

interface ResponseRatioProps {
  responseRatio: number;
  totalComments: number;
  repliedComments: number;
  unrepliedComments: number;
}

export function ResponseRatio({
  responseRatio,
  totalComments,
  repliedComments,
  unrepliedComments,
}: ResponseRatioProps) {
  const { t } = useTranslation();

  // Calculate not replied ratio
  const unrepliedRatio = 100 - responseRatio;

  return (
    <Card
      title={t("dashboard.ResponseRatio.title")}
      icon={iconWidgetHours}
      className="min-h-[420px]"
    >
      {/* Top Section: Replied and Unreplied Percentages */}
      <div className="relative mb-4 mt-12 flex items-center justify-between">
        <div className="ml-7 text-4xl font-extrabold text-primary">
          {responseRatio}%
        </div>

        {/* Position not replied ratio absolutely */}
        {responseRatio >= 25 && responseRatio <= 85 && (
          <div
            className="absolute text-4xl font-extrabold text-dark-grey"
            style={{
              left: `${responseRatio}%`,
              transform: `translateX(${-(responseRatio - 60)}%)`,
            }}
          >
            {unrepliedRatio}%
          </div>
        )}
      </div>
      {/* Progress Bar Container */}
      <div className=" flex w-full justify-center rounded-[10px] bg-light-grey px-7 py-8">
        {/* Replied Bar */}
        <div
          className="h-6 rounded-full"
          style={{
            width: `${responseRatio}%`,
            backgroundImage: createStripedBackground(responseRatio), // Dynamic stripes
          }}
        ></div>

        {/* Unreplied Bar */}
        <div
          className="ml-2 h-6 rounded-full bg-dark-grey"
          style={{
            width: `${unrepliedRatio}%`,
          }}
        ></div>
      </div>
      {/* Bottom Section: Total, Replied, and Unreplied */}
      <div className="mt-14 flex w-full justify-between">
        {/* Total Comments */}
        <div className="max-w-24 flex-1 text-left">
          <div className="text-3xl font-extrabold text-primary">
            {totalComments}
          </div>
          <div className="mt-3 text-sm font-semibold text-primary">
            {t("dashboard.ResponseRatio.total_comments")}
          </div>
        </div>

        {/* Replied Comments */}
        <div className="max-w-24 flex-1 text-left">
          <div className="text-3xl font-extrabold text-primary">
            {repliedComments}
          </div>
          <div className="mt-3 text-sm font-semibold text-primary">
            {t("dashboard.ResponseRatio.replied_comments")}
          </div>
        </div>

        {/* Unreplied Comments */}
        <div className="max-w-24 flex-1 text-left">
          <div className="text-3xl font-extrabold text-dark-grey">
            {unrepliedComments}
          </div>
          <div className="mt-3 text-sm font-semibold text-primary">
            {t("dashboard.ResponseRatio.not_replied_comments")}
          </div>
        </div>
      </div>
    </Card>
  );
}

// Utility function to create dynamic stripes based on the responseRatio
const createStripedBackground = (responseRatio: number) => {
  let stripes = [];

  const baseStripeWidth = 3; // La largeur de base quand le responseRatio est 100
  const stripeWidth = baseStripeWidth * (100 / responseRatio); // Calcul de la proportion inverse

  for (let i = 0; i <= 100; i += stripeWidth * 2) {
    stripes.push(
      `#1E1E1E ${i}%`,
      `#1E1E1E ${i + stripeWidth}%`,
      `#474747 ${i + stripeWidth}%`,
      `#474747 ${i + stripeWidth * 2}%`,
    );
  }
  return `linear-gradient(90deg, ${stripes.join(", ")})`;
};
