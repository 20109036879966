// components/icons/SEPAIcon.tsx
import React from "react";

const SEPAIcon: React.FC = () => (
  <svg
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    focusable="false"
    className="size-6"
  >
    <path
      d="M22.761 0H1.982l-.216.004a3.14 3.14 0 0 0-.47.041A1.5 1.5 0 0 0 .192.85a1.58 1.58 0 0 0-.147.447 3.116 3.116 0 0 0-.041.47A10.61 10.61 0 0 0 0 1.982v12.036c0 .072.002.144.004.216.004.157.013.315.041.47a1.496 1.496 0 0 0 .804 1.104c.143.072.29.119.447.147.155.028.313.037.47.041l.216.003.257.001h20.778l.217-.004c.156-.004.314-.013.47-.04.157-.03.304-.075.447-.148a1.492 1.492 0 0 0 .657-.657c.073-.143.119-.29.147-.447.028-.155.037-.313.041-.47l.003-.216.001-.256V1.982l-.004-.216a3.126 3.126 0 0 0-.041-.47 1.507 1.507 0 0 0-1.25-1.251 3.15 3.15 0 0 0-.471-.041A10.787 10.787 0 0 0 23.017 0h-.256Z"
      fill="#10298D"
    ></path>
    <path
      d="M22.761.533h.253l.205.004c.12.003.26.01.39.033.113.02.208.051.3.098a.966.966 0 0 1 .423.424c.046.09.077.185.098.299.023.129.03.27.033.39.002.068.002.135.003.205v12.03l-.003.204c-.004.12-.01.26-.034.39a.964.964 0 0 1-.521.722c-.09.047-.186.078-.298.098-.133.024-.279.03-.389.033-.069.002-.137.003-.208.003H1.985c-.07 0-.138-.001-.204-.003a2.709 2.709 0 0 1-.39-.033 1.05 1.05 0 0 1-.3-.098.958.958 0 0 1-.423-.423 1.047 1.047 0 0 1-.098-.3c-.024-.13-.03-.27-.033-.39a9.968 9.968 0 0 1-.003-.204V1.985c0-.068 0-.136.003-.205.003-.119.01-.259.033-.39.02-.113.051-.208.098-.3A.962.962 0 0 1 1.09.669c.091-.047.186-.078.3-.098.13-.023.27-.03.39-.033l.204-.003h20.776"
      fill="#10298D"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.14 9.905h-2.064l-.279 1.008h-1.596l1.722-5.833h2.332L23 10.913h-1.564l-.295-1.008Zm-.307-1.136L20.11 6.24l-.703 2.529h1.426Zm-7.85 2.144V5.08h2.878c.395 0 .696.033.903.098.473.151.79.46.953.926.084.245.126.613.126 1.106 0 .592-.046 1.016-.138 1.276-.185.511-.565.806-1.138.883-.068.011-.355.021-.861.03l-.257.009h-.921v1.505h-1.544Zm1.545-2.859h.963c.305-.011.49-.035.558-.072.091-.051.152-.154.182-.307.02-.102.03-.253.03-.454 0-.247-.02-.43-.059-.55-.055-.167-.188-.269-.399-.306-.1-.008-.2-.01-.3-.008h-.975v1.697ZM6.666 6.941H5.229c0-.24-.029-.4-.083-.482-.086-.122-.322-.182-.707-.182-.376 0-.625.035-.746.104-.121.07-.182.223-.182.458 0 .212.054.352.161.419a.677.677 0 0 0 .307.084l.29.02c.388.024.776.054 1.163.089.492.05.847.183 1.068.398.174.167.278.388.311.658.02.162.028.345.028.55 0 .469-.043.813-.131 1.031-.16.4-.52.652-1.08.759-.234.044-.591.066-1.074.066-.805 0-1.366-.048-1.683-.147-.387-.118-.644-.362-.768-.728C2.033 9.836 2 9.498 2 9.021h1.438v.122c0 .253.071.416.215.488a.82.82 0 0 0 .359.08h.528c.27 0 .44-.014.516-.042a.428.428 0 0 0 .26-.255c.021-.074.033-.167.033-.282 0-.256-.092-.412-.277-.469-.069-.022-.39-.053-.961-.092a11.565 11.565 0 0 1-.956-.096c-.47-.09-.785-.274-.94-.553-.139-.237-.208-.596-.208-1.076 0-.366.038-.66.112-.88a.956.956 0 0 1 .36-.503c.241-.176.552-.279.93-.31.347-.029.695-.043 1.044-.042.603 0 1.033.035 1.29.105.625.171.937.65.937 1.435 0 .065-.004.16-.014.29Z"
      fill="#fff"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.792 5.86c.659 0 1.246.303 1.633.777l.344-.736a3.022 3.022 0 0 0-2.079-.821 2.992 2.992 0 0 0-2.776 1.832h-.671l-.397.847h.863a2.89 2.89 0 0 0 .005.526h-.435l-.396.848h1.051a2.993 2.993 0 0 0 2.756 1.78 3.03 3.03 0 0 0 1.682-.505V9.37a2.105 2.105 0 0 1-3.34-.236h2.2l.396-.848h-2.92a2.162 2.162 0 0 1-.013-.526h3.179l.396-.847h-3.3a2.106 2.106 0 0 1 1.822-1.052Z"
      fill="#FFCC02"
    ></path>
  </svg>
);

export default SEPAIcon;
