// useDeletePreSavedReply.ts

import { Workspace } from "@/types";
import { useFetcher } from "@axios/fetcher";

function useDeletePreSavedReply() {
  const { fetcher } = useFetcher();

  /**
   * Function to delete a pre-saved reply by its index
   * @param workspace_id - The ID of the workspace
   * @param replyIndex - The index of the pre-saved reply to delete
   * @returns The updated list of pre-saved replies
   */
  const deletePreSavedReply = async (
    workspace_id: string,
    replyIndex: number,
  ): Promise<string[]> => {
    if (!workspace_id) {
      throw new Error("workspace_id is required to delete a pre-saved reply");
    }

    // Make the API request to delete the pre-saved reply
    const updatedWorkspace: Workspace = await fetcher(
      `/api/workspaces/${workspace_id}/pre-saved-replies/${replyIndex}`,
      "delete",
    );

    return updatedWorkspace.pre_saved_replies;
  };

  return {
    deletePreSavedReply,
  };
}

export { useDeletePreSavedReply };
