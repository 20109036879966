// useReplyToComments.ts
import { Message, Reply } from "@/types";
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

// types.ts

export interface ReplyToCommentsInput {
  _id_comment: string;
  channel_id: string;
  message: string;
}

export interface ReplyToConversationsInput {
  conversation_id: string;
  message_id: string;
  channel_id: string;
  message: string;
}

export interface ReplyToItemInput {
  workspace_id: string;
  comments?: ReplyToCommentsInput[];
  conversations?: ReplyToConversationsInput[];
}

export interface CommentReplyResponse {
  comment_id: string;
  reply: Reply;
}

export interface ConversationReplyResponse {
  conversation_id: string;
  reply: Message;
}

export type ReplyToItemResponse = {
  comments?: CommentReplyResponse[];
  conversations?: ConversationReplyResponse[];
};

function useReplyToItems() {
  const { fetcher } = useFetcher();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [replies, setReplies] = useState<ReplyToItemResponse>();

  const replyToItems = async (
    data: ReplyToItemInput,
  ): Promise<{
    comments?: CommentReplyResponse[];
    conversations?: ConversationReplyResponse[];
  }> => {
    setIsLoading(true);
    setError(null);
    setReplies({});

    try {
      const response = await fetcher("/api/engagement/reply", "post", data);
      const replies = response as ReplyToItemResponse;

      setReplies(replies);
      return replies;
    } catch (err: any) {
      setError(err.message || "An error occurred while replying.");
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    replyToItems,
    isLoading,
    error,
    replies,
  };
}

export { useReplyToItems };
