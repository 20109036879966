// hooks/usePaymentMethods.ts

import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { PaymentMethodDetails } from "@types";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";
import { useState } from "react";

/**
 * Custom hook to fetch payment methods for the current workspace's subscription.
 */
function usePaymentMethods() {
  const { fetcher } = useFetcher();
  const { currentWorkspace } = useWorkspaceContext();
  const subscriptionId = currentWorkspace?.subscription_id;
  const workspaceId = currentWorkspace?.workspace_id;

  const [isRefetching, setIsRefetching] = useState(false); // Refetch loading state

  // Determine if we should fetch: both subscriptionId and workspaceId must be present
  const shouldFetch = !!subscriptionId && !!workspaceId;

  // Build the cache key with query parameters
  const queryString = new URLSearchParams({
    workspace_id: workspaceId || "",
  }).toString();
  const cacheKey = shouldFetch
    ? `/api/stripe/payment-methods/${subscriptionId}?${queryString}`
    : null;

  const { data, error, mutate, isValidating } = useSWR<PaymentMethodDetails[]>(
    cacheKey,
    fetcher,
    {
      // Optionally, you can add SWR configurations here
      revalidateOnFocus: false,
    },
  );

  const isLoading = isValidating && !data && !error;

  /**
   * Refetch function for manual revalidation, ensures data is always returned
   */
  const refetch = async (): Promise<PaymentMethodDetails[]> => {
    setIsRefetching(true);
    const updatedData = await mutate(); // Try to get updated data
    setIsRefetching(false);

    // If no updated data, return the current cached data
    if (!updatedData) {
      if (data) {
        return data; // Return cached data
      } else {
        throw new Error("No data available after refetching.");
      }
    }

    return updatedData; // Return the updated data from mutate
  };

  return {
    paymentMethods: data || [],
    isLoading: isLoading, // True if data is loading for the first time or refetching
    isRefetching, // True if background fetching is in progress
    error,
    refetch,
  };
}

export { usePaymentMethods };
