// components/icons/ApplePayIcon.tsx
import React from "react";

const ApplePayIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    focusable="false"
    className="size-6"
  >
    <path fill="#F6F8FA" d="M0 0h32v32H0z"></path>
    <path
      d="M26.672 8H5.328c-.163 0-.327.002-.49.004a3.265 3.265 0 0 0-.49.043 1.56 1.56 0 0 0-1.148.837c-.076.149-.124.3-.153.464a3.442 3.442 0 0 0-.043.49L3 10.06v12.255c0 .164.002.328.004.492.004.163.014.328.043.489a1.562 1.562 0 0 0 .837 1.148c.149.076.3.124.464.153a3.3 3.3 0 0 0 .49.044l.223.003h21.877l.226-.003c.162-.005.326-.015.488-.044a1.65 1.65 0 0 0 .465-.153c.295-.15.534-.389.683-.683.074-.147.126-.304.153-.466.027-.161.041-.324.043-.488.002-.075.003-.149.003-.224l.001-.268V10.062c0-.075-.002-.15-.004-.225a3.243 3.243 0 0 0-.043-.489 1.567 1.567 0 0 0-1.3-1.301 3.274 3.274 0 0 0-.49-.043L26.938 8h-.266Z"
      fill="#000"
    ></path>
    <path
      d="M26.672 8.555h.262c.071 0 .143.002.215.004.123.003.27.009.405.034.118.022.217.053.312.103a1.004 1.004 0 0 1 .54.751c.025.134.032.28.035.405l.004.214v12.515c0 .07-.002.141-.004.212 0 .136-.012.272-.034.406a1.08 1.08 0 0 1-.102.311.996.996 0 0 1-.44.44c-.098.05-.202.084-.31.102a2.822 2.822 0 0 1-.404.035 8.19 8.19 0 0 1-.217.002H5.064c-.071 0-.143 0-.212-.002a2.832 2.832 0 0 1-.406-.035 1.087 1.087 0 0 1-.312-.102.995.995 0 0 1-.44-.44 1.09 1.09 0 0 1-.102-.312 2.744 2.744 0 0 1-.033-.405 10.392 10.392 0 0 1-.004-.213V10.066c0-.072.001-.143.004-.215.003-.124.01-.269.034-.405.018-.108.052-.213.102-.31a.998.998 0 0 1 .44-.441 1.11 1.11 0 0 1 .311-.103c.135-.02.27-.032.406-.033l.213-.004h21.607Z"
      fill="#fff"
    ></path>
    <path
      d="M10.098 13.599c.223-.28.373-.652.333-1.035-.325.016-.723.214-.953.494-.207.238-.39.628-.342.994.366.032.731-.183.962-.453Zm.33.524c-.531-.032-.984.302-1.237.302-.254 0-.643-.286-1.063-.278a1.567 1.567 0 0 0-1.331.81c-.571.983-.151 2.442.404 3.244.27.396.594.833 1.022.817.405-.016.564-.26 1.055-.26s.634.26 1.062.252c.444-.008.722-.396.991-.793.31-.453.437-.889.444-.913-.007-.007-.857-.333-.864-1.308-.007-.818.666-1.206.699-1.23-.382-.563-.976-.627-1.183-.642m4.626-1.106c1.155 0 1.959.796 1.959 1.955 0 1.162-.82 1.963-1.988 1.963h-1.278v2.032h-.924v-5.95h2.231Zm-1.307 3.143h1.06c.804 0 1.261-.433 1.261-1.184 0-.75-.457-1.18-1.257-1.18h-1.064v2.364Zm3.508 1.574c0-.759.581-1.224 1.612-1.282l1.187-.07v-.334c0-.482-.326-.771-.87-.771-.515 0-.837.247-.915.635h-.84c.049-.784.716-1.362 1.788-1.362 1.052 0 1.724.557 1.724 1.428v2.99h-.853v-.714h-.02c-.252.483-.802.788-1.37.788-.85 0-1.443-.528-1.443-1.308Zm2.8-.39v-.343l-1.069.065c-.53.037-.832.273-.832.644 0 .38.313.627.791.627.623 0 1.11-.428 1.11-.994Zm1.692 3.22v-.722c.066.017.215.017.289.017.413 0 .635-.174.771-.619 0-.009.078-.264.078-.268l-1.566-4.342h.965l1.098 3.53h.016l1.097-3.53h.94l-1.625 4.565c-.37 1.052-.8 1.39-1.699 1.39a3.699 3.699 0 0 1-.363-.021Z"
      fill="#000"
    ></path>
  </svg>
);

export default ApplePayIcon;
