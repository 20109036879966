// AddMemberDialog.tsx
import { useMemo, useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Loader } from "@/assets/icons";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useTranslation } from "react-i18next";

type AddMemberDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddMember: (email: string, name?: string) => Promise<void>;
};

const AddMemberDialog = ({
  isOpen,
  onClose,
  onAddMember,
}: AddMemberDialogProps) => {
  const { t } = useTranslation();

  const { currentWorkspace } = useWorkspaceContext();
  const [newMemberName, setNewMemberName] = useState("");
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [newMemberEmailError, setNewMemberEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const members = useMemo(() => {
    return (
      currentWorkspace?.members.filter(
        (member) => member.status === "active" || member.status === "pending",
      ) || []
    );
  }, [currentWorkspace]);

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleAddMember = async () => {
    setIsLoading(true);
    const email = newMemberEmail.toLowerCase().trim();
    if (!validateEmail(email)) {
      setNewMemberEmailError(
        t("workspace.add_member_dialog.email_error_invalid"),
      );
      setIsLoading(false);
      return;
    }

    if (members.find((member) => member.email === email)) {
      setNewMemberEmailError(
        t("workspace.add_member_dialog.email_error_exists"),
      );
      setIsLoading(false);
      return;
    }

    try {
      await onAddMember(email, newMemberName);
      setNewMemberName("");
      setNewMemberEmail("");
      setNewMemberEmailError("");
      onClose();
    } catch (error) {
      console.error("Failed to add member", error);
      setNewMemberEmailError(t("workspace.add_member_dialog.add_member_error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("workspace.add_member_dialog.add_new_member")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "workspace.add_member_dialog.please_enter_the_details_of_the_new_member",
            )}
          </DialogDescription>
        </DialogHeader>

        <div>
          <Label htmlFor="email">
            {t("workspace.add_member_dialog.email")}
          </Label>
          <Input
            id="email"
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
            placeholder={t("workspace.add_member_dialog.placeholder_email")}
            className={`mt-3 ${newMemberEmailError ? "border-red" : ""}`}
          />
          {newMemberEmailError && (
            <p className="mt-2 text-sm text-red">{newMemberEmailError}</p>
          )}
        </div>

        <DialogFooter>
          <div className="mt-4 flex justify-end space-x-3">
            <button
              className="w-fit rounded-lg bg-light-grey p-2.5 px-6 text-sm font-semibold text-primary hover:scale-[102%] active:scale-95"
              onClick={onClose}
            >
              {t("workspace.add_member_dialog.cancel")}
            </button>
            <button
              className={`flex w-fit items-center justify-center rounded-lg p-2.5 px-6 text-sm font-semibold text-white hover:scale-[102%] active:scale-95 ${
                !newMemberEmail?.length || !validateEmail(newMemberEmail)
                  ? "bg-dark-grey text-light-grey"
                  : "bg-primary"
              }`}
              onClick={handleAddMember}
            >
              {t("workspace.add_member_dialog.add_member")}
              {isLoading && (
                <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddMemberDialog;
