// hooks/useRemovePaymentMethod.ts

import { useFetcher } from "@axios/fetcher";

/**
 * Custom hook to remove a payment method.
 */
export function useRemovePaymentMethod() {
  const { fetcher } = useFetcher();

  const removePaymentMethod = async (paymentMethodId: string) => {
    try {
      await fetcher(
        `/api/stripe/payment-methods/${paymentMethodId}/delete`,
        "post",
        {},
        false,
        true,
      );
    } catch (error) {
      console.error("Error removing payment method:", error);
      throw error;
    }
  };

  return { removePaymentMethod };
}
