// LoadingDots.tsx
const LoadingDots = () => (
  <div className="flex items-center justify-center space-x-0.5">
    <div className="animate-bounce-custom size-1.5 rounded-full bg-[var(--Dark)]"></div>
    <div className="animate-bounce-custom animation-delay-200 size-1.5 rounded-full bg-[var(--Dark)]"></div>
    <div className="animate-bounce-custom animation-delay-400 size-1.5 rounded-full bg-[var(--Dark)]"></div>
  </div>
);

export default LoadingDots;
