// src/containers/settings/channel-settings/RemoveChannelDialog.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useRemoveChannel } from "@/axios/mutations"; // <-- Adjust path as needed
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { Channel } from "@/types";
import { UnlinkWhiteIcon, Loader } from "@/assets/icons"; // Ensure Loader is available

interface RemoveChannelDialogProps {
  isOpen: boolean;
  onClose: () => void;
  channel: Channel;
}

const RemoveChannelDialog: React.FC<RemoveChannelDialogProps> = ({
  isOpen,
  onClose,
  channel,
}) => {
  const { t } = useTranslation();
  const { removeChannel } = useRemoveChannel();
  const { refetchChannels } = useWorkspaceContext();

  // Local loading state to indicate the removal is in progress
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = async () => {
    if (!channel || !channel.channel_id) return;
    setIsLoading(true);
    try {
      await removeChannel({
        workspace_id: channel.workspace_id,
        channel_id: channel.channel_id,
      });

      // Optionally, refetch channels before reloading
      await refetchChannels();

      // Reload the page once the removal is complete
      window.location.reload();
    } catch (error) {
      console.error("Error removing channel:", error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-red">
            {t("navbar.channel.disconnect")} — {channel.name}
          </DialogTitle>
          <DialogDescription className="text-base text-primary">
            {t("navbar.channel.disconnect_description")}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="mt-4 space-x-2">
          <Button
            variant="secondary"
            className="hover:brightness-90"
            onClick={onClose}
            disabled={isLoading}
          >
            {t("settings.workspace.cancel")}
          </Button>

          <Button
            variant="destructive"
            onClick={handleRemove}
            className="bg-red text-white hover:bg-red hover:brightness-90"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader className="mr-2 size-3.5 shrink-0 animate-spin stroke-white" />
            ) : (
              <UnlinkWhiteIcon className="mr-1.5 size-4" />
            )}
            {t("navbar.channel.disconnect")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveChannelDialog;
