// useUpdateWorkspaceSettings.ts
import { useFetcher } from "@axios/fetcher";
import { Workspace } from "@types";

function useUpdateAutoHide() {
  const { fetcher } = useFetcher();

  // Function to update the workspace_settings of a workspace
  const updateAutoHide = async (workspace_id: string, auto_hide: boolean) => {
    if (!workspace_id || (auto_hide != false && auto_hide != true)) {
      throw new Error(
        "workspace_id and workspace_settings are required to update a workspace workspace_settings",
      );
    }

    // Make the API request to update the workspace workspace_settings
    const updatedWorkspace = await fetcher(
      `/api/workspaces/${workspace_id}/settings/auto-hide`,
      "patch",
      { auto_hide },
    );

    return updatedWorkspace as Workspace;
  };

  return {
    updateAutoHide,
  };
}

export { useUpdateAutoHide };
