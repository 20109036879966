// useUpdateSource.ts
import { useFetcher } from "@axios/fetcher";
import { User } from "@types";

function useUpdateSource() {
  const { fetcher } = useFetcher();

  const updateSource = async (
    user_id: string,
    source: {
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      referral_id?: string;
    },
  ) => {
    const updatedUser = await fetcher(`/api/users/${user_id}/source`, "patch", {
      source,
    });

    return updatedUser as User;
  };

  return {
    updateSource,
  };
}

export { useUpdateSource };
