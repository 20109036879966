// useGenerateReply.ts

import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

// Define a type for comment-based replies
interface GenerateCommentReplyInput {
  _id_comment: string;
  post_id: string;
  channel_id: string;
  workspace_id: string;
  additional_instruction?: string;
  thread_id?: string; // Include thread_id if needed
}

// Define a type for message-based replies
interface GenerateMessageReplyInput {
  _id_message: string;
  channel_id: string;
  workspace_id: string;
  additional_instruction?: string;
  thread_id?: string; // Include thread_id if needed
}

// Create a union type
export type GenerateReplyInput =
  | GenerateCommentReplyInput
  | GenerateMessageReplyInput;

// The response type remains the same
export type GenerateReplyResponse = string | null;

function useGenerateReply() {
  const { fetcher } = useFetcher(); // Get the custom fetcher
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [generatedReply, setGeneratedReply] =
    useState<GenerateReplyResponse>(null); // Generated reply state

  /**
   * Type guard to determine if input is for a comment reply
   */
  const isCommentInput = (
    input: GenerateReplyInput,
  ): input is GenerateCommentReplyInput => {
    return (input as GenerateCommentReplyInput)._id_comment !== undefined;
  };

  /**
   * Function to generate a reply for either a comment or a message.
   * @param input - The input data containing either _id_comment or _id_message along with other required fields.
   * @returns The generated reply text or null in case of an error.
   */
  const generateReply = async (
    input: GenerateReplyInput,
  ): Promise<GenerateReplyResponse> => {
    // Reset the state for new requests
    setIsLoading(true);
    setError(null);
    setGeneratedReply(null);

    try {
      // Determine the type of input
      let payload: any = {
        channel_id: input.channel_id,
        workspace_id: input.workspace_id,
        additional_instruction: input.additional_instruction,
        thread_id: input.thread_id,
      };

      if (isCommentInput(input)) {
        // Input is for a comment
        payload._id_comment = input._id_comment;
        payload.post_id = input.post_id;
      } else {
        // Input is for a message
        payload._id_message = input._id_message;
        // Note: post_id is not required for messages based on the union type
      }

      // Make the API request to generate a reply
      const response = await fetcher(
        `/api/assistant/generate-reply`,
        "post",
        payload,
      );

      // Save the generated reply in the state
      const reply = response as GenerateReplyResponse;
      setGeneratedReply(reply);

      // Return the reply object directly to the caller
      return reply;
    } catch (err: any) {
      // Handle errors and set error state
      setError(err.message || "An unexpected error occurred.");
      return null; // Return null in case of error
    } finally {
      // End the loading state
      setIsLoading(false);
    }
  };

  return {
    generateReply, // Function to trigger the API call
    isLoading, // Loading state for UI feedback
    error, // Error state for UI feedback
    generatedReply, // The generated reply after a successful API call
  };
}

export { useGenerateReply };
