// components/billing-settings/InvoicesTable.tsx

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Invoice } from "@/types";
import { useTranslation } from "react-i18next";

/**
 * Props for InvoicesTable component.
 */
type InvoicesTableProps = {
  invoices: Invoice[];
};

/**
 * Displays a table of invoices for the subscription.
 * @param invoices - Array of Invoice objects.
 */
const InvoicesTable = ({ invoices }: InvoicesTableProps) => {
  const { t } = useTranslation();

  if (!invoices || invoices.length === 0) {
    return (
      <p className="text-left text-sm font-medium text-dark-grey">
        {t("billing.invoices.no_invoices")}
      </p>
    );
  }

  return (
    <div className="overflow-x-auto font-medium text-primary">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("billing.invoices.date")}</TableHead>
            <TableHead>{t("billing.invoices.amount")}</TableHead>
            <TableHead>{t("billing.invoices.status")}</TableHead>
            <TableHead>{t("billing.invoices.invoice")}</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell>
                {new Date(invoice.date * 1000).toLocaleDateString("FR")}
              </TableCell>

              <TableCell>
                {(invoice.amount_due / 100).toLocaleString("FR", {
                  style: "currency",
                  currency: invoice.currency.toUpperCase(),
                })}
              </TableCell>

              <TableCell className="capitalize">
                {t(
                  `billing.invoices.statuses.${invoice.status}`,
                  invoice.status,
                )}
              </TableCell>

              <TableCell>
                {invoice.hosted_invoice_url ? (
                  <a
                    href={invoice.hosted_invoice_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark-blue hover:underline"
                  >
                    {t("billing.invoices.view_invoice")}
                  </a>
                ) : (
                  "N/A"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoicesTable;
