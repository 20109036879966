/* eslint-disable react-hooks/rules-of-hooks */
// useTaskContext.ts

import { useContext } from "react";
import { CommentsContext } from "@/containers/comments/comments.context";
import { ConversationsContext } from "@/containers/messages/messages.context";
import { TaskContextType } from "../types/task-context.type";

export const useTaskContext = (): TaskContextType => {
  const commentsContext = useContext(CommentsContext);
  if (commentsContext) {
    return commentsContext;
  }

  const messagesContext = useContext(ConversationsContext);
  if (messagesContext) {
    return messagesContext;
  }

  throw new Error(
    "useTaskContext must be used within a CommentsProvider or MessagesProvider",
  );
};
