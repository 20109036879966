// DeletePreSavedReplyDialog.tsx
import { Loader } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useTranslation } from "react-i18next";

interface DeletePreSavedReplyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  replyText?: string; // optional, if you want to show which reply is being deleted
  loading?: boolean; // if you want to disable the buttons while deleting
}

const DeletePreSavedReplyDialog = ({
  isOpen,
  onClose,
  onConfirm,
  replyText,
  loading = false,
}: DeletePreSavedReplyDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-4">
            {t("workspace.pre_saved_replies.confirm_delete_title")}
          </DialogTitle>

          <DialogDescription>
            {t("workspace.pre_saved_replies.confirm_delete_message", {
              reply: replyText,
            })}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="mt-4">
          <Button onClick={onClose} disabled={loading} className="bg-primary">
            {t("workspace.pre_saved_replies.cancel")}
          </Button>

          <Button
            variant="destructive" // if you have a "destructive" variant
            onClick={onConfirm}
            disabled={loading}
            className="bg-red hover:bg-red hover:brightness-90"
          >
            {t("workspace.pre_saved_replies.delete")}
            {loading && (
              <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePreSavedReplyDialog;
