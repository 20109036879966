import { OnboardingSkeleton } from "@/containers/onboarding/OnboardingSekeleton";
import { ErrorPage } from "@components/ErrorPage";
import Onboarding from "@containers/onboarding/Onboarding";

import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateWithParams } from "@/utils/navigateWithParams";

const OnboardingPage = () => {
  const { user, isLoadingUser, isLoadingWorkspaces, workspaces } =
    useUserContext();

  const navigate = useNavigate();

  // Combine loading states
  const isLoading =
    !user || isLoadingUser || isLoadingWorkspaces || !workspaces;

  useEffect(() => {
    if (!isLoading && user?.onboarding_done) {
      navigateWithParams("/", navigate, location.search);
    }
  }, [user, navigate, isLoading]);

  return (
    <AppWrapper
      isLoading={isLoading}
      navBar={false}
      loadingSkeleton={<OnboardingSkeleton />}
    >
      <Onboarding />
    </AppWrapper>
  );
};

export default OnboardingPage;

export const Catch = () => {
  return <ErrorPage />;
};
