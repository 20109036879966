import { TaskList, ConversationPanel, AIPanel } from "@/components";
import { useConversationsContext } from "./messages.context";
import { useNavBar } from "@/layouts/NavBar.context";
import { useWindowWidth } from "@/utils/useWindowWidth";
// import PlanRestrictionAlertDialog from "@/layouts/app-wrapper/PlanRestrictionAlertDialog";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { navigateWithParams } from "@/utils/navigateWithParams";

/**
 * Renders the Messages page with a task list, conversation panel,
 * and an AI suggestions panel.
 */
export const Messages = () => {
  // const navigate = useNavigate();
  // const { t } = useTranslation();

  const { isCollapsed } = useNavBar();

  const width = useWindowWidth();
  const isBelow1440 = width < 1440;

  const {
    items: conversations,
    openedItemId: openedConversationId,
    activeTab,
    AiPanelIsOpen,
  } = useConversationsContext();

  const selectedMessage = conversations.find(
    (message) => message._id === openedConversationId,
  );

  // Determine widths based on conditions
  const taskListWidth = isCollapsed && !isBelow1440 ? 450 : 350;
  const aiPanelWidth = AiPanelIsOpen ? 300 : 0;

  // Calculate the available width for ConversationPanel
  const availableWidth =
    width - taskListWidth - (AiPanelIsOpen ? aiPanelWidth : 0);

  return (
    <div className="flex h-screen w-full overflow-hidden">
      {/* PlanRestrictionAlertDialog */}
      {/* <PlanRestrictionAlertDialog
        requiredPlanId={1} // Plan 2 (Pro) is required
        message={t("plan_restriction_dialog.messages.need_plan_pro")}
        trigger={true}
        onClose={() => navigateWithParams("/", navigate, location.search)}
      /> */}

      <div
        className={`h-full ${isCollapsed && !isBelow1440 ? "w-[450px]" : "w-[350px]"}`}
      >
        <TaskList title={"Messages"} type="message" />
      </div>

      <div className={`w-[${availableWidth}px] min-w-[580px]`}>
        {selectedMessage && (
          // Usage in ConversationPanel component
          <ConversationPanel type={"message"} conversation={selectedMessage} />
        )}
      </div>

      {activeTab == "to-do" && <AIPanel type="conversation" />}
    </div>
  );
};
