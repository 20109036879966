// settings/Settings.tsx
import Skeleton from "react-loading-skeleton";
import BillingSettingsSkeleton from "./billing-settings/BillingSettingsSkeleton";

const baseColor = "#F2F4F7";
const highlightColor = "#F9FAFB";

/**
 * Renders the SettingsSkeleton page with sidebar navigation for different settings sections.
 */
export const SettingsSkeleton = () => {
  return (
    <div className="flex h-screen">
      {/* Sidebar for navigation */}
      <div className="flex w-[270px] flex-col space-y-3 bg-white p-7 pb-10 pt-[100px]">
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={200}
          height={50}
        />
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={200}
          height={50}
        />
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={200}
          height={50}
        />
        <Skeleton
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={200}
          height={50}
        />
      </div>

      {/* Main content area */}
      <main className="flex-1 p-5">
        <BillingSettingsSkeleton />
      </main>
    </div>
  );
};

export default SettingsSkeleton;
