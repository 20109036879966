// src/contexts/NavBarContext.tsx
import { useCommentsTodoCount } from "@/axios/queries/useCommentsTodoCount";
import { useMessagesTodoCount } from "@/axios/queries/useMessagesTodoCount";
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useWorkspaceContext } from "./workspace-provider/useWorkspaceContext";
import { useWebSocket } from "./WebSocketContext";

type NavBarContextType = {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  commentsTodoCount: number;
  messagesTodoCount: number;
  refetchCountTodo: () => void;
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  setAddChannelPopupIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addChannelPopupIsOpen: boolean;
};

const NavBarContext = createContext<NavBarContextType | undefined>(undefined);

export const NavBarProvider = ({ children }: { children: ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { currentWorkspace } = useWorkspaceContext();

  // 1) SWR queries for "comments todo" and "messages todo"
  const { data: commentsData, refetch: refetchComments } = useCommentsTodoCount(
    currentWorkspace?.workspace_id as string,
  );

  const { data: messagesData, refetch: refetchMessages } = useMessagesTodoCount(
    currentWorkspace?.workspace_id as string,
  );

  // 2) Derive the counts (default to 0 if undefined)
  const commentsTodoCount = commentsData?.count || 0;
  const messagesTodoCount = messagesData?.count || 0;

  // 3) A combined refetch if needed
  const refetchCountTodo = async () => {
    await refetchComments();
    await refetchMessages();
  };

  // Local states for NavBar
  const [isHovered, setIsHovered] = useState(false);
  const [addChannelPopupIsOpen, setAddChannelPopupIsOpen] = useState(false);

  // 4) Get the shared socket
  const { socket } = useWebSocket();

  // 5) Subscribe to "commentsUpdated" and "messagesUpdated"
  useEffect(() => {
    // Only proceed if we have a socket and a valid workspace
    if (!socket || !currentWorkspace?.workspace_id) return;

    // If needed, ask server to join the workspace room
    // (only do this if your backend requires an explicit join)
    socket.emit("joinWorkspace", {
      workspace_id: currentWorkspace.workspace_id,
    });

    // Handlers that simply refetch counts
    const handleCommentsUpdated = () => {
      // console.info("[NavBarContext] 'commentsUpdated' => refetching counts...");
      refetchComments();
    };

    const handleMessagesUpdated = () => {
      // console.info("[NavBarContext] 'messagesUpdated' => refetching counts...");
      refetchMessages();
    };

    // Subscribe to these events
    socket.on("commentsUpdated", handleCommentsUpdated);
    socket.on("messagesUpdated", handleMessagesUpdated);

    // Cleanup on unmount or workspace change
    return () => {
      socket.off("commentsUpdated", handleCommentsUpdated);
      socket.off("messagesUpdated", handleMessagesUpdated);
    };
  }, [
    socket,
    currentWorkspace?.workspace_id,
    refetchComments,
    refetchMessages,
  ]);

  return (
    <NavBarContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        commentsTodoCount,
        messagesTodoCount,
        refetchCountTodo,
        isHovered,
        setIsHovered,
        addChannelPopupIsOpen,
        setAddChannelPopupIsOpen,
      }}
    >
      {children}
    </NavBarContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte plus facilement
export const useNavBar = () => {
  const context = useContext(NavBarContext);

  if (!context) {
    throw new Error(
      "useNavBar doit être utilisé à l'intérieur d'un NavBarProvider",
    );
  }
  return context;
};
