// App.tsx
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { changeLanguage } from "@services/lang/i18n";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { useAdminStore } from "@redux/store";
import { useUpdateLanguage } from "@axios/mutations";
import { ScreenLoader } from "@components/ScreenLoader";
import { ErrorPage } from "@components/ErrorPage";
import DashboardPage from "@pages/DashboardPage";
import LoginPage from "@pages/LoginPage";
import OnboardingPage from "@pages/OnboardingPage";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspaceContext } from "./layouts/workspace-provider/useWorkspaceContext";
import MessagesPage from "./pages/MessagesPage";
import CommentsPage from "./pages/CommentsPage";
import { CommentsProvider } from "./containers/comments/comments.context";
import { ConversationsProvider } from "./containers/messages/messages.context";
import SettingsPage from "./pages/SettingsPage";
import { Helmet } from "react-helmet";
import LogoutPage from "./pages/LogoutPage";
import { getBaseLanguage } from "./utils/getBaseLanguage";
import { navigateWithParams } from "./utils/navigateWithParams";
import { useAnalytics } from "@segment/analytics-react";
import { useSubscriptionContext } from "./layouts/subscription-provider/SubscriptionProvider";
import useSessionTracking from "./hooks/useSessionTracking";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import SocialLoginErrorPage from "./pages/SocialLoginErrorPage";

const App = () => {
  const { i18n, t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const { user, isLoadingUser, isLoadingWorkspaces } = useUserContext();
  const { isLoadingChannels, currentWorkspace } = useWorkspaceContext();

  const { subscriptionDetails } = useSubscriptionContext();

  const { updateLanguage } = useUpdateLanguage();
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const setAdmin = useAdminStore((state) => state.setAdmin);
  const setUserId = useAdminStore((state) => state.setUserId);

  const { page, identify, group } = useAnalytics();
  useSessionTracking(user?.user_id as string);

  const isAppLoading = useMemo(() => {
    return (
      isLoadingUser ||
      !user ||
      isLoadingWorkspaces ||
      (!currentWorkspace && user?.email_verified != false) ||
      isLoadingChannels
    );
  }, [
    isLoadingUser,
    user,
    isLoadingWorkspaces,
    currentWorkspace,
    isLoadingChannels,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("user_id");
    if (user_id) {
      setUserId(user_id);
    }
  }, [setUserId]);

  useEffect(() => {
    if (user?.is_admin) {
      setAdmin(user.is_admin);
    }
  }, [user, setAdmin]);

  useEffect(() => {
    const initializeLanguage = async () => {
      if (!user) return;

      const userLangRaw = user.language;
      const userLang = getBaseLanguage(userLangRaw);

      const browserLangRaw =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language || null;
      const browserLang = getBaseLanguage(browserLangRaw);

      const supportedLangs = ["en", "fr"]; // Adjust based on your supported languages
      const preferredLang = userLang || browserLang || "fr";
      const languageCode = supportedLangs.includes(preferredLang)
        ? preferredLang
        : "fr";

      if (i18n.language !== languageCode) {
        await changeLanguage(languageCode);
      }

      if (user.user_id && user.language != languageCode) {
        await updateLanguage(user.user_id, languageCode);
      }

      document.documentElement.lang = languageCode;
      setIsLanguageInitialized(true); // Set the flag to true after initialization
    };

    // Run only once when user data is loaded
    if (user && !isLanguageInitialized) {
      void initializeLanguage();
    }
  }, [user, updateLanguage, i18n.language, i18n, isLanguageInitialized]);

  // Handle Onboarding Redirection
  useEffect(() => {
    if (
      isAuthenticated &&
      !isLoadingUser &&
      user &&
      (user.email_verified ||
        user.primary_connection != "Username-Password-Authentication") &&
      !user.onboarding_done &&
      location.pathname !== "/onboarding"
    ) {
      navigateWithParams("/onboarding", navigate, location.search, {
        replace: true,
      });
    }
  }, [isAuthenticated, isLoadingUser, user, location.pathname, navigate]);

  useEffect(() => {
    if (user?.user_id) {
      const { email, name, user_id, created_at, source, referral_id } = user;

      identify(user_id, {
        email: email,
        name: name,
        language: user.language,
        signup_date: created_at,
        utm_source: source?.utm_source || null,
        utm_campaign: source?.utm_source || null,
        utm_medium: source?.utm_source || null,
        referrer: referral_id,
      });
    }
  }, [user, identify]);

  useEffect(() => {
    if (!currentWorkspace) return;
    if (!user?.user_id) return;

    if (currentWorkspace?.workspace_id) {
      const { workspace_id, name, created_at, source, referral_id } =
        currentWorkspace;

      group(workspace_id, {
        name: name,
        plan: subscriptionDetails?.plan_id
          ? subscriptionDetails.plan_id.toString()
          : null,
        workspaceCreationDate: created_at,
        utm_source: source?.utm_source || null,
        utm_campaign: source?.utm_source || null,
        utm_medium: source?.utm_source || null,
        referrer: referral_id,
      });
    }
  }, [currentWorkspace, group]);

  useEffect(() => {
    // Envoie un événement "page" chaque fois que le chemin change
    page(undefined, document.title, {
      path: location?.pathname,
      url: location?.pathname,
      workspaceId: currentWorkspace?.workspace_id,
    });
  }, [location.pathname, page]);

  // console.debug("Loading:", {
  //   isAppLoading,
  //   isLoadingUser,
  //   user,
  //   isLoadingChannels,
  //   isLoadingWorkspaces,
  //   email_verified: user?.email_verified,
  // });

  if (
    location.pathname !== "/onboarding" &&
    location.pathname !== "/logout" &&
    isAppLoading &&
    isAuthenticated
  ) {
    return <ScreenLoader />;
  }

  return (
    <>
      {/* Dynamic Head Content */}
      <Helmet>
        <html lang={i18n.language} />
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />

        {/* Open Graph Metadata */}
        <meta property="og:image" content="assets/openGraph.png" />
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
        <meta property="og:url" content="/" />
        <meta property="og:type" content="website" />

        {/* Add other dynamic meta tags as needed */}
      </Helmet>

      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />

        <Route path="/social-login-error" element={<SocialLoginErrorPage />} />

        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />

        <Route
          path="/messages"
          element={
            <ConversationsProvider>
              <MessagesPage />
            </ConversationsProvider>
          }
        />
        <Route
          path="/comments"
          element={
            <CommentsProvider>
              <CommentsPage />
            </CommentsProvider>
          }
        />
        {/* Settings Page with nested routes */}
        <Route path="/settings/*" element={<SettingsPage />} />

        {/* Catch-all route for 404s */}
        <Route
          path="*"
          element={
            <ErrorPage
              title={t("error.404.title")}
              message={t("error.404.message")}
            />
          }
        />
      </Routes>
    </>
  );
};

export default App;
