// src/hooks/useNango.ts

import Nango from "@nangohq/frontend";
import { useCallback } from "react";

const nango = new Nango({
  publicKey: import.meta.env.VITE_NANGO_PUBLIC_KEY,
  debug: true,
});

export const useNango = () => {
  const connectWithNango = useCallback(
    async (
      integration_id: string,
      workspace_id: string,
      authorization_params: Record<string, any> = {},
    ) => {
      console.info("additionalParams", authorization_params);
      console.info(
        `Starting Nango connection for integration_id: ${integration_id}, workspace_id: ${workspace_id}`,
      );

      try {
        // Attempt to connect with Nango
        const result = await nango.auth(integration_id, workspace_id, {
          detectClosedAuthWindow: true,
          authorization_params,
        });

        console.info("Nango connection successful.", { result });
        return { success: true, data: result };
      } catch (error: any) {
        console.error("An error occurred during Nango connection.", { error });

        if (error.type === "windowClosed") {
          console.warn(
            "The authorization window was closed before the flow was completed.",
          );
          return { success: false, error: "popup_closed" };
        } else if (error.message.includes("blocked")) {
          console.warn(
            "Authorization window was possibly blocked by the browser.",
          );
          return { success: false, error: "popup_blocked" };
        } else if (error.type === "windowIsOpened") {
          console.warn("The authorization window is already opened.");
          return { success: false, error: "popup_opened" };
        } else {
          console.error(
            "An unexpected error occurred during the Nango connection.",
            error.type,
          );
          return { success: false, error: "auth_failed" };
        }
      }
    },
    [],
  );

  return { connectWithNango };
};
