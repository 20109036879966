// AppWrapper.tsx
import { NavBar } from "@components";
import { Crisp } from "crisp-sdk-web";
import { cloneElement, ReactElement, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

import LogRocket from "logrocket";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import SubscriptionAlertDialog from "./SubscriptionAlertDialog";
import ErrorMobile from "@/components/ErrorMobile";
import WorkspaceInvitationPopup from "./WorkspaceInvitationPopup";
import ChannelsAlertDialog from "./ChannelsAlertDialog"; // Import the new component
// import { MaintenanceAlert } from "@/components/MaintenanceBanner";

type AppWrapperProps = {
  isLoading?: boolean;
  children?: ReactElement;
  mobileSupported?: boolean;
  loadingSkeleton?: ReactElement;
  navBar?: boolean;
};

const AppWrapper = ({
  isLoading: isLoadingProp = false,
  children,
  mobileSupported = false,
  navBar = true,
  loadingSkeleton,
}: AppWrapperProps) => {
  const location = useLocation();

  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      // console.info("Identifying user in LogRocket", { user: user?.email });
      LogRocket.identify(user.user_id, {
        name: user.name,
        email: user.email,
        subscriptionType: "pro",
      });
    }
  }, [user]);

  useEffect(() => {
    const pathWithoutCrisp = ["/comments", "/messages"];
    const isPathExcluded = pathWithoutCrisp.some((excludedPath) =>
      location.pathname.includes(excludedPath),
    );

    if (user) {
      // console.info("Configuring Crisp for user", { user: user?.email });
      Crisp.configure("6a975e2b-e56f-4161-b6ec-e3e13a870ebc");
      Crisp.setTokenId(user.user_id);
      Crisp.user.setEmail(user.email);
      Crisp.user.setNickname(user.name);
      Crisp.setZIndex(isPathExcluded ? -10 : 999999);
      Crisp.setHideOnMobile(true);
    } else {
      console.warn("No user found for Crisp, hiding Crisp");
      Crisp.setZIndex(-10);
    }
  }, [user, location.pathname]);

  if (
    isMobile &&
    !mobileSupported &&
    import.meta.env.VITE_NODE_ENV !== "development" &&
    !user?.is_admin
  ) {
    console.warn("Mobile not supported for this route");
    return <ErrorMobile />;
  }

  return (
    <div className="flex h-screen bg-light-grey">
      {/* Using Tailwind classes */}
      {navBar && <NavBar />}

      <div
        className={`${!isLoadingProp && "overflow-y-auto"} grow scrollbar-hide`}
      >
        {/* This triggers the maintenance toast automatically on mount */}
        {/* <MaintenanceAlert /> */}

        {/* This allows the content to expand and fill the space */}
        {isLoadingProp ? (
          loadingSkeleton ? (
            cloneElement(loadingSkeleton)
          ) : (
            <></>
          )
        ) : (
          children && cloneElement(children)
        )}
      </div>

      {location.pathname !== "/onboarding" &&
      location.pathname !== "/verify-email" ? (
        <>
          {/* Show popup for pending workspace invitations */}
          <WorkspaceInvitationPopup />

          {!user?.is_admin && (
            <>
              <SubscriptionAlertDialog />
              <ChannelsAlertDialog />
            </>
          )}
        </>
      ) : (
        <>
          {/* Show popup for pending workspace invitations */}
          <WorkspaceInvitationPopup />
        </>
      )}
    </div>
  );
};

export { AppWrapper };
