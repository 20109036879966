/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  AiIcon,
  SendButtonAiChat,
  BlablaBrainIcon,
  CloseAiChatIcon,
  AiIconWhite,
  EditIcon,
} from "@assets/icons";
import { CustomTextarea } from "./CustomTextArea";
import LoadingDots from "../LoadingDots";
import { Img } from "react-image";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { useTaskContext } from "@/layouts/useTask.context";
import { isComment } from "@/utils/isComment";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";

type AIPanelProps = {
  type: "comment" | "conversation";
};

export const AIPanel = ({ type }: AIPanelProps) => {
  const { t } = useTranslation();

  const { setIsCollapsed } = useNavBar();

  const [input, setInput] = useState<string>("");

  const {
    openedItemId,
    selectedItemIds,
    items,
    addMessage,
    messages,
    interactWithAssistant,
    isStreaming,
    resetChat,
    setAIPanelIsOpen,
    AiPanelIsOpen,
    setReply,
    setSelectedGeneratedReplies,
    selectedGeneratedReplies,
    removeSelectedGeneratedReply,
  } = useTaskContext();

  const openedItem = items.find((item) => item._id === openedItemId);
  const { currentWorkspace } = useWorkspaceContext();
  const chatWindowRef = useRef<HTMLDivElement>(null);

  // Create a ref to hold the previous workspace_id
  const prevWorkspaceIdRef = useRef(currentWorkspace?.workspace_id);

  const togglePanel = () => {
    if (selectedItemIds.length == 0 && !openedItemId) {
      return;
    }

    setAIPanelIsOpen(!AiPanelIsOpen);
  };

  const sendMessage = () => {
    if (!openedItemId || !openedItem) {
      console.error("No item is currently opened or found");
      return;
    }
    if (input.trim() && !isStreaming[openedItemId]) {
      const latestGeneratedReply = messages[openedItemId]
        ?.slice()
        .reverse()
        .find((msg) => msg.type === "generatedReply");

      if (isComment(type == "comment" ? "comment" : "message", openedItem)) {
        interactWithAssistant({
          new_message: input,
          comment_id: openedItemId,
          post_id: openedItem.post_id,
          channel_id: openedItem.channel.channel_id,
          workspace_id: openedItem.channel.workspace_id,
          ai_reply: latestGeneratedReply?.text,
        });
      } else {
        interactWithAssistant({
          new_message: input,
          conversation_id: openedItemId,
          channel_id: openedItem.channel.channel_id,
          workspace_id: openedItem.channel.workspace_id,
          ai_reply: latestGeneratedReply?.text,
        });
      }
      setInput("");
    }
  };

  const updateSelectedGeneratedReplies = (
    itemId: string,
    replyText: string,
  ) => {
    if (selectedGeneratedReplies[itemId] === replyText) {
      removeSelectedGeneratedReply(itemId);
    } else {
      setSelectedGeneratedReplies((prev) => ({
        ...prev,
        [itemId]: replyText,
      }));
    }
  };

  useEffect(() => {
    if (openedItemId && !selectedItemIds.length && !AiPanelIsOpen) {
      resetChat(openedItemId);
      setInput("");
    }
    if (!openedItemId && !selectedItemIds.length) {
      setAIPanelIsOpen(false);
    }
  }, [openedItemId, selectedItemIds]);

  useEffect(() => {
    if (
      currentWorkspace &&
      currentWorkspace?.workspace_id !== prevWorkspaceIdRef.current
    ) {
      setAIPanelIsOpen(false);
      selectedItemIds.forEach((item) => {
        resetChat(item);
      });

      // Update the ref to the new workspace_id
      prevWorkspaceIdRef.current = currentWorkspace?.workspace_id;
    }
  }, [currentWorkspace, type]);

  useEffect(() => {
    if (
      openedItemId &&
      !isStreaming[openedItemId] &&
      AiPanelIsOpen &&
      !messages[openedItemId]?.length
    ) {
      addMessage(openedItemId, {
        text: t("tasks.ai_panel.initial_message"),
        from: "ai",
        type: "message",
      });
    }
  }, [AiPanelIsOpen, openedItemId, selectedItemIds]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages, openedItemId]);

  useEffect(() => {
    if (AiPanelIsOpen) {
      setIsCollapsed(true);
    }
  }, [AiPanelIsOpen]);

  return (
    <div
      className={`h-full shrink-0 break-words bg-white ${AiPanelIsOpen ? "w-[300px]" : "w-[50px]"}`}
    >
      {AiPanelIsOpen ? (
        <div className="flex h-full flex-col px-5 pb-9 pt-8">
          <div className="mb-6 ml-2 flex items-center justify-between">
            <BlablaBrainIcon />
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <button onClick={togglePanel}>
                    <CloseAiChatIcon className={`size-4 cursor-pointer`} />
                  </button>
                </TooltipTrigger>

                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  {t("tasks.ai_panel.hide_blabla_brain")}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div
            className="flex-1 overflow-y-auto scrollbar-hide"
            ref={chatWindowRef}
          >
            {!messages[openedItemId || ""]?.length &&
              isStreaming[openedItemId || ""] && (
                <div className="m-4 flex justify-center">
                  <LoadingDots />
                </div>
              )}

            {messages[openedItemId || ""]?.map((message, index) => {
              const isAiMessage = message.from === "ai";
              const isUserMessage = message.from === "user";
              const isLastMessage =
                index === messages[openedItemId || ""]!.length - 1;

              return (
                <div key={index}>
                  {isUserMessage && (
                    <div className="mb-3 rounded-[10px] border border-light-grey bg-white p-2 text-left text-base font-medium text-primary">
                      <div className="flex items-center">
                        <Img
                          src={
                            openedItem?.channel?.profile_picture_url as string
                          }
                          alt="Channel Avatar"
                          className="mr-3 mt-0.5 size-10 shrink-0 rounded-full"
                        />
                        <div>{message.text}</div>
                      </div>
                    </div>
                  )}
                  {isAiMessage && message.text && (
                    <div className="mb-3 rounded-[10px] bg-light-grey p-2.5 text-left">
                      {message.type === "generatedReply" ? (
                        <div>
                          <div className="flex items-center text-base font-medium">
                            <AiIconWhite className="mb-3 size-10 shrink-0 fill-primary" />
                            <span className="ml-3 text-primary">
                              {t("tasks.ai_panel.generated_reply_title")}
                            </span>
                          </div>

                          <div
                            onClick={() => {
                              if (selectedItemIds.length > 0) {
                                updateSelectedGeneratedReplies(
                                  openedItemId as string,
                                  message.text,
                                );
                              } else {
                                setReply(message.text);
                              }
                            }}
                            className={`mt-4 cursor-pointer rounded-lg bg-white p-3 text-primary ${
                              selectedItemIds.length > 0 &&
                              selectedGeneratedReplies[
                                openedItemId as string
                              ] === message.text
                                ? "shadow-[0_0_0_2px_theme('colors.dark-blue')]"
                                : ""
                            }`}
                          >
                            <div className="mb-4 whitespace-pre-line">
                              {message.text}
                            </div>
                            <div className="flex justify-end space-x-3">
                              {selectedItemIds.length > 0 && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateSelectedGeneratedReplies(
                                      openedItemId as string,
                                      message.text,
                                    );
                                  }}
                                  className={`rounded-[8px] px-3 py-1.5 text-sm font-semibold text-white hover:brightness-110 ${
                                    selectedGeneratedReplies[
                                      openedItemId as string
                                    ] === message.text
                                      ? "bg-dark-grey"
                                      : "bg-dark-blue"
                                  }`}
                                >
                                  {selectedGeneratedReplies[
                                    openedItemId as string
                                  ] === message.text
                                    ? t("tasks.ai_panel.deselect")
                                    : t("tasks.ai_panel.select_reply")}
                                </button>
                              )}

                              <TooltipProvider>
                                <Tooltip delayDuration={100}>
                                  <TooltipTrigger asChild>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setReply(message.text);
                                      }}
                                    >
                                      <EditIcon className="cursor-pointer hover:brightness-150" />
                                    </button>
                                  </TooltipTrigger>
                                  <TooltipContent
                                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                                    sideOffset={5}
                                  >
                                    <p>{t("tasks.ai_panel.use_this_text")}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`flex items-center text-base font-medium ${message.type == "error" ? "text-red" : "text-primary"}`}
                        >
                          <AiIconWhite className="mr-3 mt-0.5 size-10 shrink-0" />
                          <div>{message.text}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {isLastMessage && isStreaming[openedItemId || ""] && (
                    <div className="m-8 flex justify-center">
                      <LoadingDots />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-auto flex items-center justify-center">
            <CustomTextarea
              value={input}
              setValue={setInput}
              onSubmit={sendMessage}
            />
            <button
              onClick={sendMessage}
              disabled={!input.trim() || isStreaming[openedItemId || ""]}
            >
              <SendButtonAiChat
                className={`size-9 ${
                  input.trim() && !isStreaming[openedItemId || ""]
                    ? "fill-primary hover:fill-primary/90"
                    : "cursor-not-allowed fill-dark-grey"
                }`}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex justify-center p-2">
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <button
                  onClick={togglePanel}
                  className={`${selectedItemIds.length == 0 && !openedItemId ? "cursor-default" : "cursor-pointer"}`}
                >
                  <AiIcon
                    className={`${selectedItemIds.length == 0 && !openedItemId ? "cursor-default" : "cursor-pointer"}`}
                  />
                </button>
              </TooltipTrigger>

              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                {t("Blabla Brain ✨")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </div>
  );
};
