// useFacebookPages.ts
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { FacebookPage } from "@types";
import { useState } from "react";

interface UseFacebookPagesParams {
  workspace_id: string;
  channel_id?: string; // Optional channel_id to fetch a specific Facebook page
  enabled?: boolean; // To control whether the hook is enabled
}

function useFacebookPages({
  workspace_id,
  channel_id,
  enabled = true,
}: UseFacebookPagesParams) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  // Construct the cache key based on whether channel_id is provided
  const cacheKey = channel_id
    ? `/api/facebook/accounts/${workspace_id}?channel_id=${channel_id}`
    : `/api/facebook/accounts/${workspace_id}`;

  const { data, error, mutate, isValidating } = useSWR<
    FacebookPage | FacebookPage[]
  >(
    enabled ? cacheKey : null, // Fetch only if enabled
    (url: string) => fetcher(url, "get", null, false, enabled),
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<FacebookPage[] | FacebookPage> => {
    setIsRefetching(true);
    const updatedData = (await mutate()) as FacebookPage[] | FacebookPage; // Try to get updated data
    setIsRefetching(false);

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as FacebookPage | FacebookPage[],
    isLoading: !data && !error && !isValidating, // True if data is loading for the first time
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch,
  };
}

export { useFacebookPages };
