// WorkspaceInvitationPopup.tsx
import React, { useEffect, useState } from "react";
import PopupComponent from "@/components/popup/PopupComponent";
import { User, WorkspaceDetails } from "@types";

import { useUpdateMembershipStatus } from "@/axios/mutations";
import { useUserContext } from "../user-provider/useUserContext";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "@segment/analytics-react";

const WorkspaceInvitationPopup: React.FC = ({}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const { user, workspaces, refetchWorkspaces } = useUserContext();

  const [showWorkspacePopup, setShowWorkspacePopup] = useState(false);
  const [pendingWorkspace, setPendingWorkspace] =
    useState<WorkspaceDetails | null>(null); // State to track the pending workspaces

  const { updateMembershipStatus } = useUpdateMembershipStatus();

  // Check if there is a pending workspace invitation
  useEffect(() => {
    if (workspaces) {
      const pendingWorkspace = workspaces.find(
        (ws) => ws.current_user_status === "pending",
      );

      if (pendingWorkspace) {
        setPendingWorkspace(pendingWorkspace);
        setShowWorkspacePopup(true);
      }
    }
  }, [workspaces, user]);

  // Confirm acceptance of workspace invitation
  const handleAcceptInvitation = async () => {
    if (pendingWorkspace) {
      try {
        await updateMembershipStatus(
          pendingWorkspace.current_user_membership_id,
          "active",
        ); // Update the membership status to 'active'

        const { user_id, source, referral_id } = user as User;

        track("Join Workspace", {
          user_id: user_id,
          workspace_id: pendingWorkspace._id,
          utm_source: source?.utm_source,
          utm_medium: source?.utm_medium,
          utm_campaign: source?.utm_campaign,
          referral_id: referral_id,
          timestamp: new Date().toISOString(),
        });

        await refetchWorkspaces();
        setShowWorkspacePopup(false); // Close popup
      } catch (error) {
        console.error("Failed to accept workspace invitation", error);
      }
    }
  };

  // Decline the invitation
  const handleDeclineInvitation = async () => {
    if (pendingWorkspace) {
      try {
        await updateMembershipStatus(
          pendingWorkspace.current_user_membership_id,
          "declined",
        ); // Update the membership status to 'declined'
        await refetchWorkspaces();
        setShowWorkspacePopup(false); // Close popup
      } catch (error) {
        console.error("Failed to decline workspace invitation", error);
      }
    }
  };

  return (
    <>
      {showWorkspacePopup && pendingWorkspace && (
        <PopupComponent
          title={t("workspace_invitation.title", {
            name: pendingWorkspace.name,
          })}
          description={t("workspace_invitation.description", {
            name: pendingWorkspace.name,
          })}
          buttonText={t("workspace_invitation.accept")}
          onConfirm={handleAcceptInvitation}
          onCancelButtonText={t("workspace_invitation.decline")}
          onCancel={handleDeclineInvitation}
        />
      )}
    </>
  );
};

export default WorkspaceInvitationPopup;
