// useCreateWorkspace.ts
import { useFetcher } from "@axios/fetcher";
import { Workspace } from "@types";

function useCreateWorkspace() {
  const { fetcher } = useFetcher();

  // Function to create a new workspace
  const createWorkspace = async (
    name: string,
    owner_user_id: string,
    organization_id?: string | null,
  ) => {
    // Validate input explicitly for creation
    if (!name || !owner_user_id) {
      throw new Error(
        "Name and owner_user_id are required to create a workspace",
      );
    }

    // Make the API request to create the workspace
    const newWorkspace = await fetcher(`/api/workspaces`, "post", {
      name,
      owner_user_id,
      organization_id,
    });

    return newWorkspace as Workspace;
  };

  return {
    createWorkspace,
  };
}

export { useCreateWorkspace };
