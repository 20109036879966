import { Attachment } from "@/types/messages.types";
import { useTranslation } from "react-i18next";
import { Img } from "../Img";

/**
 * Helper: Renders a single Attachment in your standardized way.
 * This is the same code you used in `CommentRenderer` – you can unify.
 */
export const RenderAttachment = ({
  attachment,
}: {
  attachment: Attachment;
}) => {
  const { t } = useTranslation();

  if (!attachment?.url) {
    return;
  }

  switch (attachment.type) {
    case "image":
      return (
        <a
          href={attachment.url}
          target="_blank"
          rel="noopener noreferrer"
          className="relative h-[150px] w-fit shrink-0 cursor-pointer overflow-hidden rounded-lg bg-white hover:brightness-90"
        >
          <Img
            src={attachment.url}
            alt="Attachment Thumbnail"
            className="h-[150px] rounded-lg object-cover"
          />
        </a>
      );
    case "video":
      return (
        <a
          href={attachment.url}
          target="_blank"
          rel="noopener noreferrer"
          className="relative h-[150px] w-fit shrink-0 cursor-pointer overflow-hidden rounded-lg bg-white hover:brightness-90"
        >
          <video
            src={attachment.url}
            className="max-h-[150px] w-fit rounded-lg"
            autoPlay
            loop
            muted
            // controls
            // playsInline
          >
            {t("tasks.comments_renderer.video_not_supported")}
          </video>
        </a>
      );
    case "file":
      return (
        <div className="">
          <a
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-dark-blue underline hover:font-semibold hover:brightness-75"
          >
            {attachment.name || t("tasks.comments_renderer.download_file")}
          </a>
        </div>
      );
    case "audio":
      return (
        <a
          href={attachment.url}
          target="_blank"
          rel="noopener noreferrer"
          className="relative h-[150px] w-fit shrink-0 cursor-pointer overflow-hidden rounded-lg bg-white hover:brightness-90"
        >
          <audio controls className="rounded-md">
            <source src={attachment.url} />
            {t("tasks.comments_renderer.audio_not_supported")}
          </audio>
        </a>
      );
    case "link":
      return (
        <div className="">
          <a
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-dark-blue underline hover:font-semibold hover:brightness-75"
          >
            {attachment.name || t("tasks.comments_renderer.open_attachment")}
          </a>
        </div>
      );
    default:
      return (
        <div className="">
          <a
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-dark-blue underline hover:font-semibold hover:brightness-75"
          >
            {attachment.name || t("tasks.comments_renderer.open_attachment")}
          </a>
        </div>
      );
  }
};
