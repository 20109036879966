// src/hooks/useMessagesTodoCount.ts

import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { useState } from "react";

type TodoCountResponse = {
  count: number;
};

function useMessagesTodoCount(workspace_id: string, enabled = true) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false);

  // Determine if we should fetch data
  const shouldFetch = enabled && !!workspace_id;
  const cacheKey = shouldFetch
    ? `/api/messages/${workspace_id}/todo-count`
    : null;

  const { data, error, mutate, isValidating, isLoading } =
    useSWR<TodoCountResponse>(cacheKey, (url: string) =>
      fetcher(url, "get", null, false, enabled),
    );

  const refetch = async (): Promise<TodoCountResponse | undefined> => {
    setIsRefetching(true);
    const updatedData = await mutate();
    setIsRefetching(false);

    if (updatedData) {
      return updatedData;
    } else if (data) {
      return data;
    } else {
      throw new Error("No data available after refetching.");
    }
  };

  return {
    data: data as TodoCountResponse,
    isLoading: (shouldFetch && !data && !error && !isValidating) || isLoading,
    isRefetching,
    error,
    refetch,
  };
}

export { useMessagesTodoCount };
