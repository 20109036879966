// MembersTable.tsx
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { MoreVertical } from "lucide-react";
import { MemberDetails } from "@/types";
import RemoveMemberAlertDialog from "./RemoveMemberAlertDialog";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";

type MembersTableProps = {
  members: MemberDetails[];
  currentUserEmail: string;
  onRemoveMember: (member: MemberDetails) => void;
};

const MembersTable = ({
  members,
  currentUserEmail,
  onRemoveMember,
}: MembersTableProps) => {
  const { t } = useTranslation();

  const [isRemoveAlertOpen, setIsRemoveAlertOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<MemberDetails | null>(
    null,
  );

  const handleOpenRemoveDialog = (member: MemberDetails) => {
    setMemberToRemove(member);
    setIsRemoveAlertOpen(true);
  };

  const handleConfirmRemove = () => {
    if (memberToRemove) {
      onRemoveMember(memberToRemove);
      setIsRemoveAlertOpen(false);
      setMemberToRemove(null);
    }
  };

  return (
    <>
      <div className="flex-1 overflow-y-auto scrollbar-hide">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("workspace.members_table.user")}</TableHead>
              <TableHead>{t("workspace.members_table.email")}</TableHead>
              <TableHead className="text-right">
                {t("workspace.members_table.actions")}
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {members.map((member) => (
              <TableRow key={member.user_id || member.email}>
                <TableCell>{member.name || "-"}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell className="text-right">
                  {member.role !== "owner" && (
                    <Popover>
                      <PopoverTrigger asChild>
                        <button
                          className="hover:brightness-50"
                          onClick={(e) => e.stopPropagation()}
                          aria-label="Options"
                        >
                          <MoreVertical className="size-4 text-dark-grey" />
                        </button>
                      </PopoverTrigger>

                      <PopoverContent className="w-44 rounded-lg border-0 p-0 shadow-lg">
                        <div className="flex flex-col">
                          <Button
                            variant="destructive"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenRemoveDialog(member);
                            }}
                            className="bg-red text-white hover:bg-red hover:brightness-90"
                          >
                            {member.email === currentUserEmail
                              ? t("workspace.members_table.quit_workspace")
                              : t("workspace.members_table.remove")}
                          </Button>
                        </div>
                      </PopoverContent>
                    </Popover>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Remove Member Alert Dialog */}
      <RemoveMemberAlertDialog
        isOpen={isRemoveAlertOpen}
        onClose={() => setIsRemoveAlertOpen(false)}
        onConfirm={handleConfirmRemove}
        memberEmail={memberToRemove?.email}
      />
    </>
  );
};

export default MembersTable;
