// hooks/useSubscriptionDetails.ts

import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { SubscriptionDetails } from "@types";

/**
 * Custom hook to fetch subscription details for a given subscription ID.
 */
export function useSubscriptionDetails(workspace_id: string | undefined) {
  const { fetcher } = useFetcher();

  const shouldFetch = !!workspace_id;

  const cacheKey = shouldFetch
    ? `/api/stripe/subscription/${workspace_id}/details`
    : null;

  const { data, error, mutate, isLoading } = useSWR<SubscriptionDetails>(
    cacheKey,
    (url: string) => fetcher(url, "get"),
    {
      revalidateOnFocus: false, // Prevent revalidation on window focus
      dedupingInterval: 60000, // Adjust as needed (e.g., 60 seconds)
    },
  );

  const refetch = async () => {
    try {
      await mutate();
    } catch (err) {
      console.error("Error refetching subscription details:", err);
    }
  };

  // const isLoading = shouldFetch && !data && !error && isValidating;

  return {
    subscriptionDetails: data,
    isLoading,
    error,
    refetch,
  };
}
