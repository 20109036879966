export const getKey = (options: any) => {
  const params = new URLSearchParams();

  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        // Append key with empty value to represent empty array
        params.append(key, "");
      } else {
        value.forEach((v) => params.append(key, v));
      }
    } else if (value !== null && value !== undefined && value !== "") {
      params.append(key, value as string);
    }
  });

  return `${params.toString()}`;
};
