import * as React from "react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { ArrowSVG, CheckRedCircleIcon, ToggleIcon } from "@/assets/icons";
import { Img } from "../Img";
import { Plus } from "lucide-react";
import AddWorkspaceDialog from "../workspace-settings/AddWorkspaceDialog";
import { useTranslation } from "react-i18next";
import { highlightMatch } from "@/utils/highlightMatch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type WorkspaceSelectorProps = {
  isCollapsed: boolean;
};

export const WorkspaceSelector = ({ isCollapsed }: WorkspaceSelectorProps) => {
  const { t } = useTranslation();

  const { workspaces } = useUserContext();
  const { currentWorkspace, changeWorkspace } = useWorkspaceContext();
  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);

  const filteredWorkspaces = React.useMemo(() => {
    let result;
    if (!searchValue) {
      result = workspaces || [];
    } else {
      const lowerSearch = searchValue.toLowerCase();
      result = (workspaces || []).filter((ws) => {
        const nameMatch = ws.name.toLowerCase().includes(lowerSearch);
        const emailMatch = ws.members.some((member) =>
          member.email.toLowerCase().includes(lowerSearch),
        );
        return nameMatch || emailMatch;
      });
    }

    // Sort the result to place the current workspace at the top
    return result.sort((a, b) => {
      if (a.workspace_id === currentWorkspace?.workspace_id) return -1;
      if (b.workspace_id === currentWorkspace?.workspace_id) return 1;
      return 0;
    });
  }, [searchValue, workspaces, currentWorkspace]);

  React.useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                {isCollapsed ? (
                  // Collapsed view: only show first letter in circle
                  <button className="flex h-[46px] w-full items-center justify-center rounded-[12px] bg-white hover:bg-white">
                    <span className="flex size-[38px] items-center justify-center rounded-full bg-light-grey font-sans text-[16px] font-extrabold uppercase leading-[16px] text-primary">
                      {currentWorkspace?.name.charAt(0)}
                    </span>
                  </button>
                ) : (
                  // Expanded view: show full workspace name
                  <button className="flex h-[46px] w-full items-center justify-between rounded-[12px] bg-white px-4 py-2 hover:bg-white">
                    <span className="overflow-hidden truncate font-sans text-[16px] font-bold text-primary">
                      {currentWorkspace?.name ||
                        t("navbar.workspace_selector.select_workspace")}
                    </span>
                    <Img src={ToggleIcon} alt="Toggle Menu" />
                  </button>
                )}
              </PopoverTrigger>
            </TooltipTrigger>

            {isCollapsed && (
              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                {currentWorkspace?.name ||
                  t("navbar.workspace_selector.select_workspace")}
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>

        <PopoverContent
          className={`absolute z-10 grid gap-1.5 rounded-[12px] bg-white p-3 shadow-lg ${isCollapsed ? "mt-[-4px]" : "mt-2"} border-0`}
          side="bottom"
          sideOffset={isCollapsed ? 0 : 2}
          align={"start"}
          style={{
            boxShadow: "6px 8px 36.9px rgba(0, 0, 0, 0.1)",
            minWidth: "max-content", // Ensure width adjusts to content
            left: isCollapsed ? 15 : 0, // Align to left based on collapse state and workspace length
          }}
        >
          {/* Arrow Shape */}
          <Img
            src={ArrowSVG}
            alt="Arrow"
            className={`absolute top-[-3.5px] ${isCollapsed ? "left-[13px]" : workspaces && workspaces?.length > 1 ? "left-[190px]" : "left-[62px]"}`}
            style={{
              boxShadow: "6px 8px 36.9px rgba(0, 0, 0, 0.1)",
            }}
          />

          <Command>
            <CommandInput
              ref={inputRef}
              placeholder={t("navbar.workspace_selector.search_workspaces")}
              value={searchValue}
              onValueChange={setSearchValue}
            />
            <CommandList className="scrollbar-hide">
              <CommandEmpty>
                {t("navbar.workspace_selector.no_workspace_found")}
              </CommandEmpty>

              <CommandGroup>
                {filteredWorkspaces &&
                  filteredWorkspaces.map((ws) => {
                    const members = ws.members.filter((member) =>
                      member.email
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()),
                    );
                    return (
                      <CommandItem
                        value={`${ws.name} ${ws.members.map((member) => member.email).join(" ")}`}
                        key={ws.workspace_id}
                        onSelect={() => {
                          changeWorkspace(ws.workspace_id);
                          setSearchValue("");
                          setOpen(false);
                        }}
                        className={`relative mt-2.5 flex w-[250px] items-center justify-center rounded-lg border border-light-grey
                      px-2.5 text-primary
                      ${
                        currentWorkspace?.workspace_id === ws.workspace_id
                          ? "!bg-light-grey !text-primary"
                          : "!hover:bg-dark-grey/20 cursor-pointer bg-white"
                      }
                      ${searchValue ? "font-medium" : "font-bold"}
                      ${searchValue && members.length > 0 ? "h-fit flex-col items-center justify-center py-2" : "h-[35px]"}
                      [&[aria-selected="true"]:not(:hover)]:bg-transparent
                      `}
                      >
                        {/* Workspace Name with highlighted matches */}
                        <span className="max-w-[85%] truncate">
                          {highlightMatch(ws.name, searchValue)}
                        </span>

                        {/* Display member emails if searching by email */}
                        {searchValue && members.length > 0 && (
                          <span className="mt-0.5 max-w-[85%] truncate text-sm font-normal text-primary/40">
                            {members.map((member, index) => (
                              <span key={member.user_id}>
                                {highlightMatch(member.email, searchValue)}
                                {index < members.length - 1 && ", "}
                              </span>
                            ))}
                          </span>
                        )}

                        {/* Checkmark icon for selected item */}
                        {currentWorkspace?.workspace_id === ws.workspace_id && (
                          <Img
                            src={CheckRedCircleIcon}
                            alt="Selected"
                            className="absolute right-[-5px] top-[-5px] size-[14px]"
                          />
                        )}
                      </CommandItem>
                    );
                  })}
              </CommandGroup>
            </CommandList>
          </Command>

          <div className="p-1">
            <div className="mb-4 mt-1 border border-light-grey" />
            <Button
              onClick={() => {
                setOpen(false);
                setIsAddWorkspaceModalOpen(true);
              }}
              className={`relative flex h-[40px] w-full cursor-pointer items-center justify-center rounded-lg border border-dark-grey bg-light-grey px-2.5 font-bold text-primary hover:bg-dark-grey hover:brightness-90`}
            >
              <Plus className="mr-2 size-4" />
              {t("navbar.workspace_selector.add_new_workspace")}
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      {/* Add Workspace Dialog */}
      <AddWorkspaceDialog
        isOpen={isAddWorkspaceModalOpen}
        onClose={() => setIsAddWorkspaceModalOpen(false)}
      />
    </>
  );
};
