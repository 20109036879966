import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { WorkspaceDocumentsManager } from "./WorkspaceDocumentsManager";
import { useTranslation } from "react-i18next";
import {
  FileUploaderRegular,
  OutputErrorFile,
  OutputFileEntry,
  OutputFileStatus,
  OutputCollectionState,
  OutputCollectionStatus,
} from "@uploadcare/react-uploader";
import { toast } from "sonner";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useState } from "react";

import "@uploadcare/react-uploader/core.css";

interface Props {
  open: boolean;
  onClose: () => void;
}

export function WorkspaceDocumentsDialog({ open, onClose }: Props) {
  const { t, i18n } = useTranslation();

  const { currentWorkspace, fetchDocuments, uploadDocument } =
    useWorkspaceContext();
  const workspaceId = currentWorkspace?._id;

  // Keep track of already processed file internalIds to avoid duplicate backend calls.
  const [processedIds, setProcessedIds] = useState<Set<string>>(new Set());

  /**
   * Handler for uploader state changes.
   * This callback is triggered with the entire collection state of the uploader.
   */
  const handleUploaderChange = async (
    collectionState: OutputCollectionState<
      OutputCollectionStatus,
      "maybe-has-group"
    >,
  ) => {
    // Process each new successful file entry
    collectionState.successEntries.forEach(async (entry) => {
      // If this file has already been processed, skip it.
      if (processedIds.has(entry.internalId)) {
        return;
      }

      // Mark this file as processed.
      processedIds.add(entry.internalId);
      setProcessedIds(new Set(processedIds));

      try {
        // Ensure the file has a cdnUrl before calling backend upload.
        if (entry.cdnUrl) {
          await uploadDocument({
            file_url: entry.cdnUrl,
            filename: entry.name,
            mime_type: entry.mimeType,
            size: entry.size,
            uuid: entry.uuid,
          });
          await fetchDocuments();
        }
      } catch (err) {
        console.error("Error uploading file to backend", err);
        toast.error(t("settings.smart_features.upload_documents_error"));
      }
    });

    // Log failed entries if any (optional)
    if (collectionState.failedEntries.length > 0) {
      collectionState.failedEntries.forEach((entry) => {
        console.error("Upload failed for file:", entry.name);
        toast.error(t("settings.smart_features.upload_documents_error"));
      });
    }

    // When the overall state shows no files uploading, refresh the list.
    if (collectionState.uploadingCount === 0) {
      await fetchDocuments();
    }
  };

  /**
   * Custom file validator to restrict uploads to PDFs.
   * Returns an error message (using translations) if the file is not a PDF.
   */
  const pdfFileValidator = (
    fileInfo: OutputFileEntry<OutputFileStatus>,
  ): OutputErrorFile | undefined => {
    if (fileInfo.file?.type !== "application/pdf") {
      return {
        message: t("settings.smart_features.only_pdf_allowed"),
      };
    }
    return undefined;
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[80vh] max-w-2xl overflow-y-auto p-10 scrollbar-hide">
        <DialogHeader className="mb-3 flex flex-row items-center justify-between">
          <div className="mr-14 flex-col space-y-2">
            <DialogTitle>
              {t("settings.smart_features.documents_title")}
            </DialogTitle>

            <DialogDescription>
              {t("settings.smart_features.documents_description")}
            </DialogDescription>
          </div>

          {/* Title & Upload */}
          <div className="font-semibold text-primary">
            <FileUploaderRegular
              classNameUploader="uc-light my-uploadcare-theme"
              useCloudImageEditor={false}
              sourceList="local, gdrive"
              pubkey={import.meta.env.VITE_UPLOADCARE_PUBLIC_KEY}
              multiple
              // Use our custom file validator for PDFs.
              fileValidators={[pdfFileValidator]}
              // The "accept" attribute guides the native file dialog.
              accept=".pdf"
              // onChange receives the entire collection state.
              onChange={handleUploaderChange}
              localeName={i18n.language === "fr" ? "fr" : "en"}
              metadata={{ workspace: workspaceId as string }}
            />
          </div>
        </DialogHeader>

        <WorkspaceDocumentsManager />

        <DialogFooter className="mt-3">
          <Button variant="secondary" onClick={onClose}>
            {t("settings.smart_features.cancel")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
