// AuthProvider.tsx
import { Auth0Provider, useAuth0, AppState } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Importing useLocation for current route

import { navigateWithParams } from "@/utils/navigateWithParams";
import { useUser } from "@/axios/queries";

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;

  /**
   * Define the onRedirectCallback function to handle post-authentication redirection.
   * It retrieves the appState and redirects the user accordingly.
   * @param {AppState} appState - The state passed through Auth0's login.
   */
  const onRedirectCallback = (appState?: AppState) => {
    const returnTo = appState?.returnTo || window.location.pathname;
    const queryParams = appState?.queryParams || {};
    const queryString = new URLSearchParams(queryParams).toString();

    // Construct the final URL with query parameters
    const finalURL = queryString ? `${returnTo}?${queryString}` : returnTo;

    // Replace the current history state with the final URL
    window.history.replaceState({}, document.title, finalURL);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access",
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <AuthRedirect>{children}</AuthRedirect>
    </Auth0Provider>
  );
};

const AuthRedirect = ({ children }: { children: ReactNode }) => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuthUser,
    user,
    error,
    logout,
  } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  const {
    data: dbUser,
    isLoading: isLoadingDbUser,
    isRefetching: isRefetchingDbUser,
  } = useUser(user?.sub as string, !!user);

  const isLoading = isLoadingAuthUser || isLoadingDbUser;

  useEffect(() => {
    // console.log("AuthProvider:", {
    //   error: (error as any)?.error,
    //   pathname: location.pathname,
    // });

    if (error || location.pathname == "/social-login-error") {
      console.error("Auth error:", error);
      // If error is account_social_only, redirect to our custom error page.
      if (
        (error as any)?.error === "account_social_only" ||
        location.pathname == "/social-login-error"
      ) {
        if (location.pathname !== "/social-login-error") {
          console.error("Redirecting to social-login-error", {
            pathname: location.pathname,
          });

          window.location.href = "/social-login-error";
        }
      } else {
        // For any other error, log out and redirect to the login page with a retry screen.
        logout();
        window.location.href = "/login";
      }
    } else {
      if (!isLoadingAuthUser) {
        // console.info("Redirect to ?", {
        //   pathname: location.pathname,
        //   error,
        // });

        if (!isAuthenticated && location.pathname !== "/login") {
          // console.debug("Redirecting to login", {
          //   isAuthenticated,
          //   location: location.pathname,
          // });
          console.info("Redirect to login page");
          navigateWithParams("/login", navigate, location.search);
        } else if (
          isAuthenticated &&
          dbUser &&
          !dbUser.email_verified &&
          dbUser.primary_connection == "Username-Password-Authentication"
        ) {
          // If the email is not verified, redirect to the verify page.
          if (location.pathname !== "/verify-email") {
            navigateWithParams("/verify-email", navigate, location.search);
          }
        } else if (isAuthenticated && dbUser && dbUser.email_verified) {
          if (location.pathname == "/verify-email") {
            navigateWithParams("/", navigate, location.search);
          }
        } else if (isAuthenticated && location.pathname === "/login") {
          // console.debug("Redirecting to home", {
          //   isAuthenticated,
          //   location: location.pathname,
          // });
          console.info("Redirect to home page");
          navigateWithParams("/", navigate, location.search);
        } else if (
          isAuthenticated &&
          user?.sub &&
          !isLoading &&
          !dbUser &&
          !isLoadingDbUser &&
          !isRefetchingDbUser &&
          import.meta.env.VITE_NODE_ENV !== "development"
        ) {
          // console.error("LOGOUT", {
          //   isAuthenticated,
          //   user,
          //   dbUser,
          //   isLoadingAuthUser,
          //   isLoadingDbUser,
          //   isRefetchingDbUser,
          // });
          logout();
        }
      }
    }
  }, [
    isAuthenticated,
    isLoading,
    location.pathname,
    navigate,
    dbUser,
    user,
    isLoadingDbUser,
    isLoadingAuthUser,
    error,
    logout,
    location.search,
    isRefetchingDbUser,
  ]);

  // console.log("AuthProvider:", {
  //   error: (error as any)?.error,
  //   isLoading,
  //   isAuthenticated,
  //   user,
  //   dbUser,
  //   email_verified: user?.email_verified,
  //   pathname: location.pathname,
  // });

  if (isLoading && isAuthenticated) {
    return null;
  }

  if (
    !isAuthenticated &&
    location.pathname !== "/login" &&
    !error &&
    location.pathname != "/social-login-error"
  ) {
    return null; // Ou un composant de chargement
  }

  if (isAuthenticated && !dbUser) {
    return null; // Redirect to the verify page if email not verified
  }

  return <>{children}</>; // Display children if authenticated
};

export { AuthProvider };
