// PlanDetailsPopup.tsx
import React, { useState, useRef, useEffect } from "react";
import { AiIconWhite, CheckIcon, Loader } from "@/assets/icons";
import { BillingCycle, PlanId, PLANS, PRICE_MAP } from "@/types/PLANS.config";
import { Switch } from "./ui/switch";
import { Crisp } from "crisp-sdk-web";
import { loadStripe } from "@stripe/stripe-js";

import { useCreateCheckoutSession } from "@/axios/mutations/useCreateCheckoutSession";
import { useChangePlan, useSetOnboardingDone } from "@/axios/mutations";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import { useTranslation } from "react-i18next";
import { useUserContext } from "@/layouts/user-provider/useUserContext";

// Initialize Stripe with your public key
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type PlanDetailsPopupProps = {
  onClose: () => void;
};

const PlanDetailsPopup: React.FC<PlanDetailsPopupProps> = ({ onClose }) => {
  const { t, i18n } = useTranslation(); // Destructure i18n to get the current language
  const [language, setLanguage] = useState<string>(
    localStorage.getItem("i18nextLng") || i18n.language || "fr",
  );

  const { user } = useUserContext();

  const modalRef = useRef<HTMLDivElement>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanId | null>(null); // Default to plan ID 1
  const [billingCycle, setBillingCycle] = useState<BillingCycle>("yearly");
  const [isLoading, setIsLoading] = useState<PlanId | null>(null);

  const { createCheckoutSession } = useCreateCheckoutSession();
  const { changePlan } = useChangePlan();
  const { subscriptionDetails, refetchSubscriptionDetails } =
    useSubscriptionContext();

  const { setOnboardingDone } = useSetOnboardingDone();

  /**
   * Toggles the billing cycle between monthly and yearly.
   */
  const toggleBillingCycle = () => {
    setBillingCycle((prev) => (prev === "monthly" ? "yearly" : "monthly"));
  };

  /**
   * Handles the 'Next' action after plan selection.
   * Initiates Stripe checkout or opens contact support based on the selected plan.
   */
  const handleSelectPlan = async (planId: PlanId) => {
    setSelectedPlan(planId);

    if (planId === 3) {
      handleContactUs();
      return;
    }

    try {
      setIsLoading(planId);
      const priceId = PRICE_MAP[planId as PlanId][billingCycle];

      if (
        subscriptionDetails &&
        ["active", "trialing", "past_due", "unpaid", "paused"].includes(
          subscriptionDetails.status,
        )
      ) {
        // Update existing subscription
        await changePlan(priceId as string);
        await refetchSubscriptionDetails();
        onClose();
      } else {
        // No existing subscription or cannot update, create new subscription via Checkout Session
        const sessionId = await createCheckoutSession(priceId as string);
        const stripe = await stripePromise;

        // Call the hook to mark onboarding as done
        void setOnboardingDone(user?.user_id as string);

        if (stripe) {
          await stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe failed to initialize.");
        }
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setIsLoading(null);
    }
  };

  /**
   * Handles the "Contact Us" action for the Enterprise Plan.
   */
  const handleContactUs = () => {
    if (window.$crisp) {
      Crisp.chat.open();
      $crisp.push([
        "do",
        "message:send",
        ["text", t("planDetailsPopup.wantMoreInformation")],
      ]);
    } else {
      console.error(
        "Crisp chat is not available. Please contact us via email at support@example.com.",
      );
    }
  };

  // Helper function to render key features
  const renderKeyFeature = (feature: { label: string; value: any }) => {
    const { label, value } = feature;

    if (typeof value === "boolean") {
      return value ? t(label) : <br></br>;
      // language == "en" ? (
      //   <>
      //     <strong className="mr-0.5">{t("planDetailsPopup.no")}</strong>{" "}
      //     {t(label)}
      //   </>
      // ) : (
      //   <br></br>
      // );
    }

    if (typeof value === "string") {
      return language == "en" ? (
        <>
          <strong className="mr-0.5">{t(value)}</strong> {t(label)}
        </>
      ) : (
        <>
          {t(label)} : <strong>{t(value)}</strong>
        </>
      );
    }

    if (typeof value === "number") {
      return (
        <>
          <strong>{value}</strong> {t(label).toLowerCase()}
        </>
      );
    }

    return `${t(value)} ${t(label)}`;
  };

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
    }
  }, [i18n.language]);

  return (
    <div className="fixed inset-0 z-50 flex size-full items-center justify-center bg-black/50 px-5 scrollbar-hide">
      {/* Modal Content */}
      <div
        ref={modalRef}
        className="relative max-h-[90vh] w-fit max-w-5xl overflow-auto rounded-[12px] bg-white p-8 scrollbar-hide"
      >
        {/* Close Button */}
        <button
          className="absolute right-8 top-4 text-2xl font-medium text-dark-grey hover:brightness-90"
          onClick={onClose}
          disabled={isLoading ? true : false}
        >
          &times;
        </button>

        {/* Billing Cycle Switch */}
        <div className="mb-6 flex w-full items-center space-x-2 font-semibold">
          <span
            className={
              billingCycle === "monthly" ? "text-primary" : "text-dark-grey"
            }
          >
            {t("planDetailsPopup.monthly")}
          </span>
          <Switch
            id="billing-cycle-switch"
            defaultChecked={billingCycle === "yearly"}
            onCheckedChange={toggleBillingCycle}
          />
          <span
            className={
              billingCycle === "yearly" ? "text-primary" : "text-dark-grey"
            }
          >
            {t("planDetailsPopup.yearly")}
          </span>

          {billingCycle == "monthly" && (
            <span className="ml-2 text-nowrap rounded-full bg-violet/70 px-1.5 pb-1 pt-0.5 text-xs font-semibold text-white">
              {t("planDetailsPopup.saveUpTo40")}
            </span>
          )}
        </div>

        {/* Plan Details */}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {PLANS.filter((plan) => plan.id !== 3).map((plan) => {
            let monthlyTotal, yearlyTotal, savings;
            // let savingsPercentage;

            const iconColorClasses: Record<string, string> = {
              "dark-grey": "text-dark-grey",
              "light-violet": "text-light-violet",
              orange: "text-orange",
            };

            const color = iconColorClasses[plan?.iconColor];

            if (plan.monthlyPrice && plan.yearlyPrice) {
              monthlyTotal = plan.monthlyPrice * 12;
              yearlyTotal = plan.yearlyPrice * 12;
              savings = monthlyTotal - yearlyTotal;
              // savingsPercentage = Math.round((savings / monthlyTotal) * 100);
            }

            return (
              <div
                key={plan.id}
                // onClick={() => handleSelectPlan(plan.id as PlanId)}
                className={`relative flex h-full min-w-[275px] flex-col justify-between rounded-[12px] bg-light-grey p-8 ${plan.id == 1 ? "border-2 border-violet" : ""}`}
              >
                {/* "Most Popular" Label */}
                {plan.id === 1 && (
                  <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-violet px-6 py-1 text-xs font-semibold text-white">
                    {t("planDetailsPopup.mostPopular")}
                  </div>
                )}
                <div>
                  {/* Plan Header */}
                  <div className="flex flex-row items-center">
                    <AiIconWhite
                      className={`mr-3 ${color} size-[44px] shrink-0`}
                    />

                    <div>
                      <div className="flex flex-row">
                        <div className="h-6 text-nowrap text-sm font-extrabold text-primary">
                          {t(plan.name)}
                        </div>

                        {/* Savings Label */}
                        {billingCycle === "yearly" &&
                          plan.monthlyPrice &&
                          plan.yearlyPrice && (
                            <span className="ml-2 flex h-5 items-center justify-center text-nowrap rounded-full bg-violet/70 px-1.5 text-[8px] font-semibold tracking-wider text-white">
                              {t("planDetailsPopup.saveAmount", { savings })}
                            </span>
                          )}
                      </div>

                      <div className="text-xl font-extrabold text-primary">
                        {billingCycle === "monthly"
                          ? plan.monthlyPrice
                          : plan.yearlyPrice}
                        €
                        <span className="text-sm">
                          / {t("planDetailsPopup.month")}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Key Features */}
                  <ul className="mt-8 space-y-3.5 text-sm text-primary">
                    {plan.keyFeatures.map((feature, index) => (
                      <li key={index}>{renderKeyFeature(feature)}</li>
                    ))}
                  </ul>

                  {/* Separator */}
                  <div className="my-8 border-t border-dashed border-dark-grey/30"></div>

                  {/* Additional Sentence
                  {index > 0 && (
                    <div className="mb-6 text-sm text-dark-grey">
                      {t("planDetailsPopup.everythingIn")}{" "}
                      <strong>{t(PLANS[index - 1].name)}</strong>,{" "}
                      {t("planDetailsPopup.plus")}
                    </div>
                  )} */}

                  {/* Additional Features */}
                  <ul className="space-y-3.5 text-sm">
                    {plan.additionalFeatures.map((feature, index) => (
                      <li key={index} className="flex items-center font-medium">
                        <CheckIcon className="mr-3 size-4 shrink-0" />
                        {t(feature)}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Select Plan Button */}
                <button
                  className={`mt-8 flex w-full items-center justify-center rounded-lg py-3 text-sm font-semibold hover:scale-[102%] active:scale-95 ${
                    selectedPlan == plan.id && "brightness-90"
                  } ${plan.id == 1 ? "bg-violet text-white hover:brightness-110" : "bg-white text-primary hover:brightness-90 "}`}
                  onClick={() => handleSelectPlan(plan.id as PlanId)}
                >
                  {selectedPlan === plan.id
                    ? t("planDetailsPopup.selectedPlan")
                    : t("planDetailsPopup.selectPlan")}
                  {isLoading == plan.id && (
                    <Loader
                      className={`ml-2 mt-0.5 size-3.5 shrink-0 animate-spin ${plan.id == 1 ? "stroke-white" : "stroke-primary"}`}
                    />
                  )}
                </button>
              </div>
            );
          })}
        </div>

        {/* Enterprise Plan */}
        <div className="mt-6">
          <div className="flex flex-col rounded-lg bg-light-grey p-6 sm:flex-row sm:items-center">
            <div className="flex-1">
              {/* Plan Header */}
              <div className="flex items-center">
                <AiIconWhite
                  className={`mr-3 ${"text-" + PLANS[3].iconColor} size-[44px] shrink-0`}
                />

                <div className="h-6 text-nowrap text-base font-extrabold text-primary">
                  {t(PLANS[3].name)}
                </div>
              </div>

              {/* Custom Requirements Text */}
              <p className="mt-4 text-sm font-medium text-primary">
                {t("planDetailsPopup.haveCustomRequirements")}
              </p>

              {/* Contact Us Button */}
              <button
                className="mt-6 w-fit rounded-lg bg-primary px-4 py-2 text-sm font-semibold text-white hover:scale-[102%] hover:brightness-110 active:scale-95"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the event from bubbling up
                  handleContactUs();
                }}
              >
                {t("planDetailsPopup.contactUs")}
              </button>
            </div>

            {/* Key Features */}
            <ul className="mt-6 space-y-3.5 text-sm sm:ml-12 sm:mt-0">
              {PLANS[3].keyFeatures.map((feature, index) => (
                <li key={index} className="flex items-center font-medium">
                  <CheckIcon className="mr-3 size-4 shrink-0" />
                  {t(feature.label)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlanDetailsPopup };
