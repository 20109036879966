// BulkActionPopup.tsx
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

type BulkActionPopupProps = {
  icon: JSX.Element | null;
  message: string;
  selectedCount: number;
  onConfirm: () => void;
  onCancel: () => void;
  position: { top: number; left: number };
  type: "comment" | "message";
};

const BulkActionPopup: React.FC<BulkActionPopupProps> = ({
  icon,
  message,
  onConfirm,
  onCancel,
  position,
}) => {
  const { t } = useTranslation();

  const popupRef = useRef<HTMLDivElement>(null);
  const [adjustedPosition, setAdjustedPosition] = useState<{
    top: number;
    left: number;
  }>({
    top: position.top,
    left: position.left,
  });

  // Close popup when clicking outside or pressing Escape
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onCancel]);

  // Prevent background scrolling when popup is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // Adjust position to prevent overflow
  useEffect(() => {
    const popup = popupRef.current;
    if (popup) {
      const { innerWidth, innerHeight } = window;
      const rect = popup.getBoundingClientRect();
      let newTop = position.top;
      let newLeft = position.left;

      // Check right overflow
      if (newLeft + rect.width > innerWidth - 10) {
        // 10px margin
        newLeft = innerWidth - rect.width - 10;
      }

      // Check bottom overflow
      if (newTop + rect.height > innerHeight - 10) {
        // 10px margin
        newTop = position.top - rect.height - 10; // Position above the button
      }

      // Check left overflow
      if (newLeft < 10) {
        newLeft = 10;
      }

      // Check top overflow
      if (newTop < 10) {
        newTop = 10;
      }

      setAdjustedPosition({ top: newTop, left: newLeft });
    }
  }, [position]);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50">
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black/50"></div>

      {/* Popup */}
      <div
        ref={popupRef}
        className="absolute flex w-full max-w-[500px] flex-row items-center rounded-lg bg-white p-5 shadow-lg"
        style={{
          top: `${adjustedPosition.top}px`,
          left: `${adjustedPosition.left}px`,
        }}
      >
        {/* Icon */}
        {icon && <div className="mr-3 shrink-0">{icon}</div>}

        {/* Message */}
        <div className="flex-1">
          <p className="text-sm font-semibold text-primary">{message}</p>
        </div>

        {/* Buttons */}
        <div className="ml-4 flex flex-row gap-2">
          <button
            onClick={onCancel}
            className="h-[40px] w-[80px] rounded-lg bg-light-grey text-sm font-semibold text-primary hover:brightness-90"
          >
            {t("tasks.bulk_action_popup.cancel")}
          </button>
          <button
            onClick={onConfirm}
            className="h-[40px] w-[80px] rounded-lg bg-primary text-sm font-semibold text-white hover:brightness-90"
          >
            {t("tasks.bulk_action_popup.ok")}
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default BulkActionPopup;
