// VerifyEmailPage.tsx
import React, { useEffect, useState } from "react";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// Shadcn & validation imports
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";

import { REGEXP_ONLY_DIGITS } from "input-otp";
import { Button } from "@/components/ui/button";

// (Optional) If you have a Spinner/Loader icon:
import { DarkBlablaLogo, Loader } from "@/assets/icons"; // Adjust to your actual loader import
import { Img } from "@/components/Img";
import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";
import {
  useSendVerification,
  useVerifyCode,
} from "@/axios/mutations/useAuthEndpoints";

const VerifyEmailPage: React.FC = () => {
  const { user, changeLanguage, language, isLoadingUser } = useUserContext();
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const email = user?.email;

  // -- STATES -------------------------------------------------------------- //
  // We'll track whether we have already sent the code (persisted in sessionStorage)
  const hasSentSessionKey = `verifyEmailCodeSent_${email}`;
  const [, setCodeSent] = useState<boolean>(() => {
    return sessionStorage.getItem(hasSentSessionKey) === "true";
  });

  const [resendCooldown, setResendCooldown] = useState<number>(0); // countdown in seconds
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isSending, setIsSending] = useState<boolean>(false); // loading for sending
  const [isVerifying, setIsVerifying] = useState<boolean>(false); // loading for verifying

  // New state to prevent repeated auto-submissions
  const [autoSubmitted, setAutoSubmitted] = useState<boolean>(false);

  // -- Custom Hooks for API calls ----------------------------------------- //
  const { sendVerification } = useSendVerification();
  const { verifyCode } = useVerifyCode();

  // -- FORM SETUP WITH SHADCN / REACT-HOOK-FORM --------------------------- //

  const codeSchema = z.object({
    code: z.string().min(6, { message: t("verify_email_page.otp_min_length") }),
  });

  const form = useForm<z.infer<typeof codeSchema>>({
    resolver: zodResolver(codeSchema),
    defaultValues: {
      code: "",
    },
  });

  // Watch the OTP code field for automatic submission
  const watchedCode = form.watch("code");

  const isLoading = !user || isLoadingUser;

  // -- EFFECTS ------------------------------------------------------------ //
  // On load, if code not yet sent, send once
  useEffect(() => {
    if (email) {
      const sentAtStr = sessionStorage.getItem(`verifyEmailSentAt_${email}`);
      let elapsed = 0;

      if (sentAtStr) {
        const sentAt = parseInt(sentAtStr, 10);
        elapsed = (Date.now() - sentAt) / 1000;
      }

      if (!sentAtStr || elapsed > 60) {
        handleSendVerificationEmail();
      }
    }
  }, []);

  // On component mount, check for cooldown timestamp in sessionStorage
  useEffect(() => {
    if (email) {
      const sentAtStr = sessionStorage.getItem(`verifyEmailSentAt_${email}`);
      if (sentAtStr) {
        const sentAt = parseInt(sentAtStr, 10);
        const elapsed = (Date.now() - sentAt) / 1000;
        if (elapsed < 60) {
          setResendCooldown(Math.ceil(60 - elapsed));
        }
      }
    }
  }, [email]);

  // If we are in cooldown, countdown each second
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resendCooldown]);

  // Auto-submit when the user has entered 6 digits and we're not already verifying
  useEffect(() => {
    if (watchedCode.length === 6 && !isVerifying && !autoSubmitted) {
      form.handleSubmit(onSubmit)();
      setAutoSubmitted(true);
    }
    // Reset autoSubmitted if user changes the code (e.g., deletes a digit)
    if (watchedCode.length !== 6 && autoSubmitted) {
      setAutoSubmitted(false);
    }
  }, [watchedCode, isVerifying, autoSubmitted]);

  // -- HANDLERS ---------------------------------------------------------- //
  const handleSendVerificationEmail = async () => {
    if (!email) return;

    setIsSending(true);
    setErrorMessage("");

    try {
      const success = await sendVerification(email);
      if (!success) {
        setErrorMessage(t("verify_email_page.error_sending"));
      } else {
        // Mark code as sent in sessionStorage and update state
        sessionStorage.setItem(hasSentSessionKey, "true");
        sessionStorage.setItem(
          `verifyEmailSentAt_${email}`,
          Date.now().toString(),
        );
        setCodeSent(true);
        setResendCooldown(60);
      }
    } catch (error) {
      setErrorMessage(t("verify_email_page.error_sending"));
    } finally {
      setIsSending(false);
    }
  };

  const handleResend = () => {
    // We reuse the same function. We do not reset codeSent = false,
    // because we want to track the fact that it was sent at least once.
    handleSendVerificationEmail();
  };

  const onSubmit = async (values: { code: string }) => {
    if (!email) return;
    setIsVerifying(true);
    setErrorMessage("");
    try {
      const success = await verifyCode(email, values.code);
      if (success) {
        // Now reload the page so that the Auth0Provider picks up the updated user profile.
        window.location.reload();
      } else {
        setErrorMessage(t("verify_email_page.verification_failed"));
      }
    } catch (error) {
      setErrorMessage(t("verify_email_page.verification_error"));
    } finally {
      setIsVerifying(false);
    }
  };

  // -- RENDER ------------------------------------------------------------- //
  return (
    <AppWrapper isLoading={isLoading} navBar={false}>
      <div className="flex min-h-screen w-screen flex-col items-center bg-white pb-16 sm:bg-light-grey sm:p-12 sm:pb-32 sm:pt-20">
        {/* Main section */}
        <div className="flex size-fit max-w-lg flex-col items-center bg-white p-16 pb-8 text-center sm:w-full sm:rounded-2xl">
          <Img src={DarkBlablaLogo} alt="Logo" className="mb-6 h-5 shrink-0" />

          <h1 className="mb-2 text-2xl font-semibold">
            {t("verify_email_page.title")}
          </h1>
          <p className="text-center font-normal text-primary">
            {t("verify_email_page.enter_code_description")}
            <br />
            {email + "."}
          </p>

          {/* OTP Form */}
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mt-4 flex w-full flex-col items-center justify-center space-y-4"
            >
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <>
                    <FormItem>
                      <FormControl>
                        <InputOTP
                          maxLength={6}
                          disabled={isSending || isVerifying}
                          pattern={REGEXP_ONLY_DIGITS}
                          {...field}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>
                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                    </FormItem>
                    <FormMessage />
                  </>
                )}
              />

              {/* Submit Button */}
              <Button
                type="submit"
                disabled={isSending || isVerifying}
                className="px-6"
              >
                {isVerifying ? (
                  <div className="flex items-center">
                    {t("verify_email_page.verifying")}
                    <Loader className="ml-2 size-4 animate-spin" />
                  </div>
                ) : (
                  t("verify_email_page.verify")
                )}
              </Button>
            </form>
          </Form>

          {/* Resend Button */}
          <div className="mt-4">
            <button
              onClick={handleResend}
              disabled={resendCooldown > 0 || isSending || isVerifying}
              className={`flex cursor-pointer items-center justify-center text-sm font-normal text-primary  disabled:cursor-not-allowed disabled:opacity-50 ${resendCooldown > 0 || isSending || isVerifying ? "" : "hover:font-semibold"}`}
            >
              {isSending ? (
                <div className="flex items-center">
                  {t("verify_email_page.sending")}
                  <Loader className="ml-2 size-4 animate-spin" />
                </div>
              ) : resendCooldown > 0 ? (
                `${t("verify_email_page.resend")} (${resendCooldown}s)`
              ) : (
                t("verify_email_page.resend")
              )}
            </button>
          </div>

          {/* Error messages */}
          {errorMessage && (
            <p className="mb-2 mt-1 text-sm font-medium text-red">
              {errorMessage}
            </p>
          )}

          {/* Terms & Privacy */}
          <div className="mt-8 flex flex-row space-x-4 text-xs">
            <a
              href="https://blabla.ai/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:underline"
            >
              {t("verify_email_page.terms_of_use")}
            </a>
            <p>{" | "}</p>
            <a
              href="https://blabla.ai/privacy-policies"
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:underline"
            >
              {t("verify_email_page.privacy_policy")}
            </a>
          </div>
        </div>

        {/* bottom-right: language + logout */}
        <div className="absolute bottom-8 right-32 flex shrink-0 items-center justify-center space-x-5 text-nowrap">
          <Select
            value={language}
            onValueChange={async (value) => {
              await changeLanguage(value);
            }}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language === "en" ? "English" : "Français"}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
            </SelectContent>
          </Select>

          <button
            onClick={() => logout()}
            className="font-medium text-primary hover:underline active:brightness-110"
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </AppWrapper>
  );
};

export default VerifyEmailPage;
