import { useEffect } from "react";
import useSWRInfinite from "swr/infinite";
import { useFetcher } from "@axios/fetcher";
import {
  GetCommentsDto,
  PaginatedCommentsResponse,
} from "@/types/comments.type";
import { useWebSocket } from "@/layouts/WebSocketContext";

function useComments(
  workspace_id: string,
  filter: GetCommentsDto,
  enabled = true,
) {
  const { fetcher } = useFetcher();
  const { socket } = useWebSocket();

  const getKey = (
    pageIndex: number,
    previousPageData: PaginatedCommentsResponse | null,
  ) => {
    if (!enabled || !workspace_id) return null;
    if (previousPageData && !previousPageData.comments.length) return null;

    const { page, limit, ...restFilter } = filter;
    const params = new URLSearchParams();

    Object.entries(restFilter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          params.append(key, "");
        } else {
          value.forEach((v) => params.append(key, v));
        }
      } else if (value !== null && value !== undefined && value !== "") {
        params.append(key, value as string);
      }
    });

    params.append("page", (pageIndex + 1).toString());
    params.append("limit", limit?.toString() || "50");

    return `/api/comments/${workspace_id}?${params.toString()}`;
  };

  const { data, error, mutate, size, setSize, isValidating } =
    useSWRInfinite<PaginatedCommentsResponse>(
      getKey,
      (url: string) => fetcher(url, "get"),
      {
        revalidateOnFocus: true,
        shouldRetryOnError: true,
      },
    );

  // Listen for "commentsUpdated"
  useEffect(() => {
    if (!socket || !workspace_id || !enabled) return;

    socket.emit("joinWorkspace", { workspace_id });

    const handleCommentsUpdated = async () => {
      // console.info("[useComments] 'commentsUpdated' => revalidating...");
      await refetch();
    };

    socket.on("commentsUpdated", handleCommentsUpdated);

    return () => {
      socket.off("commentsUpdated", handleCommentsUpdated);
    };
  }, [socket, workspace_id, enabled, mutate]);

  const refetch = async (): Promise<PaginatedCommentsResponse[]> => {
    try {
      const updatedData = await mutate();
      return updatedData || [];
    } catch (err) {
      console.error("Error refetching comments:", { error: err });
      throw err;
    }
  };

  const loadMore = async () => {
    setSize(size + 1);
  };

  const hasMore = data
    ? data[data.length - 1].page * filter.limit < data[data.length - 1]?.total
    : true;

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  const allComments = data ? data.flatMap((page) => page.comments) : [];

  return {
    data: allComments,
    isLoadingInitialData,
    isLoadingMore,
    isValidating,
    error,
    size,
    setSize,
    hasMore,
    refetch,
    loadMore,
  };
}

export { useComments };
