// Step1PersonalInfo.tsx
import { useEffect, useState } from "react";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { useUpdateFullName } from "@axios/mutations";
import { AiIcon, Loader } from "@/assets/icons";
import { AppPreview } from "@/assets";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

type Step1PersonalInfoProps = {
  onNext: (data: { fullName: string }) => void;
  initialData: { fullName: string; email: string };
};

const Step1PersonalInfo = ({ onNext, initialData }: Step1PersonalInfoProps) => {
  const { t } = useTranslation(); // Destructure i18n to get the current language
  const { logout } = useAuth0();

  const { user, changeLanguage, language } = useUserContext();
  const { updateFullName } = useUpdateFullName();

  const [fullName, setFullName] = useState(initialData.fullName ?? "");

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async () => {
    setIsLoading(true);
    await updateFullName(user?.user_id as string, fullName);

    await onNext({ fullName });
  };

  useEffect(() => {
    if (initialData.fullName && initialData.email != initialData.fullName) {
      setFullName(initialData.fullName);
    } else if (user?.name && user.email != user.name) {
      setFullName(user.name);
    }
  }, [initialData.email, initialData.fullName]);

  return (
    <div className="flex h-screen w-screen items-center justify-center space-x-10 overflow-hidden bg-light-grey">
      {/* Left Section: Personal Information Form */}
      <div className="flex h-full grow flex-col items-center justify-center p-8">
        <div className="flex size-full flex-col justify-center overflow-y-auto rounded-xl bg-white p-10 scrollbar-hide md:px-[20%] md:py-32 lg:justify-start">
          <AiIcon className="mb-8 size-10 shrink-0" />
          <h2 className="mb-4 text-2xl font-bold text-primary">
            {t("onboarding.step1_personal_info.step_1_personal_information")}
          </h2>

          <p className="mb-10 text-left text-base font-semibold text-dark-grey">
            {t(
              "onboarding.step1_personal_info.please_provide_your_personal_details_to_continue",
            )}
          </p>

          <div className="mb-6">
            <label className="mb-3 block text-sm font-medium text-dark-grey">
              {t("onboarding.step1_personal_info.email")}
            </label>
            <Input
              type="text"
              id="email"
              value={initialData.email}
              disabled
              className="mt-1 w-full rounded-lg border border-light-grey bg-light-grey p-3"
            />
          </div>

          <div className="mb-8">
            <label className="mb-3 block text-sm font-medium text-dark-grey">
              {t("onboarding.step1_personal_info.full_name")}
            </label>
            <Input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder={t("onboarding.step1_personal_info.enter_full_name")}
              className="mt-1 w-full rounded-lg border border-light-grey p-3"
            />
          </div>

          <div className="mb-12 flex items-center">
            <Checkbox
              id="terms"
              checked={termsAccepted}
              onCheckedChange={(checked) => setTermsAccepted(!!checked)}
              className="size-4 border-primary text-primary data-[state=checked]:bg-primary"
            />
            <label
              htmlFor="terms"
              className="ml-2 text-sm leading-none text-dark-grey"
            >
              {t("onboarding.step1_personal_info.i_agree_to_the")}{" "}
              <a
                href="https://blabla.ai/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                className="text-primary underline"
              >
                {t("onboarding.step1_personal_info.terms_and_conditions")}
              </a>
            </label>
          </div>

          {/* Button Wrapper to align right */}
          <div className="flex justify-end">
            <button
              onClick={handleNext}
              disabled={!termsAccepted || !fullName || fullName.trim() === ""}
              className={`flex w-44 items-center justify-center rounded-lg px-4 py-3 text-base font-semibold text-white ${
                termsAccepted && fullName?.trim()?.length > 0
                  ? "bg-primary hover:scale-[102%] active:scale-95"
                  : "cursor-not-allowed bg-dark-grey text-light-grey"
              }`}
            >
              {t("onboarding.step1_personal_info.next")}
              {isLoading && (
                <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Right Section: App Preview Image */}
      <div className="hidden h-[85%] items-center justify-end py-8 lg:flex">
        <img
          src={AppPreview} // Replace with your own image if needed
          alt="App preview"
          className="max-h-full max-w-full rounded-xl transition-transform"
        />

        <div className="absolute bottom-8 right-32 flex shrink-0 items-center justify-center space-x-5 text-nowrap">
          <Select
            value={language}
            onValueChange={async (value) => {
              await changeLanguage(value);
            }}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language == "en" ? "English" : "Français"}
              />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
            </SelectContent>
          </Select>

          <button
            onClick={() => logout()}
            className="font-medium text-primary hover:underline active:brightness-110"
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export { Step1PersonalInfo };
