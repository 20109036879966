import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "@/components";

const SocialLoginErrorPage: React.FC = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const handleRetry = () => {
    // Log out and redirect to the login page with the query parameter
    logout();
    window.location.href = "/login?screen=login";
  };

  return (
    <ErrorPage
      title={t("error.social.title")}
      message={t("error.social.message")}
      onRetry={handleRetry}
      targetPath="/login?screen=login"
      showLogout={false}
    />
  );
};

export default SocialLoginErrorPage;
