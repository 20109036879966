// useUpdateFullName.ts
import { useFetcher } from "@axios/fetcher";
import { User } from "@types";

function useUpdateFullName() {
  const { fetcher } = useFetcher();

  const updateFullName = async (user_id: string, fullName: string) => {
    const updatedUser = await fetcher(`/api/users/${user_id}/name`, "patch", {
      fullName,
    });

    return updatedUser as User;
  };

  return {
    updateFullName,
  };
}

export { useUpdateFullName };
