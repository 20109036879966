// hooks/useCancelSubscription.ts

import { useFetcher } from "@axios/fetcher";

/**
 * Custom hook to cancel a subscription.
 */
export function useCancelSubscription() {
  const { fetcher } = useFetcher();

  const cancelSubscription = async (subscription_id: string): Promise<void> => {
    try {
      await fetcher(
        `/api/stripe/subscription/${subscription_id}/cancel`,
        "post",
        undefined,
        false,
        true,
      );
    } catch (error) {
      console.error("Error canceling subscription:", error);
      throw error;
    }
  };

  return { cancelSubscription };
}
