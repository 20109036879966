// useCreateMembership.ts
import { useFetcher } from "@axios/fetcher";
import { Membership } from "@types";

function useCreateMembership() {
  const { fetcher } = useFetcher();

  const createMembership = async (input: {
    user_id: string;
    workspace_id: string;
    email: string;
    role: "owner" | "admin" | "member";
  }) => {
    // Make a POST request to create a new membership
    const newMembership = await fetcher(`/api/memberships`, "post", input);

    return newMembership as Membership;
  };

  return {
    createMembership,
  };
}

export { useCreateMembership };
