// hooks/useSetDefaultPaymentMethod.ts

import { useFetcher } from "@axios/fetcher";
import { useWorkspaceContext } from "@layouts/workspace-provider/useWorkspaceContext";

/**
 * Custom hook to set the default payment method.
 */
export function useSetDefaultPaymentMethod() {
  const { fetcher } = useFetcher();
  const { currentWorkspace } = useWorkspaceContext();
  const subscriptionId = currentWorkspace?.subscription_id;

  const setDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      await fetcher(
        `/api/stripe/payment-methods/${subscriptionId}/default`,
        "post",
        {
          payment_method_id: paymentMethodId,
          workspace_id: currentWorkspace?.workspace_id,
        },
        false,
        true,
      );
    } catch (error) {
      console.error("Error setting default payment method:", error);
      throw error;
    }
  };

  return { setDefaultPaymentMethod };
}
