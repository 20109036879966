// utils/collectQueryParams.ts

/**
 * Extracts all query parameters from the current URL.
 * @returns {Record<string, string>} An object containing all query parameters.
 */
export const getAllQueryParameters = (): Record<string, string> => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

/**
 * Collects and returns an object of all query parameters.
 * @returns {Record<string, string>} An object with key-value pairs of query parameters.
 */
export const collectQueryParams = (): Record<string, string> => {
  return getAllQueryParameters();
};
