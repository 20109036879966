// Step3ChoosePlan.tsx
import { useEffect, useState } from "react";
import { AiIconWhite, Loader } from "@/assets/icons";
import { AppPreview } from "@/assets";
import { Switch } from "../ui/switch";
import { PLANS, PRICE_MAP } from "@/types/PLANS.config";
import { PlanDetailsPopup } from "../PlanDetailsPopup";
import { Crisp } from "crisp-sdk-web";
import { useCreateCheckoutSession } from "@/axios/mutations/useCreateCheckoutSession";
import { loadStripe } from "@stripe/stripe-js";

import { useTranslation } from "react-i18next";
import { useSetOnboardingDone } from "@/axios/mutations";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// Initialize Stripe with your public key
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type Step3ChoosePlanProps = {
  onNext: () => void;
  onPrevious: () => void;
};

const Step3ChoosePlan = ({ onNext, onPrevious }: Step3ChoosePlanProps) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const { user, changeLanguage, language } = useUserContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<0 | 1 | 2 | 3>(1);
  const [billingCycle, setBillingCycle] = useState<"monthly" | "yearly">(
    "yearly",
  );
  const [showPlanDetails, setShowPlanDetails] = useState<boolean>(false);

  const { createCheckoutSession } = useCreateCheckoutSession();
  const { setOnboardingDone } = useSetOnboardingDone();

  // Generate storage keys based on user_id
  const getStorageKey = (key: string) => {
    return user ? `${key}_${user.user_id}` : key;
  };

  const handleNext = async () => {
    if (selectedPlan === 3) {
      handleContactUs();
      return;
    }

    try {
      setIsLoading(true);
      const priceId = PRICE_MAP[selectedPlan][billingCycle];
      const sessionId = await createCheckoutSession(priceId as string);

      console.debug("Plan ID:", selectedPlan);
      console.debug("Session ID:", sessionId);

      const stripe = await stripePromise;

      if (stripe) {
        console.debug("Stripe initialized.");

        // Call the hook to mark onboarding as done
        void setOnboardingDone(user?.user_id as string);

        // Clear onboarding data from localStorage
        localStorage.removeItem(getStorageKey("onboardingData"));
        localStorage.removeItem(getStorageKey("step"));

        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Stripe failed to initialize.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setIsLoading(false);
    }

    onNext();
  };

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === "monthly" ? "yearly" : "monthly");
  };

  // Function to handle Contact Us button click for Enterprise Plan
  const handleContactUs = () => {
    if (window.$crisp) {
      Crisp.chat.open();
      $crisp.push([
        "do",
        "message:send",
        ["text", t("onboarding.step3_choose_plan.contact_message")],
      ]);
    }
  };

  useEffect(() => {
    const storageKey = "crispMessageSentAt";
    const now = Date.now();
    const tenMinutesInMs = 10 * 60 * 1000; // 10 minutes en millisecondes
    const delayInMs = 2000; // 2 secondes en millisecondes

    // Récupérer le timestamp de l'envoi précédent
    const sentAt = localStorage.getItem(storageKey);

    if (sentAt) {
      const sentTime = parseInt(sentAt, 10);
      if (now - sentTime < tenMinutesInMs) {
        // Le message a déjà été envoyé il y a moins de 10 minutes
        return;
      }
    }

    // Définir un délai de 2 secondes avant d'envoyer le message
    const timer = setTimeout(() => {
      if (window.$crisp) {
        try {
          // Envoyer le message via le SDK Crisp
          Crisp.message.showText(
            t("onboarding.step3_choose_plan.crisp_message"),
          );
          Crisp.message.send(
            t("onboarding.step3_choose_plan.crisp_message"),
            "string",
          );

          // Enregistrer le timestamp de l'envoi dans localStorage
          localStorage.setItem(storageKey, Date.now().toString());
        } catch (error) {
          console.error("Erreur lors de l'envoi du message Crisp:", error);
        }
      } else {
        console.warn("Crisp n'est pas disponible.");
      }
    }, delayInMs);

    // Nettoyer le timeout si le composant est démonté avant l'expiration du délai
    return () => clearTimeout(timer);
  }, [t]);

  return (
    <>
      <div className="flex h-screen w-screen items-center justify-center space-x-10 overflow-hidden bg-light-grey">
        {/* Left Section: Choose Plan Form */}
        <div className="flex h-full grow flex-col items-center justify-center p-8">
          {/* Updated Scrolling Container */}
          <div className="min-h-0 w-full flex-1 overflow-auto rounded-xl bg-white p-10 px-16 scrollbar-hide">
            {/* Inner Wrapper for Centering */}
            <div className="flex min-h-full flex-col justify-center">
              <h2 className="mb-4 text-2xl font-bold text-primary">
                {t("onboarding.step3_choose_plan.choose_your_plan")}
              </h2>
              <p className="mb-6 text-base font-semibold text-primary">
                {t(
                  "onboarding.step3_choose_plan.let_s_select_the_plan_that_works_for_you",
                )}
                <br />
                <span className="text-sm font-medium">
                  {t(
                    "onboarding.step3_choose_plan.after_selecting_a_plan_your_will_be_redirected_to_",
                  )}
                </span>
              </p>

              {/* **New Trial Message** */}
              <div className="mb-8 w-fit rounded-lg border border-violet/50 bg-violet/20 p-4 font-medium text-violet">
                {t("onboarding.step3_choose_plan.trial_message")}
              </div>

              {/* Billing Cycle Switch */}
              <div className="mb-6 flex w-full items-center space-x-2 font-semibold">
                <span
                  className={
                    billingCycle === "monthly"
                      ? "text-primary"
                      : "text-dark-grey"
                  }
                >
                  {t("onboarding.step3_choose_plan.monthly")}
                </span>
                <Switch
                  id="billing-cycle-switch"
                  defaultChecked={billingCycle === "yearly"}
                  onCheckedChange={toggleBillingCycle}
                />
                <span
                  className={
                    billingCycle === "yearly"
                      ? "text-primary"
                      : "text-dark-grey"
                  }
                >
                  {t("onboarding.step3_choose_plan.yearly")}
                </span>

                {billingCycle == "monthly" && (
                  <span className="ml-2 rounded-full bg-violet/70 px-1.5 pb-1 pt-0.5 text-xs font-semibold text-white">
                    {t("onboarding.step3_choose_plan.save_up_to_40")}
                  </span>
                )}
              </div>

              {/* Plan Selection */}

              <div className="max-w-[616px]">
                {" "}
                <div className="mb-8 grid gap-4 [grid-template-columns:repeat(auto-fit,minmax(260px,1fr))]">
                  {PLANS.map((plan) => {
                    let monthlyTotal, yearlyTotal, savings;
                    // let savingsPercentage;

                    const iconColorClasses: Record<string, string> = {
                      "dark-grey": "text-dark-grey",
                      "light-violet": "text-light-violet",
                      orange: "text-orange",
                      red: "text-red",
                    };

                    const color = iconColorClasses[plan?.iconColor];

                    if (plan.monthlyPrice && plan.yearlyPrice) {
                      monthlyTotal = plan.monthlyPrice * 12;
                      yearlyTotal = plan.yearlyPrice * 12;
                      savings = monthlyTotal - yearlyTotal;
                      // savingsPercentage = Math.round(
                      //   (savings / monthlyTotal) * 100,
                      // );
                    }

                    return (
                      <div
                        key={plan.id}
                        onClick={() => setSelectedPlan(plan.id as 0 | 1 | 2)}
                        className={`relative flex w-[260px] cursor-pointer flex-col overflow-hidden text-nowrap rounded-[12px] bg-light-grey px-4 py-3.5 transition-all`}
                      >
                        {/* Preferred Plan Label */}
                        {/* {plan.isPopular && (
                          <span className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 rounded-full bg-red px-2.5 py-1 text-xs font-semibold text-white">
                            Preferred Plan
                          </span>
                        )} */}

                        {/* Plan Header */}
                        <div className="flex flex-row items-center">
                          <AiIconWhite
                            className={`mr-3 ${color} size-[44px] shrink-0`}
                          />

                          <div>
                            <div className="flex flex-row">
                              <div className="h-5 text-sm font-extrabold text-primary">
                                {t(plan.name)}
                              </div>

                              {/* Savings Label */}
                              {billingCycle === "yearly" &&
                                plan.monthlyPrice &&
                                plan.yearlyPrice && (
                                  <span className="ml-2 flex items-center justify-center rounded-full bg-violet/70 px-2 text-[10px] font-semibold text-white">
                                    {t("onboarding.step3_choose_plan.save")}{" "}
                                    {savings}€
                                  </span>
                                )}
                            </div>

                            <div className="text-xl font-extrabold text-primary">
                              {plan.monthlyPrice && plan.yearlyPrice ? (
                                <>
                                  {billingCycle === "monthly"
                                    ? plan.monthlyPrice
                                    : plan.yearlyPrice}
                                  €
                                  <span className="ml-1 text-sm">
                                    {t("onboarding.step3_choose_plan.month")}
                                  </span>
                                </>
                              ) : (
                                t("onboarding.step3_choose_plan.contact_us")
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setSelectedPlan(plan.id as 0 | 1 | 2 | 3)
                          }
                          className={`mt-6 w-full self-center text-nowrap rounded-[8px] py-3 text-sm font-semibold ${selectedPlan == plan.id ? "bg-primary text-white" : "bg-white text-primary"}`}
                        >
                          {selectedPlan === plan.id
                            ? t("onboarding.step3_choose_plan.selected_plan")
                            : t("onboarding.step3_choose_plan.select_plan")}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Show Details Button */}
              <div className="mb-6 flex w-full items-center justify-between">
                <button
                  className="mb-4 w-[260px] rounded-lg border border-primary py-2 font-semibold text-primary hover:bg-light-grey"
                  onClick={() => setShowPlanDetails(true)}
                >
                  {t("onboarding.step3_choose_plan.show_plan_details")}
                </button>
              </div>

              {/* Navigation Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  className="w-44 rounded-lg bg-light-grey px-4 py-3 text-base font-semibold text-primary hover:scale-[102%] active:scale-95"
                  onClick={onPrevious}
                >
                  {t("onboarding.step3_choose_plan.previous")}
                </button>

                <button
                  className={`flex w-44 items-center justify-center rounded-lg bg-primary px-4 py-3 text-base font-semibold text-white hover:scale-[102%]
                active:scale-95`}
                  onClick={handleNext}
                >
                  {t("onboarding.step3_choose_plan.next")}
                  {isLoading && (
                    <Loader className="ml-2 mt-0.5 size-3.5 shrink-0 animate-spin stroke-white" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section: App Preview Image */}
        <div className="hidden h-[85%] items-center justify-end py-8 lg:flex">
          <img
            src={AppPreview}
            alt="App preview"
            className="max-h-full max-w-full rounded-xl transition-transform"
          />
        </div>

        <div className="absolute bottom-8 right-32 flex shrink-0 items-center justify-center space-x-5 text-nowrap">
          <Select
            value={language}
            onValueChange={async (value) => {
              await changeLanguage(value);
            }}
          >
            <SelectTrigger className="w-full rounded-lg border border-light-grey p-3">
              <SelectValue
                placeholder={language == "en" ? "English" : "Français"}
              />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="fr">Français</SelectItem>
            </SelectContent>
          </Select>

          <button
            onClick={() => logout()}
            className="font-medium text-primary hover:underline active:brightness-110"
          >
            {t("logout")}
          </button>
        </div>
      </div>

      {/* Modal for Plan Details */}
      {showPlanDetails && (
        <PlanDetailsPopup onClose={() => setShowPlanDetails(false)} />
      )}
    </>
  );
};

export { Step3ChoosePlan };
