// Helper function to highlight matched text
export const highlightMatch = (text: string, search: string) => {
  if (!search) return text;

  // Escape special regex characters in the search string
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const escapedSearch = escapeRegExp(search);
  const regex = new RegExp(`(${escapedSearch})`, "gi");
  const parts = text.split(regex);

  // Render each part, wrapping matches in <strong> and others in <span>
  return (
    <>
      {parts.map((part, index) =>
        index % 2 === 1 ? (
          <strong key={index} className="font-bold">
            {part}
          </strong>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </>
  );
};
