// AddWorkspaceDialog.tsx
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useCreateMembership } from "@/axios/mutations";
import { Badge } from "@/components/ui/badge";
import { Loader } from "@/assets/icons";
import { Checkbox } from "../ui/checkbox";
import { useTranslation } from "react-i18next";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";

type AddWorkspaceDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AddWorkspaceDialog = ({ isOpen, onClose }: AddWorkspaceDialogProps) => {
  const { t } = useTranslation();

  const { user, refetchWorkspaces, organization } = useUserContext();

  const { createWorkspace } = useWorkspaceContext();
  const { createMembership } = useCreateMembership();

  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceNameError, setWorkspaceNameError] = useState("");

  const [teamMembers, setTeamMembers] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assignLicense, setAssignLicense] = useState(false);

  const validateWorkspaceName = (name: string) => {
    if (name.length > 20) {
      setWorkspaceNameError(
        t("workspace.add_workspace_dialog.workspace_name_too_long"),
      );
      return false;
    } else {
      setWorkspaceNameError("");
      return true;
    }
  };

  const handleEmailInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Enter" || e.key === "," || e.key === "Tab") {
      e.preventDefault();
      addEmailToTeam();
    }
  };

  const addEmailToTeam = () => {
    const email = emailInput.trim().toLowerCase();

    if (!email) {
      setEmailError(t("workspace.add_workspace_dialog.email_cannot_be_empty"));
    } else if (!validateEmail(email)) {
      setEmailError(t("workspace.add_workspace_dialog.invalid_email"));
    } else if (email == user?.email) {
      setEmailError(t("workspace.add_workspace_dialog.self_email_error"));
      return false;
    } else if (teamMembers.includes(email)) {
      setEmailError(t("workspace.add_workspace_dialog.email_already_added"));
    } else {
      setTeamMembers([...teamMembers, email]);
      setEmailInput("");
      setEmailError("");
      return true;
    }

    return false;
  };

  const handleRemoveMember = (emailToRemove: string) => {
    setTeamMembers(teamMembers.filter((email) => email !== emailToRemove));
  };

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleWorkspaceNameChange = (name: string) => {
    setWorkspaceName(name);
    validateWorkspaceName(name);
  };

  const handleCreateWorkspace = async () => {
    try {
      // Validate workspace name before proceeding
      if (!validateWorkspaceName(workspaceName.trim())) {
        return;
      }

      if (emailInput) {
        if (!addEmailToTeam()) {
          return;
        }
      }

      setIsLoading(true);
      const newWorkspace = await createWorkspace(
        workspaceName.trim() !== ""
          ? workspaceName
          : user?.name || "New Workspace",
        user?.user_id as string,
        assignLicense && organization ? organization?._id : null,
      );

      // Filter out any empty team member emails
      const filteredTeamMembers = Array.from(new Set(teamMembers))?.filter(
        (email) => email.trim() !== "",
      );

      // Invite team members
      await Promise.all(
        filteredTeamMembers?.map(async (email) => {
          await createMembership({
            user_id: user?.user_id as string,
            workspace_id: newWorkspace._id,
            email,
            role: "member",
          });
        }),
      );

      await refetchWorkspaces();

      // Reset state and close dialog
      setWorkspaceName("");
      setTeamMembers([]);
      setEmailInput("");
      setEmailError("");
      onClose();
    } catch (error) {
      console.error(error);
      setEmailError(t("workspace.add_workspace_dialog.creation_failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>
            {t("workspace.add_workspace_dialog.create_new_workspace")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "workspace.add_workspace_dialog.set_up_your_workspace_and_invite_team_members",
            )}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {/* Workspace Name */}
          <div>
            <Label htmlFor="workspaceName">
              {t("workspace.add_workspace_dialog.workspace_name")}
            </Label>
            <Input
              id="workspaceName"
              type="text"
              value={workspaceName}
              onChange={(e) => handleWorkspaceNameChange(e.target.value)}
              placeholder={t(
                "workspace.add_workspace_dialog.enter_workspace_name",
              )}
              className="mt-4"
            />
            {workspaceNameError && (
              <p className="mt-2.5 text-sm text-red">{workspaceNameError}</p>
            )}
          </div>

          {/* Team Members */}
          <div>
            <Label htmlFor="teamMembers">
              {t("workspace.add_workspace_dialog.team_members_email")}
            </Label>

            {teamMembers.length > 0 && (
              <div className="my-3 flex flex-wrap items-center gap-2">
                {Array.from(new Set(teamMembers))?.map((email) => (
                  <Badge
                    key={email}
                    variant="secondary"
                    className="flex items-center space-x-1"
                  >
                    <span className="mb-0.5">{email}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveMember(email)}
                      className="mb-1 ml-1 text-sm text-dark-grey hover:text-primary"
                    >
                      ×
                    </button>
                  </Badge>
                ))}
              </div>
            )}

            <Input
              id="teamMembers"
              type="email"
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
                if (emailError) setEmailError("");
              }}
              onKeyDown={handleEmailInputKeyDown}
              placeholder={t(
                "workspace.add_workspace_dialog.enter_team_emails",
              )}
              className={`mt-4 ${emailError ? "border-red" : ""}`}
            />
            {emailError ? (
              <p className="mt-2.5 text-sm text-red">{emailError}</p>
            ) : (
              <p className="mt-2.5 text-sm text-dark-grey">
                {t(
                  "workspace.add_workspace_dialog.press_enter_comma_or_tab_to_add_email",
                )}
              </p>
            )}
          </div>

          {/* Assign License */}
          {organization && organization.available_licenses > 0 && (
            <div className="py-4">
              <Label htmlFor="assignLicense">
                {t("workspace.add_workspace_dialog.assign_license")}
              </Label>
              <div className="mt-4 flex items-center">
                <Checkbox
                  id="assignLicense"
                  checked={assignLicense}
                  onCheckedChange={(checked) => setAssignLicense(!!checked)}
                  className="mr-2 size-4 border-primary text-primary data-[state=checked]:bg-primary"
                />

                <span className="text-sm font-medium text-primary">
                  {t(
                    "workspace.add_workspace_dialog.use_one_of_your_organization_licenses",
                  )}
                </span>
              </div>
              <p className="ml-6 mt-2 text-sm text-dark-grey">
                {t("workspace.add_workspace_dialog.available_licenses")}

                {organization.available_licenses}
              </p>
            </div>
          )}
        </div>

        <DialogFooter className="mt-4">
          <Button variant="secondary" onClick={onClose}>
            {t("workspace.add_workspace_dialog.cancel")}
          </Button>
          <Button
            onClick={handleCreateWorkspace}
            disabled={
              isLoading || !workspaceName.trim() || workspaceNameError !== "" // Disable if there's a workspace name error
            }
          >
            {t("workspace.add_workspace_dialog.create_workspace")}
            {isLoading && (
              <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkspaceDialog;
