import { useFetcher } from "@axios/fetcher";
import { Channel } from "@types"; // Make sure Channel type is defined and imported

/**
 * Hook to create a new channel and link it to a workspace.
 */
function useCreateChannel() {
  const { fetcher } = useFetcher();

  /**
   * Function to create a new channel by making a POST request to the API.
   * @param channelData - Data required to create a new channel.
   * @returns The created Channel object.
   */
  const createChannel = async (channelData: {
    workspace_id: string;
    channel_id: string;
    type: Channel["type"];
  }): Promise<Channel> => {
    try {
      // Make the POST request to create a channel
      const createdChannel = await fetcher(
        `/api/channels/create`,
        "post",
        channelData,
      );

      return createdChannel as Channel;
    } catch (error) {
      // Log the error if needed and rethrow for further handling
      console.error("Failed to create channel", error);
      throw error;
    }
  };

  return {
    createChannel,
  };
}

export { useCreateChannel };
