import React from "react";

// Accept icon and title as props for modular use
interface CardProps {
  title?: string; // The card title
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; // Icon as a React component (e.g., imported SVGs)
  iconClassName?: string; // Additional className for the icon
  iconStyle?: React.CSSProperties; // Optional style props like fill
  children: React.ReactNode; // Children will be the card content (metrics, charts, etc.)
  className?: string; // Optional for extra styling
}

export function Card({
  title,
  icon: IconComponent,
  iconClassName = "", // Default empty class for icon
  iconStyle = {}, // Default empty style for icon
  children,
  className = "", // Default empty class for the card container
}: CardProps) {
  return (
    <div className={`${className} h-[300px] rounded-[10px] bg-white p-6`}>
      {/* Title and Icon */}
      <div className="mb-8 flex items-center">
        {IconComponent && (
          <IconComponent
            className={`mr-3 size-6 shrink-0 text-primary ${iconClassName}`} // Custom icon styles
            style={iconStyle} // Additional icon styles like fill
          />
        )}

        {/* Render Title if provided */}
        {title && (
          <h3 className="truncate font-sans text-lg font-extrabold text-primary">
            {title}
          </h3>
        )}
      </div>

      {/* Card content (Metrics, BarCharts, etc.) */}
      {children}
    </div>
  );
}
