// settings/BillingSettings.tsx

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import InvoicesTable from "@/components/billing-settings/InvoicesTable";
import { useMemo, useState } from "react";
import { PlanDetailsPopup } from "@/components/PlanDetailsPopup";
import PaymentMethods from "@/components/billing-settings/PaymentMethods";
import { Invoice } from "@/types";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import BillingSettingsSkeleton from "./BillingSettingsSkeleton";
import { PLANS } from "@/types/PLANS.config";
import { useCancelSubscription } from "@/axios/mutations";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Loader } from "@/assets/icons";
import { useUserContext } from "@/layouts/user-provider/useUserContext";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useTranslation } from "react-i18next";

/**
 * Renders the Billing Settings page, allowing users to manage their subscription and billing.
 */
const BillingSettings = () => {
  const { t } = useTranslation();

  const { organization } = useUserContext();

  const { currentWorkspace } = useWorkspaceContext();

  const {
    subscriptionDetails,
    refetchSubscriptionDetails,
    isLoadingSubscriptionDetails,
    isLoadingPaymentMethods,
  } = useSubscriptionContext();

  const [isPlanPopupOpen, setIsPlanPopupOpen] = useState(false);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const { cancelSubscription } = useCancelSubscription();

  const handleCancelSubscription = async () => {
    setIsCanceling(true);
    try {
      await cancelSubscription(subscriptionDetails?.id as string);
      await refetchSubscriptionDetails();
      setIsCancelDialogOpen(false);
    } catch (error) {
      // Handle error (e.g., show a notification)
      console.error("Failed to cancel subscription", error);
    } finally {
      setIsCanceling(false);
    }
  };

  const isLoading = useMemo(
    () => isLoadingSubscriptionDetails || isLoadingPaymentMethods,
    [isLoadingSubscriptionDetails, isLoadingPaymentMethods],
  );

  if (isLoading) {
    return <BillingSettingsSkeleton />;
  }

  console.debug("Subscription details:", subscriptionDetails);
  console.debug("Organization Subscription ID:", organization?.subscription_id);

  const plan_name =
    subscriptionDetails?.plan_id !== undefined &&
    subscriptionDetails?.plan_id !== null
      ? PLANS[subscriptionDetails.plan_id].name
      : null;

  console.info("Subscription details:", subscriptionDetails);
  console.info("Organization Subscription ID:", organization?.subscription_id);

  return (
    <div className="flex h-full overflow-hidden bg-light-grey">
      <div className="flex h-full w-[550px] flex-col overflow-auto rounded-[12px] bg-white p-8 scrollbar-hide">
        <div>
          <h2 className="mb-8 text-base font-semibold text-primary">
            {t("settings.billing.subscription_billing")}
          </h2>
          {/* Current Plan Section */}
          <div className="mb-12 flex flex-row items-center justify-between">
            <div>
              <Label className="mb-2 block text-sm font-semibold text-dark-grey">
                {t("settings.billing.current_plan")}
              </Label>
              <p className="text-sm font-bold text-primary">
                {plan_name && subscriptionDetails?.status != "canceled"
                  ? t(plan_name)
                  : t("settings.billing.no_active_subscription")}
              </p>

              {currentWorkspace?.organization_id && (
                <p className="mt-2 text-sm text-dark-grey">
                  {t("settings.billing.managed_by_organization")}
                </p>
              )}

              {subscriptionDetails?.cancel_at_period_end &&
                subscriptionDetails.status != "canceled" && (
                  <p className="mt-2 text-sm text-red">
                    {t("settings.billing.subscription_canceled_until")}{" "}
                    {new Date(
                      subscriptionDetails.current_period_end * 1000,
                    ).toLocaleDateString()}
                    .
                  </p>
                )}
            </div>

            {/* Action Buttons */}
            {(!organization ||
              !subscriptionDetails ||
              organization.subscription_id !=
                currentWorkspace?.subscription_id) && (
              <Button
                onClick={() => setIsPlanPopupOpen(true)}
                className="flex min-w-40 items-center justify-center space-x-4 bg-violet px-3 font-semibold hover:bg-violet hover:brightness-110"
              >
                {!subscriptionDetails ||
                subscriptionDetails?.status == "canceled"
                  ? t("settings.billing.choose_plan")
                  : subscriptionDetails?.cancel_at_period_end
                    ? t("settings.billing.change_plan")
                    : t("settings.billing.upgrade_plan")}
              </Button>
            )}
          </div>

          {subscriptionDetails &&
            (!organization ||
              organization.subscription_id !=
                currentWorkspace?.subscription_id) && (
              <>
                {/* Payment Methods */}
                {subscriptionDetails?.status != "canceled" && (
                  <PaymentMethods />
                )}

                {/* Invoices Table */}
                <div>
                  <Label className="mb-2 block text-sm font-semibold text-primary">
                    {t("settings.billing.invoices")}
                  </Label>
                  <InvoicesTable
                    invoices={
                      subscriptionDetails?.invoices.filter((invoice) => {
                        const date = new Date(invoice.date * 1000);
                        const blabla_start = new Date(2024, 11, 1); // Note : Les mois commencent à 0 (0 = janvier)

                        if (date < blabla_start) {
                          return false;
                        }

                        if (invoice.amount_due <= 0) {
                          return false;
                        }

                        return true;
                      }) as Invoice[]
                    }
                  />
                </div>

                {subscriptionDetails?.status != "canceled" &&
                  !subscriptionDetails?.cancel_at_period_end &&
                  (!organization ||
                    organization.subscription_id !=
                      currentWorkspace?.subscription_id) && (
                    <Button
                      onClick={() => setIsCancelDialogOpen(true)}
                      className="mt-12 flex w-44 items-center justify-center space-x-4 justify-self-end bg-red font-semibold hover:bg-red hover:brightness-90"
                    >
                      {t("settings.billing.cancel_subscription")}
                    </Button>
                  )}

                {!organization &&
                  subscriptionDetails?.cancel_at_period_end &&
                  subscriptionDetails?.status != "canceled" && (
                    <Button
                      onClick={() => setIsPlanPopupOpen(true)}
                      className="mt-12 flex w-52 items-center justify-center space-x-4 justify-self-end bg-violet font-semibold hover:bg-violet hover:brightness-110"
                    >
                      {t("settings.billing.reactivate_subscription")}
                    </Button>
                  )}
              </>
            )}
        </div>
      </div>

      {/* Dialog for Confirming Subscription Cancellation */}
      <Dialog
        open={isCancelDialogOpen}
        onOpenChange={() => {
          setIsCancelDialogOpen(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t("settings.billing.cancel_subscription_title")}
            </DialogTitle>
            <DialogDescription>
              {t("settings.billing.cancel_subscription_prompt")}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => setIsCancelDialogOpen(false)}
              disabled={isCanceling}
            >
              {t("settings.billing.keep_subscription")}
            </Button>
            <Button
              onClick={handleCancelSubscription}
              className="bg-red hover:bg-red hover:brightness-90"
              disabled={isCanceling}
            >
              {t("settings.billing.confirm_cancellation")}
              {isCanceling && (
                <Loader className="ml-2 size-3.5 shrink-0 animate-spin stroke-white" />
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {/* Plan Details Popup */}
      {isPlanPopupOpen && (
        <PlanDetailsPopup onClose={() => setIsPlanPopupOpen(false)} />
      )}
    </div>
  );
};

export default BillingSettings;
