import { useRef, useEffect, useState, useMemo } from "react";
import { Textarea } from "@/components/ui/textarea";
import { highlightMatch } from "@/utils/highlightMatch";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useTranslation } from "react-i18next";
import { useUserContext } from "@/layouts/user-provider/useUserContext";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import i18nEmoji_en from "@emoji-mart/data/i18n/en.json";
import i18nEmoji_fr from "@emoji-mart/data/i18n/fr.json";
import { Smile } from "lucide-react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface EmojiMartEmoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  shortcodes: string;
  skin?: number;
  keywords?: string[];
  aliases?: string[];
}

type CustomTextareaProps = {
  value: string;
  setValue: (value: string) => void;
  onSubmit: () => void;
  showPreSavedReplies?: boolean;
  error?: string | null;
};

export const CustomTextarea = ({
  value,
  setValue,
  onSubmit,
  showPreSavedReplies = false,
  error,
}: CustomTextareaProps) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Tracks which result is keyboard-highlighted
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Track the slash-popover open state
  const [slashPopoverOpen, setSlashPopoverOpen] = useState(false);

  // When slash command was triggered, store the index of `/` within the textarea value.
  const [slashIndex, setSlashIndex] = useState<number | null>(null);

  // The searchValue is the text from the slash index up to the current caret position
  const [searchValue, setSearchValue] = useState("");

  // Pre-saved replies from your workspace
  const { currentWorkspace } = useWorkspaceContext();
  const { refetchWorkspaces } = useUserContext();
  const preSavedReplies = useMemo(
    () => currentWorkspace?.pre_saved_replies || [],
    [currentWorkspace, refetchWorkspaces],
  );

  // Filter them based on searchValue
  const filteredReplies = useMemo(() => {
    return preSavedReplies.filter((reply) =>
      reply.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [preSavedReplies, searchValue]);

  // Emoji picker states/refs
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const i18nEmoji = language === "fr" ? i18nEmoji_fr : i18nEmoji_en;

  // =============== KEYBOARD HANDLERS ===============

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (slashPopoverOpen) {
      // If slash popover is already open, handle arrow/enter/escape
      if (!filteredReplies || !showPreSavedReplies) return;

      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev < filteredReplies.length - 1 ? prev + 1 : 0,
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev > 0 ? prev - 1 : filteredReplies.length - 1,
        );
      } else if (e.key === "Enter") {
        e.preventDefault();
        // If there's a selected reply, pick it
        if (filteredReplies[selectedIndex]) {
          handleSelectReply(filteredReplies[selectedIndex]);
        } else {
          // If nothing is selected, maybe close or do something else.
          // We'll just close in this example.
          setSlashPopoverOpen(false);
        }
      } else if (e.key === "Escape") {
        e.preventDefault();
        closeSlashPopover(); // We'll define a helper to close
      }
    } else {
      // If slash popover is NOT open, watch for user typing slash
      if (e.key === "/" && showPreSavedReplies) {
        // The user literally pressed '/'
        const caretPos = e.currentTarget.selectionStart;
        setSlashIndex(caretPos);
        // Start with an empty searchValue; it will get updated in handleChange below
        setSearchValue("");
        setSelectedIndex(0);
        setSlashPopoverOpen(true);
        setShowEmojiPicker(false);
      } else if (e.key === "Escape") {
        // maybe you want to blur on ESC if popover isn't open
        e.preventDefault();
        textareaRef.current?.blur();
      } else if (e.key === "Enter" && !e.shiftKey) {
        // Normal message submit
        e.preventDefault();
        onSubmit();
      }
    }
  };

  // =============== TEXTAREA ONCHANGE ===============
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setValue(newValue);

    // If slash popover is open, we want to extract the substring from slashIndex to caret
    if (slashPopoverOpen && slashIndex !== null) {
      const caretPos = e.target.selectionStart;
      // If the caret is behind the slashIndex, or if user removed the slash, close
      if (
        caretPos < slashIndex ||
        !newValue[slashIndex] ||
        newValue[slashIndex] !== "/"
      ) {
        closeSlashPopover();
        return;
      }

      // Extract substring from slashIndex + 1 up to caret
      const query = newValue.substring(slashIndex + 1, caretPos);

      // If user typed whitespace or newline, or nothing after slash => handle closing or keep
      // You can decide what triggers closing.
      if (!query || query.trim() === "") {
        // e.g. if user typed "/ " we might close or keep open with an empty search
        setSearchValue("");
      } else {
        setSearchValue(query.trim());
      }

      // If the user removed slash or pressed enter we handle that in onKeyDown
    }
  };

  // =============== SLASH POPOVER SELECT ===============
  const handleSelectReply = (reply: string) => {
    if (!showPreSavedReplies || !textareaRef.current) return;

    if (slashIndex !== null) {
      const text = value;
      const beforeSlash = text.slice(0, slashIndex);
      // Suppose the user might have typed something after slash but before caret
      // we can skip that text and just insert the reply
      const caretPos = textareaRef.current.selectionStart;
      const afterCaret = text.slice(caretPos);

      // Insert the reply right after the slash, ignoring typed substring
      const newText = beforeSlash + reply + afterCaret;
      setValue(newText);

      // Move the caret
      const newCaretPosition = beforeSlash.length + reply.length;
      requestAnimationFrame(() => {
        textareaRef.current?.setSelectionRange(
          newCaretPosition,
          newCaretPosition,
        );
        textareaRef.current?.focus();
      });
    }

    closeSlashPopover();
  };

  // =============== CLOSE SLASH POPOVER HELPER ===============
  const closeSlashPopover = () => {
    setSlashPopoverOpen(false);
    setSlashIndex(null);
    setSearchValue("");
    setSelectedIndex(0);
  };

  // =============== TEXTAREA AUTO-RESIZE ===============
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "40px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${Math.min(scrollHeight, 250)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  // =============== CLICK OUTSIDE HANDLERS ===============
  useEffect(() => {
    if (!slashPopoverOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        closeSlashPopover();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [slashPopoverOpen]);

  // For Emoji picker
  useEffect(() => {
    if (!showEmojiPicker) return;
    const handleClickOutsideEmoji = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideEmoji);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEmoji);
    };
  }, [showEmojiPicker]);

  // =============== EMOJI SELECT ===============
  const handleEmojiSelect = (emoji: EmojiMartEmoji) => {
    const newText = value + emoji.native;
    setValue(newText);
    setShowEmojiPicker(false);
    textareaRef.current?.focus();
  };

  return (
    <div className="relative mr-2 grow" ref={containerRef}>
      <div className="flex w-full items-center justify-between">
        <Textarea
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          value={value}
          onChange={handleChange}
          placeholder={
            showPreSavedReplies
              ? t("custom_textarea.placeholder_with_replies")
              : t("custom_textarea.placeholder")
          }
          className="resize-none rounded-[10px] border border-light-grey bg-white pr-12 font-medium text-primary scrollbar-hide placeholder:text-dark-grey"
          style={{ minHeight: "40px", maxHeight: "250px" }}
        />

        {/* Emoji toggle button */}
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <button
                ref={toggleButtonRef}
                type="button"
                onClick={() => {
                  closeSlashPopover();
                  setShowEmojiPicker((prev) => !prev);
                }}
                className="absolute right-4 text-lg"
              >
                <Smile className="size-5 text-primary" />
              </button>
            </TooltipTrigger>

            {!showEmojiPicker && (
              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                {t("custom_textarea.emoji_picker_tooltip")}
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <div
          ref={emojiPickerRef}
          className="absolute bottom-[50px] right-0 z-50"
        >
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            locale={language}
            i18n={i18nEmoji}
            previewPosition="none"
            skinTonePosition="search"
          />
        </div>
      )}

      {/* Slash popover */}
      {slashPopoverOpen && filteredReplies?.length > 0 && (
        <div className="absolute bottom-[50px] left-0 z-50 max-h-[400px] w-full max-w-full overflow-auto rounded-md border border-light-grey bg-white shadow-[0_2px_20px_rgba(0,0,0,0.25)] scrollbar-hide">
          {filteredReplies.map((r, i) => (
            <div
              key={i}
              onMouseDown={(e) => {
                // Use onMouseDown, so we don't lose focus before we can handle the click
                e.preventDefault();
                handleSelectReply(r);
              }}
              onMouseEnter={(e) => {
                e.preventDefault();
                setSelectedIndex(i);
              }}
              className={`cursor-pointer p-2 hover:bg-light-grey ${
                i === selectedIndex ? "bg-light-grey" : ""
              }`}
            >
              <span className="line-clamp-3">
                {highlightMatch(r, searchValue)}
              </span>
            </div>
          ))}
        </div>
      )}

      {/* Display error message */}
      {error && <p className="mt-3 text-sm text-red">{error}</p>}
    </div>
  );
};
