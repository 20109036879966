import { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { DateRangePicker } from "./DateRangePicker";
import { DateRange } from "react-day-picker";
import { startOfDay, endOfDay, subMonths } from "date-fns";
import { Channel, Classification, GetCommentsDto } from "@/types";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import {
  FacebookCircleIcon,
  InstagramCircleIcon,
  LinkedinCircleIcon,
  OptionButton,
  OptionOpenedButton,
  TiktokCircleIcon,
  YoutubeCircleIcon,
} from "@/assets/icons";
import { SelectIcon, SelectedIcon } from "@assets/icons";
import { categoryEmojis, sentimentEmojis } from "@/types/classification.types";
import { useTaskContext } from "@/layouts/useTask.context";
import { useTranslation } from "react-i18next";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";
import { PlanId } from "@/types/PLANS.config";
import PlanRestrictionAlertDialog from "@/layouts/app-wrapper/PlanRestrictionAlertDialog";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export type AllowedCategory = "Business" | "Sensitive Content";

type FilterPopoverProps = {
  type: "comment" | "message";
};

export const FilterPopover = ({ type }: FilterPopoverProps) => {
  const { t } = useTranslation();

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { applyFilters, resetFilters, filterOptions } = useTaskContext();
  const { subscriptionDetails } = useSubscriptionContext();

  const { channels } = useWorkspaceContext();

  const defaultDate = {
    from: type == "comment" ? subMonths(new Date(), 1) : undefined,
    to: type == "comment" ? new Date() : undefined,
  };

  const defaultSentiments: Classification["sentiment"][] = [
    "Positive",
    "Negative",
  ];

  const defaultCategories: Classification["category"][] = [
    "Business",
    "Direct Interactions",
    "Sensitive Content",
    "Feedback",
    "Questions",
    "Complaint",
    "Humor",
    "Sharing",
  ];

  const defaultPlatforms: Channel["type"][] = [
    "facebook",
    "instagram",
    // "linkedin",
    "youtube",
    "tiktok",
  ];

  const defaultPlatformIcons = {
    facebook: <FacebookCircleIcon className="size-3.5" />,
    instagram: <InstagramCircleIcon className="size-3.5" />,
    youtube: <YoutubeCircleIcon className="size-3.5" />,
    tiktok: <TiktokCircleIcon className="size-3.5" />,
    "tiktok-ads": <TiktokCircleIcon className="size-3.5" />,
    "facebook-ads": <FacebookCircleIcon className="size-3.5" />,
    linkedin: <LinkedinCircleIcon className="size-3.5" />,
  };

  const defaultAdsFilter =
    (subscriptionDetails?.plan_id as PlanId) >= 2 ? "both" : "organic";

  const defaultHiddenFilter: "hidden" | "not_hidden" | "both" = "both";
  const defaultLikedFilter: "liked" | "not_liked" | "both" = "both";

  const [date, setDate] = useState<DateRange | undefined>({
    from: filterOptions.startDate
      ? new Date(filterOptions.startDate)
      : defaultDate.from,
    to: filterOptions.endDate
      ? new Date(filterOptions.endDate)
      : defaultDate.to,
  });

  const [sentiments, setSentiments] = useState<Classification["sentiment"][]>(
    filterOptions.sentiment ?? defaultSentiments,
  );

  const [categories, setCategories] = useState<Classification["category"][]>(
    filterOptions.category ?? defaultCategories,
  );

  const [platforms, setPlatforms] = useState<Channel["type"][]>(
    filterOptions.channel_type ? filterOptions.channel_type : defaultPlatforms,
  );

  const [adFilter, setAdFilter] = useState<"organic" | "ads" | "both">(
    filterOptions.adFilter ?? defaultAdsFilter,
  );

  const [hiddenFilter, setHiddenFilter] = useState<
    "hidden" | "not_hidden" | "both"
  >(filterOptions.hiddenFilter ?? defaultHiddenFilter);

  const [likedFilter, setLikedFilter] = useState<
    "liked" | "not_liked" | "both"
  >(filterOptions.likedFilter ?? defaultLikedFilter);

  const [firstChangeDone, setFirstChangeDone] = useState(false);

  const [showAlertDialog, setShowAlertDialog] = useState(false);

  // Add this useEffect to sync local state with context's filterOptions
  useEffect(() => {
    // console.debug("Filters changed, syncing local state with context");
    // console.debug("Filter options:", filterOptions);

    setDate({
      from: filterOptions.startDate
        ? new Date(filterOptions.startDate)
        : defaultDate.from,
      to: filterOptions.endDate
        ? new Date(filterOptions.endDate)
        : defaultDate.to,
    });
    setSentiments(filterOptions.sentiment ?? defaultSentiments);
    setCategories(filterOptions.category ?? defaultCategories);
    setPlatforms(filterOptions.channel_type ?? defaultPlatforms);
    setAdFilter(filterOptions.adFilter ?? defaultAdsFilter);
    setHiddenFilter(filterOptions.hiddenFilter ?? defaultHiddenFilter);
    setLikedFilter(filterOptions.likedFilter ?? defaultLikedFilter);
  }, [resetFilters]);

  useEffect(() => {
    setFirstChangeDone(false);
  }, []);

  const handleApply = () => {
    const startDate = date?.from ? startOfDay(date.from) : undefined;
    const endDate = date?.to ? endOfDay(date.to) : undefined;

    // Determine if all sentiments are selected
    const allSentimentsSelected =
      defaultSentiments.length === sentiments.length;

    // Determine if all categories are selected
    const allCategoriesSelected =
      defaultCategories.length === categories.length;

    // Determine if all platforms are selected
    const allPlatformsSelected = defaultPlatforms.length === platforms.length;

    // Construct filters conditionally
    const filters: Partial<GetCommentsDto> = {
      ...(startDate
        ? { startDate: startDate.toISOString() }
        : { startDate: undefined }),
      ...(endDate
        ? { endDate: endDate.toISOString() }
        : { endDate: undefined }),
      // Include sentiment only if not all selected and at least one is selected
      ...(!allSentimentsSelected
        ? { sentiment: sentiments }
        : { sentiment: undefined }),
      // Include category only if not all selected and at least one is selected
      ...(!allCategoriesSelected
        ? { category: categories }
        : { category: undefined }),
      // Include channel_type only if not all selected and at least one is selected
      ...(!allPlatformsSelected
        ? { channel_type: platforms }
        : { channel_type: undefined }),
      // Include adFilter only if it's not "both"
      ...(adFilter !== "both" ? { adFilter } : { adFilter: undefined }),

      ...(hiddenFilter !== "both"
        ? { hiddenFilter }
        : { hiddenFilter: undefined }),

      ...(likedFilter !== "both"
        ? { likedFilter }
        : { likedFilter: undefined }),
    };

    console.debug("Filters to apply:", filters);

    applyFilters(filters);
    setIsSelectOpen(false);
    console.info("Filters applied", filters);
    setFirstChangeDone(false);
  };

  const handleReset = () => {
    setDate(defaultDate);
    setSentiments(defaultSentiments);
    setCategories(defaultCategories);
    setPlatforms(defaultPlatforms);
    setAdFilter(defaultAdsFilter);

    setHiddenFilter(defaultHiddenFilter);
    setLikedFilter(defaultLikedFilter);
    console.info("Filters reset to default values");
  };

  const toggleSentiment = (sentiment: Classification["sentiment"]) => {
    if (sentiments.length + categories.length == 1 && !firstChangeDone) {
      setSentiments([sentiment]);
      setCategories([]);
    } else if (
      sentiments.length + categories.length ==
      defaultSentiments.length + defaultCategories.length
    ) {
      setSentiments([sentiment]);
      setCategories([]);
    } else {
      setSentiments((prev) =>
        prev.includes(sentiment)
          ? prev.filter((s) => s !== sentiment)
          : [...prev, sentiment],
      );
    }

    setFirstChangeDone(true);
  };

  const toggleCategory = (category: Classification["category"]) => {
    if (sentiments.length + categories.length == 1 && !firstChangeDone) {
      setCategories([category]);
      setSentiments([]);
    } else if (
      sentiments.length + categories.length ==
      defaultSentiments.length + defaultCategories.length
    ) {
      setCategories([category]);
      setSentiments([]);
    } else {
      setCategories((prev) =>
        prev.includes(category)
          ? prev.filter((c) => c !== category)
          : [...prev, category],
      );
    }

    setFirstChangeDone(true);
  };

  const togglePlatform = (platform: Channel["type"]) => {
    if (!firstChangeDone) {
      setPlatforms([platform]);
      setFirstChangeDone(true);
    } else if (platforms.length == defaultPlatforms.length) {
      setPlatforms([platform]);
    } else {
      setPlatforms((prev) =>
        prev.includes(platform)
          ? prev.filter((p) => p !== platform)
          : [...prev, platform],
      );
    }

    setFirstChangeDone(true);
  };

  const handleAdFilterChange = (value: "organic" | "ads" | "both" | null) => {
    if ((subscriptionDetails?.plan_id as number) < 2 && value != "organic") {
      setShowAlertDialog(true);
    } else {
      setAdFilter(value || "both"); // Fallback to "both" if deselected
    }
  };

  const handleHiddenFilterChange = (
    value: "hidden" | "not_hidden" | "both" | null,
  ) => {
    setHiddenFilter(value || "both");
  };

  const handleLikedFilterChange = (
    value: "liked" | "not_liked" | "both" | null,
  ) => {
    setLikedFilter(value || "both");
  };

  return (
    <Popover open={isSelectOpen} onOpenChange={setIsSelectOpen}>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <button
                className={`flex size-fit shrink-0 p-0 ${!isSelectOpen && "hover:brightness-90"}`}
              >
                {isSelectOpen ? <OptionOpenedButton /> : <OptionButton />}
              </button>
            </PopoverTrigger>
          </TooltipTrigger>

          {!isSelectOpen && (
            <TooltipContent
              className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
              sideOffset={5}
            >
              {t("tasks.FilterPopover.filter_tooltip")}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <PopoverContent className="max-h-[80vh] w-fit overflow-y-auto rounded-lg bg-white p-0 shadow-lg scrollbar-hide">
        <div className="px-6 pt-6">
          {/* Date Selection */}
          <div className="mb-5">
            <p className="mb-4 text-base font-extrabold">
              {t("tasks.FilterPopover.select_by_dates")}
            </p>
            <DateRangePicker date={date} setDate={setDate} />
          </div>

          {/* Sentiments, Categories, and Platforms */}
          <div className="mb-6">
            <p className="mb-5 text-base font-extrabold">
              {t("tasks.FilterPopover.filter_options")}
            </p>

            <div className="flex flex-row space-x-8">
              <div className="flex flex-col">
                <p className="mb-2 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.sentiments")}
                </p>

                <div className="flex flex-col space-y-1.5">
                  {defaultSentiments.map((sentiment) => (
                    <div
                      key={sentiment}
                      onClick={() => toggleSentiment(sentiment)}
                      className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                        sentiments.includes(sentiment)
                          ? "bg-light-grey"
                          : "bg-white hover:bg-light-grey/60"
                      }`}
                    >
                      <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                        <span>{sentimentEmojis[sentiment]}</span>
                        <span>
                          {t(
                            `tasks.classification.sentiment.${sentiment.toLowerCase()}`,
                          )}
                        </span>
                      </span>
                      {sentiments.includes(sentiment) ? (
                        <SelectedIcon />
                      ) : (
                        <SelectIcon />
                      )}
                    </div>
                  ))}
                </div>

                {/* Categories */}
                <p className="mb-2 mt-4 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.categories")}
                </p>

                <div className="flex flex-col space-y-1.5">
                  {defaultCategories.map((category) => (
                    <div
                      key={category}
                      onClick={() => toggleCategory(category)}
                      className={`flex cursor-pointer items-center justify-between space-x-4 rounded-[6px] px-3 py-2 ${
                        categories.includes(category)
                          ? "bg-light-grey"
                          : "bg-white hover:bg-light-grey/60"
                      }`}
                    >
                      <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                        <span>{categoryEmojis[category]}</span>
                        <span>
                          {t(
                            `tasks.classification.category.${category.toLowerCase().replace(/ /g, "_")}`,
                          )}
                        </span>
                      </span>
                      {categories.includes(category) ? (
                        <SelectedIcon />
                      ) : (
                        <SelectIcon />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Platforms */}
              {defaultPlatforms?.filter((platform) =>
                channels?.find((c) => c.type == platform),
              )?.length ? (
                <div>
                  <p className="mb-4 text-sm font-semibold text-dark-grey">
                    {t("tasks.FilterPopover.select_platform")}
                  </p>

                  <div className="flex flex-col space-y-1.5">
                    {defaultPlatforms
                      .filter((platform) =>
                        channels?.find((c) => c.type == platform),
                      )
                      .map((platform) => (
                        <div
                          key={platform}
                          onClick={() => togglePlatform(platform)}
                          className={`flex w-[210px] cursor-pointer items-center justify-between rounded-[6px] px-3 py-2 ${
                            platforms.includes(platform)
                              ? "bg-light-grey"
                              : "bg-white hover:bg-light-grey/60"
                          }`}
                        >
                          <span className="flex items-center space-x-3 text-sm font-semibold text-primary">
                            <span>{defaultPlatformIcons[platform]}</span>
                            <span>
                              {platform.charAt(0).toUpperCase() +
                                platform.slice(1)}
                            </span>
                          </span>
                          {platforms.includes(platform) ? (
                            <SelectedIcon />
                          ) : (
                            <SelectIcon />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* Toggle Group */}
          {type === "comment" && (
            <>
              <div className="mb-6">
                <p className="mb-4 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.comment_types")}
                </p>
                <ToggleGroup
                  type="single"
                  value={adFilter}
                  onValueChange={(value: string) =>
                    handleAdFilterChange(
                      value as "organic" | "ads" | "both" | null,
                    )
                  }
                  className="flex items-start justify-around space-x-2"
                >
                  {["organic", "ads", "both"].map((type) => (
                    <ToggleGroupItem
                      key={type}
                      value={type}
                      className={`flex w-32 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                        adFilter === type
                          ? "bg-light-grey"
                          : "border border-light-grey bg-white hover:bg-light-grey/60"
                      }`}
                      aria-label={t(`tasks.FilterPopover.${type}`)}
                    >
                      {t(`tasks.FilterPopover.${type}`)}
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              </div>

              {/* NEW: Hidden comments */}
              <div className="mb-6">
                <p className="mb-4 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.hidden_comments")}
                </p>
                <ToggleGroup
                  type="single"
                  value={hiddenFilter}
                  onValueChange={(value: string) =>
                    handleHiddenFilterChange(
                      value as "hidden" | "not_hidden" | "both" | null,
                    )
                  }
                  className="flex items-start justify-around space-x-2"
                >
                  {["hidden", "not_hidden", "both"].map((typeOption) => (
                    <ToggleGroupItem
                      key={typeOption}
                      value={typeOption}
                      className={`flex w-32 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                        hiddenFilter === typeOption
                          ? "bg-light-grey"
                          : "border border-light-grey bg-white hover:bg-light-grey/60"
                      }`}
                      aria-label={t(`tasks.FilterPopover.${typeOption}`)}
                    >
                      {t(`tasks.FilterPopover.${typeOption}`)}
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              </div>

              {/* NEW: Liked comments */}
              <div className="mb-10">
                <p className="mb-4 text-sm font-semibold text-dark-grey">
                  {t("tasks.FilterPopover.liked_comments")}
                </p>
                <ToggleGroup
                  type="single"
                  value={likedFilter}
                  onValueChange={(value: string) =>
                    handleLikedFilterChange(
                      value as "liked" | "not_liked" | "both" | null,
                    )
                  }
                  className="flex items-start justify-around space-x-2"
                >
                  {["liked", "not_liked", "both"].map((typeOption) => (
                    <ToggleGroupItem
                      key={typeOption}
                      value={typeOption}
                      className={`flex w-32 cursor-pointer items-center justify-center rounded-[6px] py-1 ${
                        likedFilter === typeOption
                          ? "bg-light-grey"
                          : "border border-light-grey bg-white hover:bg-light-grey/60"
                      }`}
                      aria-label={t(`tasks.FilterPopover.${typeOption}`)}
                    >
                      {t(`tasks.FilterPopover.${typeOption}`)}
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              </div>
            </>
          )}

          {/* Sticky Footer with Buttons */}
          <div className="sticky bottom-0 flex items-center justify-end border-t border-light-grey bg-white py-6 text-sm font-semibold">
            <button
              onClick={handleReset}
              className="mr-3 w-32 cursor-pointer rounded-[8px] bg-light-grey py-2.5 text-primary hover:brightness-90"
            >
              {t("tasks.FilterPopover.reset")}
            </button>
            <button
              onClick={handleApply}
              className="w-32 cursor-pointer rounded-[8px] bg-primary py-2.5 text-white hover:brightness-150"
            >
              {t("tasks.FilterPopover.apply")}
            </button>
          </div>
        </div>
      </PopoverContent>

      {/* PlanRestrictionAlertDialog */}
      <PlanRestrictionAlertDialog
        message={t("plan_restriction_dialog.messages.need_plan_pro")}
        trigger={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
      />
    </Popover>
  );
};
