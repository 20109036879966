// src/types/channel.config.ts

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TiktokIcon,
  YoutubeIcon,
} from "@/assets/icons";
import { Channel } from "./channel.type";

interface ChannelConfig {
  name: string;
  icon: string; // This is the path to the image (SVG).
  integration_id: string; // ID used for Nango or similar service.
  description?: string;
  additionalParams?: Record<string, any>;
}

export const CHANNELS_CONFIG: Record<Channel["type"], ChannelConfig> = {
  facebook: {
    name: "connect_channel.facebook.name",
    icon: FacebookIcon,
    integration_id: "facebook",
    description: "connect_channel.facebook.description",
  },
  instagram: {
    name: "connect_channel.instagram.name",
    icon: InstagramIcon,
    integration_id: "facebook",
    description: "connect_channel.instagram.description",
  },
  tiktok: {
    name: "connect_channel.tiktok.name",
    icon: TiktokIcon,
    integration_id: "tiktok",
    description: "connect_channel.tiktok.description",
  },
  youtube: {
    name: "connect_channel.youtube.name",
    icon: YoutubeIcon,
    integration_id: "youtube",
  },
  "tiktok-ads": {
    name: "connect_channel.tiktok-ads.name",
    icon: TiktokIcon,
    integration_id: "tiktok-ads",
    description: "connect_channel.tiktok-ads.description",
  },
  "facebook-ads": {
    name: "connect_channel.facebook-ads.name",
    icon: FacebookIcon,
    integration_id: "facebook",
    description: "connect_channel.facebook-ads.description",
  },
  linkedin: {
    name: "connect_channel.linkedin.name",
    icon: LinkedinIcon,
    integration_id: "linkedin",
    description: "connect_channel.linkedin.description",
  },
};

// Determine if additional steps are required for the channel type
export const requiresAccountSelection = (type: Channel["type"]) => {
  return [
    "facebook",
    "instagram",
    "youtube",
    "tiktok-ads",
    "linkedin",
  ].includes(type);
};
