// useUpdateWorkspaceSettings.ts
import { useFetcher } from "@axios/fetcher";
import { Workspace } from "@types";

function useUpdateAutoReply() {
  const { fetcher } = useFetcher();

  // Function to update the workspace_settings of a workspace
  const updateAutoReply = async (workspace_id: string, auto_reply: boolean) => {
    if (!workspace_id || (auto_reply != false && auto_reply != true)) {
      throw new Error(
        "workspace_id and workspace_settings are required to update a workspace workspace_settings",
      );
    }

    // Make the API request to update the workspace workspace_settings
    const updatedWorkspace = await fetcher(
      `/api/workspaces/${workspace_id}/settings/auto-reply`,
      "patch",
      { auto_reply },
    );

    return updatedWorkspace as Workspace;
  };

  return {
    updateAutoReply,
  };
}

export { useUpdateAutoReply };
