import { AppWrapper } from "@/layouts/app-wrapper/AppWrapper";
import { useUserContext } from "@layouts/user-provider/useUserContext";
import { Messages } from "@/containers/messages/Messages";
import { ErrorPage } from "@components/ErrorPage";
import { MessagesSkeleton } from "@/containers/messages/MessagesSkeleton";
import { useConversationsContext } from "@/containers/messages/messages.context";
import { useSubscriptionContext } from "@/layouts/subscription-provider/SubscriptionProvider";

const MessagesPage = () => {
  const { isLoadingUser } = useUserContext();
  const { isLoadingInitialData: isLoadingConversations } =
    useConversationsContext(); // Use loading state from comments context
  const { isLoadingSubscriptionDetails } = useSubscriptionContext();

  // Replace with actual loading logic for comments
  const isLoading: boolean =
    isLoadingUser || isLoadingConversations || isLoadingSubscriptionDetails;

  return (
    <AppWrapper isLoading={isLoading} loadingSkeleton={<MessagesSkeleton />}>
      <Messages />
    </AppWrapper>
  );
};

export default MessagesPage;

export const Catch = () => {
  return <ErrorPage />;
};
