// src/components/popup/ConnectChannelsPopup.tsx

import { useState, useEffect, useRef, ReactElement } from "react";
import { useNango } from "@hooks/useNango";
import {
  Channel,
  FacebookPage,
  InstagramAccount,
  TiktokAccount,
  WorkspaceDetails,
  YoutubeChannel,
} from "@/types";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import {
  useFacebookPages,
  useInstagramAccounts,
  useTiktokAccounts,
  useYoutubeChannels,
} from "@axios/queries";

import GuideTip from "@/components/GuideTip";
import { CloseIcon } from "@/assets/icons";
import ChannelList from "./ChannelList";
import ChooseAccountStep from "./ChooseAccountStep";
import {
  CHANNELS_CONFIG,
  requiresAccountSelection,
} from "@/types/channel.config";
import { Crisp } from "crisp-sdk-web";
import { useCreateChannel } from "@/axios/mutations/useCreateChannel";
import { Img } from "../Img";
import { useTranslation } from "react-i18next";
// import { useTiktokAdAccounts } from "@/axios/queries/useTiktokAdAccounts";
import { TiktokAdAccount } from "@/types/tiktok.type";
import { useRemoveChannel } from "@/axios/mutations";
// import { useLinkedinAccounts } from "@/axios/queries/useLinkedinAccounts";
import { LinkedinAccount } from "@/types/linkedin.type";

type ConnectChannelsPopupProps = {
  onClose: () => void;
};

const ConnectChannelsPopup = ({ onClose }: ConnectChannelsPopupProps) => {
  const { t } = useTranslation();

  const { currentWorkspace, refetchChannels, channels } = useWorkspaceContext();
  const workspace_id = (currentWorkspace as WorkspaceDetails).workspace_id;

  const [newChannel, setNewChannel] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const [isError, setIsError] = useState<boolean>(false);
  const [guideTipText, setGuideTipText] = useState<string | ReactElement>(
    channels?.length == 4
      ? t("connect_channel.all_channels_connected")
      : t("connect_channel.guide_tip_initial"),
  );

  const [appendGuideTipText, setAppendGuideTipText] = useState<boolean>(false);
  const [step, setStep] = useState<"connect" | "choose-account">("connect");
  const [channelType, setChannelType] = useState<Channel["type"] | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [associatedAccounts, setAssociatedAccounts] = useState<
    | FacebookPage[]
    | InstagramAccount[]
    | YoutubeChannel[]
    | TiktokAdAccount[]
    | LinkedinAccount[]
    | null
  >([]);
  const [showPopupLoading, setShowPopupLoading] = useState(false);

  const { connectWithNango } = useNango();

  const popupRef = useRef<HTMLDivElement>(null);
  const guideTipRef = useRef<HTMLDivElement>(null);

  // Fetch Facebook Pages
  const { refetch: refetchFacebookPages } = useFacebookPages({ workspace_id });

  // Fetch Instagram Accounts
  const { refetch: refetchInstagramAccounts } = useInstagramAccounts({
    workspace_id,
  });

  // Fetch tiktok Accounts
  const { refetch: refetchTiktokAccounts } = useTiktokAccounts(workspace_id);

  // // Fetch tiktok Ad Accounts
  // const { refetch: refetchTiktokAdAccounts } =
  //   useTiktokAdAccounts(workspace_id);

  // // Fetch Linkedin Accounts
  // const { refetch: refetchLinkedinAccounts } =
  //   useLinkedinAccounts(workspace_id);

  // Fetch Youtube Channels
  const { refetch: refetchYoutubeChannels } = useYoutubeChannels({
    workspace_id,
  });

  const { createChannel } = useCreateChannel();
  const { removeChannel } = useRemoveChannel();

  // State to control AlertDialog visibility
  const [isAlertOpen, setIsAlertOpen] = useState<Channel | null>(null);

  const handleRemoveChannel = async (
    workspace_id: string,
    channel_id: string,
  ) => {
    try {
      // Call the removeChannel function with workspace_id and channel_id
      const updatedChannel = await removeChannel({ workspace_id, channel_id });
      await refetchChannels();

      console.debug("Channel removed successfully:", updatedChannel);
      window.location.reload();

      // Update the UI or state as necessary
    } catch (error) {
      console.error("Error removing channel:", error);
      // Optionally, show an error message to the user
    }
  };

  // Update guide tip text with loader state and potential warning
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (showPopupLoading) {
      setIsError(false);
      setGuideTipText(t("connect_channel.opening_auth_popup"));
      setAppendGuideTipText(false);

      timeoutId = setTimeout(() => {
        setGuideTipText(
          (prev) => `${prev} ${t("connect_channel.popup_blocked_warning")}`,
        );
        setAppendGuideTipText(true);
      }, 20000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [showPopupLoading]);

  // Update guide tip text when changing state
  useEffect(() => {
    if (step == "connect") {
      setIsError(false);

      setGuideTipText(
        channels?.length == 4
          ? t("connect_channel.all_channels_connected")
          : t("connect_channel.guide_tip_select_media"),
      );
      setSelectedAccount(null);
    }
  }, [step, setStep]);

  // // Close popup when clicking outside and the step is "connect"
  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     // Check if the click is outside the popup, guide tip, and the step is "connect"
  //     if (
  //       popupRef.current &&
  //       guideTipRef.current &&
  //       !popupRef.current.contains(event.target as Node) &&
  //       !guideTipRef.current.contains(event.target as Node) &&
  //       step === "connect" &&
  //       !isAlertOpen
  //     ) {
  //       handleOnClose();
  //     }
  //   };

  //   // Add event listener for clicks
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Clean up event listener on unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [step, onClose]);

  useEffect(() => {
    if (
      step === "choose-account" &&
      (!associatedAccounts || associatedAccounts.length === 0)
    ) {
      let errorMessage: string = "";
      switch (channelType) {
        case "facebook":
          errorMessage = t("connect_channel.no_accounts_found_facebook");
          break;
        case "instagram":
          errorMessage = t("connect_channel.no_accounts_found_instagram");
          break;
        case "youtube":
          errorMessage = t("connect_channel.no_accounts_found_youtube");
          break;
        default:
          errorMessage = t("connect_channel.no_accounts_found_default");
      }

      setGuideTipText(
        <p className="leading-normal">
          {errorMessage}
          <br />
          <br />
          <a
            href="https://blablaapp.notion.site/Welcome-to-Blabla-f609d554f3324ef7a1684d295712da7c"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-blue underline"
          >
            {t("connect_channel.no_accounts_found_guide_follow")}
          </a>{" "}
          {t("connect_channel.or")}{" "}
          <a
            href="#"
            className="text-dark-blue underline"
            onClick={() => Crisp.chat.open()}
          >
            {t("connect_channel.no_accounts_found_contact_support")}
          </a>
          .
        </p>,
      );
      setAppendGuideTipText(false);
      setIsError(true);
    }
  }, [step, associatedAccounts, channelType, t]);

  const handleConnect = async (type: Channel["type"]) => {
    setIsLoading((prev) => ({ ...prev, [type]: true }));
    setIsError(false);
    setShowPopupLoading(true);

    try {
      const result = await connectWithNango(
        CHANNELS_CONFIG[type].integration_id,
        workspace_id,
        CHANNELS_CONFIG[type].additionalParams || {},
      );

      // const result = {
      //   success: true,
      // };

      console.debug("Connect result:", result);

      if (result.success) {
        console.info(`${type} connected successfully.`);
        setShowPopupLoading(false);

        if (requiresAccountSelection(type)) {
          // Fetch relevant accounts depending on the channel type
          let accounts:
            | FacebookPage[]
            | InstagramAccount[]
            | YoutubeChannel[]
            | TiktokAdAccount[]
            | LinkedinAccount[]
            | null = [];

          if (type === "facebook") {
            // Wait for refetch to return updated Facebook pages
            const newFacebookPages = await refetchFacebookPages();

            console.debug("New Facebook pages:", newFacebookPages);

            accounts = newFacebookPages
              ? Array.isArray(newFacebookPages)
                ? newFacebookPages
                : [newFacebookPages]
              : null;
          } else if (type === "instagram") {
            console.debug("Fetching Instagram accounts...");
            // Wait for refetch to return updated Instagram accounts
            const newInstagramAccounts = await refetchInstagramAccounts();

            console.debug("New Instagram accounts:", newInstagramAccounts);

            accounts = newInstagramAccounts
              ? Array.isArray(newInstagramAccounts)
                ? newInstagramAccounts
                : [newInstagramAccounts]
              : null;
          } else if (type === "youtube") {
            // Wait for refetch to return updated Youtube channels
            const newYoutubeChannels = await refetchYoutubeChannels();

            console.debug("New Youtube channels:", newYoutubeChannels);

            accounts = newYoutubeChannels
              ? Array.isArray(newYoutubeChannels)
                ? newYoutubeChannels
                : [newYoutubeChannels]
              : null;
          } else if (type == "tiktok-ads") {
            // // Wait for refetch to return updated Tiktok Ad channels
            // const newTiktokAdsChannels =
            //   (await refetchTiktokAdAccounts()) as TiktokAdAccount[];
            // console.debug("New Tiktok Ad channels:", newTiktokAdsChannels);
            // accounts = newTiktokAdsChannels
            //   ? Array.isArray(newTiktokAdsChannels)
            //     ? newTiktokAdsChannels
            //     : [newTiktokAdsChannels]
            //   : null;
          }
          // else if (type == "linkedin") {
          // // Wait for refetch to return updated Tiktok Ad channels
          // const newLinkedinAccounts =
          //   (await refetchLinkedinAccounts()) as LinkedinAccount[];
          // console.debug("New Linkedin accounts:", newLinkedinAccounts);
          // accounts = newLinkedinAccounts
          //   ? Array.isArray(newLinkedinAccounts)
          //     ? newLinkedinAccounts
          //     : [newLinkedinAccounts]
          //   : null;
          // }

          setAssociatedAccounts(accounts);
          setChannelType(type);
          setStep("choose-account");

          // Change guide tip text to prompt account selection
          setIsError(false);
          setGuideTipText(
            type == "youtube"
              ? t("connect_channel.guide_tip_select_channel")
              : t("connect_channel.guide_tip_select_account"),
          );
          setAppendGuideTipText(false);
        } else if (type === "tiktok") {
          const newTiktokAccount =
            (await refetchTiktokAccounts()) as TiktokAccount;

          // Now create the TikTok channel after a successful connection
          await createChannel({
            workspace_id,
            channel_id: newTiktokAccount?.business_id, // Assuming the result provides a TikTok channel_id
            type: "tiktok",
          });

          // Refetch channels after successful creation
          await refetchChannels();
          setNewChannel(true);

          // Handle TikTok directly since it doesn't require account selection
          const successMessage = t("connect_channel.success_message");
          setIsError(false);
          setGuideTipText(successMessage);
          setAppendGuideTipText(false);
          setSelectedAccount(null);
        }
      } else {
        handleError(result.error as string, type);
      }
    } catch (error) {
      console.error(`Unexpected error during connection process:`, error);
      const errorMessage = t("connect_channel.error_unexpected");
      setIsError(true);
      setGuideTipText(errorMessage);
      setAppendGuideTipText(false);
    } finally {
      setIsLoading((prev) => ({ ...prev, [type]: false }));
      setShowPopupLoading(false);
    }
  };

  // Handle errors
  const handleError = (error: string, type: Channel["type"]) => {
    let errorMessage: string | ReactElement = "";
    setIsError(true);

    switch (error) {
      case "popup_closed":
        errorMessage = t("connect_channel.error_popup_closed");
        break;
      case "popup_blocked":
        errorMessage = (
          <span>
            {t("connect_channel.error_popup_blocked")}{" "}
            <a
              href="#"
              className="text-dark-blue underline"
              onClick={() => Crisp.chat.open()}
            >
              {t("connect_channel.contact_support")}
            </a>
            .
          </span>
        );
        break;
      case "popup_opened":
        errorMessage = t("connect_channel.error_popup_already_open");
        break;
      default:
        errorMessage = t("connect_channel.error_default");
        break;
    }

    setGuideTipText(errorMessage);
    setAppendGuideTipText(false);
    console.warn(`Failed to connect ${type}: ${error}`);
  };

  const handleOnClose = () => {
    console.debug("New Channel:", newChannel);
    if (newChannel) {
      setNewChannel(false);
      window.location.reload();
    }
    onClose();
  };

  const handleOnConfirmAccount = () => {
    setStep("connect");
    setNewChannel(true);

    // Handle success message
    const successMessage = t("connect_channel.success_message");
    setIsError(false);
    setGuideTipText(successMessage);
    setAppendGuideTipText(false);
    setSelectedAccount(null);
  };

  // Handler for confirming disconnection
  const confirmDisconnect = async (channel: Channel) => {
    setIsLoading((prev) => ({ ...prev, [channel.type]: true }));
    if (channel.channel_id) {
      handleRemoveChannel(channel.workspace_id, channel.channel_id);
    }
    setIsLoading((prev) => ({ ...prev, [channel.type]: true }));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75">
      <div className="relative flex items-center">
        {/* Main Popup Container */}
        <div
          ref={popupRef}
          className="relative w-[500px] rounded-[12px] bg-white p-7 shadow-lg"
        >
          <button className="absolute right-5 top-5" onClick={handleOnClose}>
            <Img src={CloseIcon} alt="Close" className="size-3" />
          </button>

          {/* Title */}
          <h2 className="mb-2 text-[16px] font-bold text-primary">
            {step === "connect"
              ? t("connect_channel.title_connect_new_channel")
              : t("connect_channel.title_connect_account", {
                  channelName: t(
                    CHANNELS_CONFIG[channelType as Channel["type"]].name,
                  ),
                })}
          </h2>

          {/* Description: Help line */}
          <p className="mb-6 text-sm text-primary">
            {t("connect_channel.guide_tip_help_line_start")}{" "}
            <a
              href="https://blablaapp.notion.site/Welcome-to-Blabla-f609d554f3324ef7a1684d295712da7c"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark-blue underline"
            >
              {t("connect_channel.follow_our_guide")}
            </a>{" "}
            {t("connect_channel.or")}{" "}
            <a
              href="#"
              className="text-dark-blue underline"
              onClick={() => {
                handleOnClose();
                Crisp.chat.open();
                Crisp.setZIndex(999999);
              }}
            >
              {t("connect_channel.contact_support")}
            </a>
            .
          </p>

          {/* Render Step: Connect Channels or Choose Account */}
          {step === "connect" || !associatedAccounts?.length ? (
            <ChannelList
              isLoading={isLoading}
              handleConnect={handleConnect}
              confirmDisconnect={confirmDisconnect}
              isAlertOpen={isAlertOpen}
              setIsAlertOpen={setIsAlertOpen}
              onClose={onClose}
            />
          ) : (
            <ChooseAccountStep
              accounts={associatedAccounts}
              onAccountSelect={(account) => setSelectedAccount(account)}
              onConfirm={() => handleOnConfirmAccount()}
              onCancel={() => setStep("connect")}
              selectedAccount={selectedAccount}
              channelType={channelType as Channel["type"]}
            />
          )}
        </div>

        {/* Guide Tip Section */}
        <div ref={guideTipRef} className="absolute left-full top-10">
          <GuideTip
            title={
              isError
                ? null
                : step === "connect"
                  ? channels?.length == 4
                    ? t(
                        "connect_channel.guide_tip_title_all_channels_connected",
                      )
                    : t("connect_channel.guide_tip_title_connect_new_channel")
                  : t("connect_channel.guide_tip_title_select_account")
            }
            description={guideTipText}
            warning={isError}
            append={appendGuideTipText}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectChannelsPopup;
