// ErrorPage.tsx
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Crisp } from "crisp-sdk-web";

export type ErrorPageProps = {
  title?: string;
  message?: string;
  targetPath?: string;
  onRetry?: () => void;
  showLogout?: boolean;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  message,
  targetPath = "/",
  onRetry,
  showLogout = true,
}) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  useEffect(() => {
    Crisp.setZIndex(-10);
  }, []);

  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    } else {
      // Use current query parameters if any
      const currentQuery = window.location.search;
      const path = currentQuery ? `/?${currentQuery.substring(1)}` : targetPath;
      window.location.href = path;
    }
  };

  return (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center bg-light-grey p-12 text-primary">
      {/* Error Message Box */}
      <div className="flex w-full max-w-md flex-col items-center gap-4 rounded-xl bg-white p-6 text-center">
        <div className="text-xl font-semibold leading-relaxed text-primary">
          {title || t("error.general.title")}
        </div>

        <p className="text-sm leading-relaxed text-primary">
          {message || t("error.general.try_again")}
        </p>

        <button
          onClick={handleRetry}
          className="mt-4 w-fit max-w-xs rounded-[12px] bg-primary px-12 py-3 text-base font-semibold text-white hover:brightness-150"
        >
          {onRetry ? t("error.general.retry") : t("error.general.return_home")}
        </button>
      </div>

      {/* Optional Logout Button */}
      {showLogout && (
        <button
          onClick={() => logout()}
          className="absolute bottom-8 right-8 text-sm font-medium text-dark-grey transition-colors hover:text-primary"
        >
          {t("logout")}
        </button>
      )}
    </div>
  );
};

export { ErrorPage };
