import React from "react";
import { Comment } from "@/types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { format } from "date-fns";
import { ClockFilledIcon } from "@assets/icons";
import { categoryEmojis, sentimentEmojis } from "@/types/classification.types";
import { ProfileImg } from "../ProfileImg";
import { Img } from "../Img";
import { useCommentsContext } from "@/containers/comments/comments.context";
import { ExpandableText } from "../ExpandableText";
import { useTranslation } from "react-i18next";
import { Attachment } from "@/types/messages.types";
import { RenderAttachment } from "./RenderAttachment";

export const CommentRenderer: React.FC<{ comment: Comment }> = ({
  comment,
}) => {
  const { t } = useTranslation();
  const { category, sentiment } = comment.classification || {};
  const categoryEmoji: string | null =
    category && category in categoryEmojis ? categoryEmojis[category] : null;

  const sentimentEmoji: string | null =
    sentiment && sentiment in sentimentEmojis
      ? sentimentEmojis[sentiment]
      : null;

  // Format times for the pinned (main) comment
  const formattedTime: string = format(new Date(comment.timestamp), "HH:mm");
  const formattedDate: string = format(
    new Date(comment.timestamp),
    "dd.MM.yyyy",
  );

  const { selectedItemIds: selectedCommentIds } = useCommentsContext();
  const isOwner: boolean = !!comment.owner;

  const hasAttachmentUrl = comment.attachments?.some(
    (a) => a.url && a.type != "link" && a.type != "unknown",
  );

  /**
   * Renders the vertical-line section with attachments (if any).
   * We replicate the "message" style: a tall vertical line if attachments exist.
   */
  const renderAttachmentsSection = () => {
    // If there are no attachments, do nothing
    if (!comment.attachments || comment.attachments.length === 0) {
      return null;
    }

    return (
      <div
        className={`flex flex-col ${comment.username || comment.text?.length > 0 ? "pl-14" : "pl-1"}`}
      >
        <div
          className={`flex items-end ${comment.username || comment.text?.length > 0 ? "mt-2" : ""}`}
        >
          {/* Left vertical line if not owner */}
          {!isOwner && (
            <div
              className={`mr-3 rounded-xl border-[1.5px] border-dark-grey/30 ${
                comment.attachments.length && hasAttachmentUrl
                  ? "h-[150px]"
                  : "h-[18px]"
              }`}
            />
          )}

          <div
            className={`flex flex-col ${isOwner ? "items-end text-end" : ""}`}
          >
            {comment.attachments.map(
              (attachment: Attachment, index: number) => (
                <>
                  {attachment.url ? (
                    <div
                      key={index}
                      className={`${comment.username || comment.text?.length > 0 ? "mt-2" : ""}`}
                    >
                      <RenderAttachment attachment={attachment} />
                    </div>
                  ) : (
                    <span
                      className={`text-sm font-medium text-dark-grey ${
                        isOwner ? "text-end" : ""
                      }`}
                    >
                      {t("tasks.comments_renderer.not_supported")}
                    </span>
                  )}
                </>
              ),
            )}
          </div>

          {/* Right vertical line if owner */}
          {isOwner && (
            <div
              className={`ml-3 rounded-xl border-[1.5px] border-dark-grey/30 ${
                comment.attachments.length && hasAttachmentUrl
                  ? "h-[150px]"
                  : "h-[18px]"
              }`}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      key={comment._id}
      className={`relative mb-3 rounded-[10px] p-5 pb-10
    ${isOwner ? "border border-white bg-light-blue" : "bg-white"}`}
    >
      {/* Category Emoji - top-right corner (unchanged logic) */}
      {categoryEmoji && (
        <div className="absolute right-5 top-5 text-xs font-medium">
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger>
                <span className="text-sm text-primary">{categoryEmoji}</span>
                <span className="ml-2 text-sm font-semibold text-primary">
                  {t(
                    `tasks.classification.category.${category
                      ?.toLowerCase()
                      .replace(/\s+/g, "_")}`,
                  )}
                </span>
              </TooltipTrigger>
              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                <p>
                  {sentimentEmoji && (
                    <span className="mr-1">{sentimentEmoji}</span>
                  )}
                  {sentiment && (
                    <span>
                      {t(
                        `tasks.classification.sentiment.${sentiment
                          ?.toLowerCase()
                          .replace(/\s+/g, "_")}`,
                      )}
                    </span>
                  )}{" "}
                  {" - "}
                  {categoryEmoji && (
                    <span className="mr-1">{categoryEmoji}</span>
                  )}
                  {category && (
                    <span>
                      {t(
                        `tasks.classification.category.${category
                          ?.toLowerCase()
                          .replace(/\s+/g, "_")}`,
                      )}
                    </span>
                  )}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}

      {/* Main Content */}
      <div
        className={`flex ${comment.username || comment.text?.length > 0 || !hasAttachmentUrl ? "items-center" : "items-start"}`}
      >
        {/* Profile Picture */}
        {isOwner ? (
          <Img
            src={comment.channel.profile_picture_url}
            alt={t("tasks.comments_renderer.channel_avatar_alt")}
            className="mr-3 size-12 shrink-0 rounded-full"
          />
        ) : (
          <ProfileImg
            username={comment.username}
            id={comment._id}
            profilePictureUrl={comment?.profile_picture_url}
            className="mr-3 size-12 shrink-0 rounded-full"
          />
        )}

        {(comment.username || comment.text?.length > 0) && (
          <div className="flex flex-1 flex-col justify-between pr-36 2xl:max-w-[70%]">
            {/* Username */}
            {comment.username && (
              <span className="text-sm font-extrabold text-primary">
                @{comment.username.replace("@", "")}
              </span>
            )}

            {comment.text?.length > 0 && (
              <ExpandableText
                text={comment.text}
                maxLines={4}
                className={`${
                  comment.username ? "mt-1.5" : ""
                } text-sm font-medium text-primary`}
              />
            )}
          </div>
        )}

        {/* If comment has attachments, render them with vertical-line layout */}
        {!comment.username && !comment.text?.length && (
          <>{renderAttachmentsSection()}</>
        )}
      </div>

      {/* If comment has attachments, render them with vertical-line layout */}
      {(comment.username || comment.text?.length > 0) && (
        <>{renderAttachmentsSection()}</>
      )}

      {/* Timestamp - bottom-right corner */}
      <div className="absolute bottom-3.5 right-3.5 text-right text-xs font-medium">
        <div className="flex items-center justify-between">
          {/* Timestamp and Date */}
          <div className="-ml-0.5 flex items-center text-xs font-medium">
            <ClockFilledIcon className="size-4" />
            <span className="ml-1 mr-2.5 text-primary">{formattedDate}</span>
            <span className="text-dark-grey">{formattedTime}</span>
          </div>

          {/* Like and Hide Actions */}
          {/* <div className="flex space-x-2">
              <button className="transition-colors hover:brightness-90">
                {comment.liked?.status ? (
                  <UnlikeGreyButton className="size-8" />
                ) : (
                  <LikeGreyButton className="size-8" />
                )}
              </button>
              <button className="transition-colors hover:text-primary hover:brightness-90">
                {comment.hidden?.status ? (
                  <ShowGreyButton className="size-8" />
                ) : (
                  <HideGreyButton className="size-8" />
                )}
              </button>
            </div> */}
        </div>
      </div>

      {/* If multiple comments are selected, show a highlight behind */}
      {selectedCommentIds.length > 1 && (
        <div className="absolute -bottom-2 left-1/2 -z-10 h-10 w-[94%] -translate-x-1/2 rounded-[10px] bg-white/50" />
      )}
    </div>
  );
};
