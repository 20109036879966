// ChannelButton.tsx
import { CHANNELS_CONFIG } from "@/types/channel.config";
import { Channel } from "@/types";
import { Img } from "../Img";
import { useState } from "react";
import { Loader } from "@/assets/icons";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type ChannelButtonProps = {
  channel: Partial<Channel>;
  isCollapsed: boolean;
  openPopup: () => void;
};

const ChannelButton = ({
  channel,
  isCollapsed,
  openPopup,
}: ChannelButtonProps) => {
  const { t } = useTranslation();

  const channelConfig = CHANNELS_CONFIG[channel.type as Channel["type"]];
  const is_connected = channel.is_connected;

  const [isLoading] = useState(false);

  return (
    <>
      <div
        className={`flex w-full items-center ${
          isCollapsed ? "justify-center" : "justify-between"
        }`}
        onClick={() => {
          if (!is_connected) {
            openPopup();
          }
        }}
      >
        {/* Channel Details */}
        <div className={`flex items-center ${isCollapsed ? "" : "w-[70%]"}`}>
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                {/* Profile Picture */}
                <div className="relative size-[36px] shrink-0">
                  <Img
                    src={
                      is_connected
                        ? (channel.profile_picture_url as string)
                        : channelConfig.icon
                    }
                    alt={channel.username || channel.type}
                    className={`rounded-full border bg-white object-contain ${
                      is_connected
                        ? "size-[36px] border-light-grey"
                        : "size-[36px] border-2 border-red"
                    }`}
                  />

                  {/* Social Network Icon Badge */}
                  {is_connected && (
                    <Img
                      src={channelConfig.icon}
                      alt={`${channel.type}-icon`}
                      className="absolute bottom-0 right-[-3px] size-[16px] rounded-full bg-white object-contain shadow-[0_0_0_2px_theme('colors.light-grey')]"
                    />
                  )}
                </div>
              </TooltipTrigger>

              {!!isCollapsed && (
                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  {is_connected
                    ? `${channel.username} - ${t(channelConfig.name)}`
                    : `${t("navbar.channel.reconnect")} ${t(channelConfig.name)}`}
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>

          {/* Channel Details (when not collapsed) */}
          {!isCollapsed && (
            <div className="ml-3.5 flex w-4/5 flex-col">
              <span
                className={`truncate text-xs font-extrabold ${
                  is_connected ? "text-primary" : "text-red"
                }`}
              >
                {is_connected
                  ? channel.username
                  : `${t("navbar.channel.reconnect")} ${t(channelConfig.name)}`}
              </span>
              <span className="mt-0.5 truncate text-xs capitalize text-dark-grey">
                {t(channelConfig.name)}
              </span>
            </div>
          )}
        </div>

        {isLoading && (
          <Loader className="mt-0.5 size-3.5 animate-spin stroke-dark-grey" />
        )}
      </div>
    </>
  );
};

export default ChannelButton;
