// BulkActionsBar.tsx

import {
  HideWhiteButton,
  LikeWhiteButton,
  UnlikeWhiteButton,
  ShowWhiteButton,
  NextButton,
  PreviousButton,
  Loader,
  UnlikeGreyButton,
  LikeGreyButton,
  ShowGreyButton,
  HideGreyButton,
} from "@/assets/icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Comment, Conversation } from "@/types";
import { useState } from "react";
import { useTaskContext } from "@/layouts/useTask.context";
import BulkActionPopup from "./BulkActionPopup";
import { useTranslation } from "react-i18next";
import { useNavBar } from "@/layouts/NavBar.context";

export const BulkActionsBar = ({ type }: { type: "comment" | "message" }) => {
  const { t } = useTranslation();

  const { refetchCountTodo } = useNavBar();

  const [likeIsLoading, setLikeIsLoading] = useState(false);
  const [hideIsLoading, setHideIsLoading] = useState(false);

  const [popupData, setPopupData] = useState<{
    actionType: "like" | "unlike" | "hide" | "show" | "skip" | "moveToTodo";
    icon: JSX.Element | null;
    message: string;
    position: { top: number; left: number };
  } | null>(null);

  const {
    openedItemId,
    selectedItemIds,
    items,
    nextItem,
    previousItem,
    likeSelectedItems,
    hideSelectedItems,
    skipSelectedItems,
    isBulkActionLoading,
    activeTab,
  } = useTaskContext();

  const openedItem = items.find((item) => item._id === openedItemId);
  const selectedCount: number = selectedItemIds.length;

  /**
   * Handles skipping all selected items.
   */
  const handleBulkSkip = (skip: boolean) => {
    skipSelectedItems(
      selectedItemIds.length > 0
        ? selectedItemIds
        : [(openedItem as Comment | Conversation)?._id],
      skip,
    );

    refetchCountTodo();
  };

  /**
   * Handles liking all selected items.
   */
  const handleBulkLike = async (like: boolean) => {
    const itemsToLike = items.filter(
      (item) =>
        selectedItemIds.includes(item._id) &&
        (item.channel?.type == "facebook" ||
          item.channel?.type == "tiktok" ||
          item.channel?.type == "tiktok-ads"),
    );

    if (activeTab !== "to-do") {
      setLikeIsLoading(true);
    }

    if (!likeSelectedItems) return;

    await likeSelectedItems(
      selectedItemIds.length > 0
        ? itemsToLike.map((item) => item._id)
        : [(openedItem as Comment)?._id],
      like,
    );

    refetchCountTodo();
    setLikeIsLoading(false);
  };

  /**
   * Handles hiding all selected items.
   */
  const handleBulkHide = async (hide: boolean) => {
    if (activeTab !== "to-do") {
      setHideIsLoading(true);
    }

    if (!hideSelectedItems) return;

    await hideSelectedItems(
      selectedItemIds.length > 0
        ? selectedItemIds
        : [(openedItem as Comment)?._id],
      hide,
    );

    refetchCountTodo();
    setHideIsLoading(false);
  };

  /**
   * Function to display the popup near the clicked button
   */
  const showPopup = (
    actionType: "like" | "unlike" | "hide" | "show" | "skip" | "moveToTodo",
    message: string,
    icon: JSX.Element | null,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const popupWidth = 500; // Max width as per BulkActionPopup's design
    const buttonCenterX = rect.left + rect.width / 2;
    const adjustedLeft = buttonCenterX - popupWidth / 2;

    setPopupData({
      actionType,
      icon,
      message,
      position: {
        top: rect.bottom + window.scrollY + 10, // 10px below the button
        left: adjustedLeft > 10 ? adjustedLeft : 10, // Prevent overflow on the left
      },
    });
  };

  /**
   * Handlers to initiate bulk actions by showing the popup
   */
  const initiateBulkLike = (event: React.MouseEvent<HTMLElement>) => {
    const isLiked =
      (openedItem as Comment)?.liked?.status == true ||
      (openedItem as Comment)?.liked?.transition == "liking";

    const itemsToLike = items.filter(
      (item) =>
        selectedItemIds.includes(item._id) &&
        (item.channel?.type == "facebook" ||
          item.channel?.type == "tiktok" ||
          item.channel?.type == "tiktok-ads"),
    );
    const action = isLiked ? "unlike" : "like";
    const entity = type === "comment" ? "comment" : "message";
    const keyBase = `tasks.bulk_actions_bar.confirm_${action}_${entity}.${itemsToLike.length > 1 ? "other" : "one"}`;

    const message = t(keyBase, { count: itemsToLike.length });

    const icon = isLiked ? (
      <UnlikeGreyButton className="size-9" />
    ) : (
      <LikeGreyButton className="size-9" />
    );
    const actionType = isLiked ? "unlike" : "like";

    showPopup(actionType, message, icon, event);
  };

  const initiateBulkHide = (event: React.MouseEvent<HTMLElement>) => {
    const isHidden =
      (openedItem as Comment)?.hidden?.status == true ||
      (openedItem as Comment)?.hidden?.transition == "hiding";

    const action = isHidden ? "show" : "hide";
    const entity = type === "comment" ? "comment" : "message";
    const keyBase = `tasks.bulk_actions_bar.confirm_${action}_${entity}.${selectedCount > 1 ? "other" : "one"}`;

    const message = t(keyBase, { count: selectedCount });

    const icon = isHidden ? (
      <ShowGreyButton className="size-9" />
    ) : (
      <HideGreyButton className="size-9" />
    );
    const actionType = isHidden ? "show" : "hide";

    showPopup(actionType, message, icon, event);
  };

  const initiateBulkSkip = (event: React.MouseEvent<HTMLElement>) => {
    const isSkipped =
      openedItem?.skipped?.status || openedItem?.status === "done";

    const action = isSkipped ? "move_to_todo" : "skip";
    const entity = type === "comment" ? "comment" : "message";
    const keyBase = `tasks.bulk_actions_bar.confirm_${action}_${entity}.${selectedCount > 1 ? "other" : "one"}`;

    const message = t(keyBase, { count: selectedCount });

    const icon = null;

    const actionType = isSkipped ? "moveToTodo" : "skip";

    showPopup(actionType, message, icon, event);
  };

  /**
   * Confirm handler to execute the bulk action
   */
  const handleConfirm = () => {
    if (!popupData) return;

    const { actionType } = popupData;

    switch (actionType) {
      case "like":
        handleBulkLike(true);
        break;
      case "unlike":
        handleBulkLike(false);
        break;
      case "hide":
        handleBulkHide(true);
        break;
      case "show":
        handleBulkHide(false);
        break;
      case "skip":
        handleBulkSkip(true);
        break;
      case "moveToTodo":
        handleBulkSkip(false);
        break;
      default:
        break;
    }

    setPopupData(null); // Close the popup after action
  };

  /**
   * Cancel handler to close the popup without action
   */
  const handleCancel = () => {
    setPopupData(null);
  };

  if (!openedItemId && selectedCount === 0) {
    return null; // Don't show the bar if no items are selected
  }

  const isLiked =
    ((openedItem as Comment)?.liked?.status == true ||
      (openedItem as Comment)?.liked?.transition == "liking") &&
    (openedItem as Comment)?.liked?.transition != "unliking";

  const isHidden =
    ((openedItem as Comment)?.hidden?.status == true ||
      (openedItem as Comment)?.hidden?.transition == "hiding") &&
    (openedItem as Comment)?.hidden?.transition != "showing";

  return (
    <div className="relative mb-4 flex items-center justify-between">
      {/* Selected Items Count */}
      <span className="flex items-center text-sm font-semibold text-primary">
        <div className="mr-2 flex size-8 items-center justify-center rounded-full bg-white font-extrabold">
          {selectedCount || "1"}
        </div>
        {t(
          `tasks.bulk_actions_bar.${selectedCount <= 1 ? "item_count.one" : "item_count.other"}`,
          { count: selectedCount || 1 },
        )}
      </span>
      {/* Action Buttons */}
      <div className="flex items-center space-x-5">
        <div className="flex items-center space-x-2">
          {/* Like/Unlike Button */}
          {likeSelectedItems &&
            (openedItem?.channel?.type == "facebook" ||
              openedItem?.channel?.type == "tiktok" ||
              openedItem?.channel?.type == "tiktok-ads") && (
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <span
                      onClick={async (e) => {
                        return !isBulkActionLoading?.length ||
                          !openedItemId ||
                          (openedItemId &&
                            !isBulkActionLoading?.includes(openedItemId))
                          ? selectedItemIds.length
                            ? initiateBulkLike(e)
                            : await handleBulkLike(!isLiked)
                          : undefined;
                      }}
                      role="button"
                      aria-disabled={
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? true
                          : false
                      }
                      className={`flex size-8 items-center justify-center rounded-full bg-white transition-colors hover:brightness-90 ${
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                    >
                      {isBulkActionLoading?.includes(openedItemId as string) ||
                      likeIsLoading ? (
                        <Loader className="size-3 shrink-0 animate-spin stroke-dark-grey stroke-2" />
                      ) : isLiked ? (
                        <UnlikeWhiteButton className="size-8" />
                      ) : (
                        <LikeWhiteButton className="size-8" />
                      )}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent
                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                    sideOffset={5}
                  >
                    {t(`tasks.bulk_actions_bar.${isLiked ? "unlike" : "like"}`)}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}

          {/* Hide/Show Button */}
          {hideSelectedItems && (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <span
                    onClick={async (e) => {
                      return !isBulkActionLoading?.length ||
                        !openedItemId ||
                        (openedItemId &&
                          !isBulkActionLoading?.includes(openedItemId))
                        ? selectedItemIds.length
                          ? initiateBulkHide(e)
                          : await handleBulkHide(!isHidden)
                        : undefined;
                    }}
                    role="button"
                    aria-disabled={
                      openedItemId &&
                      isBulkActionLoading?.includes(openedItemId)
                        ? true
                        : false
                    }
                    className={`flex size-8 items-center justify-center rounded-full bg-white transition-colors hover:brightness-90 ${
                      openedItemId &&
                      isBulkActionLoading?.includes(openedItemId)
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                  >
                    {isBulkActionLoading?.includes(openedItemId as string) ||
                    hideIsLoading ? (
                      <Loader className="size-3 shrink-0 animate-spin stroke-dark-grey stroke-2" />
                    ) : isHidden ? (
                      <ShowWhiteButton className="size-8" />
                    ) : (
                      <HideWhiteButton className="size-8" />
                    )}
                  </span>
                </TooltipTrigger>
                <TooltipContent
                  className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                  sideOffset={5}
                >
                  <p>
                    {t(`tasks.bulk_actions_bar.${isHidden ? "show" : "hide"}`)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        {/* Skip/Move to To-Do Button */}
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <span
                  onClick={(e) => {
                    return !isBulkActionLoading?.length ||
                      !openedItemId ||
                      (openedItemId &&
                        !isBulkActionLoading?.includes(openedItemId))
                      ? selectedItemIds.length
                        ? initiateBulkSkip(e)
                        : handleBulkSkip(
                            !openedItem?.skipped?.status &&
                              openedItem?.status != "done",
                          )
                      : undefined;
                  }}
                  role="button"
                  aria-disabled={
                    openedItemId && isBulkActionLoading?.includes(openedItemId)
                      ? true
                      : false
                  }
                  className={`flex h-8 items-center justify-center rounded-[28px] bg-white px-4 py-1 text-xs font-semibold text-primary transition-colors hover:brightness-90 ${
                    openedItemId && isBulkActionLoading?.includes(openedItemId)
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                >
                  {t(
                    `tasks.bulk_actions_bar.${openedItem?.skipped?.status || openedItem?.status === "done" ? "move_to_todo" : "skip"}`,
                  )}
                </span>
              </TooltipTrigger>
              <TooltipContent
                className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                sideOffset={5}
              >
                <p>
                  <p>
                    {t(
                      `tasks.bulk_actions_bar.${openedItem?.skipped?.status || openedItem?.status === "done" ? "move_to_todo" : "skip"}`,
                    )}
                  </p>
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          {selectedItemIds.length > 1 && (
            <>
              {/* Previous Button */}
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <span
                      onClick={() => {
                        if (
                          !isBulkActionLoading?.length ||
                          !openedItemId ||
                          (openedItemId &&
                            !isBulkActionLoading?.includes(openedItemId))
                        )
                          previousItem();
                      }}
                      role="button"
                      aria-disabled={
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? true
                          : false
                      }
                      className={`flex size-8 items-center justify-center rounded-full bg-white transition-colors hover:brightness-90 ${
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                    >
                      <PreviousButton className="size-8" />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent
                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                    sideOffset={5}
                  >
                    <p>{t("tasks.bulk_actions_bar.previous")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              {/* Next Button */}
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <span
                      onClick={() => {
                        if (
                          !isBulkActionLoading?.length ||
                          !openedItemId ||
                          (openedItemId &&
                            !isBulkActionLoading?.includes(openedItemId))
                        )
                          nextItem();
                      }}
                      role="button"
                      aria-disabled={
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? true
                          : false
                      }
                      className={`flex size-8 items-center justify-center rounded-full bg-white transition-colors hover:brightness-90 ${
                        openedItemId &&
                        isBulkActionLoading?.includes(openedItemId)
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                    >
                      <NextButton className="size-8" />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent
                    className="rounded-md bg-primary/90 px-2 py-1 text-xs font-medium text-white"
                    sideOffset={5}
                  >
                    <p>{t("tasks.bulk_actions_bar.next")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
        </div>
      </div>
      {/* Bulk Action Popup */}
      {popupData && (
        <BulkActionPopup
          type={type}
          icon={popupData.icon}
          message={popupData.message}
          selectedCount={selectedCount}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          position={popupData.position}
        />
      )}
    </div>
  );
};
