// WebSocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspaceContext } from "./workspace-provider/useWorkspaceContext";

interface WebSocketContextValue {
  socket: Socket | null;
}

const WebSocketContext = createContext<WebSocketContextValue>({ socket: null });

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { currentWorkspace } = useWorkspaceContext();

  useEffect(() => {
    let newSocket: Socket | null = null;

    // If no workspace is selected, disconnect any existing socket & do nothing
    if (!currentWorkspace?.workspace_id) {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      return;
    }

    // Otherwise, connect to the new workspace
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        newSocket = io(import.meta.env.VITE_BACKEND_SERVER_URL, {
          query: {
            workspace_id: currentWorkspace.workspace_id,
            token: token,
          },
          transports: ["websocket"],
        });

        newSocket.on("connect", () => {
          console.info("[WebSocketProvider] connected:", newSocket?.id);
        });

        newSocket.on("disconnect", () => {
          console.info("[WebSocketProvider] disconnected");
        });

        newSocket.on("connect_error", (err) => {
          console.error("[WebSocketProvider] connection error:", err);
        });

        setSocket(newSocket);
      } catch (err) {
        console.error("[WebSocketProvider] error initializing socket:", err);
      }
    })();

    // Cleanup: disconnect old/new socket on unmount or before re-run
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [
    getAccessTokenSilently,
    currentWorkspace?.workspace_id, // re-run when workspace changes
  ]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export function useWebSocket() {
  return useContext(WebSocketContext);
}
