// alertUtils.ts
export const ALERT_INTERVAL = 60 * 60 * 1000; // 1 hour in milliseconds

/**
 * Generates a unique key for storing alert timestamps based on workspace and alert type.
 * @param workspaceId - The ID of the workspace.
 * @param alertType - The type of alert (e.g., 'channels').
 * @returns A unique string key.
 */
export const getAlertKey = (workspaceId: string, alertType: string): string => {
  return `${alertType}AlertLastShown_${workspaceId}`;
};

/**
 * Retrieves the last shown timestamp for a specific alert and workspace.
 * @param workspaceId - The ID of the workspace.
 * @param alertType - The type of alert.
 * @returns The timestamp in milliseconds or null if not found.
 */
export const getLastShown = (
  workspaceId: string,
  alertType: string,
): number | null => {
  const key = getAlertKey(workspaceId, alertType);
  const timestamp = localStorage.getItem(key);
  return timestamp ? parseInt(timestamp, 10) : null;
};

/**
 * Sets the current timestamp as the last shown time for a specific alert and workspace.
 * @param workspaceId - The ID of the workspace.
 * @param alertType - The type of alert.
 */
export const setLastShown = (workspaceId: string, alertType: string): void => {
  const key = getAlertKey(workspaceId, alertType);
  localStorage.setItem(key, Date.now().toString());
};

/**
 * Determines whether an alert should be shown based on the last shown timestamp and interval.
 * @param workspaceId - The ID of the workspace.
 * @param alertType - The type of alert.
 * @param interval - The minimum interval between alert displays in milliseconds.
 * @returns True if the alert should be shown, false otherwise.
 */
export const shouldShowAlert = (
  workspaceId: string,
  alertType: string,
  interval: number = ALERT_INTERVAL,
): boolean => {
  const lastShown = getLastShown(workspaceId, alertType);
  if (!lastShown) return true;
  const now = Date.now();
  return now - lastShown > interval;
};
