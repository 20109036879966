import { useState } from "react";
import useSWR from "swr";
import { useFetcher } from "@axios/fetcher";
import { Channel } from "@/types"; // Assuming Channel type is defined somewhere

interface UseWorkspaceChannelsParams {
  workspace_id: string;
  enabled?: boolean; // To control whether the hook is enabled
}

function useWorkspaceChannels({
  workspace_id,
  enabled = true,
}: UseWorkspaceChannelsParams) {
  const { fetcher } = useFetcher();
  const [isRefetching, setIsRefetching] = useState(false); // New refetch loading state

  // Cache key for fetching channels
  const cacheKey = `/api/channels/${workspace_id}`;

  // Use SWR for data fetching
  const { data, error, mutate, isValidating, isLoading } = useSWR<Channel[]>(
    enabled ? cacheKey : null, // Fetch only if enabled
    (url: string) => fetcher(url, "get", null, false, enabled),
    { revalidateOnFocus: false }, // Disable automatic revalidation on focus if not desired
  );

  // Refetch function for manual revalidation, ensures data is always returned
  const refetch = async (): Promise<Channel[]> => {
    setIsRefetching(true);
    const updatedData = await mutate(); // Try to get updated data
    setIsRefetching(false);

    // If no updated data, return the current cached data
    if (!updatedData) {
      if (data) {
        return data; // Return cached data
      } else {
        throw new Error("No data available after refetching.");
      }
    }

    return updatedData; // Return the updated data from mutate
  };

  return {
    data: data as Channel[], // Cast data to an array of Channel type
    isLoading: (enabled && !data && !error && !isValidating) || isLoading,
    isRefetching, // Refetch loading state
    error, // Capture any errors from the API call
    refetch, // Manual refetch function
  };
}

export { useWorkspaceChannels };
