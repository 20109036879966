// src/containers/settings/channel-settings/ChannelsSettings.tsx
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useWorkspaceContext } from "@/layouts/workspace-provider/useWorkspaceContext";
import { useNavBar } from "@/layouts/NavBar.context";
import ChannelDetails from "./ChannelDetails";
import { Img } from "@/components/Img";
import { SelectedIcon, SelectIcon } from "@/assets/icons";
import { Channel } from "@/types";
import { CHANNELS_CONFIG } from "@/types/channel.config";
import { Label } from "@/components/ui/label";

const ChannelsSettings: React.FC = () => {
  const { t } = useTranslation();
  const { channels } = useWorkspaceContext();
  const { setAddChannelPopupIsOpen } = useNavBar();
  const { channelId } = useParams<{ channelId?: string }>();
  const navigate = useNavigate();

  // Local state for active channel
  const [activeChannelId, setActiveChannelId] = useState<string | null>(null);

  // When the route parameter changes, update the active channel id.
  // If none exists, default to the first channel (and update the URL).
  useEffect(() => {
    if (channelId) {
      setActiveChannelId(channelId);
    } else if (channels && channels.length > 0) {
      // If no channelId is provided, navigate to the first channel.
      navigate(`/settings/channels/${channels[0].channel_id}`, {
        replace: true,
      });
    }
  }, [channelId, channels, navigate]);

  const activeChannel = channels?.find(
    (ch) => ch.channel_id === activeChannelId,
  );

  // When a channel is selected, update the URL so that the route parameter changes.
  const handleSelectChannel = (channel: Channel) => {
    // If the channel is not connected, open the add-channel popup
    if (!channel.is_connected) {
      setAddChannelPopupIsOpen(true);
      return;
    }
    // Otherwise, update the URL
    navigate(`/settings/channels/${channel.channel_id}`);
  };

  // If no channels exist, display a fallback
  if (!channels || channels.length === 0) {
    return (
      <div className="flex h-full overflow-hidden bg-light-grey">
        <div className="flex w-full max-w-fit flex-col rounded-[12px] bg-white p-8">
          <div>
            <div className="mb-2 flex items-center justify-between">
              <h2 className="text-base font-semibold text-primary">
                {t("settings.channels.title")}
              </h2>
            </div>
            <p className="mb-10 max-w-[60%] text-left text-sm font-semibold text-dark-grey">
              {t("settings.channels.description")}
            </p>

            <button
              onClick={() => setAddChannelPopupIsOpen(true)}
              className="flex items-center justify-center rounded-[10px] bg-light-violet px-3.5 py-2.5 text-xs font-semibold text-primary hover:brightness-110"
            >
              <span>
                {t("navbar.channel_section.add_channel")}
                <span className="ml-1 text-xs font-semibold text-primary">
                  +
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full overflow-hidden bg-light-grey">
      {/* Left Column: Channels List */}
      <div className="flex h-full max-w-fit flex-col rounded-[12px] bg-white p-8">
        <div>
          <div className="mb-2 flex items-center justify-between">
            <h2 className="text-base font-semibold text-primary">
              {t("settings.channels.title")}
            </h2>
          </div>
          <p className="mb-10 max-w-[60%] text-left text-sm font-semibold text-dark-grey">
            {t("settings.channels.description")}
          </p>

          <Label
            htmlFor="channel-name"
            className="mb-4 block text-sm font-semibold text-primary"
          >
            {t("settings.channels.Channels")}
          </Label>
        </div>

        {/* Render each channel as a row */}
        <div className="mt-2 flex grow flex-col overflow-y-auto">
          {channels.map((channel) => {
            const isActive = channel.channel_id === activeChannelId;
            const channelConfig = CHANNELS_CONFIG[channel.type];
            return (
              <div
                key={channel.channel_id}
                onClick={() => handleSelectChannel(channel)}
                className={`mb-2 flex cursor-pointer items-center rounded-[12px] px-5 py-3 text-sm font-bold text-primary hover:brightness-95 ${
                  isActive ? "bg-light-grey" : ""
                }`}
              >
                {/* Check/Select icon */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectChannel(channel);
                  }}
                  className="brightness-90 hover:brightness-50 focus-visible:outline-none"
                  style={{ minWidth: "20px", minHeight: "20px" }}
                >
                  {isActive ? <SelectedIcon /> : <SelectIcon />}
                </button>

                {/* Profile Picture & Channel Info */}
                <div className="flex items-center">
                  <div className="relative size-[36px] shrink-0">
                    <Img
                      src={
                        channel.is_connected
                          ? channel.profile_picture_url
                          : channelConfig.icon
                      }
                      alt={channel.name || channel.type}
                      className={`rounded-full border bg-white object-cover ${
                        channel.is_connected
                          ? "size-[36px] border-light-grey"
                          : "size-[36px] border-2 border-red"
                      }`}
                    />
                    {channel.is_connected && (
                      <Img
                        src={channelConfig.icon}
                        alt={`${channel.type}-icon`}
                        className="absolute bottom-0 right-[-3px] size-[16px] rounded-full bg-white object-contain shadow-[0_0_0_2px_theme('colors.light-grey')]"
                      />
                    )}
                  </div>

                  <div className="ml-3.5 flex flex-col">
                    <span
                      className={`truncate text-xs font-extrabold ${
                        channel.is_connected ? "text-primary" : "text-red"
                      }`}
                    >
                      {channel.is_connected
                        ? channel.username || channel.name
                        : t(channelConfig.name)}
                    </span>

                    <span
                      className={`mt-0.5 truncate text-xs ${channel.is_connected ? "capitalize text-dark-grey" : "text-red"}`}
                    >
                      {channel.is_connected
                        ? t(channelConfig.name)
                        : t("connect_channel.channel_disconnected")}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Right Column: Channel Details */}
      <div className="ml-4 flex-1 overflow-y-auto rounded-[12px] bg-white p-8">
        {activeChannel ? (
          <ChannelDetails channel={activeChannel} />
        ) : (
          <div className="text-sm text-dark-grey">
            {t("settings.channels.select_channel")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelsSettings;
