import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { CommentWidgetIcon, iconAction } from "@/assets/icons"; // Use iconAction for the card and CommentWidgetIcon for metrics

interface ActionMetricsProps {
  likedComments: number;
  hiddenComments: number;
  skippedComments: number;
  repliedComments: number;
}

export function ActionMetrics({
  likedComments,
  hiddenComments,
  skippedComments,
  repliedComments,
}: ActionMetricsProps) {
  const { t } = useTranslation();

  // Helper function to dynamically set icon color
  const getIconColor = (metricType: string) => {
    switch (metricType) {
      case "liked":
        return "#EF4444"; // Red for liked comments
      case "hidden":
        return "#BBBFC5"; // Grey for hidden comments
      case "skipped":
        return "#1E1E1E"; // Dark for skipped comments
      case "replied":
        return "#375DFB"; // Blue for replied comments
      default:
        return "#1E1E1E"; // Default to dark
    }
  };

  // Reusable render function for metric items
  const renderMetric = (value: number, label: string, metricType: string) => (
    <div className="flex flex-1 items-start space-x-4">
      {/* Metric Icon */}
      <div className="shrink-0">
        <CommentWidgetIcon
          className="mt-1.5 size-6"
          style={{ fill: getIconColor(metricType) }}
        />
      </div>

      {/* Metric Value and Label */}
      <div className="flex flex-col">
        <span className="font-sans text-3xl font-extrabold text-primary">
          {value.toLocaleString()}
        </span>

        {/* Wrapping label for two lines */}
        <span className="mt-3 max-w-[120px] font-sans text-sm font-semibold leading-tight text-primary">
          {label}
        </span>
      </div>
    </div>
  );

  return (
    <Card
      title={t("dashboard.action_metrics.title")}
      icon={iconAction}
      className="min-h-[420px]"
    >
      {/* Top Horizontal Separator */}
      <div className="my-8 mt-10 border-t border-dashed border-light-grey"></div>

      {/* Metrics Row with Responsive Spacing */}
      <div className="flex w-full gap-3">
        {renderMetric(
          likedComments,
          t("dashboard.action_metrics.liked_comments"),
          "liked",
        )}
        <div className="mx-8 h-auto border-l border-dashed border-light-grey"></div>

        {renderMetric(
          hiddenComments,
          t("dashboard.action_metrics.hidden_comments"),
          "hidden",
        )}
      </div>

      {/* Big Horizontal Separator */}
      <div className="my-11 border-t border-dashed border-light-grey"></div>

      {/* Bottom Row with Responsive Spacing */}
      <div className="flex w-full gap-3">
        {renderMetric(
          skippedComments,
          t("dashboard.action_metrics.skipped_comments"),
          "skipped",
        )}
        <div className="mx-8 h-auto border-l border-dashed border-light-grey"></div>
        {renderMetric(
          repliedComments,
          t("dashboard.action_metrics.replied_comments"),
          "replied",
        )}
      </div>
    </Card>
  );
}
