// src/containers/settings/channel-settings/ChannelDetails.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { UnlinkWhiteIcon } from "@/assets/icons"; // Adjust the import if needed
import { Channel } from "@/types";
import RemoveChannelDialog from "./RemoveChannelDialog";
import { Img } from "@/components/Img";
import { CHANNELS_CONFIG } from "@/types/channel.config";
import { useUpdateActiveAdAccounts } from "@/axios/mutations/useUpdateActiveAdAccounts";

interface ChannelDetailsProps {
  channel: Channel;
}

const ChannelDetails: React.FC<ChannelDetailsProps> = ({ channel }) => {
  const { t } = useTranslation();
  const channelConfig = CHANNELS_CONFIG[channel.type];

  // Build title using a translation key, for example "Parametre {{channel}}"
  const title = t("settings.channels.parameter_title", {
    channel: t(channelConfig.name),
  });

  // Get the list of ad accounts from the channel
  const adAccounts = channel.ads_accounts || [];

  // Initialize active ad accounts from channel.settings.activeAdAccounts mapping.
  // Our mapping key is the ad_account_id.
  const initialActive = channel.settings?.activeAdAccounts || {};
  const [activeAdAccounts, setActiveAdAccounts] = useState<{
    [id: string]: boolean;
  }>(() => {
    const mapping: { [id: string]: boolean } = {};
    adAccounts.forEach((acc) => {
      mapping[acc.ad_account_id] = initialActive[acc.ad_account_id] ?? false;
    });
    return mapping;
  });

  const { updateActiveAdAccounts } = useUpdateActiveAdAccounts();

  const toggleSwitch = async (accountId: string) => {
    const newValue = !activeAdAccounts[accountId];

    setActiveAdAccounts((prev) => ({
      ...prev,
      [accountId]: newValue,
    }));

    try {
      await updateActiveAdAccounts({
        workspace_id: channel.workspace_id,
        channel_id: channel.channel_id,
        activeAdAccounts: { ...activeAdAccounts, [accountId]: newValue },
      });
    } catch (error) {
      // Optionally revert local state change or show an error message.
    }
  };

  // State for remove channel confirmation dialog.
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  return (
    <div className="flex h-full flex-col">
      {/* Title */}
      <h3 className="mb-4 text-base font-semibold text-primary">{title}</h3>

      {/* Light grey info box showing channel profile info */}
      <div className="mb-8 flex items-center rounded-md bg-light-grey p-4">
        <div className="relative size-9 shrink-0">
          <Img
            src={
              channel.is_connected
                ? channel.profile_picture_url
                : channelConfig.icon
            }
            alt={channel.name || channel.type}
            className={`rounded-full border bg-white object-contain ${
              channel.is_connected
                ? "size-9 border-light-grey"
                : "size-9 border-2 border-red"
            }`}
          />
          {channel.is_connected && (
            <Img
              src={channelConfig.icon}
              alt={`${channel.type}-icon`}
              className="absolute bottom-0 right-[-3px] size-4 rounded-full bg-white object-contain shadow-[0_0_0_2px_theme('colors.light-grey')]"
            />
          )}
        </div>
        <div className="ml-3.5 flex flex-col">
          <span
            className={`truncate text-xs font-extrabold ${
              channel.is_connected ? "text-primary" : "text-red"
            }`}
          >
            {channel.is_connected
              ? channel.username || channel.name
              : t("navbar.channel.reconnect")}
          </span>
          <span className="mt-0.5 truncate text-xs capitalize text-dark-grey">
            {t(channelConfig.name)}
          </span>
        </div>
      </div>

      {/* Ad Accounts Section */}
      {/* TODO: Remove false here when ad settings ready */}
      {false && adAccounts.length > 0 && (
        <>
          <Label
            htmlFor="channel-name"
            className="mb-4 block text-sm font-semibold text-primary"
          >
            {t("settings.channels.ad_accounts")}
          </Label>

          <div className="space-y-4">
            {adAccounts.map((acc) => (
              <div key={acc.ad_account_id} className="flex items-center">
                <Switch
                  checked={activeAdAccounts[acc.ad_account_id] || false}
                  onCheckedChange={() => toggleSwitch(acc.ad_account_id)}
                  className="data-[state=checked]:bg-violet"
                />
                <div className="ml-3">
                  <div className="text-sm font-semibold text-primary">
                    {acc.name}
                  </div>
                  <div className="text-xs text-dark-grey">
                    {t("settings.channels.account_id", {
                      id: acc.ad_account_id?.replace("act_", ""),
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {/* Bottom "Disconnect" Button at Bottom Left */}
      <div className="mt-auto flex justify-start">
        {channel.is_connected && (
          <Button
            variant="destructive"
            onClick={() => setIsRemoveDialogOpen(true)}
            className="flex items-center bg-red text-white hover:bg-red hover:brightness-90"
          >
            <UnlinkWhiteIcon className="mr-2 size-4" />
            {t("navbar.channel.disconnect")}
          </Button>
        )}
      </div>

      {/* Remove Channel Dialog */}
      <RemoveChannelDialog
        isOpen={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        channel={channel}
      />
    </div>
  );
};

export default ChannelDetails;
